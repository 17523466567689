// push one field to current location search

import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

function usePushLocationSearch() {
  const location = useLocation();
  const history = useHistory();

  function handlePushLocationSearch(
    data: Record<string, string | number | string[] | number[] | undefined>
  ) {
    const locationSearch = qs.parse(location.search.slice(1));
    history.push({
      search: `?${qs.stringify({ ...locationSearch, ...data }, { indices: false })}`,
    });
  }

  return handlePushLocationSearch;
}

export default usePushLocationSearch;
