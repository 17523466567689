import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropzone } from 'views/components/compound';

type Props = {
  handleChangeUploadFile: (file: any) => void;
  fileTypeError: string;
  handleFileTypeError: (fileTypeError: string) => void;
};

export const QuestionFormUploadFile: FC<Props> = ({
  handleChangeUploadFile,
  fileTypeError,
  handleFileTypeError,
}) => {
  const { t } = useTranslation();

  return (
    <DropzoneWrapper>
      <Dropzone
        onChange={handleChangeUploadFile}
        contentType="videos_images_pdfs"
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
      <CautionWrapper>
        <p>{t('admin.questions.messages.updateContentCaution1')}</p>
        <p>{t('admin.questions.messages.updateContentCaution2')}</p>
      </CautionWrapper>
    </DropzoneWrapper>
  );
};

// styled
const DropzoneWrapper = styled('div')({
  marginTop: 15,
});

const CautionWrapper = styled('div')({
  marginTop: 10,
  p: {
    fontSize: '0.75rem',
    color: palette.grayMedium4,
    lineHeight: 1.5,
  },
});
