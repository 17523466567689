import {
  CreateHearingLinkData,
  GetLinksByContentTypeParams,
  HearingLinksByContent,
  ProductListParams,
} from 'types/HearingLink';
import { observable, action } from 'mobx';
import { routes, hearingLinkStatus } from 'utility/constants';
import History from 'utility/history';
import i18n from 'i18n';
import {
  HearingLink,
  HearingLinkItem,
  LinkInfoOption,
  ViewingLog,
  ViewSettingOption,
} from 'types/HearingLink';
import { SharingLinkResult } from 'types/HearingLink';
import { MessageProps } from 'types/App';
import { ListMeta } from 'types/common';
import { Client } from 'types/Client';
import { RootStore } from 'stores/index';
import { defaultHearingLink, defaultListMeta } from 'stores/HearingLinkStore/constants';
import HearingLinkApi from 'services/HearingLinkApi';
import ClientApi from 'services/ClientApi';
import { searchByTitle, trimSpace } from 'utility/helpers';
import UserAnswerApi from 'services/UserAnswerApi';
import PlaylistApi from 'services/Admin/PlaylistApi';
import ProductContentApi from 'services/ProductContentApi';
import HearingSetApi from 'services/HearingSetApi';
import { HearingItem } from 'types/HearingSet';
import { PlaylistItem } from 'types/Playlist';
import { ProductItem } from 'types/Product';

class HearingLinkStore {
  private readonly hearingLinkApi: HearingLinkApi;
  private readonly userAnswerApi: UserAnswerApi;
  private readonly clientApi: ClientApi;
  private readonly hearingSetApi: HearingSetApi;
  private readonly productContentApi: ProductContentApi;
  private readonly playlistApi: PlaylistApi;
  public readonly defaultLoadingContentNumber = 50;

  @observable public loading = false;
  @observable public submitting = false;
  @observable public error: any = {};
  @observable public linkListMeta: ListMeta = { ...defaultListMeta };
  @observable public clientList: Client[] = [];
  @observable public hearingLinkDetail: HearingLink = { ...defaultHearingLink };
  @observable public rootStore: RootStore;
  @observable public hearingLinkArchives: HearingLinkItem[] = [];
  @observable public isEditingHearingDetail = false;
  @observable public hearingLinkResults: SharingLinkResult[] = [];
  @observable public linkViewLogs: ViewingLog[] = [];
  //For content_type product_contents link list:
  @observable public loadingProductLinkList = false;
  @observable public linkTypeProductList: HearingLinksByContent[] = [];
  @observable public linkTypeProductListMeta: ListMeta = { ...defaultListMeta };
  //For content_type hearing_set link list:
  @observable public loadingQuestionnaireLinkList = false;
  @observable public linkTypeQuestionnaireList: HearingLinksByContent[] = [];
  @observable public linkTypeQuestionnaireListMeta: ListMeta = { ...defaultListMeta };
  //For content_type playlist link list:
  @observable public loadingPlaylistLinkList = false;
  @observable public linkTypePlaylistList: HearingLinksByContent[] = [];
  @observable public linkTypePlaylistListMeta: ListMeta = { ...defaultListMeta };
  //For Create Link drawer with content selector:
  @observable public loadingQuestionnaireList = false;
  @observable public questionnaireList: HearingItem[] = [];
  @observable public questionnaireListConst: HearingItem[] = [];
  @observable public loadingFileList = false;
  @observable public fileList: ProductItem[] = [];
  @observable public loadingPlaylistList = false;
  @observable public playlistList: PlaylistItem[] = [];

  constructor({
    rootStore,
    hearingLinkApi,
    userAnswerApi,
    clientApi,
    hearingSetApi,
    productContentApi,
    playlistApi,
  }: {
    rootStore: RootStore;
    hearingLinkApi: HearingLinkApi;
    userAnswerApi: UserAnswerApi;
    clientApi: ClientApi;
    hearingSetApi: HearingSetApi;
    productContentApi: ProductContentApi;
    playlistApi: PlaylistApi;
  }) {
    this.rootStore = rootStore;
    this.hearingLinkApi = hearingLinkApi;
    this.userAnswerApi = userAnswerApi;
    this.clientApi = clientApi;
    this.productContentApi = productContentApi;
    this.hearingSetApi = hearingSetApi;
    this.playlistApi = playlistApi;
  }

  @action.bound
  public setIsEditingHearingDetail(isEditingHearingDetail: boolean) {
    this.isEditingHearingDetail = isEditingHearingDetail;
  }

  @action.bound
  public handleChangeNoteResult(sessionId: string, answerId: number, note: string) {
    this.hearingLinkResults = this.hearingLinkResults.map(session =>
      session.id === sessionId
        ? {
            ...session,
            sharing_link_result: {
              ...session.sharing_link_result,
              answered_questions: session.sharing_link_result.answered_questions.map(answer =>
                answer.id === answerId
                  ? {
                      ...answer,
                      note,
                    }
                  : answer
              ),
              disabledSave: false,
            },
          }
        : session
    );
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getClientList() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.clientApi.getClients({ organization_id });
      this.clientList = data.clients;
    } catch (error) {
      this.error = error;
    }
  }

  @action.bound
  public async getHearingSetList() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.loadingQuestionnaireList = true;
    try {
      const { data } = await this.hearingSetApi.getHearingSets(organization_id);
      this.questionnaireListConst = data.hearing_sets;
      this.questionnaireList = data.hearing_sets;
    } catch (error) {
      this.error = error;
    } finally {
      this.loadingQuestionnaireList = false;
    }
  }

  @action.bound
  public handleSearchHearing(value: string) {
    this.questionnaireList = searchByTitle<HearingItem>(this.questionnaireListConst, value);
  }

  @action.bound
  public async getFileList({
    q = '',
    types = [],
    tags = [],
    page = 1,
    concat = false,
  }: ProductListParams) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.loadingFileList = true;
    try {
      const perPage =
        q.length > 0 || tags.length > 0 ? undefined : this.defaultLoadingContentNumber;
      const { data } = await this.productContentApi.getProductContents(organization_id, {
        page,
        per_page: perPage,
        q,
        types,
        tags,
        sort: 'desc',
      });
      this.fileList = concat ? this.fileList.concat(data.product_contents) : data.product_contents;
      return { ...data, perPage };
    } catch (error: any) {
      const content = error?.message || 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loadingFileList = false;
    }
  }

  @action.bound
  public async getPlaylistList(key: string) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.loadingPlaylistList = true;
    try {
      const { data } = await this.playlistApi.getPublishedPlaylists({
        page: 1,
        per_page: 50,
        order: 'updated_at',
        sort: 'desc',
        organization_id,
        key,
      });
      this.playlistList = data.content_playlists;
    } catch (error: any) {
      this.error = error;
    } finally {
      this.loadingPlaylistList = false;
    }
  }

  @action.bound
  public async handleCreateNewHearingLink(createHearingLinkData: CreateHearingLinkData) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      this.loading = true;
      const { data } = await this.hearingLinkApi.createHearingLink(
        createHearingLinkData,
        organization_id
      );
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.createHearingLinkSuccess'),
        status: 'success',
      });
      History.push(`${routes.hearingLink}/${data.id}`);
    } catch (error) {
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.createHearingLinkFail'),
        status: 'error',
      });
    } finally {
      this.loading = false;
      this.submitting = false;
    }
  }

  @action.bound
  public async getHearingLinkDetail(id: string) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.loading = true;
      const { data } = await this.hearingLinkApi.getHearingLinkById(id, organization_id);
      this.hearingLinkDetail = { ...data, linkType: 'hearing' };
      this.hearingLinkDetail.client_name = data.client?.name || null;
      this.getClientList();
    } catch (error: any) {
      if (error?.message.includes('404')) {
        this.putFlashMessages({
          content: i18n.t('sharingLink.messages.notFoundHearingLink'),
          status: 'error',
        });
      }
      History.push(routes.hearingLink);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async getHearingLinkResults(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingLinkApi.getHearingLinkResults(id, organization_id);
      this.hearingLinkResults = data.sessions;
    } catch (error) {
      this.error = error;
    }
  }

  @action.bound
  public async updateStatusHearingLink(id: number, status: string) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      await this.hearingLinkApi.updateHearingLinkStatus({
        ids: [id],
        status,
        organization_id,
      });
      this.hearingLinkDetail = { ...this.hearingLinkDetail, status };
      if (status === hearingLinkStatus.archived) {
        this.putFlashMessages({
          content: i18n.t('admin.common.messages.archiveSuccess', {
            item: i18n.t('common.actionTarget.links'),
          }),
          status: 'success',
        });
      } else {
        this.putFlashMessages({
          content: i18n.t('admin.productContent.messages.changeStatusSuccess'),
          status: 'success',
        });
      }
    } catch (error: any) {
      if (error?.message.includes('403')) {
        this.putFlashMessages({
          content:
            this.hearingLinkDetail.content_type === 'hearing_set'
              ? i18n.t('sharingLink.noPublicHearing')
              : this.hearingLinkDetail.content_type === 'product_contents'
              ? i18n.t('sharingLink.noPublicProductContent')
              : i18n.t('sharingLink.noPublicPlaylist'),
          status: 'error',
        });
        return;
      }
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    }
  }

  @action.bound
  public async saveLinkInfo(id: string, option: LinkInfoOption) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingLinkApi.updateHearingLinkById({
        id,
        option,
        organization_id,
      });
      this.hearingLinkDetail = { ...this.hearingLinkDetail, ...data };
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkSuccess'),
        status: 'success',
      });
      this.setIsEditingHearingDetail(false);
    } catch (error) {
      this.error = error;
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkFail'),
        status: 'error',
      });
    }
  }

  @action.bound
  public async saveViewSetting(id: string, viewSetting: ViewSettingOption) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingLinkApi.updateHearingLinkById({
        id,
        option: viewSetting,
        organization_id,
      });
      this.hearingLinkDetail = { ...this.hearingLinkDetail, ...data };

      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.error = error;
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkFail'),
        status: 'error',
      });
    }
  }

  @action.bound
  public resetHearingLink() {
    this.hearingLinkDetail = { ...defaultHearingLink };
    this.setIsEditingHearingDetail(false);
    this.hearingLinkResults = [];
    this.linkViewLogs = [];
  }

  @action.bound
  public resetClientList() {
    this.clientList = [];
  }

  @action.bound
  public resetContentList() {
    this.fileList = [];
    this.questionnaireList = [];
    this.questionnaireListConst = [];
    this.clientList = [];
  }

  @action.bound
  public resetHearingLinkList() {
    this.linkTypePlaylistList = [];
    this.linkTypeProductList = [];
    this.linkTypeQuestionnaireList = [];
    this.linkTypePlaylistListMeta = { ...defaultListMeta };
    this.linkTypeProductListMeta = { ...defaultListMeta };
    this.linkTypeQuestionnaireListMeta = { ...defaultListMeta };
  }

  @action.bound
  public async getHearingLinkArchive(page = 1, order = 'updated_at', sort = 'desc') {
    this.loading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingLinkApi.getArchivedHearingLinks({
        page,
        order,
        sort,
        per_page: 20,
        organization_id,
      });

      this.hearingLinkArchives = data.hearing_links;
      this.linkListMeta = {
        ...this.linkListMeta,
        page: data.page,
        sort,
        order,
        total: data.total,
      };
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async restoreHearingLink(id?: number, isDetail?: boolean) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const listId = id ? [id] : [];
      await this.hearingLinkApi.updateHearingLinkStatus({
        ids: listId,
        status: 'inactive',
        organization_id,
      });

      if (!isDetail) {
        const { page, sort, order } = this.linkListMeta;
        this.getHearingLinkArchive(page, order, sort);
      } else {
        if (id) {
          this.getHearingLinkDetail(id.toString());
        }
      }

      this.putFlashMessages({
        content: i18n.t('admin.common.messages.restoreSuccess', {
          item: i18n.t('common.actionTarget.links'),
        }),
        status: 'success',
      });
    } catch (error) {
      this.error = error;
    }
  }

  @action.bound
  public async submitSaveNote(sessionId: string) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const targetResult = this.hearingLinkResults.find(session => session.id === sessionId);

      if (!targetResult) return;
      const data: {
        id: number;
        note: string;
      }[] = targetResult.sharing_link_result.answered_questions.map(result => {
        return { id: result.id, note: trimSpace(result.note ?? '') };
      });

      await this.userAnswerApi.updateUserAnswerNote({ answered_questions: data }, organization_id);

      this.hearingLinkResults = this.hearingLinkResults.map(session =>
        session.sharing_link_result && session.id === sessionId
          ? {
              ...session,
              sharing_link_result: {
                ...session.sharing_link_result,
                disabledSave: true,
              },
            }
          : session
      );

      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkSuccess'),
        status: 'success',
      });
    } catch (error) {
      this.putFlashMessages({
        content: i18n.t('sharingLink.messages.updateHearingLinkFail'),
        status: 'error',
      });
    }
  }

  @action.bound
  public async getViewingLogsById(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingLinkApi.getViewingLogs({
        id,
        organization_id,
      });
      this.linkViewLogs = data.viewing_logs;
    } catch (error: any) {
      this.error = error;
      this.putFlashMessages({
        content: i18n.t('sharingLink.getViewingLogsError'),
        status: 'error',
      });
    }
  }

  @action.bound
  public async getLinkListByContent({
    page = 1,
    per_page = 20,
    content_type,
    user_id,
  }: Omit<GetLinksByContentTypeParams, 'organization_id'>) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      if (content_type === 'hearing_set') {
        this.loadingQuestionnaireLinkList = true;
        const { data } = await this.hearingLinkApi.getLinksByContentType({
          page,
          per_page,
          organization_id,
          content_type,
          user_id,
        });

        this.linkTypeQuestionnaireList = data.hearing_link_by_contents;
        this.linkTypeQuestionnaireListMeta = {
          ...this.linkTypeQuestionnaireListMeta,
          total: data.total,
          page: data.page,
        };
      }
      if (content_type === 'playlist') {
        this.loadingPlaylistLinkList = true;
        const { data } = await this.hearingLinkApi.getLinksByContentType({
          page,
          per_page,
          organization_id,
          content_type,
          user_id,
        });

        this.linkTypePlaylistList = data.hearing_link_by_contents;
        this.linkTypePlaylistListMeta = {
          ...this.linkTypePlaylistListMeta,
          total: data.total,
          page: data.page,
        };
      }
      if (content_type === 'product_contents') {
        this.loadingProductLinkList = true;
        const { data } = await this.hearingLinkApi.getLinksByContentType({
          page,
          per_page,
          organization_id,
          content_type,
          user_id,
        });

        this.linkTypeProductList = data.hearing_link_by_contents;
        this.linkTypeProductListMeta = {
          ...this.linkTypeProductListMeta,
          total: data.total,
          page: data.page,
        };
      }
    } catch (error: any) {
      const content = error?.message ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      if (content_type === 'hearing_set') this.loadingQuestionnaireLinkList = false;
      if (content_type === 'playlist') this.loadingPlaylistLinkList = false;
      if (content_type === 'product_contents') this.loadingProductLinkList = false;
    }
  }
}

export default HearingLinkStore;
