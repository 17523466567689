import HttpClient from 'adapter/HTTPClient';

export type ChannelType = {
  id: string;
  name: string;
  is_archived: boolean;
  is_member: boolean;
};

export type SlackIntegrationResponse = {
  slack_user_id: string;
  slack_team_name: string;
  state: string;
  user_slack_integration: {
    slack_channel_id: string;
    slack_channel_name?: string;
  };
};

export type ChannelListResponse = {
  channels: ChannelType[];
  response_metadata: {
    next_cursor?: string;
  };
};

class SettingApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public updateOrganizationLogo = async (data: FormData, organizationId: string) => {
    data.append('organization_id', organizationId);
    await this.httpClient.request({
      method: 'POST',
      url: 'organizations/update_logo',
      data: data,
    });
  };

  // Slack 連携

  public loadPersonalSlack = async (organization_id: string) => {
    return await this.httpClient.request<SlackIntegrationResponse>({
      method: 'GET',
      url: 'settings/slacks',
      params: {
        organization_id,
      },
    });
  };

  public updatePersonalSlack = async (code: string, organization_id: string) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'settings/slacks',
      params: {
        code,
        organization_id,
      },
    });
  };

  public deletePersonalSlack = async (organization_id: string) => {
    await this.httpClient.request({
      method: 'DELETE',
      url: 'settings/slacks',
      params: {
        organization_id,
      },
    });
  };

  public listPersonalSlackChannels = async (organization_id: string, cursor?: string) => {
    return await this.httpClient.request<ChannelListResponse>({
      method: 'GET',
      url: 'settings/integrations',
      params: {
        organization_id,
        cursor,
      },
    });
  };

  public assignPersonalSlackChannel = async (organization_id: string, channel_id: string) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'settings/integrations',
      params: {
        organization_id,
        channel_id,
      },
    });
  };

  public getHubSpotAccounts = async (organization_id: string) => {
    const response = await this.httpClient.request({
      method: 'GET',
      url: 'hubspot/accounts/linked',
      params: {
        organization_id,
      },
    });

    return response.data.data.account;
  };

  public postHubSpotAccounts = async (organization_id: string, code: string) => {
    const response = await this.httpClient.request({
      method: 'POST',
      url: 'hubspot/accounts',
      data: {
        organization_id,
        code,
      },
    });

    return response.data.data;
  };

  public deleteHubSpotAccounts = async (organization_id: string) => {
    const response = await this.httpClient.request({
      method: 'DELETE',
      url: 'hubspot/accounts/unlink',
      data: {
        organization_id,
      },
    });

    return response.data.data;
  };

  public getHubSpotAuthUrl = async (organization_id: string) => {
    const response = await this.httpClient.request({
      method: 'GET',
      url: 'hubspot/settings/auth_url',
      params: {
        organization_id,
      },
    });

    return response.data.data.auth_url;
  };
}

export default SettingApi;
