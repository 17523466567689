import styled from '@emotion/styled';
import ArrowDown from 'assets/images/arrow-down_24x24.svg';
import ArrowUp from 'assets/images/arrow-up_24x24.svg';
import { layer, palette } from 'entity/createTheme';
import { QuestionViewType } from 'entity/Question';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { optionsStatusQuestion } from 'utility/constants';
import { DeleteButton, IconButton } from 'views/components/compound';
import { InputText, Switch } from 'views/components/primitive';
import Dropdown from 'views/components/compound/Dropdown';
import AnswerItem from './AnswerItem';
import { QuestionWrapper } from './styles';

interface PropTypes {
  questionIndex: number;
  question: QuestionViewType;
  handleChangeQuestion: (index: number, data: Partial<QuestionViewType>) => void;
  hasError: boolean;
  removeQuestion: (index: number) => void;
  sortByArrow: (currentIndex: number, value: number) => void;
  hasOneQuestion: boolean;
  isQuestionDrawerOpen: boolean;
}

const Question: FC<PropTypes> = ({
  questionIndex,
  question,
  handleChangeQuestion,
  hasError,
  removeQuestion,
  sortByArrow,
  hasOneQuestion,
  isQuestionDrawerOpen,
}) => {
  const { choices, is_multiple_choice, has_other, body, can_delete } = question;
  const status = is_multiple_choice ? 'checkbox' : 'radio';
  const { t } = useTranslation();

  const canAddAnswer = choices.length < 10;

  const handleChangeBody = (val: string) => {
    handleChangeQuestion(questionIndex, { body: val });
  };

  function handleChangeStatus(status: string) {
    const isMultipleChoice = status === 'checkbox';
    handleChangeQuestion(questionIndex, { is_multiple_choice: isMultipleChoice });
  }

  function handleAddAnswer() {
    handleChangeQuestion(questionIndex, {
      choices: [...choices, { content: '', feedback: '', can_delete: true }],
    });
  }

  function handleChangeAnswer(name: string, index: number, value: string) {
    const newChoices = [...choices];
    newChoices[index] = { ...newChoices[index], [name]: value };
    handleChangeQuestion(questionIndex, { choices: newChoices });
  }

  function handleDeleteAnswerItem(choiceIndex: number) {
    handleChangeQuestion(questionIndex, {
      choices: choices.filter((_, index) => index !== choiceIndex),
    });
  }

  function handleChangeHasOther() {
    handleChangeQuestion(questionIndex, {
      has_other: !has_other,
    });
  }

  const deleteQuestion = () => {
    removeQuestion(questionIndex);
  };

  return (
    <QuestionWrapper>
      <div className="question_group">
        <InputText
          className="input_question"
          placeholder={t('admin.questions.placeholderQuestion')}
          value={body}
          onChange={handleChangeBody}
          isCustom
          error={hasError && !body.trim() ? t('admin.questions.messages.questionBlank') : ''}
          maxLength={80}
        />
        <TopAction>
          <Dropdown
            value={status}
            options={optionsStatusQuestion}
            onChange={handleChangeStatus}
            isParentComponentShown={isQuestionDrawerOpen}
            width={160}
          />
          {!hasOneQuestion && (
            <ArrowButtons>
              <IconButton
                tooltipText={t('common.tooltipText.moveUp')}
                iconSrc={ArrowUp}
                onClick={() => sortByArrow(questionIndex, -1)}
              />
              <IconButton
                tooltipText={t('common.tooltipText.moveDown')}
                iconSrc={ArrowDown}
                onClick={() => sortByArrow(questionIndex, 1)}
              />
            </ArrowButtons>
          )}
        </TopAction>
      </div>
      {choices.map((choice, index) => {
        const canDeleteAnswer = !!choice.can_delete && choices.length > 1;
        return (
          <AnswerItem
            key={`${choice.id}${index}`}
            index={index}
            answer={choice.content}
            status={status}
            hasError={hasError}
            feedback={choice.feedback}
            onChange={handleChangeAnswer}
            handleDeleteAnswerItem={handleDeleteAnswerItem}
            canDeleteAnswer={canDeleteAnswer}
          />
        );
      })}
      <div className="bottom_items">
        {canAddAnswer ? (
          <div className="add_answer">
            <span className={`status ${status}`} />
            <span onClick={handleAddAnswer} className="action_add">
              {t('admin.questions.addAnswer')}
            </span>
          </div>
        ) : (
          <LimitNote>{t('admin.questions.maximumNumberOfAnswer')}</LimitNote>
        )}
        <BottomAction>
          <div className="switch_container">
            <Switch checked={has_other} handleChange={handleChangeHasOther} />
            <span className="switch_label">{t('admin.questions.addOther')}</span>
          </div>
          {can_delete && !hasOneQuestion && (
            <DeleteButton
              isBackground
              size="md"
              onClick={deleteQuestion}
              tooltipText={t('admin.questions.deleteQuestion')}
            />
          )}
        </BottomAction>
      </div>
    </QuestionWrapper>
  );
};

export default Question;

const TopAction = styled('div')({
  display: 'flex',
});

const BottomAction = styled('div')({
  display: 'flex',
});

const ArrowButtons = styled('div')({
  display: 'flex',
  paddingTop: 5,
  marginLeft: 10,
  svg: {
    margin: '0px 3px',
    zIndex: layer.bottomAbove2,
    '&:hover': { cursor: 'pointer' },
  },
});

const LimitNote = styled('span')({
  fontSize: 14,
  color: palette.grayMedium2,
});
