import { PlaylistDetail, UpdatePlaylistData } from 'types/Playlist';
import { action, observable } from 'mobx';
import PlaylistApi from 'services/Admin/PlaylistApi';
import { RootStore } from 'stores';
import { MessageProps } from 'types/App';
import { defaultPlaylist } from './constants';
import i18n from 'i18n';
import history from 'utility/history';
import { routes } from 'utility/constants';

class PlaylistStore {
  private readonly playlistApi: PlaylistApi;

  @observable public rootStore: RootStore;
  @observable public playlistDetail: PlaylistDetail = { ...defaultPlaylist };
  @observable public loading = false;

  constructor({ rootStore, playlistApi }: { rootStore: RootStore; playlistApi: PlaylistApi }) {
    this.rootStore = rootStore;
    this.playlistApi = playlistApi;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getPlaylistDetail(id: number) {
    const {
      appStore: { organizationId },
    } = this.rootStore;

    this.loading = true;
    try {
      const { data } = await this.playlistApi.getPlaylistById(id, organizationId);
      this.playlistDetail = data;
    } catch (error) {
      this.pushFlashMessages({
        content: i18n.t('admin.playlist.messages.getPlaylistFailed'),
        status: 'error',
      });
      history.push(`${routes.management.content}?tab=playlists`);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetPlaylistDetail() {
    this.playlistDetail = { ...defaultPlaylist };
  }

  @action.bound
  public async updatePlaylist(id: number, updatedData: Partial<UpdatePlaylistData>) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.playlistApi.updatePlaylistContent({
        id,
        organization_id,
        data: {
          ...updatedData,
          ...(updatedData.product_contents && {
            product_contents: updatedData.product_contents.map(content => ({
              id: content.id,
              playlist_title: content.playlist_title,
            })),
          }),
        },
      });
      this.pushFlashMessages({
        content: i18n.t('admin.playlist.messages.updatePlaylistSuccess'),
        status: 'success',
      });
      if (!!updatedData.status && updatedData.status === 'archived') {
        history.push(`${routes.management.archive}?tab=playlists`);
      }
      this.playlistDetail = data;
    } catch (error) {
      if (!!updatedData.status && error?.message.includes('422')) {
        this.pushFlashMessages({
          content: i18n.t('admin.common.messages.updateStatusFailed'),
          status: 'error',
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.playlist.messages.updatePlaylistFailed'),
          status: 'error',
        });
      }
    }
  }
}

export default PlaylistStore;
