import arrayMove from 'array-move';
import { ContentViewType } from 'entity/Content';
import { QuestionViewType } from 'entity/Question';
import { QuestionSet, QuestionSetViewType } from 'entity/QuestionSet';
import i18n from 'i18n';
import { action, computed, observable } from 'mobx';
import { SortEnd } from 'react-sortable-hoc';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import QuestionSetApi from 'services/Admin/QuestionSetApi';
import {
  ContentMeta,
  ContentType,
  ExternalVideoURLMeta,
  MessageProps,
  NoContentMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
  ExistingContentMeta,
  QuestionSetsCreateUpdateAPIBody,
  QuestionSetsCreateUpdateAPIQuestion,
  QuestionSetsCreateUpdateAPIQuestionChoice,
} from 'types/App';
import { UploadCompletedProps } from 'types/common';
import {
  QuestionSet as QuestionSetResponseType,
  HearingItem,
  HearingItemStatus,
} from 'types/HearingSet';
import { routes } from 'utility/constants';
import {
  dataURLtoFile,
  extractFileNameFromPath,
  trimSpace,
  getContentTypeString,
} from 'utility/helpers';
import history from 'utility/history';
import { RootStore } from '../../index';
import {
  defaultHearing,
  defaultExtrenalVideoURLMeta,
  defaultImageMeta,
  defaultNoContentMeta,
  defaultPDFMeta,
  defaultQuestion,
  defaultQuestionSet,
  defaultVideoMeta,
  defaultContentType,
  defaultExistingContentMeta,
} from '../constants';

export const checkPathChange = (hearingId: string) => {
  const originalHearingPath = `${routes.management.questionnaireDetail}/${hearingId}`;
  const currentPath = history.location.pathname;
  const samePath = currentPath === originalHearingPath;
  return samePath;
};
class HearingDetailStore {
  private readonly hearingAdminApi: HearingAdminApi;
  private readonly questionSetApi: QuestionSetApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public hearingDetail: HearingItem = { ...defaultHearing };
  @observable public originalHearingDetail: HearingItem = { ...defaultHearing };
  @observable public errors = {};
  @observable public isQuestionSetListTouched = false;
  @observable public isHearingSectionTouched = false;
  @observable public isSortingQuestionSet = false;
  @observable public videoMeta: UploadVideoMeta = { ...defaultVideoMeta };
  @observable public contentType: ContentType = defaultContentType;
  @observable public externalVideoURLMeta: ExternalVideoURLMeta = {
    ...defaultExtrenalVideoURLMeta,
  };
  @observable public imageMeta: UploadImageMeta = { ...defaultImageMeta };
  @observable public pdfMeta: UploadPDFMeta = { ...defaultPDFMeta };
  @observable public noContentMeta: NoContentMeta = { ...defaultNoContentMeta };
  @observable public originalContentMeta: ContentMeta = { ...defaultExistingContentMeta };
  @observable public existingContentMeta: ExistingContentMeta = { ...defaultExistingContentMeta };
  @observable public editQuestionSetTouched = false;
  @observable public editDataFileTouched = false;
  @observable public percentUploaded: UploadCompletedProps = { filename: '', completed: 0 };
  @observable public submitting = false;
  @observable public createQuestionSetModalOpen = false;
  @observable public editQuestionSetModalOpen = false;
  @observable public questionSet: QuestionSetViewType = { ...defaultQuestionSet };
  @observable public editMode = false;

  constructor({
    rootStore,
    hearingAdminApi,
    questionSetApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
    questionSetApi: QuestionSetApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
    this.questionSetApi = questionSetApi;
  }

  @computed get originalHearingTitle() {
    return this.originalHearingDetail.title;
  }

  @computed get hearingUpdateDisabled() {
    return !this.isHearingSectionTouched;
  }

  @computed get questionSetOrderUpdateDisabled() {
    return !this.isQuestionSetListTouched;
  }

  @computed get submitQuestionSetDisabled() {
    switch (this.contentType) {
      case 'videos':
      case 'images':
      case 'pdfs':
      case 'videos_images_pdfs':
        return !this.fileMeta.file || this.errorMetaFile;
      case 'links':
        return this.fileMeta.url === '';
      case 'existing_contents':
        return this.fileMeta.creativeId === '';
      case 'none_data':
        return false;
      default:
        return true;
    }
  }

  @computed get errorMetaFile() {
    switch (this.contentType) {
      case 'videos':
        return !!this.videoMeta.error;
      case 'pdfs':
        return !!this.pdfMeta.error;
      case 'images':
        return !!this.imageMeta.error;
      case 'existing_contents':
        return !this.fileMeta.creativeId;
      default:
        return false;
    }
  }

  @computed get updateQuestionSetDisabled() {
    // if type is 'none_data', we regard it as touched
    const touched =
      this.editQuestionSetTouched || this.editDataFileTouched || this.contentType === 'none_data';
    if (this.originalContentMeta.type === this.contentType) return !touched || this.errorMetaFile;
    return !touched || this.submitQuestionSetDisabled;
  }

  @computed get fileMeta() {
    switch (this.contentType) {
      case 'videos': {
        const { file, fileThumbnail: thumbnailFile, filename } = this.videoMeta;
        return { file, thumbnailFile, filename };
      }
      case 'links': {
        const { thumbnailFile, url } = this.externalVideoURLMeta;
        return { thumbnailFile, url };
      }
      case 'images': {
        const { file, filename } = this.imageMeta;
        return { file, filename };
      }
      case 'pdfs': {
        const { file, thumbnailFile, filename } = this.pdfMeta;
        return { file, thumbnailFile, filename };
      }
      case 'existing_contents': {
        const { creativeId } = this.existingContentMeta;
        return { creativeId };
      }
      default:
        return {};
    }
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public setSubmitting(submitting: boolean) {
    this.submitting = submitting;
  }

  @action.bound
  public toggleSortingQuestionSet() {
    this.isSortingQuestionSet = !this.isSortingQuestionSet;
  }

  @action.bound
  public toggleCreateQuestionSetModal() {
    this.createQuestionSetModalOpen = !this.createQuestionSetModalOpen;
  }

  @action.bound
  public toggleEditQuestionSetModal() {
    this.editQuestionSetModalOpen = !this.editQuestionSetModalOpen;
    this.editMode = false;
  }

  //METHODS FOR CONTENT FILES
  @action.bound
  public handleChangeContentType(type: ContentType, resetEditMode?: boolean) {
    if (this.contentType !== type) this.resetAllMeta();
    if (resetEditMode && this.originalContentMeta.type === type) {
      this.editMode = false;
    }
    this.contentType = type;
    this.editDataFileTouched = true;
  }

  @action.bound
  public handleChangeVideoMeta(data: UploadVideoMeta) {
    this.videoMeta = { ...this.videoMeta, ...data };
  }

  @action.bound
  public handleChangeExternalVideoURLMeta(meta: ExternalVideoURLMeta) {
    this.externalVideoURLMeta = { ...this.externalVideoURLMeta, ...meta };
  }

  @action.bound
  public handleChangeImageMeta(meta: UploadImageMeta) {
    this.imageMeta = { ...this.imageMeta, ...meta };
  }

  @action.bound
  public handleChangePDFMeta(meta: UploadPDFMeta) {
    this.pdfMeta = { ...this.pdfMeta, ...meta };
  }

  @action.bound
  public handleChangeExistingContentMeta(meta: ExistingContentMeta) {
    this.existingContentMeta = { ...this.existingContentMeta, ...meta };
  }

  @action.bound
  public handleChangeContentMeta(meta: ContentMeta, touched = true) {
    switch (meta.type) {
      case 'videos':
        this.editMode = true;
        this.handleChangeVideoMeta(meta);
        break;
      case 'links':
        this.handleChangeExternalVideoURLMeta(meta);
        break;
      case 'images':
        this.handleChangeImageMeta(meta);
        break;
      case 'pdfs':
        this.editMode = true;
        this.handleChangePDFMeta(meta);
        break;
      case 'existing_contents':
        this.handleChangeExistingContentMeta(meta);
        break;
      case 'none_data':
        break;
      default:
        throw new Error('wrong content type');
    }

    this.editDataFileTouched = touched;
  }

  @action.bound
  public handleChangeHearingDetail(data: Partial<HearingItem>) {
    this.isHearingSectionTouched = true;
    this.hearingDetail = { ...this.hearingDetail, ...data };
  }

  @action.bound
  public async getHearingDetail(id: string) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.isLoading = true;
      const { data } = await this.hearingAdminApi.getHearingById({ id, organization_id });
      this.hearingDetail = data;
      this.originalHearingDetail = data;
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.notFoundHearing'),
        status: 'error',
      });
      history.push(`${routes.management.content}?tab=questionnaires`);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async updateHearingSet(payload: {
    id: string;
    status?: string;
    title: string;
    question_set_ids: number[];
  }) {
    const { id, title, status, question_set_ids } = payload;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.hearingAdminApi.updateHearingSet({
        id,
        title,
        status,
        question_set_ids,
        organization_id,
      });

      const samePath = checkPathChange(id);
      if (samePath) this.originalHearingDetail = data;

      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      throw error;
    }
  }

  @action.bound
  public async updateQuestionnaireStatus(id: string, status: HearingItemStatus) {
    if (status === 'published' && this.originalHearingDetail.question_sets.length <= 0) {
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.statusValidationError'),
        status: 'error',
      });
      return;
    }
    try {
      await this.updateHearingSet({
        id,
        status,
        title: this.originalHearingDetail.title,
        question_set_ids: this.originalHearingDetail.question_sets.map(qs => qs.id),
      });
    } catch (error: any) {
      this.errors = error;
      if (error?.message.includes('422')) {
        this.pushFlashMessages({
          content:
            status === 'published'
              ? i18n.t('admin.hearingSet.messages.statusValidationError')
              : i18n.t('admin.common.messages.updateStatusFailed'),
          status: 'error',
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
          status: 'error',
        });
      }
    }
  }

  @action.bound
  public async updateQuestionnaireInfo(id: string, title: string) {
    this.submitting = true;
    const { question_sets } = this.originalHearingDetail;
    const question_set_ids = question_sets.map(qs => qs.id);
    try {
      await this.updateHearingSet({
        id,
        title,
        question_set_ids,
      });

      this.isHearingSectionTouched = false;
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
      this.submitting = false;
    }
  }

  @action.bound
  public async updateQuestionSetList(id: string) {
    this.submitting = true;
    const { status, title } = this.originalHearingDetail;
    const { question_sets } = this.hearingDetail;
    const question_set_ids = question_sets.map(qs => qs.id);

    try {
      await this.updateHearingSet({
        id,
        title,
        status,
        question_set_ids,
      });

      this.isQuestionSetListTouched = false;
      this.isSortingQuestionSet = false;
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.updateHearingFail'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
      this.submitting = false;
    }
  }

  //UPDATE QUESTIONS IN QUESTION SET
  @action.bound
  public updateQuestion(questionIndex: number, data: Partial<QuestionViewType>) {
    const updatedQuestions = this.questionSet.questions.map((question, i) => {
      if (i === questionIndex) {
        return { ...question, ...data };
      }
      return question;
    });

    this.questionSet = { ...this.questionSet, questions: updatedQuestions };
    this.editQuestionSetTouched = true;
  }

  @action.bound
  public addQuestion() {
    this.questionSet = {
      ...this.questionSet,
      questions: [...this.questionSet.questions, defaultQuestion],
    };
    this.editQuestionSetTouched = true;
  }

  @action.bound
  public removeQuestion(index: number) {
    const updatedQuestions = this.questionSet.questions.filter((_, i) => i !== index);
    this.questionSet = { ...this.questionSet, questions: updatedQuestions };
    this.editQuestionSetTouched = true;
  }

  @action.bound
  public sortQuestions = ({ oldIndex, newIndex }: SortEnd) => {
    if (newIndex !== oldIndex) {
      this.questionSet = {
        ...this.questionSet,
        questions: arrayMove(this.questionSet.questions, oldIndex, newIndex),
      };
      this.editQuestionSetTouched = true;
    }
  };

  @action.bound
  public injectQuestionSetToModal(data: QuestionSetResponseType) {
    this.questionSet = QuestionSet.fromJSON(data).toJSON();
    this.editQuestionSetTouched = false;
    this.editDataFileTouched = false;
    this.injectInitialContent(this.questionSet.content);
  }

  @action.bound
  public injectInitialContent(data: ContentViewType) {
    this.contentType = data.content_type;
    switch (data.content_type) {
      case 'videos': {
        if (data.creative_search_id) {
          const meta = {
            ...this.existingContentMeta,
            creativeId: data.creative_search_id,
            creative_type: 'videos',
          };
          this.handleChangeExistingContentMeta(meta);
          this.originalContentMeta = meta;
        } else {
          const meta = {
            ...this.videoMeta,
            firstFrameVideo: data.kaizen_files_thumbnail_url ?? '',
          };
          this.handleChangeVideoMeta(meta);
          this.originalContentMeta = meta;
        }
        break;
      }
      case 'links': {
        const meta = {
          ...this.externalVideoURLMeta,
          thumbnailURL: data.thumbnail,
          url: data.url,
        };
        this.handleChangeExternalVideoURLMeta(meta);
        this.originalContentMeta = meta;
        break;
      }
      case 'images': {
        if (data.creative_search_id) {
          const meta = {
            ...this.existingContentMeta,
            creativeId: data.creative_search_id,
            creative_type: 'images',
          };
          this.handleChangeExistingContentMeta(meta);
          this.originalContentMeta = meta;
        } else {
          const meta = {
            ...this.imageMeta,
            src: data.kaizen_files_url,
            filename: extractFileNameFromPath(data.kaizen_files_url) ?? '',
          };
          this.handleChangeImageMeta(meta);
          this.originalContentMeta = meta;
        }
        break;
      }
      case 'pdfs': {
        if (data.creative_search_id) {
          const meta = {
            ...this.existingContentMeta,
            creativeId: data.creative_search_id,
            creative_type: 'pdfs',
          };
          this.handleChangeExistingContentMeta(meta);
          this.originalContentMeta = meta;
        } else {
          const meta = {
            ...this.pdfMeta,
            thumbnailSrc: data.kaizen_files_thumbnail_url ?? '',
            filename: extractFileNameFromPath(data.kaizen_files_url) ?? '',
          };
          this.handleChangePDFMeta(meta);
          this.originalContentMeta = meta;
        }
        break;
      }
      case 'none_data': {
        this.originalContentMeta = this.noContentMeta;
        break;
      }
      default:
    }
  }

  @action.bound
  public prepareFileFormData() {
    const data: QuestionSetsCreateUpdateAPIBody = {
      type: this.contentType,
      questions: [],
      file: null,
      thumbnail_file: null,
    };

    switch (this.contentType) {
      case 'videos': {
        const { file, fileThumbnail } = this.videoMeta;
        const thumbnail =
          typeof fileThumbnail === 'string' ? dataURLtoFile(fileThumbnail) : fileThumbnail;

        if (file) data.file = file;
        if (fileThumbnail) data.thumbnail_file = thumbnail;
        break;
      }
      case 'links': {
        const { thumbnailFile, thumbnailURL, url } = this.externalVideoURLMeta;

        if (thumbnailFile) {
          data.thumbnail_file = thumbnailFile;
        } else {
          data.thumbnail = thumbnailURL;
        }

        data.url = url;
        break;
      }

      case 'images': {
        const { file } = this.imageMeta;
        if (file) data.file = file;
        break;
      }
      case 'pdfs': {
        const { file, thumbnailSrc } = this.pdfMeta;
        if (file) {
          const thumbnail = dataURLtoFile(thumbnailSrc);
          data.thumbnail_file = thumbnail;
          data.file = file;
        }

        break;
      }
      case 'existing_contents': {
        const { creative_type, creativeId } = this.existingContentMeta;
        if (creativeId) data.creative_search_id = creativeId;
        data.type = getContentTypeString(creative_type);

        break;
      }
      default:
        break;
    }

    return data;
  }

  @action.bound
  public async submitAddQuestionSet(hearingId: string) {
    const { completed } = this.percentUploaded;
    if (completed > 0) return;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { filename } = this.fileMeta;
      const { questions } = this.questionSet;
      const data = this.prepareFileFormData();

      data.hearing_set_id = Number(hearingId);
      for (let i = 0; i < questions.length; i++) {
        const { body, is_multiple_choice, has_other, choices } = questions[i];
        const question: QuestionSetsCreateUpdateAPIQuestion = {
          body: trimSpace(body),
          is_multiple_choice: is_multiple_choice,
          has_other: has_other,
          is_required: true,
          choices: [],
        };
        if (choices.length) {
          for (let j = 0; j < choices.length; j++) {
            question.choices.push({
              content: trimSpace(choices[j].content),
              feedback: trimSpace(choices[j].feedback || ''),
            });
          }
        }
        data.questions.push(question);
      }

      const onUploadProgress = (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        this.percentUploaded = {
          filename: filename || '',
          completed: percent,
        };
      };
      this.resetQuestionSetMeta();
      await this.questionSetApi.createQuestionSet({
        data,
        onUploadProgress,
        organization_id,
      });
      //fix/not-saved-title
      const _title = this.hearingDetail.title; //add
      await this.getHearingDetail(hearingId);
      this.hearingDetail.title = _title; //add

      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.createQuestionSuccess'),
        status: 'success',
      });
      this.percentUploaded = { filename: '', completed: 0 };
    } catch (error: any) {
      const errors = error?.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.questions.messages.createQuestionError')} ${errors}`,
        status: 'error',
      });
      this.percentUploaded = { filename: '', completed: 0 };
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async submitUpdateQuestionSet(questionSetId: string, hearingId: string) {
    const contentChanged = this.editDataFileTouched;
    // this.revertHearingDetail();//fix/not-saved-title
    const { completed } = this.percentUploaded;
    if (completed > 0) return;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { filename, file } = this.fileMeta;
      const { questions } = this.questionSet;
      const data = this.prepareFileFormData();

      for (let i = 0; i < questions.length; i++) {
        const { body, is_multiple_choice, has_other, choices, id } = questions[i];
        const question: QuestionSetsCreateUpdateAPIQuestion = {
          id: id,
          body: trimSpace(body),
          is_multiple_choice: is_multiple_choice,
          has_other: has_other,
          is_required: true,
          choices: [],
        };
        if (choices.length) {
          for (let j = 0; j < choices.length; j++) {
            const choice: QuestionSetsCreateUpdateAPIQuestionChoice = {
              content: trimSpace(choices[j].content),
              feedback: trimSpace(choices[j].feedback || ''),
            };
            if (choices[j].id) choice.id = choices[j].id;
            question.choices.push(choice);
          }
        }
        data.questions.push(question);
      }

      const onUploadProgress = (progressEvent: any) => {
        if (file) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.percentUploaded = {
            filename: filename || '',
            completed: percent,
          };
        }
      };

      const question = this.hearingDetail.question_sets.filter(qs => {
        return qs.id.toString() === questionSetId;
      });
      const bucket_id =
        question.length > 0 ? question[0].content?.kaizen_files_bucket_id ?? '' : '';

      this.resetQuestionSetMeta();
      const { data: responseData } = await this.questionSetApi.updateQuestionSet({
        id: questionSetId,
        data,
        onUploadProgress,
        organization_id,
        bucket_id,
      });

      if (contentChanged) {
        //For better user experience, only refetch hearing when new content is uploaded. Otherwise, just simply update the states.
        //fix/not-saved-title
        const _title = this.hearingDetail.title; //add
        await this.getHearingDetail(hearingId);
        this.hearingDetail.title = _title; //add
      } else {
        const updatedHearing = {
          ...this.hearingDetail,
          question_sets: this.hearingDetail.question_sets.map(qs =>
            qs.id.toString() === questionSetId ? { ...qs, questions: responseData.questions } : qs
          ),
        };
        this.hearingDetail = updatedHearing;

        //do-not-update-main-title-until-saved
        //fix/not-saved-title
        const _title = this.originalHearingDetail.title; //add
        this.originalHearingDetail = updatedHearing;
        this.originalHearingDetail.title = _title; //add
      }

      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.updateQuestionSuccess'),
        status: 'success',
      });

      this.percentUploaded = { filename: '', completed: 0 };
    } catch (error: any) {
      const errors = error?.data?.errors?.join('; ') ?? '';

      this.pushFlashMessages({
        content: `${i18n.t('admin.questions.messages.updatedQuestionError')} ${errors}`,
        status: 'error',
      });

      this.percentUploaded = { filename: '', completed: 0 };
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public sortQuestionSets = ({ oldIndex, newIndex }: SortEnd) => {
    if (newIndex !== oldIndex) {
      const { question_sets } = this.hearingDetail;
      this.isQuestionSetListTouched = true;
      this.hearingDetail = {
        ...this.hearingDetail,
        question_sets: arrayMove(question_sets, oldIndex, newIndex),
      };
    }
  };

  @action.bound
  public deleteQuestionSet = async (idToDelete: number) => {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      await this.questionSetApi.deleteQuestionSet({ id: idToDelete, organization_id });
      this.hearingDetail = {
        ...this.hearingDetail,
        question_sets: this.hearingDetail.question_sets.filter(qs => qs.id !== idToDelete),
      };
      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.deleteQuestionSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      this.pushFlashMessages({
        content: i18n.t('admin.questions.messages.deleteQuestionError'),
        status: 'error',
      });
    } finally {
      this.submitting = false;
    }
  };

  @action.bound
  public resetHearingDetail() {
    this.questionSet = { ...defaultQuestionSet };
    this.hearingDetail = { ...defaultHearing };
    this.originalHearingDetail = { ...defaultHearing };
    this.isHearingSectionTouched = false;
    this.isQuestionSetListTouched = false;
    this.isSortingQuestionSet = false;
    this.resetQuestionSetMeta();
  }

  @action.bound
  public resetQuestionSetMeta() {
    this.originalContentMeta = { ...defaultNoContentMeta };
    this.questionSet = { ...defaultQuestionSet };
    this.editQuestionSetTouched = false;
    this.contentType = defaultContentType;
    this.editDataFileTouched = false;
    this.resetAllMeta();
  }

  @action.bound
  public resetAllMeta() {
    this.videoMeta = { ...defaultVideoMeta };
    this.externalVideoURLMeta = {
      ...defaultExtrenalVideoURLMeta,
    };
    this.imageMeta = { ...defaultImageMeta };
    this.pdfMeta = { ...defaultPDFMeta };
    this.existingContentMeta = { ...defaultExistingContentMeta };

    // Keep original content value in edit screen
    this.handleChangeContentMeta(this.originalContentMeta, false);
  }

  @action.bound
  public resetExistingContentMeta() {
    this.existingContentMeta = { ...defaultExistingContentMeta };
  }

  @action.bound
  public revertHearingDetail() {
    this.hearingDetail = this.originalHearingDetail;
  }

  @action.bound
  public revertQuestionSetOrder() {
    this.hearingDetail.question_sets = this.originalHearingDetail.question_sets;
    this.isQuestionSetListTouched = false;
  }
}

export default HearingDetailStore;
