import React, { FC } from 'react';
import styled, { CSSObject } from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import SVGICon from 'views/components/primitive/SVGIcon';

type Props = {
  srcIcon?: string;
  imgUrl?: string;
  title: string;
  subTitle: string;
  id?: string;
  className?: string;
};

const InfoItem: FC<Props> = ({ title, subTitle, srcIcon, imgUrl, id, className }) => {
  return (
    <Root id={id} className={className}>
      {srcIcon && <SVGICon src={srcIcon} asImg />}
      {imgUrl && <Img className="img_header" src={imgUrl} alt="img_header" />}
      <TitleContainer>
        <Title className="item_title">{title}</Title>
        <SubTitle className="item_subtitle">{subTitle}</SubTitle>
      </TitleContainer>
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  width: '100%',

  img: {
    width: 32,
    height: 32,
    marginRight: 10,
    alignSelf: 'flex-start',
  },
});

const Img = styled('img')({
  width: 32,
  height: 32,
  marginRight: 10,
  alignSelf: 'flex-start',
  borderRadius: '50%',
});

const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
});

const titleStyle: CSSObject = {
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

const Title = styled('h3')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  lineHeight: '19px',
  color: palette.darkMedium2,

  [`@media ${bp.md}`]: {
    fontSize: 13,
  },

  ...titleStyle,
});

const SubTitle = styled('p')({
  fontSize: '0.875rem',
  color: palette.deepBlueLight,
  [`@media ${bp.md}`]: {
    fontSize: 13,
  },

  ...titleStyle,
});

export default InfoItem;
