import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentMeta, ExternalVideoURLMeta } from 'types/App';
import { getVimeoThumbnail, getYouTubeThumbnail, validateVideoURL } from 'utility/videoLinkHelpers';
import { Button } from 'views/components/primitive';
import ReplaceThumbnailButton from '../../ReplaceFileButton';

import ExternalVideoInputModal from './ExternalVideoInputModal';
import { thumbnailMaxHeight, thumbnailMaxWidth } from 'utility/constants';

type Props = {
  meta: ExternalVideoURLMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
};

const ExternalVideoURLInput: FC<Props> = ({ meta, handleChangeContentMeta }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(meta.url);
  const [activeImage, setActiveImage] = useState<string | undefined>();

  const handleChange = (val: string) => {
    setURL(val);
  };

  const handleToggle = () => {
    setShowModal(prev => !prev);
  };

  const handleChangeURLMeta = (platform: 'vimeo' | 'youtube', thumbnailURL?: string) => {
    handleChangeContentMeta({
      ...meta,
      platform,
      thumbnailFile: undefined,
      url,
      error: '',
      ...(thumbnailURL && { thumbnailURL }),
    });
    setShowModal(false);
    setActiveImage(thumbnailURL);
  };

  const handleConfirm = async () => {
    const { isVimeo, isYouTube } = validateVideoURL(url);

    try {
      if (isVimeo) {
        const thumbnailURL = await getVimeoThumbnail(url);
        handleChangeURLMeta('vimeo', thumbnailURL);
        return;
      }

      if (isYouTube) {
        const thumbnailURL = getYouTubeThumbnail(url);
        handleChangeURLMeta('youtube', thumbnailURL);
        return;
      }
    } catch (e) {
      handleChangeContentMeta({
        ...meta,
        error: t('admin.questions.errors.notFound'),
      });

      meta.url !== '' ? setURL(meta.url) : setURL('');
      setShowModal(false);
      return;
    }

    handleChangeContentMeta({
      ...meta,
      error: t('admin.questions.errors.invalidVideoURL'),
    });

    meta.url !== '' ? setURL(meta.url) : setURL('');
    setShowModal(false);
  };

  const handleThumbnailChange = (file: any) => {
    if (!file) return;
    const url = URL.createObjectURL(file);
    const thumbnail = new Image();
    thumbnail.src = url;
    thumbnail.onload = () => {
      if (thumbnail.height > thumbnailMaxHeight || thumbnail.width > thumbnailMaxWidth) {
        handleChangeContentMeta({
          ...meta,
          thumbnailFile: undefined,
          thumbnailSrc: '',
          error: t('admin.productContent.messages.thumbnailResolutionError', {
            maxWidth: thumbnailMaxWidth,
            maxHeight: thumbnailMaxHeight,
          }),
        });
        return;
      }
      handleChangeContentMeta({
        ...meta,
        thumbnailSrc: url,
        thumbnailFile: file,
        error: '',
      });
      setActiveImage(url);
    };
  };

  const getThumbnailSrc = () => {
    return meta.thumbnailSrc === activeImage ? meta.thumbnailSrc : meta.thumbnailURL;
  };

  return (
    <Wrapper>
      {meta.url !== '' ? (
        <Root>
          <PreviewSection>
            <ContentHeading>{t('admin.common.content')}</ContentHeading>
            <MainFrameWrap>
              <MainFrame>
                <img src={activeImage ? activeImage : meta.thumbnailURL} alt="thumbnail_image" />
              </MainFrame>
            </MainFrameWrap>
          </PreviewSection>
          <InfoSection>
            <ContentHeading>{t('admin.common.thumbnail')}</ContentHeading>
            <ThumbnailSection>
              <ThumbnailRoot>
                <ThumbnailWrap>
                  <ThumbnailImg>
                    <img src={getThumbnailSrc()} alt="thumbnail-item" />
                  </ThumbnailImg>
                </ThumbnailWrap>
                <ReplaceThumbnailButton
                  handleChange={handleThumbnailChange}
                  acceptType="thumbnail"
                />
              </ThumbnailRoot>

              <FileNameSection>
                <ContentHeading>{t('admin.questions.videoURL')}</ContentHeading>
                <VideoURLWrapper>
                  <VideoURL target="_blank" rel="noopener noreferrer" href={meta.url}>
                    {meta.url}
                  </VideoURL>
                  <Button
                    size="sm"
                    type="default"
                    label={t('admin.questions.changeLink')}
                    onClick={handleToggle}
                  />
                </VideoURLWrapper>
              </FileNameSection>
            </ThumbnailSection>
          </InfoSection>
        </Root>
      ) : (
        <ButtonWrapper>
          <Button
            size="sm"
            type="primary"
            label={t('admin.questions.embedVideoLink')}
            onClick={handleToggle}
          />
        </ButtonWrapper>
      )}
      {meta.error !== '' && <p className="error">{meta.error}</p>}
      <ExternalVideoInputModal
        inputValue={url}
        showModal={showModal}
        handleToggle={handleToggle}
        handleChange={handleChange}
        handleConfirm={handleConfirm}
      />
    </Wrapper>
  );
};

const Root = styled('div')({
  display: 'flex',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
});

const ThumbnailSection = styled('div')({
  height: 349,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [`@media ${bp.xmd}`]: {
    height: 270,
  },
  [`@media ${bp.md}`]: {
    flexDirection: 'row',
    justifyContent: 'normal',
    height: 160,
  },
});

const FileNameSection = styled('div')({
  [`@media ${bp.md}`]: {
    marginLeft: 16,
  },
});

const InfoSection = styled('div')({
  overflow: 'hidden',
  justifyContent: 'space-between',
  [`@media ${bp.md}`]: {
    marginTop: 20,
  },
});

const PreviewSection = styled('div')({
  marginRight: 24,
});

const MainFrameWrap = styled('div')({
  border: `1px solid ${palette.borderInput}`,
  borderRadius: 4,
  width: 582,
  height: 349,
  padding: 24,
  marginBottom: 8,
  [`@media ${bp.xmd}`]: {
    width: 450,
    height: 270,
  },
  [`@media ${bp.md}`]: {
    padding: 16,
  },
});

const ContentHeading = styled('p')({
  fontSize: 12,
  marginBottom: 8,
});

const MainFrame = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  userSelect: 'none',
  background: palette.blackPrimary,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const ThumbnailRoot = styled('div')({
  width: 178,
  minWidth: 178,
  height: 110,
});

const ThumbnailWrap = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: 11,
  border: `1px solid ${palette.borderInput}`,
  marginBottom: 8,
});

const ThumbnailImg = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const Wrapper = styled('div')({
  '& > p.error': {
    color: palette.redPrimary,
    marginTop: 20,
    fontSize: 12,
  },
});

const VideoURLWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '84px 0',
  borderRadius: '6px',
  userSelect: 'none',
  marginTop: 15,

  boxSizing: 'border-box',
  height: 200,
  width: '100%',
  border: `0.8px solid ${palette.grayPrimary}`,
});

const VideoURL = styled('a')({
  display: 'block',
  textDecoration: 'none',
  height: 18,
  color: palette.greenPrimary,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: '18px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '60%',
  '&:hover': {
    textDecoration: 'underline',
  },
  [`@media ${bp.md}`]: {
    marginBottom: 16,
    width: '80%',
  },
});

export default ExternalVideoURLInput;
