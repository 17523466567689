import React, { FC } from 'react';
import { WrapperModal } from './styles';
import { css } from '@emotion/core';
import { bp, layer } from 'entity/createTheme';
import { useLockBodyScroll } from 'react-use';

interface ModalProps {
  isModal: boolean;
  className?: string;
}

const Modal: FC<ModalProps> = ({ isModal, className }) => {
  useLockBodyScroll(isModal);

  return isModal ? (
    <WrapperModal
      className={`modal-wrapper ${className}`}
      css={css`
        @media ${bp.sm} {
          display: block;
        }
        display: none;
        z-index: ${layer.modal1};
      `}
    ></WrapperModal>
  ) : null;
};

export default Modal;
