const LOCAL_STORAGE_KEY = '__kaizen_video_player';

type MemorableSettings = {
  muted: boolean;
  volume: number;
};
function restoreSettings() {
  const stored: any =
    (() => {
      const value = (window.localStorage && window.localStorage.getItem(LOCAL_STORAGE_KEY)) || '';
      try {
        return JSON.parse(value);
      } catch {
        return null;
      }
    })() || {};
  const settings: MemorableSettings = { muted: false, volume: 1 };
  if (typeof stored.muted === 'boolean') settings.muted = stored.muted;
  if (typeof stored.volume === 'number') settings.volume = stored.volume;
  return settings;
}
function storeSettings(settings: { muted?: boolean; volume?: number }) {
  const stored = restoreSettings();
  if (typeof settings.muted === 'boolean') stored.muted = settings.muted;
  if (typeof settings.volume === 'number') stored.volume = settings.volume;
  if (window.localStorage) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stored));
  }
  return stored;
}

export { restoreSettings, storeSettings };
