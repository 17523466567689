import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Drawer, ContentCard } from 'views/components/compound';
import { InputText, Textarea } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import { observer } from 'mobx-react';
import { useSharableLinkInfoSetting } from 'organisms/ShareableLink/useSharableLinkInfoSetting';
import { useHearingLink } from 'organisms/ShareableLink/useHearingLink';

const SharableLinkInfoSetting: FC = () => {
  const {
    isInfoDrawerOpen,
    handleOpenInfoDrawer,
    originalTitle,
    originalMemo,
    client,
    disableInfoDrawerAction,
    closeInfoDrawer,
    title,
    changeTitle,
    memo,
    changeMemo,
    handleSaveLinkInfo,
  } = useSharableLinkInfoSetting();

  const { isArchived } = useHearingLink();

  const { t } = useTranslation();

  return (
    <>
      <ContentCard
        title={t('common.description')}
        buttonLabel={t('common.edit')}
        onClickButton={handleOpenInfoDrawer}
        disableAction={isArchived}
      >
        <SubTitle>{t('sharingLink.administrationSetting.titleLabel')}</SubTitle>
        <TextContent>{originalTitle}</TextContent>
        <SubTitle>{t('common.memoTitle')}</SubTitle>
        <TextContent>{originalMemo}</TextContent>
        <SubTitle>{t('common.customerTitle')}</SubTitle>
        <TextContent>{client?.name || t('common.notSpecified')}</TextContent>
      </ContentCard>
      <Drawer
        disabledAction={disableInfoDrawerAction}
        isOpen={isInfoDrawerOpen}
        onClose={closeInfoDrawer}
        title={t('embeddingLink.infoSetting.infoInput')}
        onSave={handleSaveLinkInfo}
      >
        <Label>
          <MainLabel>{t('sharingLink.administrationSetting.titleLabel')}</MainLabel>
          <SubLabel>{t('sharingLink.administrationSetting.subTitleLabel')}</SubLabel>
        </Label>
        <Input onChange={changeTitle} value={title || ''} maxLength={40} />

        <Label>
          <MainLabel>{t('sharingLink.administrationSetting.memoLabel')}</MainLabel>
          <SubLabel>{t('sharingLink.administrationSetting.subMemoLabel')}</SubLabel>
        </Label>
        <Textarea onChange={changeMemo} value={memo || ''} height={252} maxLength={200} />
      </Drawer>
    </>
  );
};

export default observer(SharableLinkInfoSetting);

const SubTitle = styled('p')({
  fontSize: 12,
  marginBottom: 8,
  lineHeight: '160%',
  marginTop: 24,
  ':first-of-type': {
    marginTop: 0,
  },
});

const TextContent = styled('p')({
  fontSize: 14,
  lineHeight: '160%',
});

const Input = styled(InputText)({});

const Label = styled('div')({
  marginBottom: 8,
});

const MainLabel = styled('p')({
  fontSize: '0.75rem',
  fontWeight: 700,
});

const SubLabel = styled('p')({
  fontSize: '0.75rem',
  color: palette.deepBlueLight,
  lineHeight: 1.5,
});
