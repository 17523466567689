import { observable, action, computed } from 'mobx';
import i18n from 'i18n';
import { MessageProps } from 'types/App';
import { RootStore } from 'stores';
import history from 'utility/history';
import { routes, otherAnswerFixedId } from 'utility/constants';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import { HearingForPreviewViewType, HearingForPreview } from 'entity/HearingForPreview';

export type Choice = {
  questionId: number;
  answerIds: number[];
  otherText?: string;
};

class PreviewHearingStore {
  private readonly hearingAdminApi: HearingAdminApi;
  @observable rootStore: RootStore;
  @observable loading = false;
  @observable hearingForPreview?: HearingForPreviewViewType;
  @observable currentQuestionIndex = 0;
  @observable usersChoicesOfAnswers: Choice[] = []; //to store users' choice of answers
  @observable questionPlayAnimation?: 'left' | 'right';

  constructor({
    rootStore,
    hearingAdminApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
  }

  @computed get currentQuestion() {
    return this.hearingForPreview?.questions[this.currentQuestionIndex];
  }

  @computed get isFirstQuestion() {
    return this.currentQuestionIndex === 0;
  }

  @computed get isLastQuestion() {
    return this.currentQuestionIndex + 1 === this.hearingForPreview?.questions.length;
  }

  @computed get hasAnsweredCurrentQuestion() {
    return this.usersChoicesOfAnswers.some(choice => {
      if (choice.questionId !== this.currentQuestion?.id) return false;

      if (choice.answerIds.includes(otherAnswerFixedId))
        return (choice.otherText?.trim() ?? '').length > 0;

      return choice.answerIds.length > 0;
    });
  }

  @action.bound
  public pushFlashMessage(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getHearing(hearingId: number) {
    this.loading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.hearingAdminApi.getHearingById({
        id: hearingId.toString(),
        organization_id,
      });
      if (!data || data?.question_sets.length <= 0) {
        this.pushFlashMessage({
          content: i18n.t('admin.previewHearing.noQuestion'),
          status: 'error',
        });
        history.push(`${routes.management.content}?tab=questionnaires`);
        return;
      }

      this.hearingForPreview = HearingForPreview.fromDataFileResponseJSON(data).toJSON();
    } catch {
      this.pushFlashMessage({
        content: i18n.t('admin.hearingSet.messages.notFoundHearing'),
        status: 'error',
      });
      history.push(`${routes.management.content}?tab=questionnaires`);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetHearing() {
    this.hearingForPreview = undefined;
    this.currentQuestionIndex = 0;
    this.usersChoicesOfAnswers = [];
    this.questionPlayAnimation = undefined;
  }

  @action.bound
  public selectAnswerForCheckBox(payload: { questionId: number; answerId: number }) {
    const { questionId, answerId } = payload;
    const previousChoice = this.usersChoicesOfAnswers.find(
      choice => choice.questionId === questionId
    );

    if (previousChoice) {
      const newChoices = this.usersChoicesOfAnswers.map(choice => {
        if (choice.questionId !== questionId) return choice;

        return choice.answerIds.includes(answerId)
          ? { ...choice, answerIds: choice.answerIds.filter(id => id !== answerId) }
          : { ...choice, answerIds: [...choice.answerIds, answerId] };
      });
      this.usersChoicesOfAnswers = newChoices;
      return;
    }

    this.usersChoicesOfAnswers = [
      ...this.usersChoicesOfAnswers,
      { questionId, answerIds: [answerId] },
    ];
  }

  @action.bound
  public selectAnswerForRadio(payload: { questionId: number; answerId: number }) {
    const { questionId, answerId } = payload;
    const previousChoice = this.usersChoicesOfAnswers.find(
      choice => choice.questionId === questionId
    );

    if (previousChoice) {
      const newChoices = this.usersChoicesOfAnswers.map(choice => {
        if (choice.questionId === questionId)
          return { ...choice, answerIds: [answerId], otherText: undefined };
        return choice;
      });
      this.usersChoicesOfAnswers = newChoices;
      return;
    }

    this.usersChoicesOfAnswers = [
      ...this.usersChoicesOfAnswers,
      {
        questionId,
        answerIds: [answerId],
      },
    ];
  }

  @action.bound
  public selectAnswer(payload: {
    questionId: number;
    answerId: number;
    mode: 'checkbox' | 'radio';
  }) {
    const { questionId, answerId, mode } = payload;

    if (mode === 'checkbox') {
      this.selectAnswerForCheckBox({ questionId, answerId });
    } else {
      this.selectAnswerForRadio({ questionId, answerId });
    }
  }

  @action.bound
  public selectOtherForCheckBox(questionId: number) {
    const previousChoice = this.usersChoicesOfAnswers.find(
      choice => choice.questionId === questionId
    );

    if (previousChoice) {
      const newChoices = this.usersChoicesOfAnswers.map(choice => {
        if (choice.questionId !== questionId) return choice;

        return choice.answerIds.includes(otherAnswerFixedId)
          ? {
              ...choice,
              answerIds: choice.answerIds.filter(id => id !== otherAnswerFixedId),
              otherText: undefined,
            }
          : { ...choice, answerIds: [...choice.answerIds, otherAnswerFixedId] };
      });
      this.usersChoicesOfAnswers = newChoices;
      return;
    }

    this.usersChoicesOfAnswers = [
      ...this.usersChoicesOfAnswers,
      { questionId, answerIds: [otherAnswerFixedId] },
    ];
  }

  @action.bound
  public selectOtherForRadio(questionId: number) {
    const previousChoice = this.usersChoicesOfAnswers.find(
      choice => choice.questionId === questionId
    );

    if (previousChoice) {
      const newChoices = this.usersChoicesOfAnswers.map(choice => {
        if (choice.questionId === questionId)
          return { ...choice, questionId, answerIds: [otherAnswerFixedId] };
        return choice;
      });
      this.usersChoicesOfAnswers = newChoices;
      return;
    }

    this.usersChoicesOfAnswers = [
      ...this.usersChoicesOfAnswers,
      { questionId, answerIds: [otherAnswerFixedId] },
    ];
  }

  @action.bound
  public selectOther(payload: { questionId: number; mode: 'checkbox' | 'radio' }) {
    const { questionId, mode } = payload;
    if (mode === 'checkbox') {
      this.selectOtherForCheckBox(questionId);
    } else {
      this.selectOtherForRadio(questionId);
    }
  }

  @action.bound
  public setOtherText(questionId: number, value: string) {
    this.usersChoicesOfAnswers = this.usersChoicesOfAnswers.map(choice => {
      if (choice.questionId === questionId) return { ...choice, otherText: value };
      return choice;
    });
  }

  @action.bound
  public changeQuestionIndex(diff: 1 | -1) {
    if (
      this.hearingForPreview?.questions[this.currentQuestionIndex].question_set_id !==
      this.hearingForPreview?.questions[this.currentQuestionIndex + diff].question_set_id
    ) {
      this.questionPlayAnimation = undefined;
    } else if (diff === 1) {
      this.questionPlayAnimation = 'right';
    } else this.questionPlayAnimation = 'left';
    this.currentQuestionIndex = this.currentQuestionIndex + diff;
  }
}

export default PreviewHearingStore;
