import { PlaylistDetail } from 'types/Playlist';

export const defaultPlaylist: PlaylistDetail = {
  id: 0,
  title: '',
  status: 'draft',
  description: '',
  thumbnail_url: '',
  product_contents_count: 0,
  created_at: '',
  updated_at: '',
  product_contents: [],
};
