import React, { FC, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import useStore from 'hooks/useStore';
import { Client } from 'types/Client';
import { useDebounce } from 'react-use';

type Props = {
  defaultClient?: Client;
  onChange: (client?: Client) => void;
  error?: string;
  disabled?: boolean;
  label?: string;
  className?: string;
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &.error {
    & div {
      border-color: ${palette.redPrimary};
      &::placeholder {
        color: ${palette.redPrimary};
        -webkit-text-fill-color: ${palette.redPrimary};
      }
    }
    span.error_text {
      margin-top: 10px;
      color: ${palette.redPrimary};
      font-size: 13px;
    }
  }
  & label {
    margin-bottom: 8px;
    font-size: 0.75rem;
    .icon {
      position: relative;
      top: 4px;
      left: 4px;
      path {
        color: ${palette.grayMedium10};
      }
    }
    .required {
      color: ${palette.redPrimary};
      margin-left: 4px;
      font-size: 0.625rem;
    }
  }
`;

const DELAY = 500;

const ClientSelect: FC<Props> = ({
  defaultClient,
  onChange,
  error,
  disabled,
  label,
  className,
}) => {
  const {
    clientStore: { isLoading, clientList, getClients, resetClientList, loadMore },
  } = useStore();
  const [selected, setSelected] = useState<Client | undefined>(defaultClient);
  const [dispError, setDispError] = useState(false);
  const [composing, setComposing] = useState(false);
  const [key, setKey] = useState('');

  const clientOptions = useMemo(() => {
    const opts = clientList.map(it => ({ label: it.name, value: it.id }));
    if (selected && !clientList.find(cl => cl.id === selected.id)) {
      opts.unshift({ label: selected.name, value: selected.id });
    }
    return opts;
  }, [clientList]);

  useEffect(() => {
    resetClientList();
    getClients({});
  }, []);

  function handleChange(newValue: any) {
    if (newValue) {
      const { value } = newValue;
      const client = clientList.find(it => it.id === value);
      setSelected(client);
      onChange(client);
    } else {
      setSelected(undefined);
      onChange(undefined);
    }
  }

  function getValue() {
    return clientOptions.find(it => it.value === selected?.id);
  }

  useDebounce(() => !composing && getClients({ key }), DELAY, [key, composing]);

  function handleInputChange(val: string) {
    setDispError(true);
    setKey(val);
  }

  function onCompositionStart() {
    setComposing(true);
  }

  function onCompositionEnd() {
    setComposing(false);
  }

  function startListeningComposition() {
    if (document && document.addEventListener) {
      document.addEventListener('compositionstart', onCompositionStart, false);
      document.addEventListener('compositionend', onCompositionEnd, false);
    }
  }
  function stopListeningComposition() {
    if (document && document.removeEventListener) {
      document.removeEventListener('compositionstart', onCompositionStart);
      document.removeEventListener('compositionend', onCompositionEnd);
    }
  }

  useEffect(() => {
    startListeningComposition();
    return () => stopListeningComposition();
  }, []);

  return (
    <InputWrapper className={`input-wrapper ${className} ${error && dispError ? 'error' : ''}`}>
      {label && <label>{label}</label>}
      <Select
        isLoading={isLoading}
        onChange={handleChange}
        options={clientOptions}
        onInputChange={handleInputChange}
        value={getValue()}
        placeholder=""
        isDisabled={disabled}
        isClearable
        onMenuScrollToBottom={_ => loadMore({ key })}
      />
      {error && dispError && <span className="error_text">{error}</span>}
    </InputWrapper>
  );
};

export default observer(ClientSelect);
