import { useStore } from 'hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard as useCopy } from 'react-use';

const useCopyToClipboard = (textToCopy: string) => {
  const { t } = useTranslation();

  const {
    appStore: { handleFlashMessage },
  } = useStore();

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    copyToClipboard(textToCopy);
  };

  const [copyStatus, copyToClipboard] = useCopy();

  useEffect(() => {
    if (copyStatus.value && !copyStatus.error) {
      handleFlashMessage({ content: `${t('sharingLink.copySuccess')}`, status: 'success' });
    } else if (copyStatus.error) {
      handleFlashMessage({ content: `${t(`sharingLink.copyFail`)}`, status: 'error' });
    }
  }, [copyStatus]);

  return { handleCopy };
};

export default useCopyToClipboard;
