import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { HearingItem } from 'types/HearingSet';
import ClientInfo from './ClientInfo';
import { Button as _Button } from 'views/components/primitive';
import { palette } from 'entity/createTheme';
import { getImgFromContent } from 'utility/helpers';
import { ContentViewLog, SharingLinkResult } from 'types/HearingLink';
import QuestionAnswerList from './QuestionAnswerList';
import ContentItem from './ContentItem';
import NoAnswerQuestionSet from './NoAnswerQuestionSet';

type Props = {
  title?: string;
  hearingSet: HearingItem;
  handleChangeNote?: (answerId: number, note: string) => void;
  submitSaveNote?: () => void;
  isLinkCreator?: boolean;
  result: SharingLinkResult;
  hideFeedbackSection: boolean;
  viewingLogs?: ContentViewLog[];
};

const HearingResult: FC<Props> = ({
  title,
  handleChangeNote,
  submitSaveNote,
  isLinkCreator,
  hearingSet,
  result,
  hideFeedbackSection,
  viewingLogs,
}) => {
  const { t } = useTranslation();

  const memo = result.sharing_link_result?.memo || '';
  const hearingLinkAnswers = result.sharing_link_result?.answered_questions;
  const disabledSave = result.sharing_link_result?.disabledSave ?? true;

  function handleSaveNote() {
    if (submitSaveNote) {
      submitSaveNote();
    }
  }

  return (
    <HearingResultContentWrapper>
      <LinkTitle>
        <Title>{title}</Title>
        {isLinkCreator && (
          <Button
            label={t('common.save')}
            onClick={handleSaveNote}
            type="primary"
            size="sm"
            disabled={disabledSave}
          />
        )}
      </LinkTitle>

      {result?.form && <ClientInfo sharingLinkForm={result.form} />}

      <ResultDetail>
        {hearingSet.question_sets.map(questionSet => {
          const { id, content } = questionSet;
          const thumbnail = getImgFromContent(content);
          const questionSetAnswers =
            !hearingLinkAnswers || hearingLinkAnswers.length <= 0
              ? []
              : hearingLinkAnswers.filter(answer => answer.question_set_id === id);
          const contentViewLog = viewingLogs?.filter(
            log => log.content_id === questionSet.content?.id
          )[0]?.logs;
          return (
            <QuestionSetAnswer key={id}>
              <ContentItem
                thumbnail={thumbnail}
                hearingSetId={hearingSet.id}
                isLinkTypeHearing
                contentViewLog={contentViewLog}
                contentType={content?.content_type}
              />
              {questionSetAnswers.length > 0 ? (
                <QuestionAnswerList
                  questionSetAnswers={questionSetAnswers}
                  handleChangeNote={handleChangeNote}
                  isLinkCreator={isLinkCreator}
                />
              ) : (
                <NoAnswerQuestionSet questionSet={questionSet} />
              )}
            </QuestionSetAnswer>
          );
        })}
      </ResultDetail>
      <Divider />
      {!hideFeedbackSection && (
        <ClientFeedback>
          <FeedbackFieldTitle>
            {t('sharingLink.clientCommentTitle').toUpperCase()}
          </FeedbackFieldTitle>
          <FeedbackFieldContent>{memo || ''}</FeedbackFieldContent>
        </ClientFeedback>
      )}
    </HearingResultContentWrapper>
  );
};

export default HearingResult;

const Button = styled(_Button)({
  whiteSpace: 'nowrap',
});

const HearingResultContentWrapper = styled('div')({});

const ResultDetail = styled('div')({
  marginTop: 10,
});

const LinkTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Title = styled('h3')({
  fontSize: '1.25rem',
  marginBottom: 20,
  overflowWrap: 'anywhere',
  wordBreak: 'break-word',
});

const Divider = styled('div')({
  margin: '20px 0',
  width: '100%',
  borderBottom: `1px solid ${palette.grayPrimary}`,
});

const ClientFeedback = styled('div')({});

const FeedbackFieldTitle = styled('p')({
  fontSize: '1rem',
  color: palette.darkMedium6,
  marginBottom: 16,
});

const FeedbackFieldContent = styled('p')({
  wordBreak: 'break-all',
  fontSize: '0.875rem',
});

const QuestionSetAnswer = styled('div')({
  marginBottom: 20,
  padding: '10px 0',
});
