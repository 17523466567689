import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { palette } from 'entity/createTheme';

type CustomProps = {
  customSize?: number;
  customDisableBackground?: string;
};

const useStyles = makeStyles({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 6,
    width: (props: CustomProps) => props.customSize ?? 24,
    height: (props: CustomProps) => props.customSize ?? 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: palette.whitePrimary,
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: palette.whitePrimary,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: (props: CustomProps) => props.customDisableBackground ?? palette.grayMedium1,
    },
    'input:disabled:hover ~ &': {
      boxShadow: 'none',
      background: (props: CustomProps) => props.customDisableBackground ?? palette.grayMedium1,
    },
  },
  checkedIcon: {
    backgroundColor: palette.darkMedium2,
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: (props: CustomProps) => props.customSize ?? 24,
      height: (props: CustomProps) => props.customSize ?? 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: palette.darkMedium1,
    },
  },
});

// Inspired by blueprintjs
function StyledCheckbox({
  onChange,
  checked,
  customSize,
  customDisableBackground,
  ...rest
}: CheckboxProps & CustomProps) {
  const props = { customSize, customDisableBackground };
  const classes = useStyles(props);
  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.stopPropagation();
    if (onChange) onChange(event, checked);
  };

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      onChange={e => handleChange(e, !!checked)}
      checked={checked}
      onClick={e => e.stopPropagation()} // To prevent event bubbling
      {...rest}
    />
  );
}

export default StyledCheckbox;
