import styled from '@emotion/styled';
import { ICreativeListItem } from '@kaizenplatform/creative-search-ui';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from 'assets/images/spinner.gif';
import LeftIcon from 'assets/images/ic_left.svg';
import { bp, palette } from 'entity/createTheme';
import { PDFViewer } from 'views/components/compound';
import KaizenVideoPlayer from 'views/components/compound/CreativeSearch/KaizenVideoPlayerFC';
import { Button, SVGIcon } from 'views/components/primitive';

type Props = {
  selectedCreative: ICreativeListItem;
  setSelectedCreative: Dispatch<SetStateAction<ICreativeListItem | undefined>>;
  setIsSelectedCreativeLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing?: boolean;
  resetEmbeddingVideoMeta?: () => Promise<void>;
  resetExistingContentMeta?: () => void;
};

const PREVIEW_WRAPPER_PADDING = 20;
const calculatePreviewHeight = (width: number, aspectRatio: string) => {
  let ratio = 0.75; // 16:9 と 9:16 の時は 0.75 としています
  switch (aspectRatio) {
    case '6:5':
      ratio = Math.floor((5 * 100) / 6) / 100;
      break;
    case '1:1':
      ratio = 1;
      break;
    default:
      break;
  }
  return Math.floor((width - PREVIEW_WRAPPER_PADDING * 2) * ratio);
};
const DEFAULT_PREVIEW_WIDTH = 1080;
const DEFAULT_ASPECT_RATIO = '16:9';
const DEFAULT_PREVIEW_HEIGHT = calculatePreviewHeight(DEFAULT_PREVIEW_WIDTH, DEFAULT_ASPECT_RATIO);

export const SelectExistingContentsSearchAreaSelectedCreativePreview: FC<Props> = ({
  selectedCreative,
  setSelectedCreative,
  setIsSelectedCreativeLoading,
  isEditing,
  resetEmbeddingVideoMeta,
  resetExistingContentMeta,
}) => {
  const { t } = useTranslation();

  const { aspectRatio, fileUrl, contentType } = selectedCreative;

  const previewWrapperEl = useRef<HTMLDivElement>(null);

  const [previewHeight, setPreviewHeight] = useState(DEFAULT_PREVIEW_HEIGHT);
  const [previewWidth, setPreviewWidth] = useState(DEFAULT_PREVIEW_WIDTH);
  const [ready, setReady] = useState(false);

  const changePreviewHeight = useCallback(() => {
    const el = previewWrapperEl.current;
    if (!(el instanceof HTMLDivElement)) return;

    const { width } = el.getBoundingClientRect();
    const height = calculatePreviewHeight(width, aspectRatio || DEFAULT_ASPECT_RATIO);
    setPreviewHeight(height);
    setPreviewWidth(Math.floor(width - PREVIEW_WRAPPER_PADDING * 2));
  }, [aspectRatio]);

  useEffect(() => {
    changePreviewHeight();
  }, [changePreviewHeight]);

  useEffect(() => {
    window.addEventListener('resize', () => changePreviewHeight());

    return () => {
      window.removeEventListener('resize', () => changePreviewHeight());
    };
  }, []);

  useEffect(() => {
    if (!isEditing) {
      setSelectedCreative(undefined);
    }
  }, [isEditing]);

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const goBackPage = () => setPageNumber(prev => prev - 1);
  const goNextPage = () => setPageNumber(prev => prev + 1);
  const updatePageNumber = useCallback(
    (page: number) => {
      setPageNumber(page);
    },
    [setPageNumber]
  );
  const setTotalPages = useCallback(
    (totalPages: number) => {
      setNumPages(totalPages);
    },
    [setNumPages]
  );

  return (
    <Wrapper className="selected-creative-preview-wrapper">
      <div className="preview-container">
        <div ref={previewWrapperEl} className="preview-wrapper">
          {contentType.includes('video') && (
            <VideoWrapper>
              <KaizenVideoPlayerWrapper
                width={`${previewWidth}px`}
                height={`${previewHeight}px`}
                visibility={ready ? 'visible' : 'hidden'}
              >
                <KaizenVideoPlayer
                  key={selectedCreative?.id || -1}
                  videoId={selectedCreative?.kaizenFilesId || ''}
                  manifestUrl={selectedCreative?.originalHlsUrl || ''}
                  scriptKey={''}
                  onReady={() => {
                    setReady(true);
                  }}
                  poster={selectedCreative.thumbnailUrl ?? undefined}
                  autoplay
                  muted={true}
                />
              </KaizenVideoPlayerWrapper>
              {!ready && (
                <LoaderWrapper>
                  <img className="spinner" src={Spinner} alt="spinner" />
                </LoaderWrapper>
              )}
            </VideoWrapper>
          )}
          {contentType.includes('image') && (
            <Img src={fileUrl} alt="Content" width="100%" height={`${previewHeight}px`} />
          )}
          {contentType.includes('pdf') && (
            <div className="selected-creative-play-control">
              <PDFViewer
                url={fileUrl}
                pageNumber={pageNumber}
                updatePageNumber={updatePageNumber}
                goBackPage={goBackPage}
                goNextPage={goNextPage}
                totalPages={numPages}
                setTotalPages={setTotalPages}
              />
            </div>
          )}
        </div>
      </div>

      <div className="information-wrapper">
        <h3 className="title-for-sales-content">{selectedCreative.title}</h3>
      </div>

      <div className="actions-wrapper">
        <Button
          type="default"
          size="sm"
          label={
            <>
              <SVGIcon src={LeftIcon} />
              <span className="back-btn-label-text">
                {t('creativeSearch.selectAnotherContent')}
              </span>
            </>
          }
          onClick={() => {
            setSelectedCreative(undefined);
            setIsSelectedCreativeLoading && setIsSelectedCreativeLoading(false);
            resetEmbeddingVideoMeta && resetEmbeddingVideoMeta();
            resetExistingContentMeta && resetExistingContentMeta();
          }}
        />
      </div>
    </Wrapper>
  );
};

// styled
const Wrapper = styled('div')({
  '&.selected-creative-preview-wrapper': {
    '.preview-container': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.preview-wrapper': {
      width: '100%',
      padding: `${PREVIEW_WRAPPER_PADDING}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      div: {
        maxWidth: '650px',
        maxHeight: '380px',
      },
    },
    '.title-for-sales-content': {
      fontSize: '20px',
      margin: '20px 0 10px 0',
      [`@media ${bp.md}`]: {
        margin: '16px 0 8px 0',
      },
      '@supports not (overflow-wrap: anywhere)': {
        wordBreak: 'break-word',
      },
      '@supports (overflow-wrap: anywhere)': {
        overflowWrap: 'anywhere',
      },
    },
    '.back-btn-label-text': {
      display: 'inline-block',
      marginLeft: '10px',
      color: palette.deepBluePrimary,
    },
    '.selected-creative-play-control': {
      width: '100%',
      height: 580,
      position: 'relative',
      [`@media ${bp.xmd}`]: {
        height: 480,
      },

      [`@media ${bp.sm}`]: {
        height: 320,
      },

      [`@media ${bp.xs}`]: {
        height: 240,
      },
    },
    //stop applying {maxWidth: '650px', maxHeight: '380px'} for pdf-modal and its children
    '#pdf-modal': {
      maxWidth: 'initial !important',
      maxHeight: 'initial !important',
      div: {
        maxWidth: 'initial !important',
        maxHeight: 'initial !important',
      },
    },
    '.react-pdf__Page__canvas': {
      maxWidth: '100%',
    },
  },
});
const Img = styled('img')({
  objectFit: 'contain',
  maxWidth: 650,
  maxHeight: 380,
});

const KaizenVideoPlayerWrapper = styled.div<{ width: string; height: string; visibility: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
  visibility: ${props => props.visibility};
  position: relative;
`;

const VideoWrapper = styled('div')({
  position: 'relative',
});

const LoaderWrapper = styled('div')({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  left: 'calc(50% - 20px)',
  '.spinner': {
    width: '40px',
    height: '40px',
  },
});
