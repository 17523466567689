import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import QuestionSetItem from './QuestionSetItem';
import { Button as _Button } from 'views/components/primitive';
import { bp, palette } from 'entity/createTheme';
import { AnsweredQuestion } from 'types/App';
import { HearingItem } from 'types/HearingSet';

type Props = {
  hearingSet: HearingItem;
  hearingResult: AnsweredQuestion[];
  handleChangeNote: (answeredQuestionId: number, note: string, hearingId: number) => void;
  updateUserAnswerNote: (hearingId: number) => void;
};

const ResultTabPanel: FC<Props> = ({
  hearingSet,
  hearingResult,
  handleChangeNote,
  updateUserAnswerNote,
}) => {
  const { t } = useTranslation();

  const handleChangeQuestionNote = (answeredQuestionId: number, note: string) => {
    handleChangeNote(answeredQuestionId, note, hearingSet.id);
  };

  return (
    <Fragment>
      <TabContentHeader>
        <Title>{hearingSet.title}</Title>
        <Button
          label={t('common.save')}
          onClick={() => updateUserAnswerNote(hearingSet.id)}
          type="primary"
          size="sm"
          disabled={!hearingSet.isEditingHearingDetail}
        />
      </TabContentHeader>
      {hearingSet.question_sets.map(questionSet => {
        const questionSetAnswers = hearingResult.filter(
          answer => answer.question_set_id === questionSet.id
        );
        return (
          questionSetAnswers.length > 0 && (
            <QuestionSetItem
              handleChangeNote={handleChangeQuestionNote}
              key={questionSet.id}
              questionSetAnswers={questionSetAnswers}
              questionSet={questionSet}
            />
          )
        );
      })}
    </Fragment>
  );
};

export default ResultTabPanel;

const Button = styled(_Button)({
  whiteSpace: 'nowrap',
});

const TabContentHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const Title = styled('h2')({
  fontSize: '1.25rem',
  color: palette.darkPrimary,
  overflowWrap: 'anywhere',
  wordBreak: 'break-word',
  [`@media ${bp.md}`]: {
    fontSize: '1.125rem',
  },
});
