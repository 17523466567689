import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ArchiveIcon from 'assets/images/ic_archive.svg';
import { Button, SVGIcon } from 'views/components/primitive';

const Buttons = styled(Button)`
  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  &.sm {
    svg {
      margin-right: 8px;
    }
  }
`;

interface PropTypes {
  onClick: () => void;
  disabled?: boolean;
  size: 'md' | 'sm';
}

const ArchiveButton: FC<PropTypes> = props => {
  const { t } = useTranslation();
  return (
    <Buttons
      type="default"
      className="archive-btn"
      label={
        <>
          <SVGIcon src={ArchiveIcon} />
          {t('admin.common.archive')}
        </>
      }
      {...props}
    />
  );
};

export default ArchiveButton;
