import styled from '@emotion/styled';
import React, { FC } from 'react';

type Props = {
  currentTabIndex: number;
  selectedTabIndex: number;
};

const TabPanel: FC<Props> = ({ currentTabIndex, selectedTabIndex, children }) => {
  return (
    <Root
      role="tabpanel"
      hidden={selectedTabIndex !== currentTabIndex}
      aria-labelledby={`tab-panel-${currentTabIndex}`}
    >
      {selectedTabIndex === currentTabIndex && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Root>
  );
};

export default TabPanel;

const Root = styled('div')({
  position: 'relative',
  height: '100%',
  fontFamily: 'Hiragino Sans',
});

const ChildrenWrapper = styled('div')({
  paddingBottom: 30,
});
