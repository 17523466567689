import React, { FC } from 'react';
import { Modal } from 'views/components/compound';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';

type Props = {
  isOpen: boolean;
  modalTitle: string;
  modalMessage: string;
  toggleOpen(): void;
  handleConfirm(): void;
};

const HasLinkModal: FC<Props> = ({
  isOpen,
  modalTitle,
  modalMessage,
  toggleOpen,
  handleConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isModal={isOpen} handleToggle={toggleOpen} title={modalTitle}>
      <ModalContent>
        <ModalMessage>{modalMessage}</ModalMessage>
      </ModalContent>
      <Action>
        <ModalButton label={t('common.noText')} size="md" type="default" onClick={toggleOpen} />
        <ModalButton label={t('common.yesText')} size="md" type="primary" onClick={handleConfirm} />
      </Action>
    </Modal>
  );
};

export default HasLinkModal;

const ModalContent = styled('div')({
  padding: '10px 32px',
  maxWidth: 454,
  textAlign: 'center',
});

const ModalMessage = styled('p')({
  marginBottom: 10,
  fontSize: 16,
});

const Action = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const ModalButton = styled(Button)({
  width: 92, //modal action buttons must have same width
  '&:last-of-type': {
    marginLeft: 10,
    background: palette.orangePrimary,
    '&:hover': {
      background: palette.orangeSecondary,
    },
  },
});
