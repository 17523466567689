import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { PDFPageProxy } from 'react-pdf/dist/Page';
import styled from '@emotion/styled';

import NextIcon from 'assets/images/ic_right.svg';
import BackIcon from 'assets/images/ic_left_active.svg';
import ExpandViewIcon from 'assets/images/ic_expand_view.svg';
import { palette, bp, layer } from 'entity/createTheme';
import pdfCMapOptions from 'utility/pdfCMapOptions';
import { SVGIcon, Button } from 'views/components/primitive';

import LoadingIndicator from '../LoadingIndicator';
import NextBackButtons from './NextBackButtons';
import PDFModal from './PDFModal';
import ZoomButtons from '../ZoomButtons';
import { useTimeoutFn } from 'react-use';

type Props = {
  url: string;
  pageNumber: number;
  goBackPage(): void;
  goNextPage(): void;
  updatePageNumber: (page: number) => void;
  totalPages: number;
  setTotalPages: (totalPages: number) => void;
};

const PDFViewer: FC<Props> = ({
  url,
  pageNumber,
  goBackPage,
  goNextPage,
  updatePageNumber,
  setTotalPages,
  totalPages,
}) => {
  const [controlDisplay, setControlDisplay] = useState(1);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [scale, setScale] = useState(1);
  const pdfRef = useRef<HTMLDivElement>(null);
  const [pdfPageWidth, setPdfPageWidth] = useState(0);
  const fixedRatio = useRef<number>(1);

  useEffect(() => {
    // reset pageNumber when opening another PDF
    updatePageNumber(1);
  }, [url]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [, cancel] = useTimeoutFn(() => setControlDisplay(0), 5000);

  const onPageLoadSuccess = (page: PDFPageProxy) => {
    const originalWidth = page.originalWidth;
    const originalHeight = page.originalHeight;
    if (originalWidth && originalHeight) {
      const ratio = originalWidth / originalHeight;
      fixedRatio.current = ratio;
      handleResize();
    }
  };

  const handleResize = () => {
    if (pdfRef && pdfRef.current) {
      const containerWidth = pdfRef.current.getBoundingClientRect().width;
      const containerHeight = pdfRef.current.getBoundingClientRect().height;
      const containerRatio = containerWidth / containerHeight;
      if (fixedRatio.current < containerRatio)
        setPdfPageWidth(fixedRatio.current * containerHeight);
      if (fixedRatio.current > containerRatio) {
        setPdfPageWidth(containerWidth);
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setScale(1);
    setTotalPages(numPages);
  };

  const zoomIn = () => setScale(prev => (prev <= 2 ? prev + 0.1 : prev));
  const zoomOut = () => setScale(prev => (prev > 1 ? prev - 0.1 : prev));

  const openModal = () => {
    setOpenPDFModal(isOpen => !isOpen);
  };

  const onHover = useCallback(() => {
    setControlDisplay(1);
    cancel();
  }, []);

  const onLeave = useCallback(() => setControlDisplay(0), []);

  const isZooming = scale > 1;
  const isFirstPage = pageNumber <= 1;
  const isLastPage = pageNumber >= totalPages;

  return (
    <Container
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onHover}
      id="pdf-viewer__container"
    >
      <PDFWrapper isZooming={isZooming} id="pdf-viewer">
        <Document
          file={url}
          options={pdfCMapOptions}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadIcon />}
        >
          <div ref={pdfRef} style={{ width: '100%', height: '100%' }}>
            <Page
              renderAnnotationLayer={false}
              pageNumber={pageNumber}
              scale={scale}
              width={pdfPageWidth}
              onLoadSuccess={onPageLoadSuccess}
              renderTextLayer={false}
            />
          </div>
        </Document>
      </PDFWrapper>
      <SideButton
        id="pdf-viewer__side-back-button"
        opacity={controlDisplay}
        label={
          <IconWrapper disabled={isFirstPage}>
            <SVGIcon src={BackIcon} id="pdf-viewer__side-back-button__icon" />
          </IconWrapper>
        }
        onClick={goBackPage}
        disabled={isFirstPage}
      />
      <SideButton
        id="pdf-viewer__side-next-button"
        opacity={controlDisplay}
        label={
          <IconWrapper disabled={isLastPage}>
            <SVGIcon src={NextIcon} id="pdf-viewer__side-next-button__icon" />
          </IconWrapper>
        }
        onClick={goNextPage}
        disabled={isLastPage}
      />
      <ControlBar opacity={controlDisplay} id="pdf-viewer__control-bar">
        <ControlWrapper>
          <ZoomButtons zoomOut={zoomOut} zoomIn={zoomIn} scale={scale} />
          <IndexControl
            goBack={goBackPage}
            goNext={goNextPage}
            disableBack={isFirstPage}
            disableNext={isLastPage}
            activeIndex={pageNumber}
            total={totalPages}
          />
          <ExpandIcon src={ExpandViewIcon} onClick={openModal} />
        </ControlWrapper>
      </ControlBar>
      <PDFModal
        url={url}
        fixedRatio={fixedRatio.current}
        isOpen={openPDFModal}
        toggleOpen={openModal}
        pageNumber={pageNumber}
        total={totalPages}
        syncPageNumber={updatePageNumber}
      />
    </Container>
  );
};

export default PDFViewer;

const Container = styled('div')({
  height: 'inherit',
});

const PDFWrapper = styled('div')<{ isZooming: boolean }>(props => ({
  height: 'inherit',
  width: '100%',
  marginRight: '2%',
  background: palette.blackPrimary,
  overflow: 'auto',

  [`@media ${bp.md}`]: {
    width: '100%',
  },

  '&::-webkit-scrollbar-track': {
    background: palette.whitePrimary,
  },

  '&::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.grayMedium2,
    border: 'none',
    borderRadius: 4,
  },

  '.react-pdf__Document': {
    height: '100%',
  },

  '.react-pdf__Page': {
    height: '100% !important',
  },

  '.react-pdf__Page__canvas': {
    margin: '0 auto',
    width: props.isZooming ? '100%' : '100% !important',
    height: props.isZooming ? '100%' : '100% !important',
    objectFit: 'contain',
  },
}));

const SideButton = styled(Button)<{ opacity?: number }>(props => ({
  height: '100%',
  width: '10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  position: 'absolute',
  top: 0,
  '&:last-of-type': {
    right: 0,
  },
  [`@media ${bp.sm}`]: {
    '&:first-of-type': {
      left: 10,
    },
    '&:last-of-type': {
      right: 10,
    },
  },
  '&:disabled': {
    background: 'transparent !important',
  },
  opacity: props.opacity,
  transition: 'all 0.3s linear',
}));

const IconWrapper = styled('div')<{ disabled?: boolean }>(props => ({
  minWidth: 50,
  height: 50,
  background: palette.blackPrimary,
  opacity: 0.3,
  borderRadius: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    opacity: 0.6,
  },
  [`@media ${bp.sm}`]: {
    opacity: 0.6,
    '&:hover': {
      opacity: 0.9,
    },
  },
  ...(props.disabled && { display: 'none' }),
}));

const ControlWrapper = styled('div')({
  height: 35,
  opacity: 0.3,
  background: palette.blackPrimary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 30px',
  borderRadius: 6,
});

const ControlBar = styled('div')<{ opacity?: number }>(props => ({
  position: 'absolute',
  width: '100%',
  bottom: 15,
  zIndex: layer.bottomAbove1,
  paddingRight: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: props.opacity,
  transition: 'all 0.3s linear',
}));

const ExpandIcon = styled(SVGIcon)({
  cursor: 'pointer',
  margin: '0 16px',
});

const IndexControl = styled(NextBackButtons)({
  margin: '0 8px',
  whiteSpace: 'nowrap',
});

const LoadIcon = styled(LoadingIndicator)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
