import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utility/helpers';
import { palette, bp } from 'entity/createTheme';
import {
  Checkbox,
  ContentTypeLabel as _ContentTypeLabel,
  Label as _Label,
} from 'views/components/primitive';
import { ProductItem as ProductItemTypes } from 'types/Product';
import { getImgFromContent } from 'utility/helpers';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import { getChangeStatusAction } from 'utility/getMoreActionsMenuItems';
import { MoreActionsMenu } from '..';

type Props = {
  productContent: ProductItemTypes;
  isSelected: boolean;
  isSelectProduct: boolean;
  onChangeSelected: (e: number) => void;
  onChangeStatus: (id: number, val: string, confirm?: boolean) => void;
  viewDetailProduct: (id: number) => void;
  changeTargetVideo: (id: number, val: string) => void;
  isAdmin: boolean;
};

const ProductItemForManagement: FC<Props> = ({
  productContent,
  isSelected,
  isSelectProduct,
  onChangeSelected,
  onChangeStatus,
  viewDetailProduct,
  changeTargetVideo,
  isAdmin,
}) => {
  const { t } = useTranslation();

  const videos: { manifestId: string; manifestUrl: string }[] = [];
  if (
    productContent.content_type === 'videos' &&
    productContent.kaizen_files_id &&
    productContent.kaizen_files_url
  ) {
    videos.push({
      manifestId: productContent.kaizen_files_id,
      manifestUrl: productContent.kaizen_files_url,
    });
  }
  const handleChangeSelected = () => {
    onChangeSelected(productContent.id);
  };

  const handleChangeStatus = () => {
    const nextStatus = productContent.status === 'published' ? 'draft' : 'published';
    changeTargetVideo(productContent.id, nextStatus);
    onChangeStatus(productContent.id, nextStatus);
  };

  const handleViewDetail = () => {
    viewDetailProduct(productContent.id);
  };

  const changeStatusAction = getChangeStatusAction(handleChangeStatus, productContent.status);
  const adminActionMenu = [changeStatusAction];

  return (
    <WrapperProductItem {...{ onClick: handleViewDetail }}>
      <ContentTypeLabel>
        {t(`common.contentLabelText.${productContent.content_type}` as const)}
      </ContentTypeLabel>
      {videos.length > 0 && <BulkDownloader videos={videos} />}
      <ProductItemThumbnail>
        <img src={getImgFromContent(productContent)} alt="prod-img" />
      </ProductItemThumbnail>
      <ProductItemContent>
        <ProductTitle onClick={handleViewDetail} data-autify_selector="product_content_title">
          {productContent.title}
        </ProductTitle>
        <MetaInfo>
          <ProductTime>
            {t('common.lastUpdated')}: {formatTime(productContent.updated_at, 'yyyy/MM/dd')}
          </ProductTime>
          <Label variant="grey" data-autify_selector="product_content_status">
            {productContent.status === 'draft'
              ? t('admin.common.draft')
              : t('admin.common.publish')}
          </Label>
        </MetaInfo>
        <ActionButtons>
          {isSelectProduct ? (
            <Checkbox checked={isSelected} onChange={handleChangeSelected} />
          ) : (
            <>
              <MoreActionsMenu menuItems={isAdmin ? adminActionMenu : []} />
            </>
          )}
        </ActionButtons>
      </ProductItemContent>
    </WrapperProductItem>
  );
};

export default ProductItemForManagement;

const WrapperProductItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc((100% - 40px) / 3)',
  margin: '0 20px 20px 0',
  borderRadius: 6,
  background: palette.whitePrimary,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,

  /* hover interaction code */
  cursor: 'pointer',
  position: 'relative',

  '&:before': {
    borderRadius: 6,
    position: 'absolute',
    content: '""',
    display: 'block',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  '&:hover': {
    background: palette.grayMedium5,

    '&::before': {
      background: palette.grayBlur4,
    },
  },
  /* -------------------------- */
  '&:nth-of-type(3n)': {
    marginRight: 0,
  },
  [`@media ${bp.md}`]: {
    width: 'calc(50% - 8px)',
    margin: '0 16px 16px 0',
    '&:nth-of-type(3n)': {
      marginRight: 16,
    },
    '&:nth-of-type(2n)': {
      marginRight: 0,
    },
  },
});

const ContentTypeLabel = styled(_ContentTypeLabel)({
  position: 'absolute',
  top: 10,
  left: 10,
});

const ProductItemThumbnail = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 203,
  width: '100%',
  borderRadius: '6px 6px 0 0',
  overflow: 'hidden',
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const ProductItemContent = styled('div')({
  padding: '10px 20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProductTitle = styled('p')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  marginBottom: 10,
  cursor: 'pointer',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const ProductTime = styled('div')({
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: palette.deepBlueLight,
  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});

const ActionButtons = styled('div')({
  flex: 1,
  marginBottom: 13,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  [`@media ${bp.md}`]: {
    marginBottom: 10,
  },
});

const Label = styled(_Label)({
  marginLeft: 6,
  width: 'fit-content',
  height: 'fit-content',
  [`@media ${bp.md}`]: {
    marginLeft: 0,
  },
});

const MetaInfo = styled('div')({
  display: 'flex',
  marginBottom: 20,
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    marginBottom: 12,
  },
});
