import { Question, QuestionViewType } from './Question';
import { Content, ContentViewType } from './Content';
import { QuestionSet as IQuestionSet } from 'types/HearingSet';

export type QuestionSetViewType = {
  id: number;
  content: ContentViewType;
  questions: QuestionViewType[];
  can_delete?: boolean;
};

export class QuestionSet {
  private id: number;
  private content: Content;
  private questions: Question[];
  private can_delete?: boolean;

  constructor(data: IQuestionSet) {
    this.id = data.id;
    this.content = Content.fromJSON(data.content);
    this.questions = data.questions.map(question => Question.fromJSON(question));
    this.can_delete = data.can_delete;
  }

  static isValid(data: any): data is IQuestionSet {
    return true;
  }

  static fromJSON(data: any): QuestionSet {
    if (!this.isValid(data)) throw Error('Invalid type of QuestionSet');
    return new QuestionSet(data);
  }

  toJSON(): QuestionSetViewType {
    return {
      id: this.id,
      content: this.content.toJSON(),
      questions: this.questions.map(question => question.toJSON()),
      can_delete: this.can_delete,
    };
  }
}
