import styled from '@emotion/styled';
import { palette, bp, sizes } from 'entity/createTheme';

export const WrapperHearingPlay = styled('div')`
  height: 100%;
  .hearing-content {
    height: calc(100% - ${sizes.headerHeightLg});
    padding: 30px 30px 60px;
    overflow-y: auto;
    width: 100%;
    max-width: ${sizes.contentWidth};
    margin: 0 auto;

    &::-webkit-scrollbar-track {
      background-color: ${palette.whitePrimary};
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: ${palette.grayMedium1};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${palette.grayMedium1};
      border: none;
    }

    @media ${bp.md} {
      padding: 16px;
    }

    @media ${bp.xs} {
      padding: 5px;
    }

    @media ${bp.mdh} {
      padding: 5px;
    }

    &__main {
      background: ${palette.whitePrimary};
      padding: 20px 20px 30px 20px;
      overflow: hidden;

      @media ${bp.md} {
        padding: 0;
        padding-bottom: 32px;
      }
    }
  }
`;

export const WrapperMemoModal = styled('div')`
  width: 100%;
  .frm-item {
    text-align: center;
    input,
    label,
    textarea {
      width: 100%;
      display: block;
    }

    label {
      color: ${palette.deepBlueLight};
      font-size: 14px;
      text-align: left;
    }

    input {
      padding: 13px 10px;
    }

    .wrapper-area {
      height: 150px;
    }

    .input-wrapper > input::placeholder,
    .wrapper-area > textarea::placeholder {
      font-size: 14px;
      color: ${palette.grayMedium2};
      -webkit-text-fill-color: ${palette.grayMedium2};

      @media ${bp.md} {
        font-size: 16px;
      }
    }
    button.btn_end-hearings {
      margin: 0 auto;
    }
    .disabled-input {
      text-align: left;
      .input-wrapper {
        margin-bottom: 0;
      }
      .notice-text {
        margin: 10px 0 20px 0;
        color: ${palette.grayMedium10};
        font-size: 12px;
      }
    }
  }
`;
