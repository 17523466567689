import { Appointment } from 'types/Appointment';

export const defaultAppointment: Appointment = {
  id: 0,
  company_name: '',
  company_pic_name: '',
  updated_at: '',
  datetime: '',
  memo: '',
  hearing_sets: [],
  product_contents: [],
};

export const defaultAppointmentMeta = {
  currentPage: 1,
  total: undefined,
};
