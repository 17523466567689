import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function useCheckCanPushBack() {
  const [canGoback, setCanGoback] = useState(false);
  const History = useHistory();

  useEffect(() => {
    if (History.action === 'PUSH' || History.action === 'POP') {
      setCanGoback(true);
    }
  }, []);

  function pushBack() {
    if (canGoback) History.goBack();
  }

  return pushBack;
}

export default useCheckCanPushBack;
