import React, { FC } from 'react';
import styled from '@emotion/styled';
import { NoImageIcon as _NoimageIcon } from 'views/components/primitive';
import { palette } from 'entity/createTheme';

type Props = {
  src?: string;
  size?: number; //width of thumbnail
  aspectRatio?: number;
  className?: string;
};

type StyleProps = {
  width: number;
  height: number;
};

const ThumbnailIcon: FC<Props> = ({ src, size = 48, aspectRatio = 1, className }) => {
  const [width, height] = [size, size / aspectRatio];
  return (
    <Root className={className}>
      {src ? (
        <ThumbnailWrapper width={width} height={height}>
          <ThumbnailImage src={src} alt="thumbnail" />
        </ThumbnailWrapper>
      ) : (
        <NoImageWrapper width={width} height={height}>
          <NoImageIcon width={width} height={height} />
        </NoImageWrapper>
      )}
    </Root>
  );
};

const Root = styled('div')({});

const NoImageIcon = styled(_NoimageIcon)<StyleProps>(({ width, height }) => ({
  borderRadius: 6,
  width: width,
  height: height,
  svg: {
    height: height / 3,
    width: height / 3,
  },
}));

const ThumbnailWrapper = styled('div')<StyleProps>(({ width, height }) => ({
  width: width,
  height: height,
  borderRadius: 6,
  marginRight: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: palette.blackPrimary,
  overflow: 'hidden',
}));

const NoImageWrapper = styled('div')<StyleProps>(({ width, height }) => ({
  width: width,
  height: height,
  borderRadius: 6,
  marginRight: 10,
}));

const ThumbnailImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  flexShrink: 0,
});

export default ThumbnailIcon;
