import React, { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import UserInfo from './UserInfo';
import Organizations from './Organizations';
import SVGIcon from 'views/components/primitive/SVGIcon';

import { HeaderWrapper } from './styles';
import DefaultAvatar from 'assets/images/ic_default_avatar.svg';
import HumburgerIcon from 'assets/images/ic_humburger.svg';
import CloseIcon from 'assets/images/ic_black_close.svg';
import { UserInfo as UserInfoType } from 'types/App';
import { Logo } from '..';

type Props = {
  toggleOpen: () => void;
  sidebarOpen: boolean | null;
  userData: UserInfoType;
  handleLogout: () => void;
  handleChangeOrganization: (id: string) => void;
  organizationId: string;
};

const Header: FC<Props> = ({
  toggleOpen,
  sidebarOpen,
  userData,
  handleLogout,
  handleChangeOrganization,
  organizationId,
}) => {
  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    toggleOpen();
  };

  return (
    <>
      <HeaderWrapper className="header">
        <Logo />
        <span className="toggle-sidebar">
          <SVGIcon src={sidebarOpen ? CloseIcon : HumburgerIcon} onClick={handleClick} />
        </span>
        <div className="header_info">
          <Organizations
            value={organizationId}
            handleChangeOrganization={handleChangeOrganization}
            organizations={userData.organizations}
          />
          <UserInfo
            userName={userData.username || 'Default'}
            handleLogout={handleLogout}
            avatarUrl={userData.profile_image_url || DefaultAvatar}
          />
        </div>
      </HeaderWrapper>
    </>
  );
};

export default observer(Header);
