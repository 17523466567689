import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import IconButton_ from '../IconButton';
import EditIcon from 'assets/images/ic_edit.svg';
import TrashIcon from 'assets/images/ic_trash.svg';
import ArrowUp from 'assets/images/arrow-up_24x24.svg';
import { layer, palette } from 'entity/createTheme';
import { SortableElement } from 'react-sortable-hoc';
import { CTAItem } from 'types/HearingLink';

type Props = {
  ctaItem: CTAItem;
  ctaItemIndex: number;
  handleClickArrow: (oldIndex: number) => void;
  handleRemoveItem: (index: number) => void;
  handleEditItem: (index: number) => void;
  isEditMode?: boolean;
  dragging: boolean;
};

const SortableCTAItem = SortableElement(
  ({
    ctaItem,
    ctaItemIndex,
    handleClickArrow,
    handleRemoveItem,
    handleEditItem,
    isEditMode,
    dragging,
  }: Props) => {
    const { t } = useTranslation();

    return (
      <CTAItemWrapper
        draggable
        onDragStart={e => e.preventDefault()}
        dragging={dragging}
        isEditMode={!!isEditMode}
      >
        <ItemInfo>
          <Title>{ctaItem.title || t('sharingLink.viewSetting.ctaSetting.defaultLabel')}</Title>
          <LinkTo href={ctaItem.link_to} target="_blank" rel="noopener noreferrer">
            {ctaItem.link_to}
          </LinkTo>
        </ItemInfo>
        {isEditMode && (
          <ActionButtons>
            {ctaItemIndex !== 0 && (
              <ArrowButton
                tooltipText={t('common.tooltipText.moveUp')}
                iconSrc={ArrowUp}
                onClick={() => handleClickArrow(ctaItemIndex)}
              />
            )}
            <IconButton
              tooltipText={t('common.tooltipText.edit')}
              iconSrc={EditIcon}
              onClick={() => handleEditItem(ctaItemIndex)}
            />
            <IconButton
              tooltipText={t('common.tooltipText.delete')}
              iconSrc={TrashIcon}
              onClick={() => handleRemoveItem(ctaItemIndex)}
            />
          </ActionButtons>
        )}
      </CTAItemWrapper>
    );
  }
);

export default SortableCTAItem;

const CTAItemWrapper = styled('div')<{ dragging: boolean; isEditMode: boolean }>(props => ({
  zIndex: layer.modalContent,
  display: 'flex',
  width: '100%',
  border: `solid 1px ${palette.grayPrimary}`,
  borderRadius: 4,
  padding: 8,
  marginTop: 8,
  background: palette.whitePrimary,
  ...(props.isEditMode && {
    '&:hover': {
      cursor: props.dragging ? 'grabbing' : 'grab',
      boxShadow: props.dragging ? 'none' : `1px 2px 5px 0 ${palette.grayBlur2}`,
    },
  }),
}));

const ItemInfo = styled('div')({
  width: '100%',
  minWidth: 0,
});

const Title = styled('p')({
  fontSize: '0.875rem',
  fontWeight: 600,
  wordBreak: 'break-word',
});

const LinkTo = styled('a')({
  display: 'block',
  width: '100%',
  fontSize: '0.75rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ActionButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 8,
});

const IconButton = styled(IconButton_)({
  marginLeft: 8,
  width: 20,
  height: 20,
  cursor: 'pointer',
  svg: {
    width: 'inherit',
    height: 'inherit',
    '& > g > g': {
      fill: palette.darkMedium2,
    },
  },
});

const ArrowButton = styled(IconButton_)({
  width: 24,
  height: 24,
  cursor: 'pointer',
});
