import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaylistItem } from 'types/Playlist';
import {
  Drawer,
  DebouncedSearchInput,
  PlaylistItemForDisplay as PlaylistItemForDisplay_,
  LoadingIndicator,
} from 'views/components/compound';
import { RadioButton as _RadioButton } from 'views/components/primitive';

type Props = {
  open: boolean;
  toggleOpen: () => void;
  playlistList: PlaylistItem[];
  isParentComponentOpen: boolean;
  currentPlaylistId: number;
  selectedPlaylistId: number;
  handleSelectPlaylist: (id: number) => void;
  handleSavePlaylistId: (id: number) => void;
  getPlaylistList: (key: string) => void;
  loading: boolean;
};

const SelectPlaylistDrawer: FC<Props> = ({
  open,
  toggleOpen,
  playlistList,
  isParentComponentOpen,
  currentPlaylistId,
  selectedPlaylistId,
  handleSelectPlaylist,
  handleSavePlaylistId,
  getPlaylistList,
  loading,
}) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState('');

  const disableAction = !selectedPlaylistId;

  const handleClose = () => {
    handleSelectPlaylist(currentPlaylistId);
    toggleOpen();
  };

  const onSaveQuestionnaireId = () => {
    handleSavePlaylistId(selectedPlaylistId);
    toggleOpen();
  };

  useEffect(() => {
    if (!isParentComponentOpen) {
      setSearchKeyword('');
    } else {
      getPlaylistList(searchKeyword);
    }
  }, [isParentComponentOpen]);

  const handleChangeSearchKeyword = (value: string) => {
    if (searchKeyword !== value) {
      setSearchKeyword(value);
    }
  };

  const handleSearch = () => {
    getPlaylistList(searchKeyword);
  };

  return (
    <Drawer
      disabledAction={disableAction}
      isOpen={open}
      title={t('sharingLink.selectPlaylistModalTitle')}
      onClose={handleClose}
      onSave={onSaveQuestionnaireId}
      saveButtonLabel={t('common.select2')}
    >
      <DrawerInnerWrapper>
        <SearchArea>
          <SearchBar
            onSearch={handleSearch}
            placeholder={t('admin.playlist.searchPlaceHolder')}
            searchKeyword={searchKeyword}
            setSearchKeyword={handleChangeSearchKeyword}
          />
        </SearchArea>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <List className="scroll-bar-thumbnail">
            {playlistList.map(playlist => {
              return (
                <ListItem key={playlist.id}>
                  <RadioButton
                    onChange={() => handleSelectPlaylist(playlist.id)}
                    checked={playlist.id === selectedPlaylistId}
                  />
                  <PlaylistItemForDisplay
                    playlistItem={playlist}
                    width={96}
                    aspectRatio={4 / 3}
                    showContentCount
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </DrawerInnerWrapper>
    </Drawer>
  );
};

export default SelectPlaylistDrawer;

const DrawerInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
});

const SearchArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const SearchBar = styled(DebouncedSearchInput)({
  marginBottom: 10,
});

const ListItem = styled('div')({
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const List = styled('div')({
  overflowY: 'auto',
});

const RadioButton = styled(_RadioButton)({
  padding: 0,
  marginRight: 12,
});

const PlaylistItemForDisplay = styled(PlaylistItemForDisplay_)({
  padding: '0px 20px',
});
