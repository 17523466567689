import React, { FC } from 'react';
import { NavLink as _NavLink } from 'react-router-dom';
import { routes } from 'utility/constants';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import { CSSIcon as _CSSIcon } from 'views/components/primitive';
import Button from '@material-ui/core/Button';

interface PropsTypes {
  label: string;
  activeUrl: string;
  iconUrl: string;
  disabled: boolean;
  handleClickNavLink: () => void;
}

const NavigationLink: FC<PropsTypes> = ({
  activeUrl,
  label,
  iconUrl,
  handleClickNavLink,
  disabled,
}) => {
  function handleClick(): void {
    handleClickNavLink();
  }

  function handleActiveLink(match: any, location: any) {
    const { pathname } = location;
    const isSalesContentActive =
      pathname.includes(routes.startAppointment) || pathname.includes(routes.hearingsPlay);
    if (isSalesContentActive) {
      return routes.salesContent.includes(activeUrl);
    }
    return pathname.includes(activeUrl) && pathname.split('/')[1] === activeUrl.split('/')[1];
  }

  return (
    <li onClick={handleClick}>
      <NavLink
        to={activeUrl}
        className={disabled ? 'disabled-link' : ''}
        isActive={handleActiveLink}
        disabled={disabled}
      >
        <Button>{label}</Button>
        <CSSIcon type="arrow_right" />
      </NavLink>
    </li>
  );
};

const NavLink = styled(_NavLink)<{ disabled: boolean }>(props => ({
  color: palette.grayMedium2,
  fontSize: '1rem',
  lineHeight: '1.1875rem',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '0',
  marginBottom: '13px',
  pointerEvents: props.disabled ? 'none' : 'initial',
  opacity: props.disabled ? 0.2 : 1,

  svg: {
    marginRight: 10,
    width: 24,
    height: 24,
  },

  '&:hover': {
    color: palette.whitePrimary,
    'svg > g > g': {
      fill: palette.whitePrimary,
    },
  },

  '&.active': {
    background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(131, 143, 165, 0) 100%)',
    color: palette.whitePrimary,

    'svg > g > g': {
      fill: palette.whitePrimary,
    },
  },
  '.MuiButton-root': {
    padding: 'unset',
    rontSize: 'unset',
    fontFamily: 'unset',
    fontWeight: 'unset',
    lineHeight: 'unset',
    borderRadius: 'inherit',
    letterSpacing: 'unset',
    textTransform: 'unset',
    width: '100%',
    height: '100%',
    textAlign: 'left',
  },
  '.MuiButton-label': {
    margin: '10px 16px',
    width: '100%',
    height: '100%',
    justifyContent: 'unset',
    borderRadius: 'inherit',
  },
}));

const CSSIcon = styled(_CSSIcon)(() => ({
  position: 'absolute',
  right: '30px',
  display: 'none',
  [`@media ${bp.sm}`]: {
    display: 'block',
  },
}));

export default NavigationLink;
