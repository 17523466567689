import React, { FC } from 'react';
import { palette } from 'entity/createTheme';
import styled from '@emotion/styled';

type Props = {
  size?: 'sm' | 'md';
  variant?: 'primary' | 'secondary' | 'default' | 'grey'; //grey: true = 'grey', grey:false = 'default'
  className?: string;
} & JSX.IntrinsicElements['span'];

const Label: FC<Props> = ({ size = 'sm', variant = 'default', className, children, ...rest }) => {
  return (
    <Root className={className} size={size} variant={variant} {...rest}>
      {children}
    </Root>
  );
};

const Root = styled('span')<{
  size: 'sm' | 'md';
  variant: 'primary' | 'secondary' | 'default' | 'grey';
}>(props => ({
  fontSize: 8,
  borderRadius: 4,
  padding: '1px 4px',
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  border: '1px solid',

  ...(props.size === 'sm' && {
    fontSize: 8,
    padding: '0px 4px',
    height: 16,
    minHeight: 16,
    lineHeight: 1.7,
  }),

  ...(props.size === 'md' && {
    padding: '0px 8px',
    fontWeight: 800,
    fontSize: 10,
    lineHeight: 1.6,
    height: 24,
  }),

  ...(props.variant === 'default' && {
    color: palette.darkMedium3,
    borderColor: palette.darkMedium3,
  }),

  ...(props.variant === 'grey' && {
    color: palette.deepBlueLight,
    borderColor: palette.deepBlueLight,
  }),

  ...(props.variant === 'primary' && {
    color: palette.whitePrimary,
    background: palette.darkMedium2,
    borderColor: palette.darkMedium2,
  }),

  ...(props.variant === 'secondary' && {
    color: palette.deepBluePrimary,
    background: palette.base,
    borderColor: palette.borderInput,
  }),
}));

export default Label;
