import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import {
  Drawer,
  LoadingIndicator,
  HearingItemForDisplay,
  DebouncedSearchInput,
} from 'views/components/compound';
import { RadioButton as _RadioButton } from 'views/components/primitive';

type Props = {
  open: boolean;
  toggleOpen: () => void;
  questionnaireList: HearingItem[];
  handleSearchQuestionnaire: (value: string) => void;
  loading?: boolean;
  isParentComponentOpen: boolean;
  currentQuestionnaireId: number;
  selectedQuestionnaireId: number;
  handleSelectQuestionnaire: (questionnaireId: number) => void;
  handleSaveQuestionnareId: (questionnaireId: number) => void;
  getHearingSetList: () => void;
};

const SelectHearingSetDrawer: FC<Props> = ({
  open,
  toggleOpen,
  questionnaireList,
  handleSearchQuestionnaire,
  loading,
  isParentComponentOpen,
  currentQuestionnaireId,
  selectedQuestionnaireId,
  handleSelectQuestionnaire,
  handleSaveQuestionnareId,
  getHearingSetList,
}) => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState('');

  const disableAction = !selectedQuestionnaireId;

  useEffect(() => {
    if (!isParentComponentOpen) {
      setSearchKeyword('');
    } else {
      getHearingSetList();
    }
  }, [isParentComponentOpen]);

  const handleClose = () => {
    handleSelectQuestionnaire(currentQuestionnaireId || 0);
    toggleOpen();
  };

  const onSaveQuestionnaireId = () => {
    handleSaveQuestionnareId(selectedQuestionnaireId);
    toggleOpen();
  };

  const handleSearch = () => {
    handleSearchQuestionnaire(searchKeyword);
  };

  return (
    <Drawer
      disabledAction={disableAction}
      isOpen={open}
      title={t('sharingLink.selectQuestionnaireModalTitle')}
      onClose={handleClose}
      onSave={onSaveQuestionnaireId}
      saveButtonLabel={t('common.select2')}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <DrawerInnerWrapper>
          <SearchArea>
            <SearchBar
              onSearch={handleSearch}
              placeholder={t('modalhearing.searchQuestionnaire')}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </SearchArea>
          <List className="scroll-bar-thumbnail">
            {questionnaireList.map(questionnaire => {
              return (
                <ListItem key={questionnaire.id}>
                  <RadioButton
                    onChange={() => handleSelectQuestionnaire(questionnaire.id)}
                    checked={questionnaire.id === selectedQuestionnaireId}
                  />
                  <HearingItemForDisplay hearing={questionnaire} width={96} aspectRatio={4 / 3} />
                </ListItem>
              );
            })}
          </List>
        </DrawerInnerWrapper>
      )}
    </Drawer>
  );
};

export default SelectHearingSetDrawer;

const DrawerInnerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 60px)',
});

const SearchArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const SearchBar = styled(DebouncedSearchInput)({
  marginBottom: 10,
});

const ListItem = styled('div')({
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const List = styled('div')({
  overflowY: 'auto',
});

const RadioButton = styled(_RadioButton)({
  padding: 0,
  marginRight: 12,
});
