import React, { FC } from 'react';
import { SVGIcon, Tooltip } from 'views/components/primitive';

type Props = {
  tooltipText: string;
  iconSrc: string;
  onClick?: () => void;
  className?: string;
};

const IconButton: FC<Props> = ({ tooltipText, iconSrc, onClick, className }) => (
  <Tooltip className={className} tooltipText={tooltipText}>
    <SVGIcon src={iconSrc} onClick={onClick} />
  </Tooltip>
);

export default IconButton;
