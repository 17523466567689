import { action, observable } from 'mobx';
import HearingSetApi from 'services/HearingSetApi';
import { MessageProps } from 'types/App';
import { PaginationMeta } from 'types/common';
import { HearingItem } from 'types/HearingSet';
import { RootStore } from '../index';
class SalesHearingListStore {
  private readonly hearingSetApi: HearingSetApi;
  @observable public rootStore: RootStore;
  @observable public loading = false;
  @observable public hearingSortType = '';
  @observable public hearingList: HearingItem[] = [];
  @observable public hearingMeta: PaginationMeta = { currentPage: 1, totalItem: 0 };

  constructor({
    rootStore,
    hearingSetApi,
  }: {
    rootStore: RootStore;
    hearingSetApi: HearingSetApi;
  }) {
    this.rootStore = rootStore;
    this.hearingSetApi = hearingSetApi;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getHearingList(page = 1, sort = 'latest', order = 'updated_at') {
    this.loading = true;
    this.hearingSortType = sort;
    this.hearingMeta = { ...this.hearingMeta, currentPage: page };
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingSetApi.getHearingSets(organization_id, {
        order,
        page,
        sort,
        per_page: 8,
      });

      this.hearingList = data.hearing_sets;
      this.hearingMeta = { ...this.hearingMeta, totalItem: data.total };
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loading = false;
    }
  }
}

export default SalesHearingListStore;
