import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { LinkContentType } from 'types/HearingLink';
import { HearingItem } from 'types/HearingSet';
import { ProductItem } from 'types/Product';
import PlaylistSelector from './PlaylistSelector';
import ProductContentSelector from './ProductContentSelector';
import QuestionnaireSelector from './QuestionnaireSelector';
import { PlaylistItem } from 'types/Playlist';

type Props = {
  linkContentType: LinkContentType;
  productContent?: ProductItem;
  questionnaire?: HearingItem;
  playlist?: PlaylistItem;
  toggleProductContentModal: () => void;
  toggleQuestionnaireModal: () => void;
  togglePlaylistModal: () => void;
};

const ContentSelector: FC<Props> = ({
  linkContentType,
  productContent,
  questionnaire,
  toggleProductContentModal,
  toggleQuestionnaireModal,
  playlist,
  togglePlaylistModal,
}) => {
  switch (linkContentType) {
    case 'playlist':
      return (
        <Wrapper>
          <PlaylistSelector playlist={playlist} togglePlaylistModal={togglePlaylistModal} />
        </Wrapper>
      );
    case 'product_contents':
      return (
        <Wrapper>
          <ProductContentSelector
            productContent={productContent}
            toggleProductContentModal={toggleProductContentModal}
          />
        </Wrapper>
      );
    case 'hearing_set':
      return (
        <Wrapper>
          <QuestionnaireSelector
            questionnaire={questionnaire}
            toggleQuestionnaireModal={toggleQuestionnaireModal}
          />
        </Wrapper>
      );
    default:
      return null;
  }
};

export default ContentSelector;

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 24,
  borderRadius: 6,
  background: palette.base,
});
