import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import LogoUrl from 'assets/images/main_logo.svg';
import { bp } from 'entity/createTheme';
import { useStore } from 'hooks';

const Logo: React.FC = () => {
  const {
    appStore: { currentLogoUrl },
    settingLogoStore: { previewMode, previewLogoImage },
  } = useStore();

  const currentLogo = currentLogoUrl || LogoUrl;
  const logoImgSrc = React.useMemo(() => (previewMode ? previewLogoImage : currentLogo), [
    previewLogoImage,
    currentLogo,
    //remove previewMode from dependencies because previewMode is false everytime user uploads a new image
  ]);

  return (
    <HeaderLogo>
      <div className="sidebar_logo">
        {!currentLogoUrl && !previewMode && (
          <img src={LogoUrl} alt="main logo" style={{ height: '28px' }} />
        )}
        {(currentLogoUrl || previewMode) && (
          <img src={logoImgSrc} alt="main logo" style={{ height: '38px' }} />
        )}
      </div>
    </HeaderLogo>
  );
};

const HeaderLogo = styled('span')({
  [`@media ${bp.sm}`]: {
    display: 'none',
  },
  '.sidebar_logo': {
    img: {
      position: 'relative',
      maxHeight: '100%',
      maxWidth: '100%',
    },
    textAlign: 'center',
    position: 'relative',
    width: '181px',
  },
});

export default observer(Logo);
