import { useState, useEffect } from 'react';

interface Item {
  [key: string]: any;
}

function useCollapseList<T extends Item>(
  itemList: Array<T>,
  defaultNumberOfItemToView = 5,
  collapseDeps: Array<unknown> = []
) {
  const isCollapsedList = itemList?.length > defaultNumberOfItemToView;
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = () => setShowAll(true);
  const handleCollapse = () => setShowAll(false);
  const itemsToShow = showAll ? itemList : itemList?.slice(0, defaultNumberOfItemToView);

  useEffect(() => {
    return () => handleCollapse();
  }, collapseDeps);

  return { isCollapsedList, showAll, handleShowAll, handleCollapse, itemsToShow };
}

export default useCollapseList;
