import { ContentStatus } from 'types/common';
import { PlaylistDetail, PlaylistRequestBody } from 'types/Playlist';
import { PlaylistItem, PlaylistListRequestParams } from 'types/Playlist';
import HttpClient from 'adapter/HTTPClient';

type PlaylistListResponse = {
  page: number;
  total: number;
  content_playlists: PlaylistItem[];
};

class PlaylistApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getPlaylists = async ({
    page,
    order,
    per_page,
    sort,
    organization_id,
    key,
    status,
  }: PlaylistListRequestParams) => {
    const res = this.httpClient.request<PlaylistListResponse>({
      method: 'GET',
      url: 'admin/content_playlists',
      params: {
        page,
        per_page,
        order,
        sort,
        organization_id,
        key,
        status,
      },
    });

    return res;
  };

  public getPublishedPlaylists = async ({
    page,
    order,
    per_page,
    sort,
    organization_id,
    key,
  }: Omit<PlaylistListRequestParams, 'status'>) => {
    const res = this.httpClient.request<PlaylistListResponse>({
      method: 'GET',
      url: 'content_playlists',
      params: {
        page,
        per_page,
        order,
        sort,
        organization_id,
        key,
      },
    });

    return res;
  };

  public getPlaylistById = async (id: number, organization_id: string) => {
    const res = await this.httpClient.request<PlaylistDetail>({
      method: 'GET',
      url: `admin/content_playlists/${id}`,
      params: {
        organization_id,
      },
    });
    return res;
  };

  public createPlaylistContent = async (data: PlaylistRequestBody, organization_id: string) => {
    const res = await this.httpClient.request<PlaylistDetail>({
      method: 'POST',
      url: 'admin/content_playlists',
      data: {
        ...data,
        organization_id,
      },
    });

    return res;
  };

  public updatePlaylistContent = async ({
    id,
    data,
    organization_id,
  }: {
    id: number;
    organization_id: string;
    data: Partial<PlaylistRequestBody>;
  }) => {
    const res = await this.httpClient.request<PlaylistDetail>({
      method: 'PUT',
      url: `admin/content_playlists/${id}`,
      data: {
        ...data,
        organization_id,
      },
    });
    return res;
  };

  public updateMultiplePlaylistsStatus = async ({
    ids,
    status,
    organization_id,
  }: {
    ids: number[];
    status: ContentStatus;
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/content_playlists/update_status',
      data: {
        ids,
        status,
        organization_id,
      },
    });
  };
}

export default PlaylistApi;
