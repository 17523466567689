import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';
import SearchInput from 'views/components/compound/SearchInput';

type Props = {
  searchKeyword: string;
  setSearchKeyword(value: string): void;
  onSearch: (val: string) => void;
  placeholder?: string;
  className?: string;
};

const DELAY = 500;

const DebouncedSearchInput: FC<Props> = ({
  searchKeyword,
  setSearchKeyword,
  placeholder,
  onSearch,
  className,
}) => {
  const [composing, setComposing] = useState(false);

  useDebounce(() => !composing && onSearch(searchKeyword), DELAY, [searchKeyword, composing]);

  const handleCompositionStart = () => {
    setComposing(true);
  };

  const handleCompositionEnd = () => {
    setComposing(false);
  };

  const handleChange = (value: string) => {
    setSearchKeyword(value);
  };

  const handleSearch = () => {
    onSearch(searchKeyword);
  };

  return (
    <Root className={className}>
      <SearchInput
        value={searchKeyword}
        placeholder={placeholder}
        onChange={handleChange}
        onSearch={handleSearch}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
      />
    </Root>
  );
};

export default DebouncedSearchInput;

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  border: `1.5px solid ${palette.grayPrimary}`,
  borderRadius: 6,
  padding: 10,
  background: palette.whitePrimary,
  '&:focus-within': {
    borderColor: palette.deepBlueLight,
  },
});
