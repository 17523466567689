import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import Check from 'assets/images/ic_green_check.svg';
import ListItem from '@material-ui/core/ListItem';

type Props = {
  handleClickItem: () => void;
  isActive?: boolean;
  className?: string;
  stopEvent?: boolean;
  children: any;
  disabled?: boolean;
  isSelected?: boolean | null;
};

const DropdownItemComponent: FC<Props> = ({
  children,
  isActive = false,
  handleClickItem,
  className,
  stopEvent = false,
  disabled = false,
  isSelected = false,
}) => {
  return (
    <li>
      <DropdownItem
        button
        className={className}
        onClick={e => {
          if (stopEvent) {
            e.stopPropagation();
          } else {
            handleClickItem();
          }
        }}
        isActive={isActive}
        disabled={disabled}
      >
        {children}
        <img src={Check} style={{ display: isSelected ? 'block' : 'none' }} alt="isSelected" />
      </DropdownItem>
    </li>
  );
};

export default DropdownItemComponent;

// Prevent passing 'isActive' to ListItem component
const DropdownItem = styled(ListItem, { shouldForwardProp: prop => prop !== 'isActive' })<{
  isActive?: boolean;
  disabled?: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: props.isActive ? palette.grayMedium6 : 'inhehit',
  padding: '12px 60px 12px 16px',
  margin: 0,
  fontWeight: 600,
  '&:hover': {
    background: props.disabled ? 'white' : palette.grayMedium5,
  },
  cursor: props.disabled ? 'default' : 'pointer',
  opacity: props.disabled ? 'unset !important' : 'inherit',
  '&.MuiButtonBase-root.Mui-disabled': {
    pointerEvents: props.disabled ? 'unset' : 'none',
  },
  img: {
    position: 'absolute',
    right: '21px',
  },
  height: '48px',
  fontSize: '14px',
}));
