import * as React from 'react';
import { css } from '@emotion/core';
import EmptyDataImg from 'assets/images/empty_data.png';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const EmptyData: React.VFC<Props> = ({ children, className }) => {
  return (
    <div css={Root} className={className}>
      <img src={EmptyDataImg} alt="empty statement" />
      <div>{children}</div>
    </div>
  );
};

export default EmptyData;

const Root = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    margin-top: 24px;
    font-size: 1.125rem;
    font-weight: bold;
  }
`;
