import React, { useState, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import {
  DropdownMenuGlobal,
  DropdownItemGlobal,
  DropdownWrapper as _DropdownWrapper,
} from 'views/components/primitive';
import { palette } from 'entity/createTheme';
import LanguageToggle from './LanguageToggle';
import { ModalSimple } from '..';

type Props = {
  avatarUrl?: string;
  userName?: string;
  handleLogout: () => void;
};

const UserInfo: FC<Props> = ({ avatarUrl, userName, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  function toggleOpen(event: MouseEvent): void {
    event.preventDefault();
    setIsOpen(!isOpen);
  }

  const handleOpenAccSetting = () => {
    window.open(process.env.REACT_APP_KAIZEN_URL, '_blank');
  };

  return (
    <>
      {<ModalSimple isModal={isOpen ? true : false} className={'modalsimple'} />}
      <DropdownWrapper isOpen={isOpen} toggleOpenDropdown={toggleOpen} size="md">
        <UserAvatar src={avatarUrl} alt="avatar" />
        <DropdownMenuGlobal
          isOpen={isOpen}
          toggleOpenDropdown={toggleOpen}
          title={t('common.accountMenu')}
          className="scroll-bar-thumbnail"
        >
          <DropdownItemGlobal handleClickItem={() => {}} stopEvent={true} disabled={true}>
            <LanguageToggle />
          </DropdownItemGlobal>
          <DropdownItemGlobal handleClickItem={handleOpenAccSetting}>
            {t('common.accountSetting')}
          </DropdownItemGlobal>
          <DropdownItemGlobal handleClickItem={handleLogout}>
            {t('common.logoutTitle')}
          </DropdownItemGlobal>
        </DropdownMenuGlobal>
      </DropdownWrapper>
    </>
  );
};

export default UserInfo;

const UserAvatar = styled('img')({
  width: 32,
  height: 32,
  borderRadius: '50%',
  marginRight: 15,
});

const DropdownWrapper = styled(_DropdownWrapper)<{ isOpen: boolean }>(props => ({
  padding: 10,
  fontSize: '1rem',
  background: `${palette.whitePrimary} !important`,
  border: 'none',
  '& >svg': {
    transform: props.isOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: 'transform 0.3s ease',
  },
}));
