import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import { palette, layer } from 'entity/createTheme';
import LinearProgress from '@material-ui/core/LinearProgress';
import SVGIcon from 'views/components/primitive/SVGIcon';
import CloseIcon from 'assets/images/ic_close.svg';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  bar: {
    background: palette.greenPrimary,
  },
});

const Wrapper = styled('div')`
  padding: 1.2rem;
  background: ${palette.whitePrimary};
  width: 250px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.3);
  z-index: ${layer.progressBar};
  p {
    font-size: 0.785rem;
    margin-bottom: 10px;
    &.filename {
      margin-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    & > g > g {
      fill: ${palette.blackPrimary};
    }
  }
`;

interface PropTypes {
  filename: string;
  completed: number;
  text: string;
}

const ProgressBar: FC<PropTypes> = ({ filename, completed, text }) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (completed > 0 && !open) {
      setOpen(true);
    }
    if (completed >= 100 && open) {
      setOpen(false);
    }
  }, [completed]);

  function handleClose() {
    if (open) {
      setOpen(false);
    }
  }

  if (!open || !filename) return null;

  return (
    <Wrapper>
      <p>{text}</p>
      <p className="filename">{filename}</p>
      <LinearProgress variant="determinate" value={completed} classes={classes} />
      <SVGIcon src={CloseIcon} onClick={handleClose} />
    </Wrapper>
  );
};

export default ProgressBar;
