import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, defaults } from 'chart.js';
import { formatDuration } from 'utility/helpers';
import { ChartContext } from 'utility/chartPlugins';

type Props = {
  data: ChartData<'bar', unknown, unknown>;
  height: number;
  handleChangeTooltip: ({
    context,
    tooltipContent,
  }: {
    context: ChartContext;
    tooltipContent: string;
  }) => void;
};

const VideoLogChart: FC<Props> = ({ data, handleChangeTooltip, height }) => {
  return (
    <Bar
      data={data}
      height={height}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
            external: context => {
              const startViewing = context?.tooltip?.dataPoints[0]?.parsed._custom?.start || 0;
              const endViewing = context?.tooltip?.dataPoints[0]?.parsed._custom?.end || 0;
              const duration = Math.abs(endViewing - startViewing);
              const tooltipContent = formatDuration(duration);
              handleChangeTooltip({
                context,
                tooltipContent: `<p class='tooltip-text'>${tooltipContent}</p>`,
              });
            },
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            ticks: {
              callback: (value: any, _: any, __: any) =>
                formatDuration(parseFloat(`${value}`), value >= 3600000),
            },
            position: 'top',
            grid: {
              color: 'transparent',
              tickBorderDashOffset: 5,
              tickColor: `${defaults.borderColor}`,
            },
          },
        },
      }}
    />
  );
};

export default VideoLogChart;
