import Axios from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useBlobDowloader = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');

  const request = useCallback((url: string, fileName?: string) => {
    setIsLoading(true);
    Axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      onDownloadProgress: status => {
        setProgress(Math.floor((status.loaded / status.total) * 100));
      },
    })
      .then(response => {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', createFileName(response.data.type, fileName));
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(t('common.downloadFailed'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const createFileName = useCallback((responseDataType: string, fileName?: string) => {
    const extension = responseDataType.split('/')[1];
    const dataType = responseDataType.split('/')[0];
    return fileName !== '' && fileName !== undefined
      ? `${fileName}.${extension}`
      : `${dataType}.${extension}`;
  }, []);

  const reset = useCallback(() => {
    setError('');
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    progress,
    request,
    reset,
  };
};

export default useBlobDowloader;
