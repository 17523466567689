import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { palette, bp, layer } from 'entity/createTheme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const WrapperModal = styled('div')`
  position: fixed;
  z-index: ${layer.modal2};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s linear;

  .modal {
    max-height: 90vh;
    background: ${palette.whitePrimary};
    border-radius: 6px;
    overflow: hidden;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${palette.darkMedium1};
      height: 54px;
      padding: 20px;

      h2 {
        color: ${palette.whitePrimary};
        font-size: 16px;
        text-transform: uppercase;
      }

      .close_icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          cursor: pointer;
          width: 16px;
          height: 16px;
        }
      }
    }

    &-content {
      padding: 20px;
      height: calc(100% - 54px);

      @media ${bp.md} {
        padding: 16px;
      }
    }
  }
`;
