import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductItem } from 'types/Product';
import { formatTime } from 'utility/helpers';
import { ProductWrapper, Title } from './styles';
import ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';
import { getImgFromContent } from 'utility/helpers';

interface PropTypes {
  productContents: ProductItem[];
}

const ProductContentList: FC<PropTypes> = ({ productContents }) => {
  const { t } = useTranslation();

  return (
    <ProductWrapper>
      <Title>{t('productContent.title')}</Title>
      {productContents.map((content, index) => {
        const thumbnail = getImgFromContent(content);
        return (
          <ItemWithThumbnail
            className="list_prod-item"
            key={`${content.id}-${index}`}
            src={thumbnail}
            title={content.title}
            metaTitle={`${t('productContent.createDate')}: ${formatTime(
              content.updated_at,
              'yyyy/MM/dd'
            )}`}
            greyMetaTitle
          />
        );
      })}
    </ProductWrapper>
  );
};

export default ProductContentList;
