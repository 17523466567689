import HttpClient from 'adapter/HTTPClient';
import { ListRequestParamsMeta } from 'types/common';
import {
  HearingLink,
  HearingLinkItem,
  LinkSettingOption,
  LinkViewLog,
  CreateHearingLinkData,
  GetLinksByContentTypeParams,
  LinkListByContentMeta as LinkListByContent,
} from 'types/HearingLink';
import { SharingLinkResult } from 'types/HearingLink';

type LinkListResponse = {
  hearing_links: HearingLinkItem[];
  total?: number;
};

type LinkArchiveListResponse = {
  hearing_links: HearingLinkItem[];
  page: number;
  total: number;
};

class HearingLinkApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getHearingLinks = async ({
    page,
    order,
    sort,
    per_page,
    user_id,
    organization_id,
  }: ListRequestParamsMeta) => {
    const res = this.httpClient.request<LinkListResponse>({
      method: 'GET',
      url: 'sharing_links/hearings',
      params: {
        page,
        order,
        sort,
        per_page,
        user_id,
        organization_id,
      },
    });

    return res;
  };

  public getHearingLinkById = async (id: string, organization_id: string) => {
    const res = this.httpClient.request<HearingLink>({
      method: 'GET',
      url: `sharing_links/hearings/${id}`,
      params: {
        organization_id,
      },
    });

    return res;
  };

  public getArchivedHearingLinks = async ({
    page,
    order,
    sort,
    per_page,
    organization_id,
  }: ListRequestParamsMeta) => {
    const res = await this.httpClient.request<LinkArchiveListResponse>({
      method: 'GET',
      url: 'sharing_links/hearings',
      params: {
        page,
        order,
        sort,
        per_page,
        status: 'archived',
        organization_id,
      },
    });

    return res;
  };

  public createHearingLink = async (data: CreateHearingLinkData, organization_id: string) => {
    const res = await this.httpClient.request({
      method: 'POST',
      url: 'sharing_links/hearings',
      data: {
        ...data,
        organization_id,
      },
    });
    return res;
  };

  public updateHearingLinkById = async ({
    id,
    option,
    organization_id,
  }: {
    id: string;
    option: LinkSettingOption;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<HearingLink>({
      method: 'PUT',
      url: `sharing_links/hearings/${id}`,
      data: { ...option, organization_id },
    });
    return res;
  };

  public updateHearingLinkStatus = async ({
    ids,
    status,
    organization_id,
  }: {
    ids: number[];
    status: string;
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'sharing_links/update_status',
      params: {
        ids,
        status,
        organization_id,
      },
    });
  };

  public getHearingLinkResults = async (id: number, organization_id: string) => {
    const res = await this.httpClient.request<{ sessions: SharingLinkResult[] }>({
      method: 'GET',
      url: `sharing_links/hearings/${id}/results`,
      params: {
        organization_id,
      },
    });
    return res;
  };

  public getViewingLogs = async ({
    id,
    organization_id,
  }: {
    id: number;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<LinkViewLog>({
      method: 'GET',
      url: `sharing_links/${id}/viewing_logs`,
      params: { organization_id },
    });
    return res;
  };

  public getLinksByContentType = async ({
    page,
    per_page,
    organization_id,
    content_type,
    user_id,
  }: GetLinksByContentTypeParams) => {
    const res = await this.httpClient.request<LinkListByContent>({
      method: 'GET',
      url: 'sharing_links/hearings/by_content',
      params: {
        page,
        per_page,
        organization_id,
        content_type,
        user_id,
      },
    });
    return res;
  };
}

export default HearingLinkApi;
