import styled from '@emotion/styled';
import { ContentViewType } from 'entity/Content';
import { bp, palette } from 'entity/createTheme';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadPDFMeta } from 'types/App';
import { LoadingThumbnail, ReplaceFileButton } from 'views/components/compound';
import PDFViewer from '../../PDFViewer';

type Props = {
  originalContent: ContentViewType;
  editable: boolean;
  meta: UploadPDFMeta;
  handleChangeUploadFile: (file: any) => void;
  notReady?: boolean;
  editMode: boolean;
};

const UploadPDF: FC<Props> = ({
  originalContent,
  editable,
  meta,
  handleChangeUploadFile,
  notReady,
  editMode,
}) => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const goBackPage = useCallback(() => {
    setPageNumber(prev => prev - 1);
  }, [setPageNumber]);
  const goNextPage = useCallback(() => setPageNumber(prev => prev + 1), [setPageNumber]);
  const updatePageNumber = useCallback(
    (page: number) => {
      setPageNumber(page);
    },
    [setPageNumber]
  );
  const setTotalPages = useCallback(
    (totalPages: number) => {
      setNumPages(totalPages);
    },
    [setNumPages]
  );

  return (
    <Fragment>
      <Root>
        <PreviewSection>
          <MainFrameWrap>
            <MainFrame>
              {editMode ? (
                <ImageWrapper>
                  {meta.thumbnailSrc ? (
                    <img src={meta.thumbnailSrc} alt="thumbnail_image" />
                  ) : (
                    <LoadingThumbnail />
                  )}
                </ImageWrapper>
              ) : (
                originalContent.content_type === 'pdfs' && (
                  <PDFViewer
                    url={originalContent.kaizen_files_url}
                    pageNumber={pageNumber}
                    updatePageNumber={updatePageNumber}
                    goBackPage={goBackPage}
                    goNextPage={goNextPage}
                    totalPages={numPages}
                    setTotalPages={setTotalPages}
                  />
                )
              )}
            </MainFrame>
          </MainFrameWrap>
          {editable && (
            <ReplaceFileButton acceptType="content" handleChange={handleChangeUploadFile} />
          )}
        </PreviewSection>
        {meta.filename && (
          <FileNameSection>
            <ContentHeading>{t('admin.common.filename')}</ContentHeading>
            <FileName>{meta.filename}</FileName>
          </FileNameSection>
        )}
      </Root>

      <CautionWrapper>
        <p>{t('admin.questions.messages.updateContentCaution1')}</p>
        <p>{t('admin.questions.messages.updateContentCaution2')}</p>
      </CautionWrapper>
      {meta.error !== '' && <Error className="error">{meta.error}</Error>}
    </Fragment>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const PreviewSection = styled('div')({});

const FileNameSection = styled('div')({
  paddingBottom: 28,
  [`@media ${bp.md}`]: {
    marginTop: 20,
    paddingBottom: 16,
  },
});

const Error = styled('p')({
  color: palette.redPrimary,
  marginTop: 20,
  fontSize: 12,
});

const MainFrameWrap = styled('div')({
  borderRadius: 4,
  width: 582,
  height: 349,
  marginBottom: 8,
  marginRight: 24,
  position: 'relative',
  border: `1px solid ${palette.borderInput}`,
  padding: 24,
  [`@media ${bp.xmd}`]: {
    width: 450,
    height: 270,
    '& #pdf-viewer__control-bar': {
      transform: 'scale(0.85)',
    },
  },
  [`@media ${bp.md}`]: {
    padding: 16,
  },
});

const MainFrame = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  position: 'relative',
  background: palette.blackPrimary,
});

const ImageWrapper = styled('div')({
  background: palette.blackPrimary,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const ContentHeading = styled('p')({
  fontSize: 12,
  marginBottom: 8,
});

const FileName = styled('div')({
  marginTop: 10,
  color: palette.darkMedium2,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: 1.5,
  width: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 8,
  wordBreak: 'break-all',
});

const CautionWrapper = styled('div')({
  marginTop: 10,
  p: {
    fontSize: '0.75rem',
    color: palette.grayMedium4,
    lineHeight: 1.5,
  },
});

export default UploadPDF;
