import i18n from 'i18n';
import { fromJS } from 'immutable';
import { action, computed, observable } from 'mobx';
import AppointmentApi from 'services/AppointmentApi';
import HearingSetApi from 'services/HearingSetApi';
import ProductContentApi from 'services/ProductContentApi';
import { MessageProps } from 'types/App';
import { ProductItem } from 'types/Product';
import { QuestionPlayData, HearingItem, HearingMemo } from 'types/HearingSet';
import { otherAnswerFixedId, routes } from 'utility/constants';
import { getUserAnswerRelatedTags } from 'utility/hearingStoreHelpers';
import { formatWithJPTimeZone, getTotalQuestions, trimSpace } from 'utility/helpers';
import history from 'utility/history';
import { RootStore } from '../index';
import { defaultHearingMemo, defaultProductDetail } from './constants';
import { Content } from 'entity/Content';

class HearingPlayStore {
  private readonly hearingSetApi: HearingSetApi;
  private readonly appointmentApi: AppointmentApi;
  private readonly productContentApi: ProductContentApi;
  @observable public rootStore: RootStore;
  @observable public loading = false;
  @observable public fetchingProducts = false;
  @observable public fetchingHearings = false;
  @observable public isMemoModal = false;
  @observable public isConfirmModal = false;
  @observable public allHearingList: HearingItem[] = [];
  @observable public hearingMemo: HearingMemo = { ...defaultHearingMemo };
  @observable public productContentIds: Array<string> = [];
  @observable public tagRelatedVideos: Map<
    string,
    Extract<ProductItem, { content_type: 'videos' }>[]
  > = new Map();
  @observable public productDetail: ProductItem = { ...defaultProductDetail };
  @observable public isRecording = false;
  @observable public questionPlayList: QuestionPlayData[] = [];
  @observable public currentQuestionIndex = 0;
  @observable public recordedHearings: HearingItem[] = [];
  @observable public hearingTime = '';
  @observable public allProductList: ProductItem[] = [];
  @observable public questionPlayAnimation?: 'left' | 'right';

  constructor({
    rootStore,
    hearingSetApi,
    appointmentApi,
    productContentApi,
  }: {
    rootStore: RootStore;
    hearingSetApi: HearingSetApi;
    appointmentApi: AppointmentApi;
    productContentApi: ProductContentApi;
  }) {
    this.rootStore = rootStore;
    this.hearingSetApi = hearingSetApi;
    this.appointmentApi = appointmentApi;
    this.productContentApi = productContentApi;
  }

  @computed get questionPlayData() {
    return this.questionPlayList[this.currentQuestionIndex];
  }

  @computed get isAllHearingsAnswered() {
    return this.questionPlayList.every(question => {
      return question.userAnswers.includes(otherAnswerFixedId)
        ? question.otherAnswerContent.trim()
        : question.userAnswers.length;
    });
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public resetQuestionPlayAnimation() {
    this.questionPlayAnimation = undefined;
  }

  @action.bound
  public setIsRecording(value: boolean) {
    this.isRecording = value;
  }

  @action.bound
  public toggleMemoModal(): void {
    this.isMemoModal = !this.isMemoModal;
  }

  @action.bound
  public toggleConfirmModal(): void {
    this.isConfirmModal = !this.isConfirmModal;
  }

  @action.bound
  public async getAllHearings() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.fetchingHearings = true;
      const { data } = await this.hearingSetApi.getHearingSets(organization_id, {
        page: 1,
        per_page: 50,
      });
      this.allHearingList = data.hearing_sets;
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.fetchingHearings = false;
    }
  }

  @action.bound
  public startHearing(hearingId: number, isRecording: boolean = this.isRecording) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    if (this.recordedHearings.length > 0) {
      this.currentQuestionIndex = this.questionPlayList.length;
    }
    this.getHearingPlayData(hearingId);
    if (isRecording) {
      history.push(`${routes.hearingsPlay}?hearingId=${hearingId}&organization=${organization_id}`);
    } else {
      window?.open(
        `${routes.hearingsPlay}?hearingId=${hearingId}&organization=${organization_id}`,
        '_blank'
      );
    }
  }

  @action.bound
  public getHearingTime() {
    if (!this.hearingTime) this.hearingTime = new Date().toISOString();
  }

  @action.bound
  public async getHearingPlayData(hearingId: number) {
    this.getHearingTime();
    this.loading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data: hearingToPlay } = await this.hearingSetApi.getHearingSetById(
        hearingId,
        organization_id
      );

      const totalQuestions = getTotalQuestions(hearingToPlay);
      let index = 1;

      hearingToPlay?.question_sets.forEach(questionSet => {
        questionSet.questions.forEach(
          question =>
            (this.questionPlayList = [
              ...this.questionPlayList,
              {
                hearingId: hearingToPlay.id,
                hearingTitle: hearingToPlay.title,
                content: Content.fromJSON(questionSet.content).toJSON(),
                service_resource_id_for_logger: questionSet.service_resource_id_for_logger,
                index: index++,
                totalQuestions,
                ...question,
                userAnswers: [],
                otherAnswerContent: '',
                note: '',
              },
            ])
        );
      });

      this.recordedHearings = [...this.recordedHearings, hearingToPlay];
    } catch (error: any) {
      if (error?.message.includes('404')) {
        this.putFlashMessages({
          content: i18n.t('admin.hearingSet.messages.notFoundHearing'),
          status: 'error',
        });
        this.isRecording = false;
      }
      this.cancelHearingPlay();
      history.push(`${routes.salesContent}?tab=hearings`);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public onChangeHearingMemo(name: string, value: string): void {
    this.hearingMemo = { ...this.hearingMemo, [name]: value };
  }

  @action.bound
  public addProductContentId(id: string) {
    this.productContentIds = [...this.productContentIds.filter(i => i !== id), id];
  }

  @action.bound
  public prepareEndHearingData() {
    const { company_name, company_pic_name, memo } = this.hearingMemo;
    const datetime = formatWithJPTimeZone(this.hearingTime, 'yyyy-MM-dd HH:mm:ss');
    const product_content_ids = this.productContentIds.slice();
    const recordedHearingIds = this.recordedHearings.map(hearing => hearing.id);

    const hearing_sets = recordedHearingIds.map(id => {
      const questions = this.questionPlayList
        .filter(question => question.hearingId === id)
        .map(question => {
          const answer_ids = question.userAnswers.filter(id => id !== otherAnswerFixedId);
          return {
            id: question.id,
            answer_ids,
            has_other: !!question.otherAnswerContent,
            other_answer: question.otherAnswerContent || undefined,
            note: trimSpace(question.note || ''),
          };
        });
      return { id, questions };
    });

    return {
      company_name: trimSpace(company_name || ''),
      company_pic_name: trimSpace(company_pic_name),
      memo: trimSpace(memo),
      product_content_ids,
      datetime,
      hearing_sets,
    };
  }

  @action.bound
  public async handleEndHearing() {
    const data = this.prepareEndHearingData();
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.toggleMemoModal();
      this.loading = true;
      await this.appointmentApi.createQuickHearing(data, organization_id);
      this.putFlashMessages({
        content: i18n.t('hearingPlay.messages.createQuickHearingSuccess'),
        status: 'success',
      });
      window.opener?.postMessage('salesLog', '*');
      window.open('about:blank', '_top')?.close();
      this.cancelHearingPlay();
    } catch (error: any) {
      const content = error?.errors ?? 'error';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public cancelHearingPlay(showToast?: boolean) {
    this.isRecording = false;
    this.productContentIds = [];
    this.hearingMemo = { ...defaultHearingMemo };
    this.tagRelatedVideos = new Map();
    this.recordedHearings = [];
    this.questionPlayList = [];
    this.hearingTime = '';
    this.currentQuestionIndex = 0;
    this.questionPlayAnimation = undefined;
    showToast &&
      this.putFlashMessages({ content: i18n.t('hearingPlay.cancelRecording'), status: 'success' });
  }

  @action.bound
  public async fetchAnswerRelatedVideos(hearingId: number) {
    const questionPlayList = this.questionPlayList.filter(
      question => question.hearingId === hearingId
    );
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    for (const question of questionPlayList) {
      const tags = getUserAnswerRelatedTags(question);
      const tagsKey = tags.join(',');
      if (tags.length > 0 && !this.tagRelatedVideos.has(tagsKey)) {
        const { data } = await this.productContentApi.getProductContents(organization_id, { tags });
        this.tagRelatedVideos = fromJS(this.tagRelatedVideos)
          .set(tagsKey, data.product_contents)
          .toJS();
      }
    }
  }

  @action.bound
  public async getProductDetail(id: number | string) {
    this.getHearingTime();
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.productContentApi.getProductContentById(id, organization_id);
      this.productDetail = data;
    } catch (error: any) {
      this.putFlashMessages({
        content: i18n.t('admin.productContent.messages.notFoundProduct'),
        status: 'error',
      });
      this.isRecording = false;
      history.push(routes.salesContent);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public findCurrentQuestion(hearingId: number) {
    const questions = this.questionPlayList.filter(question => question?.hearingId === hearingId);
    const index = this.questionPlayList.findIndex(
      question => question.id === questions[questions.length - 1]?.id
    );
    if (index >= 0) this.currentQuestionIndex = index;
  }

  @action.bound
  public resetProductDetail() {
    this.productDetail = { ...defaultProductDetail };
  }

  @action.bound
  public changeCurrentIndex(diff: -1 | 1) {
    if (
      this.questionPlayData?.question_set_id !==
      this.questionPlayList[this.currentQuestionIndex + diff]?.question_set_id
    ) {
      this.questionPlayAnimation = undefined;
    } else if (diff === 1) {
      this.questionPlayAnimation = 'right';
    } else this.questionPlayAnimation = 'left';
    this.currentQuestionIndex = this.currentQuestionIndex + diff;
  }

  @action.bound
  public answerQuestion(choiceId: number) {
    if (this.questionPlayData.is_multiple_choice) {
      this.setCheckboxChoice(choiceId);
    } else {
      this.setRadioChoice(choiceId);
    }
  }

  @action.bound
  public setCheckboxChoice(choiceId: number) {
    this.questionPlayList = this.questionPlayList.map(question => {
      if (question.id === this.questionPlayData.id) {
        if (question.userAnswers.includes(choiceId)) {
          choiceId === otherAnswerFixedId && this.setOtherAnswer('');
          question.userAnswers = question.userAnswers.filter(id => id !== choiceId);
        } else {
          question.userAnswers = [...question.userAnswers, choiceId];
        }
      }
      return question;
    });
  }

  @action.bound
  public setRadioChoice(choiceId: number) {
    this.questionPlayList = this.questionPlayList.map(question => {
      if (question.id === this.questionPlayData.id) {
        choiceId !== otherAnswerFixedId && this.setOtherAnswer('');
        question.userAnswers = [choiceId];
      }
      return question;
    });
  }

  @action.bound
  public setOtherAnswer(value: string) {
    this.questionPlayList = this.questionPlayList.map(question => {
      if (question.id === this.questionPlayData.id) {
        question.otherAnswerContent = value;
      }
      return question;
    });
  }

  @action.bound
  public updateUserAnswerNote(questionId: number, value: string) {
    this.questionPlayList.map(question => {
      if (question.id === questionId) {
        question.note = value;
      }
      return question;
    });
  }

  @action.bound
  public async getAllProductList() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.fetchingProducts = true;
      const { data } = await this.productContentApi.getProductContents(organization_id, {
        order: 'updated_at',
        sort: 'desc',
      });

      this.allProductList = data.product_contents;
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.fetchingProducts = false;
    }
  }
}

export default HearingPlayStore;
