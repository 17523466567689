import useStore from 'hooks/useStore';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'utility/constants';
import { ConfirmModal as CancelModal } from 'views/components/compound';

type Props = {
  handleUnload?: any;
};

const ConfirmModal: FC<Props> = ({ handleUnload }) => {
  const {
    hearingPlayStore: { isConfirmModal, toggleConfirmModal, cancelHearingPlay },
  } = useStore();
  const { pathname } = useLocation();

  function confirmCancel() {
    cancelHearingPlay();
    window.removeEventListener('beforeunload', handleUnload);
    if (pathname === routes.startAppointment) {
      window.opener?.postMessage('salesContents', '*');
      window?.open('about:blank', '_top')?.close();
    } else {
      window.opener?.postMessage('salesContentsHearings', '*');
      window?.open('about:blank', '_top')?.close();
    }
  }

  return (
    <CancelModal
      isModal={isConfirmModal}
      toggleModal={toggleConfirmModal}
      handleConfirmModal={confirmCancel}
    />
  );
};

export default observer(ConfirmModal);
