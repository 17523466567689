import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  className?: string;
};

const Divider: FC<Props> = ({ className }) => {
  return <StyledDivider className={className} />;
};

export default Divider;

const StyledDivider = styled('hr')({
  height: 1,
  border: 'none',
  flexShrink: 0,
  background: palette.borderInput,
});
