import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import RadioButtonCheck from '@material-ui/icons/RadioButtonChecked';
import HearingIcon from 'assets/images/ic_hearing_set_active.svg';
import { bp, palette, sizes } from 'entity/createTheme';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { routes } from 'utility/constants';
import history from 'utility/history';
import _HearingPlayInfoItem from 'views/components/compound/HearingPlayInfoItem';
import { Button } from 'views/components/primitive';
import Logo from 'views/components/compound/Logo';
import { useStore } from 'hooks';

type Props = {
  playProductAfterHearingSet?: boolean;
  headerInfo?: { title: string; subTitle: string };
};

const HearingPlayHeader: FC<Props> = ({ playProductAfterHearingSet, headerInfo }) => {
  const { t } = useTranslation();

  const {
    hearingPlayStore: { toggleConfirmModal, toggleMemoModal, isAllHearingsAnswered },
  } = useStore();

  return (
    <Root>
      <ButtonGroup>
        <RecordStatus>
          <RecordingIcon />
          <p>{t('hearingPlay.recording')}</p>
        </RecordStatus>
        <Button type="default" label={t('common.cancel')} size="md" onClick={toggleConfirmModal} />

        {playProductAfterHearingSet ? (
          <Button
            type="primary"
            label={t('hearingPlay.backToResult')}
            size="md"
            onClick={() => {
              history.push(routes.hearingsResult);
            }}
          />
        ) : (
          <Button
            disabled={!isAllHearingsAnswered}
            type="primary"
            label={t('hearingPlay.endAppointment')}
            size="md"
            onClick={toggleMemoModal}
          />
        )}
      </ButtonGroup>
      {headerInfo && (
        <HearingInfo>
          <HearingPlayInfoItem
            title={headerInfo.title}
            subTitle={headerInfo.subTitle}
            srcIcon={HearingIcon}
          />
        </HearingInfo>
      )}
      <Logo />
    </Root>
  );
};

const Root = styled('div')<{ hasInfo?: boolean }>({
  height: sizes.headerHeightLg,
  padding: '0 30px',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: palette.whitePrimary,
  position: 'relative',

  [`@media ${bp.md}`]: {
    padding: 16,
  },

  [`@media ${bp.sm}`]: {
    height: sizes.headerHeightSm,
  },
});

const HearingInfo = styled('div')({
  width: '50%',
  overflow: 'hidden',
  [`@media ${bp.xmd}`]: {
    width: '40%',
  },
  [`@media ${bp.md}`]: {
    width: '30%',
  },
  [`@media ${bp.xs}`]: {
    display: 'none',
  },
});

const ButtonGroup = styled('div')({
  display: 'flex',
  '> button': {
    '&:last-of-type': {
      marginLeft: 10,
    },
  },
});

const RecordStatus = styled('div')({
  img: {
    height: 30,
    width: 30,
    marginRight: 7,
  },
  p: {
    [`@media ${bp.xs}`]: {
      display: 'none',
    },
  },
  '*': {
    color: palette.redPrimary,
  },
  alignItems: 'center',
  display: 'flex',
  marginRight: 16,
});

const HearingPlayInfoItem = styled(_HearingPlayInfoItem)({
  marginRight: 97,
  marginLeft: 10,

  [`@media ${bp.md}`]: {
    marginRight: 38,
  },
});

const RecordingIcon = styled(RadioButtonCheck)({
  fontSize: 24,
  marginRight: 5,
});

export default memo(observer(HearingPlayHeader));
