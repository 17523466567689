import React, { FC, useState } from 'react';
import { useCopyToClipboard } from 'hooks';
import styled from '@emotion/styled';
import { Button as _Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator as _LoadingIndicator,
  NewModal as EmbedCodeModal,
} from 'views/components/compound';
import { palette } from 'entity/createTheme';
import { EmbedCodeMeta } from 'types/EmbeddingLink';
import { TabsWrapper, TabPanel, Tab } from 'views/components/compound';
import LaunchIcon from '@material-ui/icons/Launch';

type Props = {
  embedCodeMeta?: EmbedCodeMeta;
  getEmbedCode?: () => void;
  className?: string;
};

type EmbedCodes = {
  optimizationCloudCode: string | undefined;
  HTMLCode: string | undefined;
};

type TabInfo = {
  id: number;
  tabLabel: string;
  embedCode: string | undefined;
};

const ShowEmbedCodeButton: FC<Props> = ({ className, embedCodeMeta, getEmbedCode }) => {
  const { t } = useTranslation();
  const [showCodeModal, setShowCodeModal] = useState(false);
  const toggleShowCodeModal = () => {
    !showCodeModal && getEmbedCode && getEmbedCode();
    setShowCodeModal(!showCodeModal);
  };

  const generateEmbedCode = (codeMeta: EmbedCodeMeta): EmbedCodes => {
    switch (codeMeta.content_type) {
      case 'embedding_interactive_hearing_set':
        return {
          optimizationCloudCode: `// ${t('embeddingLink.embedCodeCloudDescription1')}
(kz_feed = document.createElement('script')).src = '${codeMeta.feedjs_url}/kz-feed.js';
 document.getElementsByTagName('head')[0].appendChild(kz_feed);
  
// ${t('embeddingLink.embedCodeCloudDescription2')}
$({TARGET_DOM}).prepend('<div class="kz-hearing" data-kz-hearing-token="${
            codeMeta.hash_token
          }" data-kz-sync-mount></div>')
try{kzf.startHearing()}catch(e){};`,
          HTMLCode: `<!-- ${t('embeddingLink.embedCodeHTMLDescription1')} -->
<script src='${codeMeta.feedjs_url}/kz-feed.js' charset="utf-8"></script>
  
<!-- ${t('embeddingLink.embedCodeHTMLDescription2')} -->
<div class="kz-hearing" data-kz-hearing-token="${codeMeta.hash_token}" data-kz-sync-mount></div>`,
        };
      case 'embedding_video':
        return {
          optimizationCloudCode: `// ${t('embeddingLink.embedCodeCloudDescription1')}
(kz_feed = document.createElement('script')).src = '${codeMeta.feedjs_url}/kz-feed.js';
document.getElementsByTagName('head')[0].appendChild(kz_feed);
  
// ${t('embeddingLink.embedCodeCloudDescription2')}
$({TARGET_DOM}).prepend('<div class="kz-video" data-kz-hearing-token="${
            codeMeta.hash_token
          }" data-kz-sync-mount></div>')
try{kzf.startVideo()}catch(e){};`,
          HTMLCode: `<!-- ${t('embeddingLink.embedCodeHTMLDescription1')} -->
<script src='${codeMeta.feedjs_url}/kz-feed.js' charset="utf-8"></script>
          
<!-- ${t('embeddingLink.embedCodeHTMLDescription2')} -->
<div class="kz-video" data-kz-hearing-token="${codeMeta.hash_token}" data-kz-sync-mount></div>`,
        };

      default:
        return {
          optimizationCloudCode: undefined,
          HTMLCode: undefined,
        };
    }
  };

  const embedCode = embedCodeMeta && generateEmbedCode(embedCodeMeta);

  const handleCopyCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleCopy(e);
    toggleShowCodeModal();
  };

  const TAB_LIST: TabInfo[] = [
    {
      id: 0,
      tabLabel: t('embeddingLink.embedCodeCloud'),
      embedCode: embedCode?.optimizationCloudCode,
    },
    {
      id: 1,
      tabLabel: t('embeddingLink.embedCodeHTML'),
      embedCode: embedCode?.HTMLCode,
    },
  ];
  const currentTab = TAB_LIST[0];
  const [activeTabIndex, setActiveTabIndex] = useState(currentTab?.id || 0);
  const { handleCopy } = useCopyToClipboard(TAB_LIST[activeTabIndex].embedCode || '');
  const onChangeTab = (nextTabIndex: number) => {
    if (nextTabIndex !== activeTabIndex) {
      setActiveTabIndex(nextTabIndex);
    }
  };
  return (
    <>
      <Button
        className={className}
        label={t('embeddingLink.showEmbedCode')}
        type="primary"
        size="sm"
        onClick={toggleShowCodeModal}
      />
      <EmbedCodeModal
        isModal={showCodeModal}
        handleToggle={toggleShowCodeModal}
        title={t('embeddingLink.embedCode')}
        onAction={handleCopyCode}
        disableAction={!embedCode}
        actionLabel={t('embeddingLink.copyCode')}
      >
        <EmbedCodeWrapper>
          <TabsWrapper value={activeTabIndex} handleChange={onChangeTab} variant="scrollable">
            {TAB_LIST.map((tabInfo, index) => {
              return <Tab key={index} label={tabInfo.tabLabel} />;
            })}
          </TabsWrapper>
          {TAB_LIST.map((tabInfo, index) => {
            return (
              <TabPanel key={index} selectedTabIndex={activeTabIndex} currentTabIndex={index}>
                <EmbedCode>
                  {tabInfo.embedCode ? <pre>{tabInfo.embedCode}</pre> : <LoadingIndicator />}
                </EmbedCode>
                {tabInfo.id === 0 ? (
                  <ExternalLink
                    target="_blank"
                    href="https://support.kaizenplatform.net/hc/ja/articles/206268781#エクスペリエンススクリプト"
                  >
                    {t('embeddingLink.experienceScriptLink')}
                    <Icon>
                      <LaunchIcon fontSize="small" />
                    </Icon>
                  </ExternalLink>
                ) : (
                  <p></p>
                )}
              </TabPanel>
            );
          })}
        </EmbedCodeWrapper>
      </EmbedCodeModal>
    </>
  );
};

export default ShowEmbedCodeButton;

const Button = styled(_Button)({
  whiteSpace: 'nowrap',
});

const EmbedCodeWrapper = styled('div')({
  width: '100%',
  minHeight: '23rem',
  height: '100%',
  textAlign: 'right',
});

const Icon = styled('span')({
  path: {
    color: palette.greenPrimary,
  },
  verticalAlign: '-3px',
});

const EmbedCode = styled('div')({
  color: palette.whitePrimary,
  minHeight: 170,
  textAlign: 'initial',
  margin: '1rem 0',
  width: '100%',
  height: '100%',
  background: palette.deepBluePrimary,
  borderRadius: 4,
  padding: 8,
  display: 'flex',
  alignItems: 'center',
});

const ExternalLink = styled('a')({
  color: palette.greenPrimary,
  textDecoration: 'none',
});

const LoadingIndicator = styled(_LoadingIndicator)({
  '.icon_spinner': {
    width: '40px !important',
  },
});
