import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { formatDuration } from 'utility/helpers';
import { ChartContext } from 'utility/chartPlugins';

type Props = {
  data: ChartData<'bar', unknown, unknown>;
  handleChangeTooltip: ({
    context,
    tooltipContent,
  }: {
    context: ChartContext;
    tooltipContent: string;
  }) => void;
};

const DocumentLogChart: FC<Props> = ({ data, handleChangeTooltip }) => {
  return (
    <Bar
      data={data}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
            external: context => {
              const tooltipContent = formatDuration(context?.tooltip?.dataPoints[0].parsed.y || 0);
              handleChangeTooltip({
                context,
                tooltipContent: `<p class='tooltip-text'>${tooltipContent}</p>`,
              });
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: (value: any, _: any, __: any) => formatDuration(parseFloat(`${value}`)),
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  );
};

export default DocumentLogChart;
