import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import DocumentLogChart from './DocumentLogChart';
import VideoLogChart from './VideoLogChart';
import { palette } from 'entity/createTheme';
import { DocumentLog, VideoLog } from 'types/HearingLink';
import convertViewLogToChartData from 'utility/convertViewLogToChartData';
import { handleExternalTooltip } from 'utility/chartPlugins';

type Props = {
  contentType: 'pdfs' | 'videos';
  contentViewLog: DocumentLog[] | VideoLog[];
  className?: string;
};

export type TooltipState = {
  display: boolean;
  top: number;
  left: number;
  content: string;
};

const ViewingLogChart: FC<Props> = ({ contentType, contentViewLog, className }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const chartData = convertViewLogToChartData({
    documentLogs: contentType === 'pdfs' ? (contentViewLog as DocumentLog[]) : undefined,
    videoLogs: contentType === 'videos' ? (contentViewLog as VideoLog[]) : undefined,
  });

  if (contentType === 'pdfs') {
    return (
      <ChartWrapper height={200} className={className}>
        <DocumentLogChart
          data={chartData}
          handleChangeTooltip={({ context, tooltipContent }) =>
            handleExternalTooltip({ context, tooltipRef, tooltipContent })
          }
        />
        <ExternalTooltip ref={tooltipRef} />
      </ChartWrapper>
    );
  }
  if (contentType === 'videos') {
    const chartHeight = Math.max(contentViewLog.length * 16, 32);
    return (
      <ChartWrapper className={className}>
        <VideoLogChart
          data={chartData}
          handleChangeTooltip={({ context, tooltipContent }) =>
            handleExternalTooltip({ context, tooltipRef, tooltipContent })
          }
          height={chartHeight}
        />
        <ExternalTooltip ref={tooltipRef} />
      </ChartWrapper>
    );
  }
  return null;
};

export default ViewingLogChart;

const ChartWrapper = styled('div')<{ height?: number }>(props => ({
  width: '100%',
  position: 'relative',
  height: props.height ? `${props.height}px !important` : 'auto !important',
}));

const ExternalTooltip = styled('div')({
  display: 'none',
  position: 'fixed',
  transform: 'translateX(-50%) translateY(calc(-100% - 2px))',
  flexDirection: 'column',
  alignItems: 'center',
  opacity: '0.8 !important',
  '&.show': {
    display: 'flex',
  },
  '& > .tooltip-text': {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: palette.whitePrimary,
    background: palette.blackPrimary,
    padding: '4px 8px',
    borderRadius: 4,
  },
  '&::after': {
    content: '""',
    width: 0,
    height: 0,
    borderLeft: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderTop: `3px solid ${palette.blackPrimary}`,
  },
});
