import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
import { Button, SVGIcon as SVGIcon_ } from 'views/components/primitive';
import { SharingLinkResultModal } from 'views/components/compound';
import { HearingItem } from 'types/HearingSet';
import {
  CustomFormField,
  SharingLinkResult as SharingLinkResultType,
  ViewingLog,
} from 'types/HearingLink';
import { formatTime } from 'utility/helpers';
import { ProductItem } from 'types/Product';
import MobileIcon from 'assets/images/mobile.svg';
import PCIcon from 'assets/images/pc.svg';
import TabletIcon from 'assets/images/tablet.svg';
import DeviceOtherIcon from 'assets/images/device_other.svg';
import { compareDesc } from 'date-fns';
import { useHistory } from 'react-router';
import { routes } from 'utility/constants';

type Props = {
  linkResults: SharingLinkResultType[];
  title?: string;
  productContents?: ProductItem[];
  submitSaveNote: (sessionId: string) => void;
  handleChangeNoteResult: (sessionId: string, answerId: number, note: string) => void;
  hearingSet?: HearingItem;
  isLinkCreator: boolean;
  customFields: CustomFormField[];
  hideFeedbackSection?: boolean;
  linkViewLogs: ViewingLog[];
  sessionVisitId?: number;
  linkDetailURL: string;
};

const ShareableLinkResult: FC<Props> = ({
  linkResults,
  title,
  productContents,
  submitSaveNote,
  handleChangeNoteResult,
  hearingSet,
  isLinkCreator,
  customFields,
  hideFeedbackSection,
  linkViewLogs,
  sessionVisitId,
  linkDetailURL,
}) => {
  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(false);
  const [activeSessionVisitId, setActiveSessionVisitId] = useState<number | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    const activeResult = linkResults.find(result => result.session_visit_id === sessionVisitId);
    if (!activeResult) {
      history.replace(linkDetailURL);
    } else {
      setIsModal(true);
      setActiveSessionVisitId(sessionVisitId);
    }
  }, []);

  const resultsByDate = useMemo(
    () =>
      linkResults.slice().sort((item1, item2) => {
        return compareDesc(
          new Date(
            item1.sharing_link_result
              ? item1.sharing_link_result.created_at
              : item1.visit.updated_at
          ),
          new Date(
            item2.sharing_link_result
              ? item2.sharing_link_result.created_at
              : item2.visit.updated_at
          )
        );
      }),
    [linkResults]
  );

  const colNum = customFields.length + 1;

  const toggleModalResult = (sessionVisitId?: number) => {
    if (sessionVisitId) setActiveSessionVisitId(sessionVisitId);
    if (isModal) {
      setIsModal(false);
      history.replace(linkDetailURL);
    } else {
      setIsModal(true);
      const activeResult = linkResults.find(result => result.session_visit_id === sessionVisitId);
      if (activeResult) {
        history.replace(`${linkDetailURL}/${routes.accessLogs}/${activeResult.session_visit_id}`);
      }
    }
  };

  const openHubSpotResult = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Root>
      <Title>{t('hearingPlay.result.questionnaireTitle')}</Title>
      <div>
        <HeaderItem columns={colNum}>
          <span></span>
          {customFields.map(field => (
            <span key={field.fieldName}>{t(field.label)}</span>
          ))}
        </HeaderItem>
        {resultsByDate.map((result, index) => {
          const viewingLog = linkViewLogs.filter(log => log.session_id === result.id)[0];
          return (
            <Item key={index} columns={colNum} data-autify_selector="hearing_link_list_result_row">
              <AccessInfoWrapper>
                <SVGIcon
                  src={
                    result.device_type === 'mobile'
                      ? MobileIcon
                      : result.device_type === 'tablet'
                      ? TabletIcon
                      : result.device_type === 'pc'
                      ? PCIcon
                      : DeviceOtherIcon
                  }
                />
                <div>
                  <span>
                    {result.device_type === 'pc'
                      ? result.device_type.toUpperCase()
                      : result.device_type[0].toUpperCase() + result.device_type.slice(1)}
                    {` (${result.os} / ${result.browser})`}
                  </span>
                  <TimeText>
                    {formatTime(
                      result.sharing_link_result
                        ? result.sharing_link_result.created_at
                        : result.visit.created_at,
                      'yyyy/MM/dd HH:mm'
                    )}
                  </TimeText>
                </div>
              </AccessInfoWrapper>
              {customFields.map(field => (
                <span key={field.fieldName}>
                  {result.form ? result.form[field.fieldName] : null}
                </span>
              ))}

              <ResultButtons>
                {(result.sharing_link_result || viewingLog) && (
                  <Button
                    type="default"
                    className="result-button"
                    size="sm"
                    label={t('quickHearing.viewResult')}
                    onClick={() => toggleModalResult(result.session_visit_id)}
                    autifySelectorName={'hearing_link_list_result_button'}
                  />
                )}

                {result.hubspot.contact_url != null && (
                  <Button
                    type="default"
                    className="result-button"
                    size="sm"
                    label={t('quickHearing.hubSpotResult')}
                    onClick={() =>
                      result.hubspot.contact_url != null &&
                      openHubSpotResult(result.hubspot.contact_url)
                    }
                    autifySelectorName={'hearing_link_list_hubspot_result_button'}
                    style={{ marginTop: '10px' }}
                  />
                )}
              </ResultButtons>
            </Item>
          );
        })}
      </div>
      <SharingLinkResultModal
        isModal={isModal}
        toggleModal={toggleModalResult}
        linkResults={resultsByDate}
        title={title || ''}
        activeSessionVisitId={activeSessionVisitId}
        productContents={productContents}
        submitSaveNote={submitSaveNote}
        handleChangeNoteResult={handleChangeNoteResult}
        hearingItem={hearingSet}
        isLinkCreator={isLinkCreator}
        hideFeedbackSection={!!hideFeedbackSection}
        linkViewLogs={linkViewLogs}
      />
    </Root>
  );
};

export default ShareableLinkResult;

const Item = styled('div')<{ columns: number }>(props => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${props.columns}, 1fr) 150px`,
  gridGap: 10,
  padding: '10px 20px',
  borderTop: `1px solid ${palette.grayPrimary}`,

  span: {
    fontSize: '0.875rem',
    lineHeight: '32px',
    alignItems: 'center',
    display: 'block',
    wordBreak: 'break-all',
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  '> button': {
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'nowrap',
  },
}));

const HeaderItem = styled(Item)({
  border: 'none',
  marginTop: 5,
  span: {
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: palette.deepBlueLight,
    lineHeight: '15px',
  },
});

const Root = styled('div')({
  background: palette.whitePrimary,
  borderRadius: 6,

  marginTop: 10,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },

  marginBottom: 30,
  paddingTop: 20,
});

const Title = styled('p')({
  textTransform: 'uppercase',
  padding: '0 20px',
});

const AccessInfoWrapper = styled('div')({
  display: 'flex',
  span: {
    marginLeft: '12px',
  },
});

const SVGIcon = styled(SVGIcon_)({
  height: '32px',
  width: '32px',
  marginBottom: 'auto',
  marginTop: 'auto',
});

const TimeText = styled('span')({
  color: palette.grayMedium7,
});

const ResultButtons = styled('div')({
  display: 'grid',
  alignItems: 'center',
});
