type CustomHosts = {
  [environmentName: string]: {
    [organizationId: string]: string;
  };
};

export const customeHosts: CustomHosts = {
  production: {
    '30080': 'https://crevosales.jp',
  },
  qa: {},
  development_2nd: {},
  development_3rd: {},
  development_4th: {},
  development_5th: {},
  development_6th: {},
  development_7th: {},
  development_8th: {},
  development_9th: {},
};
