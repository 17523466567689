import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { palette, bp } from 'entity/createTheme';
import CacheablePlayer from '../CacheablePlayer';
import { LoadingIndicator } from 'views/components/compound';
import { isTouchDevice } from 'utility/helpers';
import styled from '@emotion/styled';
import { SVGIcon } from 'views/components/primitive';
import ReloadIcon from 'assets/images/ic_reload_player.svg';
import { LoggerOption } from 'types/common';
import { PlayerEventListener } from '@kaizenplatform/kaizen-video-player';

const style = css`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  /* .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  } */
`;

interface PropTypes {
  width: string;
  height: string;
  url?: string;
  status: string;
  manifestId?: string;
  manifestUrl?: string | null;
  isVimeo?: boolean;
  loggerOption?: LoggerOption;
  muted?: boolean;
  markers?: number[];
  invertTime?: boolean;
  listeners?: PlayerEventListener[];
}

const VideoPlayer: FC<PropTypes> = ({
  width,
  height,
  url,
  status,
  manifestId,
  manifestUrl,
  isVimeo,
  loggerOption,
  muted,
  markers,
  invertTime,
  listeners,
}) => {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const isVideoMuted = muted || isTouchDevice();

  useEffect(() => {
    setReady(false);
    setHasError(false);
  }, [manifestUrl, manifestId, url]);

  const isUploading = manifestUrl === null; //manifestUrl returns null when video uploading is being processed

  function handleReady() {
    setReady(true);
  }

  function handleError(error: any) {
    const notAllowedError = error.name === 'NotAllowedError';
    if (notAllowedError) return;
    setHasError(true);
  }

  const handleReloadPlayer = () => {
    setHasError(false);
  };

  if (
    (status === 'not_uploaded' && (!manifestUrl || !url)) ||
    isUploading ||
    hasError ||
    status === 'upload_failed'
  ) {
    return (
      <ErrorMessage>
        <p id="video-player__error">
          {status === 'upload_failed'
            ? t('common.videoUploadFailed')
            : t('common.videoNotavailable')}
        </p>
        {status !== 'upload_failed' && (
          <ReloadBtn onClick={handleReloadPlayer} id="video-player__reload">
            <SVGIcon src={ReloadIcon} />
            {t('common.reload')}
          </ReloadBtn>
        )}
      </ErrorMessage>
    );
  }

  return (
    <Root id="video-player-wrapper">
      <div css={style} className="video-player">
        {!ready && <LoadingIndicator />}
        <CacheablePlayer
          muted={isVideoMuted}
          url={url}
          width={width}
          height={height}
          manifestId={manifestId}
          manifestUrl={manifestUrl}
          onReady={handleReady}
          onError={handleError}
          isVimeo={isVimeo}
          loggerOption={loggerOption}
          markers={markers}
          invertTime={invertTime}
          listeners={listeners}
        />
      </div>
    </Root>
  );
};

export default VideoPlayer;

const Root = styled('div')({
  height: 'inherit',
  [`@media ${bp.md}`]: {
    width: '100%',
    marginBottom: 8,
  },
});

const ErrorMessage = styled('div')({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: palette.blackPrimary,
  p: {
    color: palette.whitePrimary,
  },
  textAlign: 'center',
  padding: 24,
  fontSize: 14,
});

const ReloadBtn = styled('button')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: palette.whitePrimary,
  background: 'transparent',
  textDecoration: 'underline',
  border: 'none',
  marginTop: 10,
  fontSize: 14,
  svg: {
    marginRight: 5,
  },
  '&:focus': {
    outline: 'none',
  },
});
