import HttpClient from 'adapter/HTTPClient';
import { Tag, DisplayableMenus } from 'types/App';
class OrganizationApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getOrganizationById = async (id: string) => {
    const res = await this.httpClient.request<{
      id: string;
      roles: string[];
      organization_logo: string | null;
      displayable_menus: DisplayableMenus;
      service_enabled: boolean;
      sfa_integrated: boolean;
    }>({
      method: 'GET',
      url: `organizations/${id}`,
    });

    return res;
  };

  public getOrganizationTags = async (id: string) => {
    const res = await this.httpClient.request<Tag[]>({
      method: 'GET',
      url: `organizations/${id}/tags`,
    });

    return res;
  };
}

export default OrganizationApi;
