import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SlackIntegrationDrawer from '../SlackIntegrationDrawer';
import { useLocationSearch, useStore } from 'hooks';
import { useHistory } from 'react-router-dom';
import { routes } from 'utility/constants';
import { SlackIntegrationResponse } from 'services/Admin/SettingApi';
import SettingMenuItem from '../SettingMenuItem';

const SettingMenuSlack: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    settingSlackStore: { loadSlackIntegration, deleteSlackIntegration },
  } = useStore();
  const [slackIntegration, setSlackIntegration] = useState<SlackIntegrationResponse>();

  const goToPopupSlack = () => {
    const callbackUrl = `https://${window.location.host}/management/callback`;
    const url = `https://slack.com/oauth/authorize?client_id=2256441852.3861454130870&scope=identify,channels:read,groups:read,chat:write:bot&redirect_uri=${callbackUrl}`;
    window.open(
      url,
      'Popup',
      'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30'
    );
  };

  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => {
    setOpen(false);
    history.push(routes.management.setting);
    setRefresh(old => old + 1);
  };

  const locationSearch = useLocationSearch<string>();
  const { action } = locationSearch;

  useEffect(() => {
    if (action === 'channels') {
      openDrawer();
    }
  }, [action]);

  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    const f = async () => {
      try {
        const item = await loadSlackIntegration();
        setSlackIntegration(item);
      } catch (err) {
        console.log(err);
      }
    };
    f();
  }, [refresh]);

  const editChannel = () => {
    setOpen(true);
  };

  const destroyChannel = async () => {
    await deleteSlackIntegration();
    setRefresh(old => old + 1);
  };

  let message;
  if (slackIntegration) {
    message = (
      <>
        {slackIntegration?.state === 'slack_invalid' ? (
          <div>Slack連携に失敗しました。もう一度連携し直して下さい。</div>
        ) : (
          <>
            <div>ワークスペース {slackIntegration.slack_team_name} に連携済です</div>
            {slackIntegration?.user_slack_integration ? (
              <div>
                通知先 #
                {slackIntegration?.user_slack_integration?.slack_channel_name ||
                  slackIntegration?.user_slack_integration?.slack_channel_id}
              </div>
            ) : (
              <div>チャンネル名未設定</div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {slackIntegration ? (
        slackIntegration.state === 'slack_invalid' ? (
          <SettingMenuItem
            menuName={t('admin.setting.slack.title')}
            description={message}
            buttonText={t('admin.setting.slack.link')}
            onClick={goToPopupSlack}
          />
        ) : (
          <SettingMenuItem
            menuName={t('admin.setting.slack.title')}
            description={message}
            buttonText={t('admin.setting.slack.link')}
            onClick={editChannel}
            secondaryButton={{
              buttonText: t('admin.setting.slack.unlink'),
              onClick: destroyChannel,
            }}
          />
        )
      ) : (
        <SettingMenuItem
          menuName={t('admin.setting.slack.title')}
          buttonText={t('admin.setting.slack.link')}
          onClick={goToPopupSlack}
        />
      )}
      <SlackIntegrationDrawer
        open={open}
        // submitting={submitting}
        currentValue={slackIntegration?.user_slack_integration?.slack_channel_name}
        closeDrawer={closeDrawer}
      />
    </>
  );
};

export default SettingMenuSlack;
