import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PlaylistItem } from 'types/Playlist';
import { palette, bp } from 'entity/createTheme';
import { Label, NoImageIcon } from 'views/components/primitive';
import { formatTime } from 'utility/helpers';
import { useTranslation } from 'react-i18next';
import PlaylistPlayRoundedIcon from '@material-ui/icons/PlaylistPlayRounded';
import { useHistory } from 'react-router';
import { routes } from 'utility/constants';
import { CreateLinkButton as _CreateLinkButton } from 'views/components/compound';

type Props = {
  playlistItem: PlaylistItem;
  handleClickCreateLinkButton: (contentId: number) => void;
};

const PlaylistItemForManagement: FC<Props> = ({ playlistItem, handleClickCreateLinkButton }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    thumbnail_url: thumbnail,
    title,
    updated_at,
    status,
    product_contents_count,
    id,
  } = playlistItem;

  const handleClickPlaylistItem = () => {
    history.push(`${routes.management.playlistDetail}/${id}`);
  };
  return (
    <Wrapper onClick={handleClickPlaylistItem}>
      <PlaylistDetailWrapper>
        <ThumbnailWrapper>
          <Thumbnail>
            {thumbnail ? (
              <img src={thumbnail} alt="hearing_avatar" loading="lazy" />
            ) : (
              <NoImageIcon size="100%" svgSize={28} />
            )}
          </Thumbnail>
          <ContentCount>
            <ContentNumber>{product_contents_count}</ContentNumber>
            <PlaylistPlayRoundedIcon />
          </ContentCount>
        </ThumbnailWrapper>
        <PlaylistInfo>
          <Title>{title}</Title>
          <MetaInfo>
            <UpdatedDate>
              {t('common.lastUpdated')}: {formatTime(updated_at || '', 'yyyy/MM/dd')}
            </UpdatedDate>
            <StatusLabel variant="grey" data-autify_selector="hearing_content_status">
              {status === 'draft' ? t('admin.common.draft') : t('admin.common.publish')}
            </StatusLabel>
          </MetaInfo>
        </PlaylistInfo>
      </PlaylistDetailWrapper>
      <CreateLinkButton
        isPublishedItem={playlistItem.status === 'published'}
        toggleCreateLinkDrawer={() => {
          handleClickCreateLinkButton(playlistItem.id);
        }}
        contentType="playlists"
      />
    </Wrapper>
  );
};

export default PlaylistItemForManagement;

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  borderRadius: 6,
  background: palette.whitePrimary,
  border: `1px solid ${palette.whitePrimary}`,
  cursor: 'pointer',
  position: 'relative',
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
  '&:hover': {
    background: palette.grayMedium5,
    border: `1px solid ${palette.grayMedium5}`,
  },
});

const PlaylistDetailWrapper = styled('div')({
  display: 'flex',
});

const ThumbnailWrapper = styled('div')({
  position: 'relative',
  borderRadius: 6,
  overflow: 'hidden',
  width: 160,
  height: 90,
  minWidth: 160,
  [`@media ${bp.md}`]: {
    height: 72,
    width: 128,
    minWidth: 128,
  },
});

const Thumbnail = styled('div')({
  height: '100%',
  width: '100%',
  background: palette.blackPrimary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',

  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },

  [`@media ${bp.md}`]: {
    height: 72,
    width: 128,
  },
});

const ContentCount = styled('div')({
  position: 'absolute',
  right: 0,
  top: 0,
  height: '100%',
  width: '40%',
  background: palette.blackPrimary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.6,
  '& > svg > path': {
    fill: palette.whitePrimary,
  },
});

const ContentNumber = styled('p')({ color: palette.whitePrimary });

const PlaylistInfo = styled('div')({
  marginLeft: 12,
});

const MetaInfo = styled('div')({
  marginTop: 8,
  display: 'flex',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
});

const Title = styled('p')({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const UpdatedDate = styled('p')({
  color: palette.deepBlueLight,
  fontSize: '0.875rem',
});

const StatusLabel = styled(Label)({
  height: 'fit-content',
  width: 'fit-content',
  marginLeft: 6,
  [`@media ${bp.md}`]: {
    marginLeft: 0,
  },
});

const CreateLinkButton = styled(_CreateLinkButton)({
  marginTop: 4,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});
