import HttpClient from 'adapter/HTTPClient';
import moment from 'moment';
import store from '../stores/index';

class DataPlatformApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getChart = async (serviceResourceIds: string[], placement: string, videoId: string) => {
    const query = `query GetChart($id: ID!, $scope: ChartScopeInput!, $breakdowns: [ChartBreakdownInput!], $identifierType: String) {
      chart(id: $id, scope: $scope, breakdowns: $breakdowns, identifierType: $identifierType) {
        id
        type
        structure {
          dimensions {
            columns
            displayOrder
            id
            label
          }
          datasets {
            id
            type
            label
            rows {
              columns
              value
              breakdown
            }
            position
            percentage
          }
        }
      }
    }
    `;
    const { organizationId } = store.appStore;
    const data = {
      operationName: 'GetChart',
      query: query,
      variables: {
        id: `video_viewing-retention_${organizationId}`,
        identifierType: 'resource_key',
        breakdowns: [
          {
            breakdown: 'service',
            filter: ['sales'],
          },
          {
            breakdown: 'placement',
            filter: [placement],
          },
          {
            breakdown: 'service_resource_id',
            filter: [...serviceResourceIds],
          },
          {
            breakdown: 'video_id',
            filter: [videoId],
          },
        ],
        scope: {
          from: '2020-01-01T00:00:00.000+09:00',
          to: moment().format(),
        },
      },
    };
    const res = await this.httpClient.request({
      method: 'POST',
      url: 'GetChart',
      data: data,
    });

    return res;
  };
}

export default DataPlatformApi;
