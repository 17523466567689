import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentType } from 'types/App';
import { QuestionContentTypeOption } from './constants';
import { Dropdown } from 'views/components/compound';

type Props = {
  contentTypeList: QuestionContentTypeOption[];
  contentType: ContentType;
  selectContentType: (type: ContentType) => void;
  className?: string;
  setIsSelectedCreativeLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreateFromExisting?: React.Dispatch<React.SetStateAction<boolean>>;
  creativeId?: string | null;
  resetExistingContentMeta?: () => void;
  isQuestionModalOpen: boolean;
};

const QuestionContentSelector: FC<Props> = ({
  contentTypeList,
  contentType,
  selectContentType,
  className,
  setIsSelectedCreativeLoading,
  setIsCreateFromExisting,
  creativeId,
  resetExistingContentMeta,
  isQuestionModalOpen,
}) => {
  const handleChangeType = (type: ContentType) => {
    selectContentType(type);
    setIsSelectedCreativeLoading && setIsSelectedCreativeLoading(false);
    resetExistingContentMeta && resetExistingContentMeta();
    creativeId && type === 'videos_images_pdfs'
      ? setIsCreateFromExisting && setIsCreateFromExisting(true)
      : setIsCreateFromExisting && setIsCreateFromExisting(false);
  };

  const currentContentType =
    contentType === 'videos' || contentType === 'images' || contentType === 'pdfs'
      ? 'videos_images_pdfs'
      : contentType;

  return (
    <Root className={className}>
      <Dropdown
        size="sm"
        value={currentContentType}
        options={contentTypeList}
        onChange={handleChangeType}
        width={216}
        isParentComponentShown={isQuestionModalOpen}
      />
    </Root>
  );
};

export default QuestionContentSelector;

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});
