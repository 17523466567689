import styled from '@emotion/styled';
import { palette, sizes, bp, layer } from 'entity/createTheme';

export const HeaderWrapper = styled('header')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
  height: ${sizes.headerHeightLg};
  background-color: ${palette.whitePrimary};
  width: 100%;
  z-index: ${layer.header};
  position: relative;
  box-shadow: 0 1px 5px 0 ${palette.grayMedium2};
  button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    border-radius: 4px;
    height: 30px;
    width: 24px;
    font-weight: 600;
    font-size: 14px;
    cursor: default;
    &.active {
      cursor: pointer;
      color: ${palette.deepBlueLight};
      font-weight: normal;
      &:hover {
        background-color: ${palette.grayMedium9};
      }
    }
    &.disabled {
      cursor: default;
    }
  }
  span {
    &.invisible {
      display: none;
    }
    &.active {
      font-weight: normal;
    }
  }

  @media ${bp.md} {
    padding: 0 16px;
  }

  .toggle-sidebar {
    display: none;
    cursor: pointer;
    padding: 12px 12px 12px 0;
  }

  .header_info {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  @media ${bp.sm} {
    height: ${sizes.headerHeightSm};
    .toggle-sidebar {
      display: inline-block;
      padding-right: 32px;
    }
    .sidebar_logo {
      display: none;
    }
  }
`;
