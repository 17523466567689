import React, { FC, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { bp, palette, layer, sizes } from 'entity/createTheme';
import { Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';

type Props = {
  disabledAction: boolean;
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  className?: string;
  onSave: () => void;
  size?: 'lg' | 'md';
  modalRef?: React.RefObject<HTMLDivElement>;
  saveButtonLabel?: string;
  isUpperDrawerOpen?: boolean;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Drawer: FC<Props> = ({
  disabledAction,
  title,
  className,
  children,
  isOpen,
  onClose,
  onSave,
  size = 'md',
  modalRef,
  saveButtonLabel,
  isUpperDrawerOpen,
}) => {
  const { t } = useTranslation();
  const mainRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      mainRef.current?.scrollTo(0, 0);
      modalRef?.current?.scrollTo(0, 0);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && <ModalBackground />}
      <Root
        className={`${className} ${isUpperDrawerOpen ? 'extra-slide' : undefined}`}
        open={isOpen}
        size={size}
      >
        <ContentWrapper size={size}>
          {/* Add extended white background so that there is no spacing between drawer and right side of the screen when sliding more to the left */}
          <ExtendedBackground />
          <Content>
            <Main ref={modalRef ?? mainRef} className="scroll-bar-thumbnail">
              {title && <Title>{title}</Title>}
              {children}
            </Main>
            <ActionBar>
              <ButtonGroup>
                <Button label={t('common.cancel')} type="default" size="md" onClick={onClose} />
                <Button
                  label={saveButtonLabel || t('common.save2')}
                  type="primary"
                  size="md"
                  onClick={onSave}
                  disabled={disabledAction}
                />
              </ButtonGroup>
            </ActionBar>
          </Content>
        </ContentWrapper>
      </Root>
    </>
  );
};

const ModalBackground = styled('div')({
  position: 'fixed',
  height: '100vh',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: layer.modal2,
  background: 'rgba(0, 0, 0, 0.6)',
  animation: `${fadeIn} 0.35s linear`,
});

const Root = styled('div')<{ open: boolean; size: 'lg' | 'md' }>(props => ({
  position: 'fixed',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: layer.modal2,
  visibility: props.open ? 'visible' : 'hidden',
  opacity: props.open ? 1 : 0,
  display: 'flex',
  justifyContent: 'flex-end',
  transform: props.open ? 'translateX(0)' : 'translateX(25%)',
  transition: '0.5s ease',
  '&.extra-slide': {
    transform:
      props.size === 'lg'
        ? `translateX(-${sizes.sizebarWidth})`
        : `translateX(calc(-50% + ${sizes.sizebarWidth}))`,
    transition: '0.4s linear',
  },
}));

const ContentWrapper = styled('div')<{ size: 'lg' | 'md' }>(props => ({
  position: 'relative',
  width: props.size === 'lg' ? `calc(100% - ${sizes.sizebarWidth})` : '50%',
  height: '100%',
  [`@media ${bp.sm}`]: {
    paddingLeft: 0,
    width: '100%',
  },
}));

const ExtendedBackground = styled('div')({
  background: palette.whitePrimary,
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: '100%',
});

const Content = styled('div')({
  width: '100%',
  height: '100%',
  background: palette.whitePrimary,
});

const Main = styled('div')({
  padding: 40,
  height: `calc(100% - ${sizes.headerHeightLg})`,
  overflowY: 'auto',
});

const Title = styled('h2')({
  marginBottom: 24,
});

const ActionBar = styled('div')({
  position: 'absolute',
  bottom: 0,
  height: 64,
  background: palette.base,
  width: '100%',
  borderTop: `1px solid ${palette.borderInput}`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 24px',
});

const ButtonGroup = styled('div')({
  display: 'flex',
  '> button': {
    ':last-child': {
      marginLeft: 10,
    },
  },
});

export default Drawer;
