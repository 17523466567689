import React, { FC } from 'react';
import styled from '@emotion/styled';
import { QuestionSet } from 'types/HearingSet';
import { bp } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';

type Props = {
  questionSet: QuestionSet;
};

const NoAnswerQuestionSet: FC<Props> = ({ questionSet }) => {
  const { t } = useTranslation();
  return (
    <QuestionList>
      {questionSet.questions.map(question => (
        <QuestionWrapper key={question.id}>
          <Question>{question.body}</Question>
          <NoAnswer>{t('sharingLink.resultModal.questionHasNoAnswer')}</NoAnswer>
        </QuestionWrapper>
      ))}
    </QuestionList>
  );
};

export default NoAnswerQuestionSet;

const QuestionList = styled('ul')({
  listStyle: 'none',
});

const QuestionWrapper = styled('li')({
  paddingTop: 20,
});

const Question = styled('p')({
  fontWeight: 'bold',
  marginBottom: 10,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem',
  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});

const NoAnswer = styled('p')({ fontSize: '0.875rem' });
