import React, { FC, useEffect, useRef, useState } from 'react';
import { ShakaPlayerInner } from './ShakaPlayerInner';

type PropsTypes = {
  manifestId: string;
  manifestUrl: string;
  visible: boolean;
  onCacheChecked: (cacheAvailable: boolean) => void;
  onReady: () => void;
  onError: (error: any) => void;
  onEnd?: () => void;
  onPlay?: () => void;
  onDownloadProgress: (downloading: boolean, progress: number) => void;
  downloadTriggered: boolean;
  playing: boolean;
};

const ShakaPlayer: FC<PropsTypes> = ({
  manifestId,
  manifestUrl,
  visible,
  onCacheChecked,
  onReady,
  onError,
  onDownloadProgress,
  downloadTriggered,
  onEnd,
  onPlay,
  playing,
}) => {
  const [inner, setInner] = useState<ShakaPlayerInner | null>(null);
  const videoTag = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!inner && videoTag.current) {
      const _inner = new ShakaPlayerInner({
        videoTag: videoTag.current,
        onLoaded: () => {
          onReady();
        },
        onDownloadProgress: (downloading, progress) => {
          onDownloadProgress(downloading, progress);
        },
        onError: error => {
          onError(error);
        },
        onEnd,
        onPlay,
      });
      setInner(_inner);
      const f = async () => {
        try {
          const loaded = await _inner.loadCachedVideo(manifestId);
          onCacheChecked(loaded);
        } catch (err) {
          onCacheChecked(false);
        }
      };
      f();
    }
  }, []);

  useEffect(() => {
    if (inner) {
      if (inner.shakaPlayer) inner.shakaPlayer.unload();
      const f = async () => {
        try {
          const loaded = await inner.loadCachedVideo(manifestId);
          onCacheChecked(loaded);
        } catch (err) {
          onCacheChecked(false);
        }
      };
      f();
    }
  }, [manifestId]);

  useEffect(() => {
    if (inner) {
      if (inner.shakaPlayer) {
        if (playing) {
          inner.start();
        } else {
          inner.pause();
        }
      }
    }
  }, [playing]);

  useEffect(() => {
    if (inner && downloadTriggered) {
      const f = async () => {
        try {
          await inner.downloadVideo(manifestId, manifestUrl, true, false);
        } catch (err) {
          console.error(err);
        }
      };
      f();
    }
  }, [downloadTriggered]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: visible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <video
        crossOrigin="anonymous"
        controls
        autoPlay
        ref={videoTag}
        playsInline={true}
        style={{
          height: '100%',
          maxWidth: '100%',
          margin: 'auto',
          display: 'block',
        }}
      />
    </div>
  );
};

export default ShakaPlayer;
