import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionSet } from 'types/HearingSet';
import { Textarea } from 'views/components/primitive';
import { getImgFromContent } from 'utility/helpers';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import ThumbnailIcon from '../ThumbnailIcon';
import { AnsweredQuestion } from 'types/App';

type Props = {
  questionSet: QuestionSet;
  handleChangeNote: (answeredQuestionId: number, note: string) => void;
  questionSetAnswers: AnsweredQuestion[];
};

const QuestionSetItem: FC<Props> = ({ questionSet, handleChangeNote, questionSetAnswers }) => {
  const { t } = useTranslation();
  const thumbnail = getImgFromContent(questionSet.content);

  const answeredQuestions = questionSet.questions
    .map(q => {
      return questionSetAnswers.find(aq => aq.question_id === q.id);
    })
    .filter((aq): aq is AnsweredQuestion => aq !== undefined);

  return (
    <QuestionSetWrapper>
      <ThumbnailIcon src={thumbnail} />

      {answeredQuestions.map((answeredQuestion, index) => {
        function onChangeNote(note: string) {
          answeredQuestion && handleChangeNote(answeredQuestion.id, note);
        }
        return (
          <QuestionItem key={index}>
            <QuestionBody>{`${index + 1} / ${answeredQuestion.body}`}</QuestionBody>
            <Answer>
              <table className="t-left">
                <thead>
                  <tr>
                    <td>{t('hearingPlay.result.answerLabel')}</td>
                    <td>{t('hearingPlay.result.feedbackLabel')}</td>
                  </tr>
                </thead>
                <tbody>
                  {answeredQuestion.answered_choices.map(answer => {
                    return (
                      <tr key={answer.id}>
                        <td>
                          {!answer.choice_id
                            ? `[${t('hearingPlay.result.otherAnswerLabel')}] ${answer.content}`
                            : answer.content}
                        </td>
                        <td>{answer.feedback}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <table className="t-right">
                <thead>
                  <tr>
                    <td>{t('hearingPlay.result.noteLabel')}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Textarea
                        onChange={onChangeNote}
                        value={answeredQuestion.note}
                        placeholder={t('hearingPlay.result.noteLabel')}
                        maxLength={20000}
                        resize={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Answer>
          </QuestionItem>
        );
      })}
    </QuestionSetWrapper>
  );
};

export default QuestionSetItem;

const QuestionSetWrapper = styled('div')({
  marginBottom: 20,
});

const QuestionItem = styled('div')({
  borderBottom: `1px solid ${palette.grayPrimary}`,
  paddingTop: 20,
});

const QuestionBody = styled('div')({
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const Answer = styled('div')({
  marginTop: 10,
  display: 'flex',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
  tr: {
    display: 'flex',
    td: {
      flex: 1,
    },
  },
  thead: {
    background: palette.grayMedium6,
    td: {
      fontSize: '0.75rem',
      color: palette.darkMedium6,
      lineHeight: '1rem',
      fontWeight: 'bold',
      padding: '14px 20px 13px 20px',
      [`@media ${bp.md}`]: {
        fontSize: 13,
        padding: '13px 16px',
      },
    },
  },

  tbody: {
    '*': {
      fontSize: '0.875rem',
    },
    td: {
      padding: '10px 20px',
      [`@media ${bp.md}`]: {
        fontSize: '1rem',
        padding: '10px 16px',
      },
    },
  },

  '& .t-left': {
    display: 'flex',
    flexDirection: 'column',
    tbody: {
      flex: 1,
    },
    flex: 2,
    thead: {
      borderRadius: '6px 0px 0px 6px',
    },
    td: {
      wordBreak: 'break-word',
    },
  },

  '& .t-right': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    tbody: {
      flex: 1,
    },
    tr: {
      height: '100%',
    },
    td: {
      paddingRight: 0,

      [`@media ${bp.md}`]: {
        paddingLeft: 0,
      },
    },
    thead: {
      borderRadius: '0px 6px 6px 0px',
    },
    [`@media ${bp.md}`]: {
      thead: {
        display: 'none',
      },
    },
  },

  '& .textarea-wrapper': {
    height: '100%',
    width: '100%',
    margin: 0,
    '& .wrapper-area': {
      height: '100%',
      borderColor: palette.grayPrimary,
      textarea: {
        '&::placeholder': {
          color: palette.grayMedium2,
        },
      },
      [`@media ${bp.md}`]: {
        height: 80,
      },
    },
  },
});
