import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ResultTab from './ResultTab';
import { Modal } from 'views/components/compound';
import { AnsweredQuestion } from 'types/App';
import { HearingItem } from 'types/HearingSet';

type Props = {
  isModal: boolean;
  handleToggle: () => void;
  hearingSets: HearingItem[];
  hearingResults: AnsweredQuestion[];
  handleChangeNote: (answeredQuestionId: number, note: string, hearingId: number) => void;
  updateUserAnswerNote: (hearingId: number) => void;
};

const SalesLogResultModal: FC<Props> = ({
  isModal,
  handleToggle,
  hearingSets,
  hearingResults,
  handleChangeNote,
  updateUserAnswerNote,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="result-modal"
      title={t('hearingPlay.result.hearingTitle')}
      handleToggle={handleToggle}
      isModal={isModal}
    >
      <ResultTab
        hearingSets={hearingSets}
        hearingResults={hearingResults}
        handleChangeNote={handleChangeNote}
        updateUserAnswerNote={updateUserAnswerNote}
      />
    </Modal>
  );
};

export default SalesLogResultModal;
