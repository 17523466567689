import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { Modal } from 'views/components/compound';

interface PropsTypes {
  className?: string;
  isModal: boolean;
  toggleModal: () => void;
  handleConfirmModal: () => void;
}

const FileChangeConfirmModal: FC<PropsTypes> = ({
  isModal,
  toggleModal,
  handleConfirmModal,
  className,
}) => {
  const { t } = useTranslation();

  function confirmModal() {
    toggleModal();
    handleConfirmModal();
  }

  return (
    <Modal
      isModal={isModal}
      handleToggle={toggleModal}
      title={t('admin.productContent.changeFileComfirm')}
    >
      <Wrapper className={className}>
        <p>{t('admin.productContent.messages.confirmChangeFile1')}</p>
        <p>{t('admin.productContent.messages.confirmChangeFile2')}</p>
        <ModalAction>
          <Button label={t('common.cancel')} size="md" type="default" onClick={toggleModal} />
          <Button
            label={t('admin.productContent.changeFileComfirm')}
            size="md"
            type="primary"
            onClick={confirmModal}
          />
        </ModalAction>
      </Wrapper>
    </Modal>
  );
};

export default FileChangeConfirmModal;

const Wrapper = styled('div')({
  padding: '10px 32px',
  'p, h4': {
    fontSize: 16,
    textAlign: 'center',
  },
  p: {
    marginBottom: 10,
  },
  h4: {
    marginBottom: 30,
    color: palette.orangePrimary,
  },
});
const ModalAction = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '> button': {
    width: 210,
    marginLeft: '10px',
  },
});
