import React, { FC } from 'react';
import styled from '@emotion/styled';
import SVGIcon from 'views/components/primitive/SVGIcon';
import { Button } from 'views/components/primitive';
import zoomOutIcon from 'assets/images/ic_zoom_out.svg';
import zoomInIcon from 'assets/images/ic_zoom_in.svg';
import { layer, palette } from 'entity/createTheme';

type Props = {
  zoomOut(): void;
  zoomIn(): void;
  onClick?: () => void;
  scale: number;
  className?: string;
};

const ZoomButtons: FC<Props> = ({ zoomOut, zoomIn, className, onClick, scale }) => {
  const minSize = scale <= 1;
  const maxSixe = scale >= 2;
  const currentScale = Math.round(scale * 100);

  return (
    <Root className={className} onClick={onClick}>
      <ZoomIcon
        id="zoom-out-button"
        label={<SVGIcon src={zoomOutIcon} id="zoom-out-button__icon" />}
        onClick={zoomOut}
        disabled={minSize}
      />
      {scale && <Scale id="scale">{`${currentScale}%`}</Scale>}
      <ZoomIcon
        id="zoom-in-button"
        label={<SVGIcon src={zoomInIcon} id="zoom-in-button__icon" />}
        onClick={zoomIn}
        disabled={maxSixe}
      />
    </Root>
  );
};

export default ZoomButtons;

const Root = styled('div')({
  zIndex: layer.bottomAbove1,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Scale = styled('span')({
  margin: '0 10px',
  color: palette.whitePrimary,
});

const ZoomIcon = styled(Button)({
  cursor: 'pointer',
  width: 'fit-content',
  background: 'transparent',
  '&:disabled': {
    background: 'transparent !important',
    opacity: 0.5,
  },
});
