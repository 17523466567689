import React, { FC } from 'react';
import { Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import PlaylistItemForDisplay_ from '../PlaylistItemForDisplay';
import { PlaylistItem } from 'types/Playlist';
import styled from '@emotion/styled';

type Props = {
  playlist?: PlaylistItem;
  togglePlaylistModal: () => void;
};

const PlaylistSelector: FC<Props> = ({ playlist, togglePlaylistModal }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!playlist && <PlaylistItemForDisplay playlistItem={playlist} />}
      <Button
        size="md"
        type="default"
        label={t('sharingLink.selectPlaylist')}
        onClick={togglePlaylistModal}
      />
    </>
  );
};

export default PlaylistSelector;

const PlaylistItemForDisplay = styled(PlaylistItemForDisplay_)({
  padding: '20px 0px',
});
