import React, { FC } from 'react';
import { LinkContentData } from 'organisms/ShareableLink/useCreateHearingLink';
import DrawerWithoutContentSelector from './DrawerWithoutContentSelector';
import DrawerWithContentSelector from './DrawerWithContentSelector';

type Props = {
  isAddLinkModalOpen: boolean;
  toggleAddLinkDrawer: () => void;
  linkContentData?: LinkContentData;
  showContentSelector?: boolean;
};

const CreateShareableLinkDrawer: FC<Props> = ({
  isAddLinkModalOpen,
  toggleAddLinkDrawer,
  linkContentData,
  showContentSelector,
}) => {
  return !showContentSelector && !!linkContentData ? (
    <DrawerWithoutContentSelector
      linkContentData={linkContentData}
      isAddLinkModalOpen={isAddLinkModalOpen}
      toggleAddLinkDrawer={toggleAddLinkDrawer}
    />
  ) : (
    <DrawerWithContentSelector
      isAddLinkModalOpen={isAddLinkModalOpen}
      toggleAddLinkDrawer={toggleAddLinkDrawer}
    />
  );
};

export default CreateShareableLinkDrawer;
