import React, { FC } from 'react';
import styled from '@emotion/styled';
import ArrowUp from 'assets/images/arrow-up_24x24.svg';
import ArrowDown from 'assets/images/arrow-down_24x24.svg';
import EditIcon from 'assets/images/ic_edit.svg';
import { DeleteButton, IconButton } from 'views/components/compound';
import { layer } from 'entity/createTheme';
import { Button, SVGIcon } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';

type Props = {
  index: number;
  isSortMode: boolean;
  handleSortWithArrow: (currentIndex: number, value: number) => void;
  shouldShowDeleteIcon: boolean;
  onDeleteQuestion: () => void;
  openEditQuestionModal: () => void;
};

const ActionButtons: FC<Props> = ({
  index,
  isSortMode,
  handleSortWithArrow,
  shouldShowDeleteIcon,
  onDeleteQuestion,
  openEditQuestionModal,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonsContainer isSortMode={isSortMode}>
      {isSortMode ? (
        <ArrowButtons>
          <IconButton
            tooltipText={t('common.tooltipText.moveUp')}
            iconSrc={ArrowUp}
            onClick={() => handleSortWithArrow(index, -1)}
          />
          <IconButton
            tooltipText={t('common.tooltipText.moveDown')}
            iconSrc={ArrowDown}
            onClick={() => handleSortWithArrow(index, 1)}
          />
        </ArrowButtons>
      ) : (
        <>
          <Button onClick={openEditQuestionModal} type="default" size="sm">
            <Icon src={EditIcon} />
            {t('common.edit')}
          </Button>

          {shouldShowDeleteIcon && (
            <DeleteButton isBackground size="md" onClick={onDeleteQuestion} />
          )}
        </>
      )}
    </ButtonsContainer>
  );
};

export default ActionButtons;

const ArrowButtons = styled('div')({
  display: 'flex',
  padding: '10px 5px',
  svg: {
    margin: '0px 5px',
    zIndex: layer.bottomAbove2,
    '&:hover': { cursor: 'pointer' },
  },
});

const ButtonsContainer = styled('div')<{ isSortMode: boolean }>(props => ({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  minWidth: props.isSortMode ? 90 : 154,
  justifyContent: 'flex-start',
}));

const Icon = styled(SVGIcon)({
  width: 16,
  height: 16,
  marginRight: 8,
});
