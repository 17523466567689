import { DocumentLog, VideoLog } from '../types/HearingLink';
import { ChartData } from 'chart.js';
import { palette } from 'entity/createTheme';
import { formatDuration } from './helpers';

const convertViewLogToChartData = ({
  documentLogs,
  videoLogs,
}: {
  documentLogs?: DocumentLog[];
  videoLogs?: VideoLog[];
}): ChartData<'bar', unknown, unknown> => {
  if (documentLogs) {
    const documentLogsObject: { [page: number]: number } = {};
    documentLogs.forEach(log => {
      documentLogsObject[log.page] = log.duration;
    });
    const minPage = documentLogs[0].page;
    const maxPage = documentLogs[documentLogs.length - 1].page;
    const processedLogs: DocumentLog[] = [];
    for (let i = minPage; i <= maxPage; i++) {
      processedLogs.push({ page: i, duration: documentLogsObject[i] || 0 });
    }
    return {
      labels: processedLogs.map(log => log.page),
      datasets: [
        {
          label: 'view document logs',
          backgroundColor: palette.chartPrimary,
          borderColor: palette.chartPrimary,
          data: processedLogs.map(log => log.duration),
          maxBarThickness: 40,
          categoryPercentage: 1.0,
          barPercentage: 0.8,
        },
      ],
    };
  }
  if (videoLogs) {
    return {
      labels: videoLogs.map((log, index) => {
        return `${index + 1}: ${formatDuration(log.start)} - ${formatDuration(log.end)}`;
      }),
      datasets: [
        {
          label: 'view video logs',
          backgroundColor: palette.chartPrimary,
          borderColor: palette.chartPrimary,
          barThickness: 8,
          data: videoLogs.map(log => [log.start, log.end]),
        },
      ],
    };
  }
  return {
    labels: [],
    datasets: [],
  };
};

export default convertViewLogToChartData;
