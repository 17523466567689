import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  DebouncedSearchInput,
  TagSelects,
  FileTypeButton as _FileTypeButton,
} from 'views/components/compound';
import { bp } from 'entity/createTheme';
import { Tag } from 'types/App';
import { useTranslation } from 'react-i18next';

type Props = {
  handleSearch: () => void;
  searchKeyword: string;
  tags?: Tag[];
  handleFilter: (typeParams: string) => void;
  currentType: string;
  currentTagIds: number[];
  onSelectTags: (tags: Tag[]) => void;
  handleChangeSearchKeyword: (searchKeyword: string) => void;
};

const SearchArea: FC<Props> = ({
  handleSearch,
  searchKeyword,
  tags,
  handleFilter,
  currentType,
  currentTagIds,
  onSelectTags,
  handleChangeSearchKeyword,
}) => {
  const { t } = useTranslation();

  return (
    <SearchAreaWrapper>
      <SearchBar
        onSearch={handleSearch}
        placeholder={t('productContent.searchContentPlaceholder')}
        searchKeyword={searchKeyword}
        setSearchKeyword={handleChangeSearchKeyword}
      />
      <TagsArea>
        <FileTypeButton handleFilter={handleFilter} currentType={currentType} />
        {tags && (
          <TagSelects tags={tags} currentTagIds={currentTagIds} onSelectTags={onSelectTags} />
        )}
      </TagsArea>
    </SearchAreaWrapper>
  );
};

export default SearchArea;

const SearchAreaWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const TagsArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const SearchBar = styled(DebouncedSearchInput)({
  marginBottom: 10,
});

const FileTypeButton = styled(_FileTypeButton)({
  margin: '1px 10px 1px 0',
});
