import React, { FC } from 'react';
import styled from '@emotion/styled';
import ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';
import { layer, palette } from 'entity/createTheme';

type Props = {
  src?: string;
  title: string | React.ReactNode;
  metaTitle: string;
  isUnpublic?: boolean;
};

const ContentItem: FC<Props> = ({ src, title, metaTitle, isUnpublic }) => {
  return (
    <Wrapper>
      {isUnpublic && (
        <>
          <IsUnpublicIcon />
          <GreyLayer />
        </>
      )}
      <ItemWithThumbnail
        className="hearing-info"
        src={src}
        title={title}
        metaTitle={metaTitle}
        greyMetaTitle
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  width: '100% !important',
  background: 'inherit',
  border: 'none',
  margin: 0,
  position: 'relative',
  paddingBottom: 20,
});

const GreyLayer = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  opacity: 0.6,
  paddingBottom: 20,
});

const IsUnpublicIcon = styled('div')({
  height: 10,
  width: 10,
  borderRadius: 10,
  backgroundColor: palette.redPrimary,
  position: 'absolute',
  zIndex: layer.bottomAbove1,
});

export default ContentItem;
