import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ThumbnailIcon from 'views/components/compound/ThumbnailIcon';
import { palette, bp } from 'entity/createTheme';
import { Label } from 'views/components/primitive';

interface PropTypes {
  src?: string;
  title: string | React.ReactNode;
  metaTitle?: string;
  greyMetaTitle?: boolean;
  id?: string;
  contentType?: 'pdfs' | 'videos' | 'images';
  className?: string;
  size?: number;
  aspectRatio?: number;
}

const ItemWithThumbnail: FC<PropTypes> = ({
  src,
  title,
  metaTitle,
  greyMetaTitle,
  id,
  contentType,
  className,
  size,
  aspectRatio,
}) => {
  const { t } = useTranslation();

  return (
    <Root className={className} id={id}>
      <ThumbnailIcon
        src={src}
        className="item-with-thumbnail__thumbnail"
        size={size}
        aspectRatio={aspectRatio}
      />
      <TitleContainer>
        <Title className="item-with-thumbnail__title">{title}</Title>

        <MetaTitle grey={greyMetaTitle} className="item-with-thumbnail__meta-title">
          {metaTitle}
        </MetaTitle>
        {contentType && (
          <>
            <ContentTypeLabel variant={greyMetaTitle ? 'grey' : 'default'}>
              {t(`common.contentLabelText.${contentType}` as const)}
            </ContentTypeLabel>
          </>
        )}
      </TitleContainer>
    </Root>
  );
};

export default ItemWithThumbnail;

const Root = styled('div')({
  display: 'flex',
  minWidth: 0,
  width: '100%',
  fontSize: 14,
});

const TitleContainer = styled('div')({
  minWidth: 0,
  flex: 1,
});

const MetaTitle = styled('span')<{ grey?: boolean }>(props => ({
  color: props.grey ? palette.deepBlueLight : palette.darkMedium2,
}));

const ContentTypeLabel = styled(Label)({
  marginLeft: 6,
});

const Title = styled('div')({
  fontWeight: 'bold',
  marginBottom: 10,

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});
