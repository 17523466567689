import { customeHosts } from './customeHosts';

export const HEARING_WEBCLIENT_LOCAL_PATH = 'https://sales-dx-hearing-local.pbcd.net:8081';

const isLocal = (hostname: string, port: string) => {
  return hostname.match(/local|development/) && port !== '';
};

const getHearingWebClientPath = (organizationId: string) => {
  const { host, hostname, port } = window.location;
  const appStage = process.env.REACT_APP_STAGE;
  const customeHostsForCurrentEnv =
    appStage && customeHosts[appStage] ? customeHosts[appStage] : {};
  const customeHost = customeHostsForCurrentEnv[organizationId];
  return isLocal(hostname, port)
    ? HEARING_WEBCLIENT_LOCAL_PATH
    : customeHost
    ? customeHost
    : `https://${host}`;
};

export default getHearingWebClientPath;
