import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductItem } from 'types/Product';
import ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';
import { formatTime, getImgFromContent } from 'utility/helpers';

type Props = {
  productContent: ProductItem;
  width?: number;
  aspectRatio?: number;
  className?: string;
};

const ProductInfo: FC<Props> = ({ productContent, width, aspectRatio, className }) => {
  const { t } = useTranslation();
  const thumbnail = getImgFromContent(productContent);

  return (
    <ItemWithThumbnail
      src={thumbnail}
      title={productContent.title}
      metaTitle={`${t('common.lastUpdated')}: ${formatTime(
        productContent.updated_at,
        'yyyy/MM/dd'
      )}`}
      greyMetaTitle
      contentType={productContent.content_type}
      size={width}
      aspectRatio={aspectRatio}
      className={className}
    />
  );
};

export default ProductInfo;
