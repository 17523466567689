import { useStore } from 'hooks';

export const useOrganization = () => {
  const {
    appStore: { organizationId },
  } = useStore();
  return {
    organizationId,
  };
};
