import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { palette, bp } from 'entity/createTheme';

type Props = {
  title: string;
  className?: string;
  hasDraftOrArchived?: boolean;
  actionButtons?: React.ReactNode;
};

const ContentContainer: FC<Props> = ({
  title,
  className,
  hasDraftOrArchived,
  children,
  actionButtons,
}) => {
  const { t } = useTranslation();

  return (
    <Root className={className}>
      <Header>
        <TitleWrapper>
          <Title>{title}</Title>
          {hasDraftOrArchived && (
            <Note>
              <NoteIcon />
              {t('admin.common.isDraftOrArchived')}
            </Note>
          )}
        </TitleWrapper>
        {actionButtons}
      </Header>
      {children}
    </Root>
  );
};

export default ContentContainer;

const Root = styled('div')({
  padding: '24px 24px 16px',
  marginBottom: 20,
  background: palette.whitePrimary,
  borderRadius: 6,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
});

const TitleWrapper = styled('div')({});

const Title = styled('h4')({
  color: palette.darkMedium2,
  fontSize: '1rem',
  lineHeight: '19px',
  textTransform: 'uppercase',
  marginBottom: 12,
});

const Note = styled('div')({
  fontSize: 12,
  color: palette.darkMedium2,
  display: 'flex',
  alignItems: 'center',
  marginTop: 5,
});

const NoteIcon = styled('div')({
  height: 10,
  minWidth: 10,
  borderRadius: 10,
  backgroundColor: palette.redPrimary,
  marginRight: 4,
});
