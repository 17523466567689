import React from 'react';
import { FC } from 'react';
import AdminActionArea from './AdminActionArea';
import MemberActionArea from './MemberActionArea';

type Props = {
  isAdmin: boolean;
  toggleDrawer: () => void;
};

const ProductListManagementAction: FC<Props> = ({ isAdmin, toggleDrawer }) => {
  return isAdmin ? <AdminActionArea toggleDrawer={toggleDrawer} /> : <MemberActionArea />;
};

export default ProductListManagementAction;
