import React, { FC, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

interface PropTypes {
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  label?: string;
  error?: string;
  value: string;
  type?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
  height?: number;
  resize?: boolean;
  className?: string;
}

interface StyleProps {
  height?: number;
  disabled?: boolean;
}

const TextareaWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  & label,
  & textarea {
    color: ${palette.darkMedium2};
    font-size: 0.875rem;
    line-height: 1rem;
    resize: none;
    border: none;
  }
  & textarea.resize {
    resize: vertical;
  }
  & label {
    font-size: 0.75rem;
    margin-bottom: 8px;
  }
  .wrapper-area.disabled {
    background-color: ${palette.base};
    & textarea {
      border: none;
      background-color: ${palette.base};
      position: relative;
      right: -5px;
    }
  }
  .wrapper-area {
    overflow: hidden;
    ${({ height }) => height && `height: ${height - 20}px`};
    border: solid 1.5px;
    ${({ disabled }) =>
      disabled ? `border-color: ${palette.base}` : `border-color: ${palette.borderInput}`};
    border-radius: 6px;
    &:focus-within {
      outline: none;
      border-color: ${palette.deepBlueLight};
    }
    textarea {
      width: 100%;
      height: 100%;
      padding: 12px 10px;
      background: ${palette.whitePrimary};
      opacity: 1;
      -webkit-opacity: 1;
      -webkit-text-fill-color: ${palette.darkMedium2};
      &::placeholder {
        color: ${palette.placeholder};
        -webkit-text-fill-color: ${palette.placeholder};
      }
      &:disabled {
        color: ${palette.deepBlueDisable};
        -webkit-text-fill-color: ${palette.deepBlueDisable};
      }
    }
  }
  &.error {
    .wrapper-area {
      border-color: ${palette.redPrimary};
    }
    & textarea {
      &::placeholder {
        color: ${palette.redPrimary};
        -webkit-text-fill-color: ${palette.redPrimary};
      }
    }
    span.error_text {
      margin-top: 10px;
      color: ${palette.redPrimary};
      font-size: 13px;
    }
  }
`;

const Textarea: FC<PropTypes> = ({
  value,
  onChange,
  label,
  error,
  maxLength,
  height,
  resize,
  disabled,
  className,
  ...rest
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <TextareaWrapper
      disabled={disabled}
      className={`textarea-wrapper ${error ? 'error' : ''} ${className}`}
      height={height}
    >
      {label && <label>{label}</label>}
      <div className={`wrapper-area ${disabled ? 'disabled' : ''}`}>
        <textarea
          value={value}
          onChange={handleChange}
          className={`scroll-bar-thumbnail ${resize ? 'resize' : ''}`}
          maxLength={maxLength}
          disabled={disabled}
          {...rest}
        />
      </div>
      {error && <span className="error_text">{error}</span>}
    </TextareaWrapper>
  );
};

export default Textarea;
