import { ListRequestParamsMeta, FilterByTagsParams } from 'types/common';
import { ProductItem } from 'types/Product';
import HttpClient from 'adapter/HTTPClient';

class ProductContentApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getProductContents = async (
    organization_id: string,
    params?: Partial<ListRequestParamsMeta & FilterByTagsParams>
  ) => {
    const res = await this.httpClient.request<{
      product_contents: ProductItem[];
      total: number;
      organization_id: string;
    }>({
      method: 'GET',
      url: '/product_contents',
      params: {
        ...params,
        organization_id,
      },
    });

    return res;
  };

  public getProductContentById = async (id: number | string, organization_id: string) => {
    const res = await this.httpClient.request<ProductItem>({
      method: 'GET',
      url: `product_contents/${id}`,
      params: {
        organization_id,
      },
    });

    return res;
  };
}

export default ProductContentApi;
