import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import TagIcon from '@material-ui/icons/LocalOffer';
import { useTranslation } from 'react-i18next';
import { palette, layer } from 'entity/createTheme';
import { Tag } from 'types/App';
import _Checkbox from '@material-ui/core/Checkbox';
import { useClickAway } from 'react-use';

interface Props {
  tags: Tag[];
  currentTagIds?: number[];
  onSelectTags: (tags: Tag[]) => void;
}

interface TagSelectBoxProps {
  categoryName: string;
  tags: Tag[];
  allTags: Tag[];
  currentTagIds?: number[];
  onSelectTags: (tags: Tag[]) => void;
}

const currentTags = (tags: Tag[], currentTagIds?: number[]) => {
  if (currentTagIds) {
    return currentTagIds.map(id => tags.find(t => t.id === id)).filter(tag => !!tag) as Tag[];
  } else {
    return [];
  }
};

const TagSelectBox: FC<TagSelectBoxProps> = ({
  categoryName,
  tags,
  allTags,
  currentTagIds,
  onSelectTags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  const isActiveTag = (tagId: number) => !!currentTagIds?.includes(tagId);
  const boxIsActive = !!tags.find((tag: Tag) => isActiveTag(tag.id));

  const handleClick = (event: MouseEvent | any) => {
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpen();
      }
    }
  };

  useClickAway(container, handleClick);

  return (
    <SelectBox
      ref={container}
      onClick={toggleOpen}
      className={[isOpen ? 'open' : '', boxIsActive ? 'active' : ''].join(' ')}
    >
      {categoryName}
      <Icon>
        <TagIcon fontSize="small" />
      </Icon>
      <SelectOptions>
        <Option onClick={() => onSelectTags([])}>
          <Checkbox checked={!currentTagIds || currentTagIds.length === 0} size="small" />
          <OptionLabel>{t('productContent.allTags')}</OptionLabel>
        </Option>
        {tags.map(tag => (
          <Option
            key={tag.id}
            className={isActiveTag(tag.id) ? 'active' : ''}
            onClick={() => {
              if (isActiveTag(tag.id)) {
                onSelectTags(
                  currentTags(allTags, currentTagIds).reduce((arr: Tag[], current: Tag) => {
                    if (current.id !== tag.id) arr.push(current);
                    return arr;
                  }, [])
                );
              } else {
                onSelectTags(currentTags(allTags, currentTagIds).concat(tag));
              }
            }}
          >
            <Checkbox checked={isActiveTag(tag.id)} size="small" />
            <OptionLabel>{tag.name}</OptionLabel>
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  );
};

const TagSelects: FC<Props> = ({ tags, currentTagIds, onSelectTags }) => {
  const categoryTags = tags.reduce((res, tag) => {
    const key = tag.category_name;
    if (res.has(key)) {
      const a = res.get(key);
      if (a) a.push(tag);
    } else {
      res.set(key, [tag]);
    }
    return res;
  }, new Map<string, Tag[]>());

  return (
    <Root>
      {Array.from(categoryTags.entries()).map(entry => (
        <TagSelectBox
          key={entry[0]}
          categoryName={entry[0]}
          tags={entry[1]}
          allTags={tags}
          currentTagIds={currentTagIds}
          onSelectTags={onSelectTags}
        />
      ))}
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  'div + div': {
    marginLeft: 10,
  },
});

const SelectBox = styled('div')({
  height: 40,
  minWidth: 150,
  padding: 10,
  fontSize: '0.875rem',
  display: 'flex',
  fontWeight: 600,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '6px',
  border: `1px solid ${palette.grayMedium1}`,
  backgroundColor: palette.whitePrimary,
  cursor: 'pointer',
  position: 'relative',
  '&.open': {
    borderRadius: '6px 6px 0 0',
    '> div': {
      display: 'block',
    },
  },
  '&.active': {
    background: palette.grayMedium6,
  },
});

const SelectOptions = styled('div')({
  position: 'absolute',
  top: 'calc(100% + 1px)',
  left: -1,
  width: 'calc(100% + 2px)',
  backgroundColor: palette.whitePrimary,
  border: `1px solid ${palette.grayMedium1}`,
  borderTop: 'none',
  borderRadius: '0 0 6px 6px',
  display: 'none',
  zIndex: layer.bottomAbove1,
  fontWeight: 'normal',
  maxHeight: 520,
  overflowY: 'scroll',
});

const Option = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 10px',
  '&.active': {
    background: palette.grayMedium6,
  },
  '&:hover': {
    backgroundColor: palette.grayMedium5,
  },
});

const Icon = styled('span')({
  display: 'inline-block',
  marginLeft: 10,
  fontWeight: 'normal',
  path: {
    color: palette.deepBlueLight,
  },
});

const OptionLabel = styled('div')({
  marginLeft: 5,
});

const Checkbox = styled(_Checkbox)({
  padding: '0 !important',
  marginBottom: 2,
  '&:hover': {
    background: 'transparent',
  },
  path: {
    color: palette.deepBlueLight,
  },
});

export default TagSelects;
