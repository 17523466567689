import NoThumbnail from 'assets/images/no-thumbnail.jpg';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentMeta,
  ContentType,
  ExternalVideoURLMeta,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
} from 'types/App';
import {
  acceptImage,
  acceptImageMimeTypes,
  acceptPDF,
  acceptPDFMimeTypes,
  acceptVideo,
  maxSizeImage,
  maxSizeImageClient,
  maxSizePdf,
  maxSizePdfClient,
  videoAcceptShow,
} from 'utility/constants';
import { extractFileExtentionFromMimeType } from 'utility/helpers';
import PDF2Image from 'utility/Pdf2Image';
import ExternalVideoURLInput from './ExternalVideoURLInput';
import NoContent from './NoContent';
import { QuestionFormUploadFile as UploadFile } from './UploadFile';
import UploadImage from './UploadImage';
import UploadPDF from './UploadPDF';
import UploadVideo from './UploadVideo';
import SelectExistingContents from '../CreativeSearch/SelectExistingContents';
import { ContentViewType } from 'entity/Content';
import { isAcceptableFile } from 'utility/helpers';

type Props = {
  contentType: ContentType;
  videoMeta: UploadVideoMeta;
  externalVideoURLMeta: ExternalVideoURLMeta;
  imageMeta: UploadImageMeta;
  pdfMeta: UploadPDFMeta;
  handleChangeContentMeta: (meta: ContentMeta) => void;
  uploadStatus?: string;
  handleChangeContentType: (type: ContentType) => void;
  isQuestionModalOpen: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
  creativeId?: string | null;
  setIsContentTypeChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  isContentTypeChanged?: boolean;
  isSelectedCreativeLoading: boolean;
  setIsSelectedCreativeLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resetExistingContentMeta: () => void;
  setIsCreateFromExisting: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateFromExisting: boolean;
  originalContent: ContentViewType;
  editMode: boolean;
  fileTypeError: string;
  handleFileTypeError: (fileTypeError: string) => void;
};

const ContentUploader: FC<Props> = ({
  contentType,
  videoMeta,
  externalVideoURLMeta,
  imageMeta,
  pdfMeta,
  handleChangeContentMeta,
  uploadStatus,
  handleChangeContentType,
  isQuestionModalOpen,
  modalRef,
  creativeId,
  isContentTypeChanged = false,
  setIsContentTypeChanged,
  isSelectedCreativeLoading,
  setIsSelectedCreativeLoading,
  resetExistingContentMeta,
  setIsCreateFromExisting,
  isCreateFromExisting,
  originalContent,
  editMode,
  fileTypeError,
  handleFileTypeError,
}) => {
  const { t } = useTranslation();

  const onInputImage = (newFile: any, meta: UploadImageMeta) => {
    setIsCreateFromExisting(false);
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (acceptImageMimeTypes.includes(extension)) {
      const exceedMaxSize = newFile.size >= maxSizeImage;
      if (exceedMaxSize) {
        handleChangeContentMeta({
          ...meta,
          file: '',
          filename: '',
          src: NoThumbnail,
          error: t('admin.common.exceedMaxSize', { sizeLimit: maxSizeImageClient }),
        });
      } else {
        handleChangeContentMeta({
          ...meta,
          file: newFile,
          filename: newFile.name,
          src: URL.createObjectURL(newFile),
          error: '',
        });
      }
    }
  };

  const onInputPdf = async (newFile: any, meta: UploadPDFMeta) => {
    setIsCreateFromExisting(false);
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (acceptPDFMimeTypes.includes(extension)) {
      const url = URL.createObjectURL(newFile);
      const pdf2image = await PDF2Image.open(url);

      const thumbnailSrc = await pdf2image.getImageDataUrl(1, {
        width: 400,
        height: 400,
      });

      const exceedMaxSize = newFile.size >= maxSizePdf;
      if (exceedMaxSize) {
        handleChangeContentMeta({
          ...meta,
          file: '',
          filename: '',
          error: t('admin.common.exceedMaxSize', { sizeLimit: maxSizePdfClient }),
          thumbnailSrc: NoThumbnail,
        });
      } else {
        handleChangeContentMeta({
          ...meta,
          file: newFile,
          filename: newFile.name,
          error: '',
          ...(thumbnailSrc && { thumbnailSrc }),
        });
      }
    }
  };

  const onInputVideo = (newFile: any, meta: UploadVideoMeta) => {
    setIsCreateFromExisting(false);
    const extension = extractFileExtentionFromMimeType(newFile.type);

    if (videoAcceptShow.includes(extension)) {
      handleChangeContentMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: URL.createObjectURL(newFile),
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: '',
      });
    } else {
      handleChangeContentMeta({
        ...meta,
        file: newFile,
        videoSrcConvert: null,
        filename: newFile.name,
        fileThumbnailConst: '',
        thumbnailSrcConvert: '',
        error: t('admin.common.notLoadThumbnail', { filename: newFile.name }),
        fileThumbnail: '',
        firstFrameVideo: NoThumbnail,
      });
    }
  };

  const handleChangeUploadFile = (newFile: any) => {
    if (!newFile) return;
    const extension = newFile.name.split('.').pop().toLowerCase();
    const extensionWithDot = `.${extension}`;

    if (isAcceptableFile(extensionWithDot, acceptVideo)) {
      handleChangeContentType('videos');
      onInputVideo(newFile, videoMeta);
    } else if (isAcceptableFile(extensionWithDot, acceptImage)) {
      handleChangeContentType('images');
      onInputImage(newFile, imageMeta);
    } else if (isAcceptableFile(extensionWithDot, acceptPDF)) {
      handleChangeContentType('pdfs');
      onInputPdf(newFile, pdfMeta);
    }
  };

  useEffect(() => {
    if (!creativeId) return;

    handleChangeContentMeta({
      type: 'existing_contents',
      creativeId: creativeId,
      creative_type: '',
    });
    handleChangeContentType('existing_contents');
    setIsContentTypeChanged && setIsContentTypeChanged(true);
  }, [creativeId]);

  useEffect(() => {
    if (!creativeId) setIsCreateFromExisting(false);
    if (creativeId) setIsCreateFromExisting(true);
  }, [creativeId]);

  if (creativeId && !isContentTypeChanged) {
    return (
      <SelectExistingContents
        handleChangeMeta={handleChangeContentMeta}
        isOpen={isQuestionModalOpen}
        modalRef={modalRef}
        creativeId={creativeId}
        isContentTypeChanged={isContentTypeChanged}
        isSelectedCreativeLoading={isSelectedCreativeLoading}
        setIsSelectedCreativeLoading={setIsSelectedCreativeLoading}
        isEditing={true}
        resetExistingContentMeta={resetExistingContentMeta}
        needContentTypeLabel={true}
        needContentTypeSelectbox={true}
        guideText={t('embeddingLink.selectEmbedVideo.guide')}
        isContentPublished={[1]}
      />
    );
  }

  if (contentType === 'existing_contents')
    return (
      <SelectExistingContents
        handleChangeMeta={handleChangeContentMeta}
        isOpen={isQuestionModalOpen}
        modalRef={modalRef}
        isContentTypeChanged={isContentTypeChanged}
        isSelectedCreativeLoading={isSelectedCreativeLoading}
        setIsSelectedCreativeLoading={setIsSelectedCreativeLoading}
        isEditing={true}
        resetExistingContentMeta={resetExistingContentMeta}
        needContentTypeLabel={true}
        needContentTypeSelectbox={true}
        guideText={t('embeddingLink.selectEmbedVideo.guide')}
        isContentPublished={[1]}
      />
    );

  if (
    isCreateFromExisting ||
    (contentType === 'videos_images_pdfs' &&
      !videoMeta.firstFrameVideo &&
      !imageMeta.src &&
      !pdfMeta.thumbnailSrc)
  )
    return (
      <UploadFile
        handleChangeUploadFile={handleChangeUploadFile}
        fileTypeError={fileTypeError}
        handleFileTypeError={handleFileTypeError}
      />
    );

  if (
    contentType === 'videos' ||
    (contentType === 'videos_images_pdfs' && videoMeta.firstFrameVideo)
  )
    return (
      <UploadVideo
        handleChangeMeta={handleChangeContentMeta}
        meta={videoMeta}
        status={uploadStatus || ''}
        handleChangeUploadFile={handleChangeUploadFile}
        originalContent={originalContent}
        editMode={editMode}
      />
    );

  if (contentType === 'images' || (contentType === 'videos_images_pdfs' && imageMeta.src))
    return (
      <UploadImage
        meta={imageMeta}
        editable
        handleChangeUploadFile={handleChangeUploadFile}
        notReady={imageMeta.src === NoThumbnail}
      />
    );
  if (contentType === 'pdfs' || (contentType === 'videos_images_pdfs' && pdfMeta.thumbnailSrc))
    return (
      <UploadPDF
        meta={pdfMeta}
        editable
        handleChangeUploadFile={handleChangeUploadFile}
        notReady={pdfMeta.thumbnailSrc === NoThumbnail}
        editMode={editMode}
        originalContent={originalContent}
      />
    );

  if (contentType === 'links')
    return (
      <ExternalVideoURLInput
        meta={externalVideoURLMeta}
        handleChangeContentMeta={handleChangeContentMeta}
      />
    );

  if (contentType === 'none_data') return <NoContent />;
  return null;
};

export default ContentUploader;
