import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utility/helpers';
import { palette, bp } from 'entity/createTheme';
import {
  Button,
  Checkbox,
  ContentTypeLabel as _ContentTypeLabel,
} from 'views/components/primitive';
import { ProductItem as ProductItemTypes } from 'types/Product';
import { getImgFromContent } from 'utility/helpers';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import { MessageProps } from 'types/App';

type Props = {
  productContent: ProductItemTypes;
  isSelected: boolean;
  isSelectProduct: boolean;
  onChangeSelected: (id: number) => void;
  restoreArchive: (id: number) => void;
  pushFlashMessages: (data: MessageProps) => void;
};

const ArchiveProductItem: FC<Props> = ({
  productContent,
  isSelected,
  isSelectProduct,
  onChangeSelected,
  restoreArchive,
  pushFlashMessages,
}) => {
  const { t } = useTranslation();

  const videos: { manifestId: string; manifestUrl: string }[] = [];
  if (
    productContent.content_type === 'videos' &&
    productContent.kaizen_files_id &&
    productContent.kaizen_files_url
  ) {
    videos.push({
      manifestId: productContent.kaizen_files_id,
      manifestUrl: productContent.kaizen_files_url,
    });
  }

  const handleChangeSelected = () => {
    onChangeSelected(productContent.id);
  };

  const handleClickTitle = () => {
    pushFlashMessages({ content: t('admin.common.notViewDetail'), status: 'warning' });
  };

  const handleRestoreArchive = () => {
    restoreArchive(productContent.id);
  };

  return (
    <ProductItemWrapper>
      <ContentTypeLabel>
        {t(`common.contentLabelText.${productContent.content_type}` as const)}
      </ContentTypeLabel>
      {videos.length > 0 && <BulkDownloader videos={videos} />}
      <ProductItemThumbnail>
        <img src={getImgFromContent(productContent)} alt="prod-img" />
      </ProductItemThumbnail>
      <ProductItemContent>
        <ProductTitle onClick={handleClickTitle}>{productContent.title}</ProductTitle>
        <ProductTime>
          {t('common.lastUpdated')}: {formatTime(productContent.updated_at, 'yyyy/MM/dd')}
        </ProductTime>
        <ActionButtons>
          {isSelectProduct ? (
            <Checkbox checked={isSelected} onChange={handleChangeSelected} />
          ) : (
            <Button
              type="default"
              size="sm"
              label={t('admin.common.restore')}
              onClick={handleRestoreArchive}
            />
          )}
        </ActionButtons>
      </ProductItemContent>
    </ProductItemWrapper>
  );
};

export default ArchiveProductItem;

const ProductItemWrapper = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: 'calc((100% - 40px) / 3)',
  margin: '0 20px 20px 0',
  borderRadius: 6,
  background: palette.whitePrimary,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,

  '&:nth-of-type(3n)': {
    marginRight: 0,
  },
  [`@media ${bp.md}`]: {
    width: 'calc(50% - 8px)',
    margin: '0 16px 16px 0',
    '&:nth-of-type(3n)': {
      marginRight: 16,
    },
    '&:nth-of-type(2n)': {
      marginRight: 0,
    },
  },
});

const ContentTypeLabel = styled(_ContentTypeLabel)({
  position: 'absolute',
  top: 10,
  left: 10,
});

const ProductItemThumbnail = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 203,
  width: '100%',
  borderRadius: '6px 6px 0 0',
  overflow: 'hidden',
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const ProductItemContent = styled('div')({
  padding: '10px 20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProductTitle = styled('p')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  marginBottom: 10,
  cursor: 'pointer',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const ProductTime = styled('div')({
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: palette.deepBlueLight,
  marginBottom: 20,
});

const ActionButtons = styled('div')({
  flex: 1,
  marginBottom: 13,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  [`@media ${bp.md}`]: {
    marginBottom: 10,
  },
});
