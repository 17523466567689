import {
  CreativeSearchProvider,
  ICreativeSearchProviderProps,
} from '@kaizenplatform/creative-search-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmbeddingVideoMeta } from 'types/EmbeddingLink';
import { ProductItem } from 'types/Product';

import { SelectExistingContentsSearchArea as SearchArea } from './SearchArea';

const EN = 'en';
const EN_US = 'en-US';
const JA = 'ja';

const getCreativeSearchProviderProps = (
  language: string
): Pick<ICreativeSearchProviderProps, 'businessRegion' | 'locale'> => {
  switch (language) {
    case EN_US:
      return {
        locale: EN,
        businessRegion: [],
      };
    default:
      return {
        locale: JA,
        businessRegion: [],
      };
  }
};

type Props = {
  handleChangeMeta?: (file: any) => void;
  isEditing?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
  creativeId?: string | null;
  isContentTypeChanged?: boolean;
  isSelectedCreativeLoading?: boolean;
  setIsSelectedCreativeLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeEmbeddingVideoMeta?: (videoMeta: Partial<EmbeddingVideoMeta>) => Promise<void>;
  isOpen: boolean;
  contentType?: string[];
  needContentTypeLabel?: boolean;
  resetEmbeddingVideoMeta?: () => Promise<void>;
  resetExistingContentMeta?: () => void;
  needContentTypeSelectbox?: boolean;
  guideText?: string;
  viewDetailProduct?: (id: number) => void;
  newAddedProduct?: ProductItem;
  resetNewAddedProduct?: () => void;
  isContentPublished?: number[];
  isCreatedAtSales?: 0 | 1;
  pageProp?: number;
  showCreateLinkButton?: boolean;
};

const SelectExistingContents: FC<Props> = props => {
  const { i18n } = useTranslation();

  const creativeSearchProviderProps = getCreativeSearchProviderProps(i18n.language);

  return (
    <CreativeSearchProvider {...creativeSearchProviderProps}>
      <SearchArea {...props} />
    </CreativeSearchProvider>
  );
};

export default SelectExistingContents;
