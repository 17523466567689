import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Textarea } from 'views/components/primitive';
import { QuestionPlayData } from 'types/HearingSet';
import { otherAnswerFixedId } from 'utility/constants';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';

type Props = {
  title: string;
  updateUserAnswerNote: (questionId: number, value: string) => void;
  questionPlayData: QuestionPlayData;
};

type AnswerItem = {
  id: number;
  content: string;
  feedback?: string;
};

const ResultQuestion: FC<Props> = ({ title, updateUserAnswerNote, questionPlayData }) => {
  const { t } = useTranslation();
  const { choices, userAnswers, otherAnswerContent, note } = useMemo(() => questionPlayData, [
    questionPlayData,
  ]);

  const [otherAnswer, setOtherAnswer] = useState(note || '');

  const handleChangeNote = (value: string) => {
    setOtherAnswer(value);
    updateUserAnswerNote(questionPlayData.id, value);
  };

  const answerList = choices.filter(choice => userAnswers.includes(choice.id));

  const finalUserAnswers = otherAnswerContent
    ? [...answerList, { id: otherAnswerFixedId, content: otherAnswerContent }]
    : answerList;

  function renderAnswers() {
    return finalUserAnswers.map((answer: AnswerItem) => {
      const otherAnswerLabel = `${t('hearingPlay.result.otherAnswerLabel')} `;
      const userAnswer =
        answer.id === otherAnswerFixedId ? `${otherAnswerLabel} ${answer.content}` : answer.content;
      return (
        <div className="tr" key={answer.id}>
          <div className="td">{userAnswer}</div>
          <div className="td">{answer.feedback}</div>
        </div>
      );
    });
  }

  return (
    <WrapperHearingItem>
      <Title>{title}</Title>
      <div className="hearing-item__bottom">
        <div className="t-left">
          <div className="thead">
            <div className="tr">
              <div className="td">{t('hearingPlay.result.answerLabel')}</div>
              <div className="td">{t('hearingPlay.result.feedbackLabel')}</div>
            </div>
          </div>
          <div className="tbody">{renderAnswers()}</div>
        </div>
        <div className="t-right">
          <div className="thead">
            <div className="tr">
              <div className="td">{t('hearingPlay.result.noteLabel')}</div>
            </div>
          </div>
          <div className="tbody">
            <div className="tr">
              <div className="td">
                <Textarea
                  onChange={handleChangeNote}
                  value={otherAnswer}
                  placeholder={t('hearingPlay.result.noteLabel')}
                  maxLength={20000}
                  resize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperHearingItem>
  );
};

export default ResultQuestion;

const WrapperHearingItem = styled('div')`
  border-bottom: 0.8px solid ${palette.grayPrimary};
  .hearing-item {
    &__bottom {
      margin-top: 20px;
      padding-bottom: 10px;

      display: flex;
      @media ${bp.md} {
        flex-direction: column;
      }

      .tr {
        display: flex;
        .td {
          flex: 1;
        }
      }
      .thead {
        background: ${palette.grayMedium6};
        .td {
          font-size: 12px;
          color: ${palette.darkMedium2};
          line-height: 16px;
          font-weight: bold;
          padding: 14px 20px 13px 20px;

          @media ${bp.md} {
            font-size: 13px;
            padding: 13px 16px;
          }
        }
      }

      .tbody {
        * {
          font-size: 0.875rem;
        }
        .td {
          padding: 10px 20px;

          @media ${bp.md} {
            font-size: 16px;
            padding: 10px 16px;
          }
        }
      }

      .t-left,
      .t-right {
        display: flex;
        flex-direction: column;
        .tbody {
          flex: 1;
        }
      }

      .t-left {
        flex: 2;
        .thead {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        .td {
          word-break: break-all;
        }
      }

      .t-right {
        flex: 1;
        .tr {
          height: 100%;
        }
        .td {
          padding-right: 0;

          @media ${bp.md} {
            padding-left: 0;
          }
        }
        .thead {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
        @media ${bp.md} {
          .thead {
            display: none;
          }
        }
      }

      .textarea-wrapper {
        height: 100%;
        width: 100%;
        margin: 0;
        .wrapper-area {
          height: 100%;
          border-color: ${palette.grayPrimary};
          textarea {
            &::placeholder {
              color: ${palette.grayMedium2};
            }
          }
          @media ${bp.md} {
            height: 80px;
          }
        }
      }
    }
  }
`;

const Title = styled('p')({
  fontWeight: 'bold',
  marginBottom: 10,
  fontSize: 14,

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});
