import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import EditIcon from 'assets/images/ic_edit.svg';
import { Button, SVGIcon } from 'views/components/primitive';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  label?: string;
};

const EditButton: FC<Props> = ({ onClick, disabled = false, label, className }) => {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      type="primary"
      size="sm"
      label={
        <>
          <Icon src={EditIcon} />
          {label ?? t('common.edit')}
        </>
      }
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default EditButton;

const Icon = styled(SVGIcon)({
  width: 20,
  height: 20,
  marginRight: 10,
});
