import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette, layer } from 'entity/createTheme';

type Props = {
  isOpen: boolean;
  align?: 'right' | 'left';
  className?: string;
};

const DropdownMenuComponent: FC<Props> = ({ children, isOpen, align, className }) => {
  return (
    <DropdownMenu className={className} isOpen={isOpen} align={align}>
      <HR />
      {children}
    </DropdownMenu>
  );
};

export default DropdownMenuComponent;

const DropdownMenu = styled('div')<Props>(({ isOpen, align = 'right' }) => ({
  display: isOpen ? 'block' : 'none',
  width: 'calc(100% + 3px)',
  padding: '0px 0px 4px 0px',
  position: 'absolute',
  top: '100%',
  [`${align}`]: '-1.5px',
  border: `solid 1.5px ${palette.borderInput}`,
  background: palette.whitePrimary,
  borderRadius: 6,
  overflow: 'hidden',
  zIndex: layer.bottomAbove2,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTop: '0px',
}));

const HR = styled('div')(() => ({
  margin: '0 15px 5px',
  borderColor: palette.grayPrimary,
  borderWidth: '1px 0px 0px 0px',
  borderStyle: 'solid',
  height: '1px',
}));
