import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { bp, palette } from 'entity/createTheme';
import { SVGIcon } from 'views/components/primitive';
import UploadIcon from 'assets/images/ic_upload.svg';
import { isAcceptableFile } from 'utility/helpers';
import { UploadableContentTypes } from 'types/common';
import { acceptVideo, acceptImage, acceptPDF, acceptVideoImageOrPDF } from 'utility/constants';

const getAcceptType = (type: UploadableContentTypes) => {
  switch (type) {
    case 'videos':
      return acceptVideo;
    case 'images':
      return acceptImage;
    case 'pdfs':
      return acceptPDF;
    case 'videos_images_pdfs':
      return acceptVideoImageOrPDF;
    default:
      throw new Error(`${type} is not supported`);
  }
};

type Props = {
  onChange: (file: any) => void;
  contentType: UploadableContentTypes;
  fileTypeError?: string;
  handleFileTypeError?: (fileTypeError: string) => void;
};

const Dropzone: FC<Props> = ({ onChange, contentType, fileTypeError, handleFileTypeError }) => {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);

  const accept = getAcceptType(contentType);

  const onDrop = function (data: any) {
    const newFile = data[0];
    const extension = newFile.name.split('.').pop().toLowerCase();
    const extensionWithDot = `.${extension}`;
    setIsDragging(false);
    if (!isAcceptableFile(extensionWithDot, accept)) {
      handleFileTypeError &&
        handleFileTypeError(
          t('admin.common.messages.fileExtensionError', {
            validExtension: accept.join(', '),
          })
        );
    } else {
      handleFileTypeError && handleFileTypeError('');
      onChange(newFile);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({ onDrop });

  const onDragOver = () => setIsDragging(true);

  return (
    <DropzoneWrapper
      {...getRootProps({})}
      onDragOver={onDragOver}
      isDragging={isDragging}
      hasError={!!fileTypeError}
    >
      <input {...getInputProps()} accept={accept.join(', ')} multiple={false} />
      <SVGIcon src={UploadIcon} />
      <DropzoneGuide>
        <MainText>
          {t('admin.common.dropzoneMainGuide', {
            type: t(`admin.common.dropzoneTarget.${contentType}` as const),
          })}
        </MainText>
        <SubText>{t('admin.common.dropzoneGuide')}</SubText>
        {fileTypeError && <UploadError>{fileTypeError}</UploadError>}
      </DropzoneGuide>
    </DropzoneWrapper>
  );
};

export default Dropzone;

const DropzoneWrapper = styled('div')<{ isDragging: boolean; hasError: boolean }>(props => ({
  display: 'flex',
  width: '100%',
  height: 349,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 12,
  border: `solid 1px ${palette.borderInput}`,
  borderRadius: 6,
  userSelect: 'none',
  backgroundColor: props.isDragging || !!props.hasError ? palette.grayMedium1 : 'inherit',
  cursor: 'pointer',
  svg: {
    margin: '0 auto',
    width: 48,
    height: 32,
  },
  '&:focus': {
    outline: 'none',
  },
  [`@media ${bp.md}`]: {
    height: 200,
  },
}));

const DropzoneGuide = styled('div')({
  marginTop: 24,
  color: palette.darkMedium2,
  textAlign: 'center',
  [`@media ${bp.md}`]: {
    marginTop: 16,
  },
});

const MainText = styled('p')({
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '160%',
  [`@media ${bp.md}`]: {
    fontSize: 12,
  },
});

const SubText = styled('p')({
  fontSize: 12,
  marginTop: 4,
  lineHeight: '160%',
  [`@media ${bp.md}`]: {
    fontSize: 10,
    marginTop: 0,
  },
});

const UploadError = styled('p')({
  color: palette.redPrimary,
  fontSize: 12,
});
