import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
import { formatTime } from 'utility/helpers';
import i18n from 'i18n';

interface PropTypes {
  time: string;
}

const AddTime: FC<PropTypes> = ({ time }) => {
  const { t } = useTranslation();

  return (
    <WrapperTime>
      <Title>{t('common.timeTitle')}</Title>
      <Time>
        <p>{formatTime(time, 'HH:mm iiii')}</p>
        <p>{formatTime(time, i18n.language === 'ja' ? 'yyyy年M月d日' : 'do LLLL yyyy')}</p>
      </Time>
    </WrapperTime>
  );
};

export default AddTime;

const Title = styled('h3')({
  fontSize: 16,
  marginBottom: 20,
  textTransform: 'uppercase',
  fontWeight: 500,
  lineHeight: '19px',
  color: palette.darkMedium2,
  [`@media ${bp.md}`]: {
    marginBottom: 16,
  },
});

const WrapperTime = styled('div')({
  background: palette.whitePrimary,
  padding: 20,
  marginBottom: 20,
  borderRadius: 6,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
  [`@media ${bp.md}`]: {
    padding: 16,
    marginBottom: 16,
  },
});

const Time = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  p: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    '&:last-of-type': {
      marginBottom: 0,
      fontWeight: 'normal',
    },
    [`@media ${bp.md}`]: {
      marginBottom: 8,
    },
  },
});
