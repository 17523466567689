import { useState, useEffect } from 'react';

const useDragCursor = () => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    return () => {
      document.body.className = '';
    };
  }, []);

  const handleDragStart = () => {
    setDragging(true);
    document.body.className = 'grabbing';
  };

  const handleDragEnd = () => {
    setDragging(false);
    document.body.className = '';
  };

  return { dragging, handleDragStart, handleDragEnd };
};

export default useDragCursor;
