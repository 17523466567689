import React, { FC, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayableMenus } from 'types/App';
import NavLink from './NavigationLink';
import {
  navLinksSales as defaultNavLinksSales,
  navLinksAdmin,
  NavLinkProps,
  hearingLinkArchiveForNonAdmin,
} from './constants';
import styled from '@emotion/styled';
import { SidebarWrapper as _SidebarWrapper } from './styles';
import { bp, sizes } from 'entity/createTheme';

interface PropTypes {
  organizationId: string;
  isOpen: boolean;
  disabled: boolean;
  toggleOpen: () => void;
  newLogoSelected: boolean;
  needsRefetchLogo: boolean;
  changeNeedsRefetchLogo: (value: boolean) => void;
  getOrganizationRole: (id: string) => void;
  userChosenLogoImageUrl: string;
  displayableMenus: DisplayableMenus;
  serviceEnabled: boolean;
  isOpendByIframe: boolean;
}

const Sidebar: FC<PropTypes> = ({
  organizationId,
  isOpen,
  toggleOpen,
  disabled,
  needsRefetchLogo,
  changeNeedsRefetchLogo,
  getOrganizationRole,
  displayableMenus,
  serviceEnabled,
  isOpendByIframe,
}) => {
  useEffect(() => {
    if (needsRefetchLogo) {
      getOrganizationRole(organizationId);
      changeNeedsRefetchLogo(false);
    }
  }, [needsRefetchLogo]);

  const { t } = useTranslation();

  const filterNavLinks = useCallback(
    (navs: NavLinkProps[], forceDisp?: boolean) =>
      navs.filter(link => {
        // serviceEnabled = false のときは強制表示したいが、 not_admin のときは「アーカイブ」メニューを
        // 管理者側に移動させるというややこしい仕様があるため、 admin 系だけは forceDisp より優先度を高くしている
        if (link.requirements.find(l => l.match(/admin/))) {
          return link.requirements.some(r => displayableMenus[r]);
        } else {
          return forceDisp || link.requirements.some(r => displayableMenus[r]);
        }
      }),
    [displayableMenus]
  );

  const navLinksSales = displayableMenus['not_admin']
    ? [...defaultNavLinksSales, ...hearingLinkArchiveForNonAdmin]
    : defaultNavLinksSales;
  const navLinksSalesFiltered = useMemo(() => filterNavLinks(navLinksSales, !serviceEnabled), [
    filterNavLinks,
    serviceEnabled,
  ]);
  const navLinksAdminFiltered = useMemo(() => filterNavLinks(navLinksAdmin), [filterNavLinks]);
  const container = useRef<HTMLDivElement>(null);

  function handleClickNavLink(): void {
    if (isOpen) {
      toggleOpen();
    }
  }

  function renderNavLinks(navLinksData: NavLinkProps[]) {
    return navLinksData.map(link => (
      <NavLink
        disabled={disabled}
        key={link.id}
        activeUrl={link.activeUrl}
        iconUrl={link.iconUrl}
        handleClickNavLink={handleClickNavLink}
        label={t(link.label)}
      />
    ));
  }

  return (
    <SidebarWrapper
      isOpendByIframe={isOpendByIframe}
      ref={container}
      className={`sidebar ${isOpen ? 'open' : ''} ${' scroll-bar-thumbnail'}`}
    >
      <ul className={`sidebar_menubar ${disabled ? 'disabled' : ''}`}>
        {navLinksSalesFiltered.length > 0 && (
          <>
            <li>
              <p>{t('sidebar.salesTitle')}</p>
            </li>
            {renderNavLinks(navLinksSalesFiltered)}
            <hr />
          </>
        )}

        {navLinksAdminFiltered.length > 0 && (
          <>
            <li>
              <p>{t('sidebar.managementTitle')}</p>
            </li>
            {renderNavLinks(navLinksAdminFiltered)}
          </>
        )}
      </ul>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled(_SidebarWrapper)<{ isOpendByIframe: boolean }>(props => ({
  height: props.isOpendByIframe ? '100vh !important' : '100%',
  [`@media ${bp.sm}`]: {
    '&.open': {
      marginTop: props.isOpendByIframe ? '100vh' : `calc(100vh + ${sizes.headerHeightSm})`,
    },
  },
}));

export default Sidebar;
