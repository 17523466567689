import styled from '@emotion/styled';
import { ContentViewType } from 'entity/Content';
import { bp, palette } from 'entity/createTheme';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageProps, UploadVideoMeta } from 'types/App';
import { getImgWithStatus } from 'utility/helpers';
import { ReplaceFileButton, VideoPlayer } from 'views/components/compound';
import LoadingThumbnail from '../../LoadingThumbnail';
import UploadThumbnail from './UploadThumbnail';

type Props = {
  originalContent: ContentViewType;
  meta: UploadVideoMeta;
  firstFrameVideo: string;
  handleChangeMeta: (data: UploadVideoMeta) => void;
  componentBottom?: React.ReactElement;
  setActiveThumbnail: (data: string) => void;
  activeSrc: string;
  status: string;
  handleFlashMessage(data: MessageProps): void;
  handleChangeUploadFile: (file: any) => void;
  editMode: boolean;
};

const UploadMeta: FC<Props> = ({
  firstFrameVideo,
  handleChangeMeta,
  setActiveThumbnail,
  activeSrc,
  status,
  handleFlashMessage,
  meta,
  handleChangeUploadFile,
  originalContent,
  editMode,
}) => {
  const { t } = useTranslation();
  const { thumbnailSrcConvert, file } = useMemo(() => meta, [meta]);
  const isNotUploaded = useMemo(
    () =>
      status === 'not_uploaded' ||
      (status === 'uploaded' &&
        originalContent.content_type === 'videos' &&
        !originalContent.kaizen_files_url),
    [status]
  );

  const notSupportedTypeError = meta.error.includes(t('admin.common.notFileVideo'));
  const videoStatus = isNotUploaded ? 'not_uploaded' : status;

  return (
    <Root>
      <PreviewSection>
        <ContentHeading>{t('admin.common.content')}</ContentHeading>
        <MainFrameWrap>
          <MainFrame>
            {editMode || isNotUploaded ? (
              <ImageWrapper>
                {firstFrameVideo ? (
                  <img
                    src={getImgWithStatus(videoStatus, firstFrameVideo)}
                    alt="thumbnail_video"
                    className={isNotUploaded ? 'not-uploaded' : ''}
                  />
                ) : (
                  <LoadingThumbnail />
                )}
              </ImageWrapper>
            ) : (
              originalContent.content_type === 'videos' && (
                <VideoPlayer
                  width="100%"
                  height="100%"
                  status={status || ''}
                  manifestId={originalContent.kaizen_files_id}
                  manifestUrl={originalContent.kaizen_files_url}
                  muted
                />
              )
            )}
          </MainFrame>
        </MainFrameWrap>
        {!isNotUploaded && (
          <ReplaceFileButton acceptType="content" handleChange={handleChangeUploadFile} />
        )}
      </PreviewSection>
      <InfoSection>
        <ContentHeading>{t('admin.common.thumbnail')}</ContentHeading>
        <ThumbnailSection>
          {!notSupportedTypeError && (
            <UploadThumbnail
              handleChangeMeta={handleChangeMeta}
              setActiveThumbnail={setActiveThumbnail}
              isNotUploaded={isNotUploaded}
              firstFrameVideo={firstFrameVideo}
              thumbnailSrcConvert={thumbnailSrcConvert}
              status={status}
              fileVideo={file}
              handleFlashMessage={handleFlashMessage}
              meta={meta}
            />
          )}
          {meta.filename !== '' && (
            <FileNameSection>
              <ContentHeading>{t('admin.common.filename')}</ContentHeading>
              <FileName>{meta.filename}</FileName>
            </FileNameSection>
          )}
        </ThumbnailSection>
      </InfoSection>
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
  },
});

const FileName = styled('div')({
  marginTop: 0,
  width: '100%',
  color: palette.darkMedium2,
  fontSize: 14,
  letterSpacing: 0,
  lineHeight: 1.5,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-all',
});

const ThumbnailSection = styled('div')({
  height: 349,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [`@media ${bp.xmd}`]: {
    height: 270,
  },
  [`@media ${bp.md}`]: {
    height: 160,
    flexDirection: 'row',
    justifyContent: 'unset',
  },
});

const FileNameSection = styled('div')({
  [`@media ${bp.md}`]: {
    marginLeft: 16,
  },
});

const InfoSection = styled('div')({
  overflow: 'hidden',
  justifyContent: 'space-between',
  [`@media ${bp.md}`]: {
    marginTop: 20,
  },
});

const PreviewSection = styled('div')({
  marginRight: 24,
  width: 'fit-content',
});

const MainFrameWrap = styled('div')({
  width: 582,
  height: 349,
  marginBottom: 8,
  borderRadius: 4,
  border: `1px solid ${palette.borderInput}`,
  padding: 24,
  [`@media ${bp.xmd}`]: {
    width: 450,
    height: 270,
  },
  [`@media ${bp.md}`]: {
    padding: 16,
  },
});

const ContentHeading = styled('p')({
  fontSize: 12,
  marginBottom: 8,
});

const MainFrame = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  position: 'relative',
  background: palette.blackPrimary,
});

const ImageWrapper = styled('div')({
  background: palette.blackPrimary,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    '&.not-uploaded': {
      objectFit: 'cover',
    },
  },
});

export default UploadMeta;
