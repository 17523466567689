import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { bp, palette } from 'entity/createTheme';
import IconTrash from 'assets/images/ic_trash.svg';
import { Button } from 'views/components/primitive';
import _IconButton from 'views/components/compound/IconButton';

const Buttons = styled(Button)`
  padding: 0;
  @media ${bp.md} {
    padding: 0;
  }
  width: 50px;
  border: none !important;
  svg > g > g {
    fill: ${palette.redPrimary};
  }
  &.default {
    background: ${palette.redBlur};
    @media (hover: hover) {
      &:hover {
        background-color: ${palette.redMedium1};
        svg > g > g {
          fill: ${palette.redPrimary};
        }
      }
    }
  }
  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  &.not_bg {
    background-color: ${palette.whitePrimary};
    &:hover {
      background-color: ${palette.whitePrimary};
      svg > g > g {
        fill: ${palette.redPrimary};
      }
    }
  }
`;

type Props = {
  onClick: () => void;
  isBackground?: boolean;
  disabled?: boolean;
  size: 'md' | 'sm';
  tooltipText?: string;
};

const DeleteButton: FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <Buttons
      type="default"
      className={`delete-btn ${props.isBackground && 'not_bg'}`}
      label={
        <IconButton
          tooltipText={props.tooltipText || t('common.tooltipText.delete')}
          iconSrc={IconTrash}
        />
      }
      {...props}
    />
  );
};

export default DeleteButton;

const IconButton = styled(_IconButton)({
  fontWeight: 400,
  svg: {
    marginLeft: '6px',
  },
});
