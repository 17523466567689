import React, { FC } from 'react';
import styled from '@emotion/styled';
import InfoIcon from 'assets/images/ic_info.svg';
import { SVGIcon, Tooltip as _Tooltip } from 'views/components/primitive';
import { bp, palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';

type Props = {
  illustrationSrc: string;
  typeName: string;
  selected?: boolean;
  className?: string;
  inDeveloping?: boolean;
  tooltipText: string;
  onClick?: () => void;
};

const InstanceTypeCard: FC<Props> = ({
  illustrationSrc,
  typeName,
  selected,
  className,
  inDeveloping,
  tooltipText,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      inDeveloping={inDeveloping}
      selected={selected}
      className={className}
      onClick={onClick}
    >
      <Illustration>
        <CustomizeSVG uniquifyIDs src={illustrationSrc} />
      </Illustration>
      <TypeInfo>
        <TypeName>{typeName}</TypeName>
        <Tooltip
          tooltipText={
            inDeveloping ? (
              <>
                <p style={{ color: palette.whitePrimary }}>{t('common.inDeveloping')}</p>
                <p style={{ height: 8 }} />
                {tooltipText}
              </>
            ) : (
              tooltipText
            )
          }
        >
          <ModeInfoIcon src={InfoIcon} inDeveloping={inDeveloping} />
        </Tooltip>
      </TypeInfo>
    </CardWrapper>
  );
};

export default InstanceTypeCard;

const CardWrapper = styled('div')<{ selected?: boolean; inDeveloping?: boolean }>(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: palette.whitePrimary,
  border: `1px solid  ${palette.borderInput}`,
  borderRadius: 8,
  overflow: 'hidden',
  '& :hover': {
    cursor: 'pointer',
  },
  ...(props.selected && {
    border: `2px solid ${palette.greenMedium1}`,
  }),
  ...(props.inDeveloping && {
    [`${TypeInfo}`]: {
      '& > p': {
        color: palette.placeholder,
      },
    },
    '& :hover': {
      cursor: 'not-allowed',
    },
    background: palette.base,
    [`${CustomizeSVG}`]: {
      '& > g > path': {
        fill: palette.base,
      },
      '& > g > rect[fill*="#C9CACD"]': {
        fill: palette.borderInput,
      },
    },
  }),
}));

const Illustration = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
});

const TypeInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 16px',
  width: '100%',
  height: 48,
  borderTop: `1px solid  ${palette.borderInput}`,
  background: palette.base,
  '& > p': {
    fontSize: '0.75rem',
    fontWeight: 800,
    whiteSpace: 'nowrap',
  },
  [`@media ${bp.xmd}`]: {
    padding: '0 12px',
    '& > p': {
      fontSize: '0.75rem',
      fontWeight: 700,
    },
  },
  [`@media ${bp.xs}`]: {
    height: 40,
    padding: '0 8px',
    '& > p': {
      fontSize: '0.675rem',
      whiteSpace: 'normal',
    },
    '& > svg': {
      width: 24,
      minWidth: 24,
    },
  },
});

const CustomizeSVG = styled(SVGIcon)({
  width: '100%',
  height: '100%',
});

//Prevent passing 'inDeveloping' to SVGIcon component
const ModeInfoIcon = styled(SVGIcon, { shouldForwardProp: prop => prop !== 'inDeveloping' })<{
  inDeveloping?: boolean;
}>(
  props =>
    props.inDeveloping && {
      '& > path': {
        fill: palette.placeholder,
      },
    }
);

const TypeName = styled('p')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Tooltip = styled(_Tooltip)({
  display: 'flex',
});
