import React, { FC } from 'react';
import { palette } from 'entity/createTheme';
import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core';

type Props = {
  tooltipText: TooltipProps['title'];
  className?: string;
  disabled?: boolean;
  maxWidth?: string | number;
};

const useTooltipStyles = makeStyles({
  tooltip: (props: { maxWidth?: string | number }) => ({
    backgroundColor: palette.blackPrimary,
    opacity: '0.8 !important',
    borderRadius: 3,
    fontSize: '0.75rem',
    padding: '6px 10px',
    margin: '4px 0',
    maxWidth: props.maxWidth,
    whiteSpace: 'pre-wrap',
  }),
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderTop: `3px solid ${palette.blackPrimary}`,
    position: 'absolute',
    top: '100%',
  },
});

const CustomizedTooltip: FC<Props> = ({
  children,
  tooltipText,
  disabled = false,
  className,
  maxWidth,
}) => {
  const classes = useTooltipStyles({ maxWidth });

  return (
    <Tooltip
      classes={classes}
      title={tooltipText}
      className={className}
      arrow
      placement="top"
      TransitionProps={{ timeout: 0 }}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default CustomizedTooltip;
