import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageProps, UploadVideoMeta } from 'types/App';
import { thumbnailMaxHeight, thumbnailMaxWidth } from 'utility/constants';
import LoadingThumbnail from '../../LoadingThumbnail';
import ReplaceThumbnailButton from '../../ReplaceFileButton';

type Props = {
  handleChangeMeta: (data: UploadVideoMeta) => void;
  setActiveThumbnail(data: string): void;
  isNotUploaded: boolean;
  firstFrameVideo: string;
  thumbnailSrcConvert: string;
  status: string;
  fileVideo: any;
  handleFlashMessage(data: MessageProps): void;
  meta: UploadVideoMeta;
};

const UploadThumbnail: FC<Props> = ({
  handleChangeMeta,
  setActiveThumbnail,
  isNotUploaded,
  firstFrameVideo,
  thumbnailSrcConvert,
  status,
  fileVideo,
  handleFlashMessage,
  meta,
}) => {
  const { t } = useTranslation();

  function onChangeFile(file: any) {
    if (!file) return;
    const url = URL.createObjectURL(file);
    const thumbnail = new Image();
    thumbnail.src = url;
    thumbnail.onload = () => {
      if (thumbnail.height > thumbnailMaxHeight || thumbnail.width > thumbnailMaxWidth) {
        handleChangeMeta({
          ...meta,
          fileThumbnail: '',
          thumbnailSrcConvert: '',
          fileThumbnailConst: '',
          error: t('admin.productContent.messages.thumbnailResolutionError', {
            maxWidth: thumbnailMaxWidth,
            maxHeight: thumbnailMaxHeight,
          }),
        });
        return;
      }
      setActiveThumbnail(url);
      handleChangeMeta({
        ...meta,
        fileThumbnail: file,
        thumbnailSrcConvert: url,
        fileThumbnailConst: file,
        error: '',
      });
    };
  }

  function handleError() {
    handleFlashMessage({ content: t('admin.common.notFileImage'), status: 'error' });
  }

  const thumbnailSrc = thumbnailSrcConvert || firstFrameVideo;

  return (
    <Root>
      <ThumbnailWrap key={thumbnailSrc}>
        <ThumbnailImg>
          {thumbnailSrc ? (
            <img src={thumbnailSrc || undefined} alt="thumbnail-item" onError={handleError} />
          ) : (
            <LoadingThumbnail />
          )}
        </ThumbnailImg>
      </ThumbnailWrap>
      <ReplaceThumbnailButton
        handleChange={onChangeFile}
        acceptType="thumbnail"
        disabled={isNotUploaded || (status === 'upload_failed' && !fileVideo)}
      />
    </Root>
  );
};

const Root = styled('div')({
  width: 178,
  minWidth: 178,
  height: 110,
});

const ThumbnailWrap = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: 11,
  border: `1px solid ${palette.borderInput}`,
  marginBottom: 8,
});

const ThumbnailImg = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

export default UploadThumbnail;
