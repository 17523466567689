import { observable, action, toJS } from 'mobx';
import i18n from 'i18n';
import { MessageProps } from 'types/App';
import { ListMeta } from 'types/common';
import { RootStore } from '../../index';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import { HearingItem } from 'types/HearingSet';
import { routes } from 'utility/constants';
import history from 'utility/history';
import { defaultListMeta } from '../constants';
import { trimSpace } from 'utility/helpers';

class HearingListStore {
  private readonly hearingAdminApi: HearingAdminApi;
  @observable rootStore: RootStore;
  @observable public isLoading = false;
  @observable public hearingListMeta: ListMeta = { ...defaultListMeta };
  @observable public hearingList: HearingItem[] = [];
  @observable public errors = {};
  @observable public isSelectHearing = false;
  @observable public selectedHearingList: number[] = [];
  @observable public targetHearing: { id: number; status: string } = { id: 0, status: '' };

  constructor({
    rootStore,
    hearingAdminApi,
  }: {
    rootStore: RootStore;
    hearingAdminApi: HearingAdminApi;
  }) {
    this.rootStore = rootStore;
    this.hearingAdminApi = hearingAdminApi;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public changeTargetHearing = (id: number, status: string) => {
    this.targetHearing = { ...this.targetHearing, id, status };
  };

  @action.bound
  public async getHearingList(page = 1, sort = 'latest', order = 'updated_at') {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.isLoading = true;
    try {
      const { data } = await this.hearingAdminApi.getAdminHearingList({
        page,
        sort,
        order,
        organization_id,
      });
      this.hearingListMeta = { page, sort, order, total: data.total };
      this.hearingList = data.hearing_sets;
    } catch (error: any) {
      this.errors = error;
      this.resetHearingList();
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetHearingList() {
    this.hearingList = [];
    this.hearingListMeta = { ...defaultListMeta };
  }

  @action.bound
  public toggleSelectHearing(): void {
    const current = toJS(this.isSelectHearing);
    if (current) {
      this.selectedHearingList = [];
    }
    this.isSelectHearing = !current;
  }

  @action.bound
  public handleSelectedHearing(listId: number[]): void {
    this.selectedHearingList = [...listId];
  }

  @action.bound
  public async createNewQuestionnaire(title: string) {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.hearingAdminApi.addHearingSet({
        title: trimSpace(title),
        status: 'draft',
        organization_id,
      });
      history.push(`${routes.management.questionnaireDetail}/${data.id}`);
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.hearingSet.messages.createHearingError'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async changeHearingStatus(status: string, id?: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const idList = id ? [id] : this.selectedHearingList;
      await this.hearingAdminApi.changeHearingStatus({
        status,
        idList,
        confirm: true,
        organization_id,
      });
      this.isLoading = true;
      this.selectedHearingList = [];
      if (status === 'draft' || status === 'published') {
        this.pushFlashMessages({
          content: i18n.t('admin.productContent.messages.changeStatusSuccess'),
          status: 'success',
        });
        this.hearingList = this.hearingList.map(hearing => {
          if (idList.includes(hearing.id)) {
            return { ...hearing, status };
          }
          return hearing;
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.common.messages.archiveSuccess', {
            item: i18n.t('common.actionTarget.hearings'),
          }),
          status: 'success',
        });
        this.getHearingList();
      }
    } catch (error: any) {
      if (error?.message.includes('422')) {
        this.pushFlashMessages({
          content:
            status === 'published'
              ? i18n.t('admin.hearingSet.messages.statusValidationError')
              : i18n.t('admin.common.messages.updateStatusFailed'),
          status: 'error',
        });
      }
    } finally {
      this.isLoading = false;
    }
  }
}

export default HearingListStore;
