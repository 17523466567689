import EmbedTypeQuestionnaire from 'assets/images/embed_type_questionnaire.svg';
import EmbedTypeInteractive from 'assets/images/embed_type_interactive.svg';

type EmbedTypeName =
  | 'admin.hearingSet.instanceType.contentAndQuestionnaire'
  | 'admin.hearingSet.instanceType.interactiveVideo';

type InstanceType = {
  id: string;
  typeName: EmbedTypeName;
  illustrationSrc: string;
};

export const questionnaireModes: InstanceType[] = [
  {
    id: 'content_questionnaire',
    typeName: 'admin.hearingSet.instanceType.contentAndQuestionnaire',
    illustrationSrc: EmbedTypeQuestionnaire,
  },
  {
    id: 'interactive_video',
    typeName: 'admin.hearingSet.instanceType.interactiveVideo',
    illustrationSrc: EmbedTypeInteractive,
  },
];

export const inDevelopingEmbedType = ['interactive_video'];
