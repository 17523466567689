import React, { FC, useRef, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useClickAway } from 'react-use';

type Props = {
  className?: string;
  isOpen: boolean;
  toggleOpenDropdown: (event: MouseEvent<HTMLDivElement>) => void;
  size: 'sm' | 'md';
  isDisabled?: boolean;
  width?: string | number;
};

const DropdownWrapperComponent: FC<Props> = ({
  className,
  children,
  isOpen,
  toggleOpenDropdown,
  size,
  isDisabled = false,
  width,
}) => {
  const container = useRef<HTMLDivElement>(null);

  const handleClick = (event: any) => {
    if (container.current) {
      if (!container.current.contains(event.target) && isOpen) {
        toggleOpenDropdown(event);
      }
    }
  };

  useClickAway(container, handleClick);

  return (
    <DropdownWrapper
      size={size}
      isOpen={isOpen}
      className={className}
      onClick={!isDisabled ? toggleOpenDropdown : undefined}
      ref={container}
      isDisabled={isDisabled}
      width={width}
    >
      {children}
    </DropdownWrapper>
  );
};

export default DropdownWrapperComponent;

const DropdownWrapper = styled('div')<{
  isActive?: boolean;
  isOpen: boolean;
  size: 'sm' | 'md';
  isDisabled: boolean;
  width?: string | number;
}>(props => ({
  height: props.size === 'sm' ? 32 : 40,
  padding: '0 16px',
  fontSize: props.size === 'sm' ? '0.75rem' : '0.875rem',
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 6,
  border: `1.5px solid ${palette.borderInput}`,
  userSelect: 'none',
  background: palette.whitePrimary,
  position: 'relative',
  '&:hover': {
    span: {
      '&:not(.item)': {
        color: palette.deepBlueLight,
        borderColor: palette.deepBlueLight,
      },
    },
  },
  ...(props.width && { width: props.width }),
  ...(props.isOpen && {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottom: 0,
  }),
  ...(props.isDisabled && {
    background: palette.borderInput,
    cursor: 'not-allowed',
    span: {
      borderColor: palette.deepBlueDisable,
      color: palette.deepBlueDisable,
    },
    '&:hover': {
      span: { borderColor: `${palette.deepBlueDisable} !important` },
    },
  }),
}));
