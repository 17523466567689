import React, { FC, ChangeEvent } from 'react';
import { css } from '@emotion/core';
import SVGIcon from 'views/components/primitive/SVGIcon';
import SearchIcon from 'assets/images/ic_search.svg';
import { palette } from 'entity/createTheme';

interface SearchInputProps {
  value: string;
  name?: string;
  placeholder?: string;
  onSearch: () => void;
  onChange: (val: string) => void;
  onCompositionStart(): void;
  onCompositionEnd(): void;
}

const style = css`
  width: 100%;
  display: flex;

  svg {
    margin-right: 10px;
  }

  input {
    font-size: 1rem;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;

    &::placeholder {
      font-size: 14px;
      color: ${palette.placeholder};
    }
  }
`;

const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  onSearch,
  onCompositionStart,
  onCompositionEnd,
  ...rest
}) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  return (
    <div css={style}>
      <SVGIcon onClick={onSearch} src={SearchIcon} uniquifyIDs />
      <input
        type="search"
        value={value}
        onChange={handleChange}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
        {...rest}
      />
    </div>
  );
};

export default SearchInput;
