import styled from '@emotion/styled';
import HelpIcon from 'assets/images/help.svg';
import { palette } from 'entity/createTheme';
import { useCopyToClipboard } from 'hooks';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SVGIcon, Tooltip as _Tooltip } from 'views/components/primitive';

type Props = {
  url: string;
};

const SharableLinkURLInfo: FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  const handleOnMouseEnter = () => {
    setIsHover(true);
  };

  const handleOnMouseLeave = () => {
    setIsHover(false);
  };

  const { handleCopy } = useCopyToClipboard(url);

  const openPreview = () => {
    const _url = new URL(url);
    _url.searchParams.append('preview', '1');
    window.open(_url.href);
  };

  return (
    <Root>
      <LinkTitleWrapper
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleCopy}
      >
        <p title={url}>{isHover ? `${t('sharingLink.copyDefault')}` : `${url}`}</p>
      </LinkTitleWrapper>
      <ButtonWrapper>
        <Button type="default" size="sm" label={t('common.preview')} onClick={openPreview} />
      </ButtonWrapper>
      <Tooltip tooltipText={t('sharingLink.previewTooltip')}>
        <SVGIcon src={HelpIcon} />
      </Tooltip>
    </Root>
  );
};

export default SharableLinkURLInfo;

const Root = styled('div')({
  display: 'flex',
});

const LinkTitleWrapper = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '40%',
  marginLeft: '34px',
  borderRadius: '1rem',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: palette.grayPrimary,
  paddingLeft: '1rem',
  p: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '0.65rem',
    color: palette.greenPrimary,
  },
  '&:hover': {
    backgroundColor: palette.whitePrimary,
    justifyContent: 'center',
  },
});

const ButtonWrapper = styled('div')({
  marginLeft: '8px',
});

const Tooltip = styled(_Tooltip)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
