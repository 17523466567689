import { UserInfo } from 'types/App';
import HttpClient from 'adapter/HTTPClient';

class UserApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getMe = async () => {
    const res = await this.httpClient.request<UserInfo>({
      method: 'GET',
      url: 'me',
    });

    return res;
  };
}

export default UserApi;
