import { isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import i18n from 'i18n';
import { ja, enUS } from 'date-fns/locale';
import { ErrorTypes } from 'types/common';
import ImgWaitToUpload from 'assets/images/upload.gif';
import ImgError from 'assets/images/ErrorImg.jpg';
import { HearingItem } from 'types/HearingSet';
import { Content } from 'types/App';
import { ProductItem } from 'types/Product';

function isTouchDevice() {
  return 'ontouchstart' in document.documentElement;
}

function formatTime(time: string, formatType: string): string {
  const language = i18n.language;
  const locale = language === 'ja' ? ja : enUS;
  if (!isValid(new Date(time))) return '';
  return format(new Date(time), formatType, { locale });
}

function formatWithJPTimeZone(time: string, formatType: string): string {
  const language = i18n.language;
  const locale = language === 'ja' ? ja : enUS;
  if (!isValid(new Date(time))) return '';
  const date = new Date(time);
  const timeZone = 'Asia/Tokyo';
  const utcTime = utcToZonedTime(date, timeZone);
  return format(utcTime, formatType, { locale, timeZone });
}

function formatError(errorsValidate: ErrorTypes): ErrorTypes {
  const obj: ErrorTypes = {};
  Object.keys(errorsValidate).forEach(key => {
    obj[key] = i18n.t(errorsValidate[key]);
  });
  return obj;
}

function dataURLtoFile(dataurl: string) {
  const pos = dataurl.indexOf(';base64,');
  const type = dataurl.substring(5, pos);
  const b64 = dataurl.substr(pos + 8);

  // decode base64
  const imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  const blob = new Blob([buffer], { type: type });
  return blob;
}

function trimSpace(str: string) {
  return str.trim().replace(/ +(?= )/g, '');
}

function getImgWithStatus(status: string, src: string | null) {
  if (status === 'not_uploaded') return ImgWaitToUpload;
  if (status === 'upload_failed' || !src) return ImgError;
  if (src) return src;
}

function getImgFromContent(content?: ProductItem | Content | null) {
  if (!content) return;
  switch (content.content_type) {
    case 'videos':
      const { kaizen_files_thumbnail_url, upload_status, kaizen_files_url } = content;
      const status = upload_status || '';
      if (!kaizen_files_url) return ImgWaitToUpload;
      return kaizen_files_thumbnail_url
        ? getImgWithStatus(status, kaizen_files_thumbnail_url)
        : undefined;
    case 'links':
      return content.thumbnail || undefined;
    case 'images':
      return content.kaizen_files_url ?? '';
    case 'pdfs':
      return content.kaizen_files_thumbnail_url ?? '';
    case 'none_data':
      return undefined;
    default:
      return undefined;
  }
}

function extractFileExtentionFromMimeType(type: string) {
  // expecting to receive mime type string, like "image/png" "video/mp4"
  const extention = type.split('/').pop();
  return extention ? extention.toLowerCase() : '';
}

function extractFileNameFromPath(path: string) {
  const withoutQuery = path.split('?').shift();
  if (withoutQuery) return withoutQuery.split('/').pop();
}

function getTotalQuestions(hearing: HearingItem | undefined) {
  if (!hearing) return 0;
  let total = 0;
  hearing.question_sets.forEach(set => (total += set.questions.length));
  return total;
}

function getVideosFromHearing(hearing: HearingItem | undefined) {
  if (!hearing) return [];
  const videos: { manifestId: string; manifestUrl: string }[] = [];
  hearing.question_sets.forEach(questionSet => {
    const content = questionSet.content;
    if (content?.content_type === 'videos' && content.kaizen_files_id && content.kaizen_files_url) {
      videos.push({ manifestId: content.kaizen_files_id, manifestUrl: content.kaizen_files_url });
    }
  });
  return videos;
}

function getContentTypeString(contentType: string) {
  if (contentType.includes('video')) {
    return 'videos';
  } else if (contentType.includes('image')) {
    return 'images';
  } else if (contentType.includes('pdf')) {
    return 'pdfs';
  } else {
    return 'invalid type';
  }
}

const isAcceptableFile = (val: string | undefined, accept: Readonly<string[]>) =>
  typeof val === 'string' && accept.includes(val);

const searchByTitle = <T extends { title: string }>(arr: T[], keyword: string) => {
  return arr.filter(item =>
    item.title.trim().toLocaleLowerCase().includes(keyword.trim().toLocaleLowerCase())
  );
};

const timeToMilliseconds = (time: string) => {
  //player time is always in format hh:mm:ss
  const [hourStr, minuteStr, secondStr] = time.split(':');
  const hours = parseInt(hourStr);
  const minutes = parseInt(minuteStr);
  const seconds = parseInt(secondStr);
  //return duration in miliseconds
  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
};

const formatDuration = (miliseconds: number, displayHour?: boolean): string => {
  const durationInSeconds = Math.floor(miliseconds / 1000);
  const hours = Math.floor(durationInSeconds / 60 / 60);
  const minutes = Math.floor((durationInSeconds - hours * 60 * 60) / 60);
  const seconds = durationInSeconds - hours * 60 * 60 - minutes * 60;
  const hourStr = hours < 10 ? `0${hours}` : hours.toString();
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondStr = seconds < 10 ? `0${seconds}` : seconds.toString();
  return displayHour || hours !== 0
    ? `${hourStr}:${minuteStr}:${secondStr}`
    : `${minuteStr}:${secondStr}`;
};

export {
  isTouchDevice,
  formatTime,
  formatError,
  dataURLtoFile,
  formatWithJPTimeZone,
  trimSpace,
  getImgWithStatus,
  getImgFromContent,
  extractFileExtentionFromMimeType,
  extractFileNameFromPath,
  getTotalQuestions,
  getVideosFromHearing,
  getContentTypeString,
  isAcceptableFile,
  searchByTitle,
  timeToMilliseconds,
  formatDuration,
};
