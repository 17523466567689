import { TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

const StyledTextField = styled(TextField)`
  & > .MuiInputBase-root {
    & > .MuiInputBase-input {
      padding: ${props => (props.multiline ? 'initial' : '11.5px 14px')};
      color: ${palette.darkMedium2};
    }
  }

  & > .MuiOutlinedInput-root {
    border-color: ${palette.borderInput};
    border-width: 1.5px;
    &.Mui-focused　 {
      & > .MuiOutlinedInput-notchedOutline {
        border-color: ${palette.deepBlueLight};
      }
      &:hover {
        & > .MuiOutlinedInput-notchedOutline {
          border-color: ${palette.deepBlueLight};
        }
      }
    }

    &.Mui-disabled {
      & > .MuiOutlinedInput-notchedOutline {
        border-color: ${palette.grayMedium6} !important;
      }
    }

    &:hover {
      & > .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23); // default color
      }
    }
  }
`;

export default StyledTextField;
