import { CTASetting, useSettingCTAItem } from 'organisms/ShareableLink/useSharableLinkViewSetting';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'views/components/compound';
import { InputText } from 'views/components/primitive';

type Props = {
  isOpen: boolean;
  toggleOpen: () => void;
  ctaSetting: CTASetting;
  handleChangeCTASetting: (setting: Partial<CTASetting>) => void;
  cancelAddCTA: () => void;
};

const CTASettingDrawer: FunctionComponent<Props> = ({
  isOpen,
  toggleOpen,
  ctaSetting,
  handleChangeCTASetting,
  cancelAddCTA,
}) => {
  const { t } = useTranslation();
  const {
    ctaItem,
    hasChanges,
    ctaSettingError,
    handleChangeCTAItem,
    resetCTAItemSetting,
    saveCTAItemChange,
    validateCTAItem,
  } = useSettingCTAItem(ctaSetting, handleChangeCTASetting);

  const onCloseDrawer = () => {
    resetCTAItemSetting();
    cancelAddCTA();
    toggleOpen();
  };

  const handleSaveChange = () => {
    const isValidCTAItem = validateCTAItem();
    if (isValidCTAItem) {
      saveCTAItemChange();
      toggleOpen();
    }
    return;
  };

  return (
    <Drawer
      disabledAction={!hasChanges}
      isOpen={isOpen}
      onClose={onCloseDrawer}
      title={
        !!ctaSetting.isAddMode
          ? t('sharingLink.viewSetting.ctaSetting.addCTA')
          : t('sharingLink.viewSetting.ctaSetting.editCTA')
      }
      onSave={handleSaveChange}
    >
      <InputText
        value={ctaItem.title || ''}
        onChange={(title: string) => handleChangeCTAItem({ title })}
        label={t('sharingLink.viewSetting.ctaSetting.dislayText')}
        placeholder={t('sharingLink.viewSetting.ctaSetting.displayTextPlaceHodler')}
        maxLength={200}
      />
      <InputText
        value={ctaItem.link_to || ''}
        onChange={(linkTo: string) => handleChangeCTAItem({ link_to: linkTo })}
        label={t('sharingLink.viewSetting.ctaSetting.url')}
        placeholder={t('sharingLink.viewSetting.ctaSetting.urlPlaceHolder')}
        maxLength={200}
        error={ctaSettingError}
        isRequired
      />
    </Drawer>
  );
};

export default CTASettingDrawer;
