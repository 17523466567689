import HttpClient from 'adapter/HTTPClient';

type UpdateNotePayload = {
  id: number;
  note: string;
};

class UserAnswerApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public updateUserAnswerNote = async (
    data: { answered_questions: UpdateNotePayload[] },
    organization_id: string
  ) => {
    await this.httpClient.request({
      method: 'PUT',
      url: 'answered_questions/update_notes',
      data: {
        ...data,
        organization_id,
      },
    });
  };
}

export default UserAnswerApi;
