import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { HearingItem } from 'types/HearingSet';
import { useTranslation } from 'react-i18next';
import { HearingItemForDisplay } from 'views/components/compound';

type Props = {
  questionnaire?: HearingItem;
  toggleQuestionnaireModal: () => void;
};

const ContentSelector: FC<Props> = ({ questionnaire, toggleQuestionnaireModal }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!questionnaire && <StyledHearingItem hearing={questionnaire} />}
      <Button
        size="md"
        type="default"
        label={t('sharingLink.selectQuestionnaire')}
        onClick={toggleQuestionnaireModal}
      />
    </>
  );
};

export default ContentSelector;

const StyledHearingItem = styled(HearingItemForDisplay)({
  padding: '20px 0',
});
