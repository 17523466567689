import { action, observable } from 'mobx';
import SettingApi from 'services/Admin/SettingApi';
import { RootStore } from 'stores';

export type LinkedAccount = {
  hubspot_account_id: string;
  hubspot_portal_id: string;
  is_optout: boolean;
};

export type AuthUrl = {
  uri: string;
  client_id: string;
  scope: Array<string>;
  redirect_uri: string;
};

class SettingHubSpotStore {
  private readonly settingApi: SettingApi;
  @observable rootStore: RootStore;
  @observable public loading = false;
  @observable public linkedAccount: LinkedAccount | undefined;
  @observable public authUrl: AuthUrl | undefined;

  constructor({ rootStore, settingApi }: { rootStore: RootStore; settingApi: SettingApi }) {
    this.rootStore = rootStore;
    this.settingApi = settingApi;
  }

  @action.bound
  public async getAccounts(organizationId: string) {
    this.linkedAccount = await this.settingApi.getHubSpotAccounts(organizationId);
  }

  @action.bound
  public async getAuthUrl(organizationId: string) {
    const authUrl = await this.settingApi.getHubSpotAuthUrl(organizationId);
    this.authUrl = authUrl;
  }

  @action.bound
  public async postAccounts(organizationId: string, code: string) {
    await this.settingApi.postHubSpotAccounts(organizationId, code);
  }

  @action.bound
  public async deleteAccounts(organizationId: string) {
    await this.settingApi.deleteHubSpotAccounts(organizationId);
  }
}

export default SettingHubSpotStore;
