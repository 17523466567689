import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './translations/en-US';
import ja from './translations/ja';
import { Languages } from './translations/models';

declare module 'react-i18next' {
  interface Resources {
    translation: Languages;
  }
}

const resources = {
  'en-US': {
    translation: enUS,
  },
  ja: {
    translation: ja,
  },
};

i18n.use(initReactI18next).init({
  lng: 'ja',
  resources,
});

export default i18n;
