import { useStore } from 'hooks';

const useLinkContent = () => {
  const {
    hearingLinkStore: { hearingLinkDetail: linkDetail },
  } = useStore();

  const { product_contents: productContents } = linkDetail;
  const hasDraftOrArchived =
    productContents &&
    productContents.some(
      productContent => productContent.status === 'draft' || productContent.status === 'archived'
    );

  return {
    hasDraftOrArchived,
  };
};

export default useLinkContent;
