import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ProductItem } from 'types/Product';
import { palette, bp } from 'entity/createTheme';
import { getImgFromContent } from 'utility/helpers';
import ThumbnailIcon from '../ThumbnailIcon';

type Props = {
  selectedContents: ProductItem[];
};

const SelectedContents: FC<Props> = ({ selectedContents }) => {
  return (
    <SelectedContentsWrapper>
      <SelectedCountContainer>
        Your selected&emsp;<SelectedCount>{selectedContents.length}</SelectedCount>
        <SelectedCountBorder />
      </SelectedCountContainer>

      <SelectedItemList className="scroll-bar-thumbnail">
        {selectedContents.map(content => (
          <ThumbnailWrapper key={content.id}>
            <ThumbnailIcon src={getImgFromContent(content)} size={40} />
          </ThumbnailWrapper>
        ))}
      </SelectedItemList>
    </SelectedContentsWrapper>
  );
};

export default SelectedContents;

const SelectedContentsWrapper = styled('div')({
  minHeight: 80,
});

const SelectedCountContainer = styled('div')({
  fontSize: 12,
  color: palette.grayMedium7,
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',

  [`@media ${bp.md}`]: {
    fontSize: 13,
  },
});

const SelectedCount = styled('span')({
  color: palette.darkMedium2,
  fontWeight: 'bold',
  marginRight: 10,
});

const SelectedCountBorder = styled('div')({
  height: 1,
  flex: 1,
  background: palette.grayPrimary,
});

const SelectedItemList = styled('div')({
  display: 'flex',
  overflowX: 'auto',
  paddingBottom: 4,
  '&::-webkit-scrollbar': {
    height: 4,
  },
});

const ThumbnailWrapper = styled('div')({
  width: 40,
  height: 40,
  borderRadius: 6,
  overflow: 'hidden',
  flexShrink: 0,
  '&:not(:first-of-type)': {
    marginLeft: 10,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
