import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette, sizes } from 'entity/createTheme';
import { resizePadding } from './styles';

const AppointmentContent: FC = ({ children }) => {
  return <Root>{children}</Root>;
};

export default AppointmentContent;

const Root = styled('div')`
  height: 100%;
  background: ${palette.grayMedium5};
  ${resizePadding('', 30, 16)};
  max-width: ${sizes.contentWidth};
  margin: 0 auto;
`;
