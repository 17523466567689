import styled from '@emotion/styled';
import { palette, sizes, bp, layer } from 'entity/createTheme';

const SidebarWrapper = styled.div`
  display: flex;
  z-index: ${layer.sidebar};
  flex-direction: column;

  @media ${bp.sm} {
    position: fixed;
  }

  .sidebar_logo {
    height: ${sizes.headerHeightSm};
    background: ${palette.whitePrimary};
    box-shadow: 3px 0 3px ${palette.grayMedium1};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    @media ${bp.sm} {
      height: ${sizes.headerHeightSm};
      padding: 16px;
    }
  }

  .sidebar_menubar {
    position: relative;
    flex: 1;
    padding-top: 21px;
    padding: 0 16px 16px;
    list-style: none;
    background-color: ${palette.whitePrimary};
    a,
    h3,
    .disable-link {
      color: ${palette.darkMedium2};
      font-size: 14px;
      line-height: 1.1875rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: unset;
      font-weight: 700;
      border-radius: 8px;
    }
    li {
      height: 40px;
      &:first-of-type {
        margin-top: 15px;
      }
    }
    hr {
      width: 100%;
      margin: 8px 0px;
      border: none;
      height: 1px;
      flex-shrink: 0;
      background-color: transparent;
    }
    a.disabled-link {
      pointer-events: none;
    }
    a:hover,
    a.active {
      background-color: ${palette.grayMedium11};
      svg {
        & > g {
          & > g {
            fill: ${palette.whitePrimary};
          }
        }
      }
    }
    &.disabled {
      a,
      .disable-link,
      h3 {
        opacity: 0.2;
      }
    }

    p {
      text-transform: uppercase;
      padding: 20px 18px 10px;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 700;
      color: ${palette.deepBlueLight};
      padding: 8px 16px;

      &:first-of-type {
        border-top: 0px solid #ccc;
      }
    }
  }
  .short_logo {
    display: none;
  }
  @media ${bp.sm} {
    position: fixed;
    top: -100vh;
    left: 0;
    margin-top: 0;
    transition: margin 0.3s ease;
    .short_logo {
      cursor: pointer;
      display: inline;
      position: fixed;
      bottom: 12px;
      left: 20px;
    }
    &.open {
      margin-top: calc(100vh + ${sizes.headerHeightSm});
    }
  }
`;

export { SidebarWrapper };
