import { Appointment, CreateAppointmentData, CreateQuickHearingData } from 'types/Appointment';
import { trimSpace } from 'utility/helpers';
import HttpClient from 'adapter/HTTPClient';
import { AnsweredQuestion } from 'types/App';
import { AppointmentType } from 'types/Appointment';

class AppointmentApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getAppointments = async ({
    currentPage,
    sort,
    order,
    type,
    organization_id,
  }: {
    currentPage: number;
    sort: string;
    order: string;
    organization_id: string;
    type?: AppointmentType;
  }) => {
    const res = await this.httpClient.request<{
      appointments: Appointment[];
      total: number;
      page: number;
      organization_id: string;
      type?: AppointmentType;
    }>({
      method: 'GET',
      url: '/appointments',
      params: {
        page: currentPage,
        per_page: 20,
        sort,
        order,
        organization_id,
        type,
      },
    });

    return res;
  };

  public getAppointmentById = async (id: string, organization_id: string) => {
    const res = await this.httpClient.request<Appointment>({
      url: `/appointments/${id}`,
      method: 'GET',
      params: {
        organization_id,
      },
    });

    return res;
  };

  public createAppointment = async (data: CreateAppointmentData, organization_id: string) => {
    await this.httpClient.request({
      url: '/appointments',
      method: 'POST',
      data: { ...data, organization_id },
    });
  };

  // FIX ME: type data
  public createQuickHearing = async (data: CreateQuickHearingData, organization_id: string) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'appointments/create_quick_hearing',
      data: {
        ...data,
        organization_id,
      },
    });
  };

  public updateAppointment = async ({
    appointment,
    id,
    organization_id,
  }: {
    appointment: Appointment;
    id: string;
    organization_id: string;
  }) => {
    const { company_name, company_pic_name, memo, client } = appointment;
    await this.httpClient.request({
      url: `/appointments/${id}`,
      method: 'PUT',
      data: {
        company_name: trimSpace(company_name || ''),
        company_pic_name: trimSpace(company_pic_name),
        memo: trimSpace(memo),
        client_id: client?.id,
        organization_id,
      },
    });
  };

  public deleteAppointments = async (idList: number[], organization_id: string) => {
    await this.httpClient.request({
      method: 'DELETE',
      url: `appointments/delete_appointments?ids=${idList.join(',')}`,
      params: {
        organization_id,
      },
    });
  };

  public getAppointmentResult = async (id: number, organization_id: string) => {
    const res = await this.httpClient.request<{ answered_questions: AnsweredQuestion[] }>({
      url: `/appointments/${id}/answered_questions`,
      method: 'GET',
      params: {
        organization_id,
      },
    });
    return res;
  };
}

export default AppointmentApi;
