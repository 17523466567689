import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  className?: string;
};

const ContentTypeLabel: FC<Props> = ({ children, className }) => (
  <LabelWrapper className={className}>{children}</LabelWrapper>
);

export default ContentTypeLabel;

const LabelWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 50,
  height: 24,
  fontSize: 10,
  backgroundColor: palette.grayBlur3,
  borderRadius: 6,
  color: palette.whitePrimary,
});
