import {
  Chart,
  ScatterDataPoint,
  BubbleDataPoint,
  ChartTypeRegistry,
  TooltipModel,
} from 'chart.js';
import { RefObject } from 'react';

export type ChartContext = { chart: Chart; tooltip: TooltipModel<keyof ChartTypeRegistry> };

const formatLabels = (
  chart: Chart<'bar', (number | ScatterDataPoint | BubbleDataPoint)[], unknown>,
  _: Record<string, never>,
  options: any
) => {
  const omitLable = (label: string, maxLength: number): string => {
    if (label.length > maxLength) {
      return label.substr(0, maxLength) + '...';
    }
    return label;
  };
  const splitByLength = (str: string, length: number) => {
    const resultArr: string[] = [];
    if (!str || !length || length < 1) {
      return resultArr;
    }
    let index = 0;
    let start = index;
    let end = start + length;
    while (start < str.length) {
      resultArr[index] = str.substring(start, end);
      index++;
      start = end;
      end = start + length;
    }
    return resultArr;
  };

  const maxLength = options.plugins?.formatLabels?.maxLength ?? 40;
  const maxLineLength = options.plugins?.formatLabels?.maxLineLength ?? 20;

  chart.data.labels?.forEach((label, index) => {
    if (typeof label !== 'string') return;
    if (!chart.data.labels) return;
    if (label.length > maxLength) {
      label = omitLable(label, maxLength) as string;
    }
    if ((label as string).length > maxLineLength) {
      const labels = splitByLength(label as string, maxLineLength);

      return (chart.data.labels[index] = labels);
      //return string array that are divided by maxLineLength
    }

    chart.data.labels[index] = label;
  });
};

const dynamicHeight = (
  chart: Chart<'bar', (number | ScatterDataPoint | BubbleDataPoint)[], unknown>,
  _: Record<string, never>,
  options: any
) => {
  if (!chart.canvas.parentNode) return;
  const topSpace = 17.4;
  const bottomSpace = 17.4;
  const space = options.plugins?.dynamicHeight?.space ?? 50;
  const elementCount = chart.data.labels?.length;
  const originalHeight = String(chart.height) + 'px';
  const height =
    elementCount && Number(elementCount * space) + Number(topSpace + bottomSpace) + 'px';
  (chart.canvas.parentNode as HTMLDivElement).style.height = !!height ? height : originalHeight;
};

const handleExternalTooltip = ({
  tooltipRef,
  context,
  tooltipContent,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
  context: ChartContext;
  tooltipContent: string;
}): void => {
  const externalTooltip = tooltipRef?.current;
  const { chart, tooltip } = context;
  if (!externalTooltip || !tooltip || !chart) return;
  if (tooltip.opacity === 0) {
    externalTooltip.classList.contains('show') && externalTooltip.classList.remove('show');
    return;
  }
  //calculate position of tooltip
  const position = chart.canvas.getBoundingClientRect();
  const left = position.left + tooltip.caretX;
  const top = position.top + tooltip.caretY;
  //add style and content to externalTooltip;
  externalTooltip.classList.add('show');
  externalTooltip.style.top = `${top}px`;
  externalTooltip.style.left = `${left}px`;
  externalTooltip.innerHTML = tooltipContent;
};

export { formatLabels, dynamicHeight, handleExternalTooltip };
