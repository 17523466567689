import React, { FC, useState } from 'react';
import styled from '@emotion/styled';

import ResultTabPanel from './ResultTabPanel';
import { AnsweredQuestion } from 'types/App';
import { bp, palette } from 'entity/createTheme';
import { HearingItem } from 'types/HearingSet';

type Props = {
  hearingSets: HearingItem[];
  hearingResults: AnsweredQuestion[];
  handleChangeNote: (answeredQuestionId: number, note: string, hearingId: number) => void;
  updateUserAnswerNote: (hearingId: number) => void;
};

const ResultTab: FC<Props> = ({
  hearingSets,
  hearingResults,
  handleChangeNote,
  updateUserAnswerNote,
}) => {
  const [activeHearingIndex, setActiveHearingIndex] = useState(0);
  const activeHearingSet = hearingSets[activeHearingIndex];
  const activeHearingSetAnswers = hearingResults.filter(
    result => result.hearing_set_id === activeHearingSet.id
  );

  return (
    <ResultContentWrapper>
      <TabWrapper>
        {hearingSets.map((hearingSet, index) => {
          const isActive = index === activeHearingIndex;
          return (
            <TabItem key={index} onClick={() => setActiveHearingIndex(index)} isActive={isActive}>
              <span>{hearingSet.title}</span>
            </TabItem>
          );
        })}
      </TabWrapper>
      <ResultContent>
        <ResultTabPanel
          hearingSet={activeHearingSet}
          hearingResult={activeHearingSetAnswers}
          handleChangeNote={handleChangeNote}
          updateUserAnswerNote={updateUserAnswerNote}
        />
      </ResultContent>
    </ResultContentWrapper>
  );
};

export default ResultTab;

const TabWrapper = styled('div')({
  display: 'flex',
  background: 'inherit',
  marginBottom: 10,
  borderBottom: `1px solid ${palette.grayPrimary}`,
  overflowX: 'auto',

  '&::-webkit-scrollbar-track': {
    backgroundColor: palette.whitePrimary,
  },

  '&::-webkit-scrollbar': {
    height: 6,
    backgroundColor: palette.grayMedium1,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.grayMedium1,
    border: 'none',
  },
});

const ResultContentWrapper = styled('div')({});

const TabItem = styled('div')<{ isActive: boolean }>(props => ({
  padding: '10px 20px 10px 0px',
  fontSize: 16,
  lineHeight: '19px',
  maxWidth: 300,
  minWidth: 150,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box', //might be delete?
  marginRight: 20,
  userSelect: 'none',
  flexShrink: 0,
  cursor: 'pointer',
  borderBottom: props.isActive ? `2ps solid ${palette.darkMedium2}` : 'none',
  span: {
    color: palette.grayMedium4,
  },
  ...(props.isActive && {
    borderBottom: `2px solid ${palette.darkMedium2}`,
    span: {
      color: palette.darkMedium2,
      fontWeight: 'bold',
    },
  }),
}));

const ResultContent = styled('div')({
  padding: 20,
  borderRadius: 6,
  background: palette.whitePrimary,
  '& > div': {
    marginBottom: 30,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  [`@media ${bp.md}`]: {
    padding: '20px 16px',
  },
});
