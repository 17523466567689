import HttpClient from 'adapter/HTTPClient';
import { Client } from 'types/Client';

type ClientListResponse = {
  clients: Client[];
  total: number;
};
class ClientApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getClients = async ({
    key,
    page,
    organization_id,
  }: {
    page?: number;
    key?: string;
    organization_id: string;
  }) => {
    const res = this.httpClient.request<ClientListResponse>({
      method: 'GET',
      url: 'clients',
      params: {
        key,
        page,
        per_page: 100,
        organization_id,
      },
    });

    return res;
  };
}

export default ClientApi;
