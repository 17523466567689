import { Content, ContentViewType } from 'entity/Content';
import { HearingItem, Question } from 'types/HearingSet';

type IHearingForPreview = {
  id: number;
  title: string;
  questions: QuestionForPreview[];
};

export type HearingForPreviewViewType = {
  id: number;
  title: string;
  questions: QuestionForPreview[];
};

// Might be better to create an entity for this too
export type QuestionForPreview = {
  content: ContentViewType;
} & Question;

export class HearingForPreview {
  private id: number;
  private title: string;
  private questions: QuestionForPreview[];

  constructor({ id, title, questions }: IHearingForPreview) {
    this.id = id;
    this.title = title;
    this.questions = questions;
  }

  toJSON(): HearingForPreviewViewType {
    return {
      id: this.id,
      title: this.title,
      questions: this.questions,
    };
  }

  static fromJSON(json: IHearingForPreview) {
    return new HearingForPreview(json);
  }

  static fromDataFileResponseJSON(json: HearingItem) {
    const questions: QuestionForPreview[] = [];

    if (json.question_sets.length) {
      json.question_sets.forEach(questionSet =>
        questionSet.questions.forEach(question =>
          questions.push({
            content: Content.fromJSON(questionSet.content).toJSON(),
            ...question,
          })
        )
      );
    }

    return this.fromJSON({
      ...json,
      questions,
    });
  }
}
