import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import { HearingItem } from 'types/HearingSet';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import {
  formatTime,
  getImgFromContent,
  getTotalQuestions,
  getVideosFromHearing,
} from 'utility/helpers';
import { useTranslation } from 'react-i18next';
import { Button, NoImageIcon } from 'views/components/primitive';
import useStore from 'hooks/useStore';

type Props = {
  hearing: HearingItem;
  startHearing: (id: number, isRecording: boolean) => void;
  disableStartHearing?: boolean;
};

const HearingItemForSales: FC<Props> = ({ hearing, startHearing, disableStartHearing }) => {
  const {
    hearingPlayStore: { isRecording },
  } = useStore();
  const { t } = useTranslation();
  const videos = getVideosFromHearing(hearing);

  const thumbnail = getImgFromContent(hearing.question_sets[0]?.content);
  const totalQuestions = useMemo(() => getTotalQuestions(hearing), [hearing]);

  return (
    <Root>
      {videos.length > 0 && (
        <BulkDownloader videos={videos} top={'0px'} right={'0px'} whiteBackground={true} />
      )}
      <HearingInfo>
        <Thumbnail>
          {thumbnail ? (
            <img src={thumbnail} alt="hearing_avatar" loading="lazy" />
          ) : (
            <NoImageIcon size="100%" svgSize={28} />
          )}
        </Thumbnail>

        <div>
          <Title data-autify_selector="hearing-info_counts_title">
            {hearing.title || t('admin.hearingSet.noTitle')}
          </Title>
          <p className="hearing-info__counts">
            {t('hearingSet.hearingItem.numOfVideo', { count: totalQuestions })}
          </p>
          <UpdatedDate>
            {t('common.lastUpdated')}: {formatTime(hearing.updated_at || '', 'yyyy/MM/dd')}
          </UpdatedDate>
        </div>
      </HearingInfo>
      <ActionArea>
        <Button
          onClick={() => startHearing(hearing.id, isRecording)}
          disabled={disableStartHearing}
          size="sm"
          type="primary"
          label={disableStartHearing ? t('hearingPlay.alreadyAnswered') : t('common.startHearing')}
        />
      </ActionArea>
    </Root>
  );
};

export default HearingItemForSales;

const Root = styled('div')({
  width: 'calc(50% - 10px)',
  padding: 20,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 6,
  background: palette.whitePrimary,
  border: `1px solid ${palette.whitePrimary}`,
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,

  '&:nth-of-type(odd)': {
    marginRight: 20,
  },

  [`@media ${bp.md}`]: {
    padding: 16,
    marginBottom: 16,
    width: 'calc(50% - 8px)',

    ':nth-of-type(odd)': {
      marginRight: 16,
    },
  },

  [`@media ${bp.xs}`]: {
    width: '100%',
    ':nth-of-type(odd)': {
      marginRight: 0,
    },
  },
});

const HearingInfo = styled('div')({
  display: 'flex',
  flex: 1,
  marginBottom: 10,
  p: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    marginBottom: 10,
    [`@media ${bp.md}`]: {
      marginBottom: 8,
      fontSize: '16px',
    },
  },
  [`@media ${bp.md}`]: {
    marginBottom: 10,
    fontSize: '16px',
  },
});

const Thumbnail = styled('div')({
  height: 88,
  minWidth: 88,
  background: palette.blackPrimary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10,
  borderRadius: 6,
  overflow: 'hidden',

  img: {
    height: '100%',
    width: 88,
    objectFit: 'contain',
    [`@media ${bp.md}`]: {
      width: 64,
    },
  },

  [`@media ${bp.md}`]: {
    height: 64,
    minWidth: 64,
  },
});

const Title = styled('p')({
  fontWeight: 'bold',
  marginRight: '30px',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const UpdatedDate = styled('p')({
  color: palette.deepBlueLight,
  fontSize: '0.875rem !important',
});

const ActionArea = styled('div')({
  display: 'flex',
  marginTop: 10,
  justifyContent: 'flex-end',
  alignItems: 'flex-end',

  [`@media ${bp.md}`]: {
    marginTop: 0,
  },
});
