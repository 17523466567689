import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

//Override ParseQs type from qs.parse (unused types ParseQs and ParseQs[] are excluded)
interface DefaultParsedQs {
  [key: string]: string | string[] | undefined;
}
interface CustomParsedQs<T> {
  [key: string]: T | undefined;
}

type locationSearchType<T> = T extends string ? CustomParsedQs<T> : DefaultParsedQs;

function useLocationSearch<T>() {
  const location = useLocation();
  const locationSearch = React.useMemo(() => qs.parse(location.search.slice(1)), [location.search]);
  return locationSearch as locationSearchType<T>;
}

export default useLocationSearch;
