import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import LinkInfo from './LinkInfo';
import {
  ConfirmModal,
  Drawer,
  LinkContentSelect,
  SelectHearingSetDrawer,
  SelectFileDrawer,
  SelectPlaylistDrawer,
} from 'views/components/compound';
import styled from '@emotion/styled';
import { useCreateHearingLinkWithContentSelector } from 'organisms/ShareableLink/useCreateHearingLink';
import { observer } from 'mobx-react';

type Props = {
  isAddLinkModalOpen: boolean;
  toggleAddLinkDrawer: () => void;
};

const DrawerWithContentSelector: FC<Props> = ({ isAddLinkModalOpen, toggleAddLinkDrawer }) => {
  const { t } = useTranslation();

  const {
    hearingLinkStore: {
      getFileList,
      getPlaylistList,
      getHearingSetList,
      questionnaireList,
      handleSearchHearing,
      loadingQuestionnaireList,
      loadingFileList,
      loadingPlaylistList,
      fileList,
      playlistList,
      clientList,
      defaultLoadingContentNumber,
    },
    appStore: { sfaIntegrated },
  } = useStore();

  const {
    currentOrganization,
    disableCreateLink,
    toggleCancelModal,
    isCancelModalOpen,
    handleCancelCreateLink,
    openAccountForm,
    onChangeAccount,
    toggleAccountForm,
    hearingLink,
    toggleSelectFileDrawer,
    toggleSelectPlaylistDrawer,
    toggleSelectQuestionnaireDrawer,
    isFileDrawerOpen,
    isPlaylistDrawerOpen,
    isQuestionnaireDrawerOpen,
    handleSelectFile,
    handleSelectPlaylist,
    handleSelectQuestionnaire,
    selectedFileId,
    selectedPlaylistId,
    selectedQuestionnaireId,
    clearSelectState,
    handleChangeHearingLink,
    handleCreateHearingLink,
    handleChangeLinkType,
  } = useCreateHearingLinkWithContentSelector();

  const cancelCreateLink = () => {
    handleCancelCreateLink();
    toggleAddLinkDrawer();
  };

  if (!currentOrganization) return null;

  const playlist = hearingLink.content_playlist_id
    ? playlistList.filter(playlist => playlist.id === hearingLink.content_playlist_id)[0]
    : undefined;
  const productContent = hearingLink.product_content_id
    ? fileList.filter(productContent => productContent.id === hearingLink.product_content_id)[0]
    : undefined;
  const questionnaire = hearingLink.hearing_set_id
    ? questionnaireList.filter(hearing => hearing.id === hearingLink.hearing_set_id)[0]
    : undefined;

  return (
    <>
      <Drawer
        disabledAction={disableCreateLink}
        isOpen={isAddLinkModalOpen}
        onClose={toggleCancelModal}
        onSave={handleCreateHearingLink}
        saveButtonLabel={t('common.createNew')}
        isUpperDrawerOpen={isFileDrawerOpen || isQuestionnaireDrawerOpen || isPlaylistDrawerOpen}
      >
        <DrawerInnerWrapper>
          <Title>{t('sharingLink.addTitleAndContent')}</Title>
          <LinkInfo
            hearingLinkDetail={hearingLink}
            clientList={clientList}
            sfaIntegrated={sfaIntegrated}
            onChangeAccount={onChangeAccount}
            handleChangeDetail={handleChangeHearingLink}
            openAccountForm={openAccountForm}
            toggleAccountForm={toggleAccountForm}
            disableEdit={false}
          />
          <LinkContentSelect
            linkContentType={hearingLink.content_type}
            productContent={productContent}
            toggleProductContentModal={toggleSelectFileDrawer}
            handleChangeLinkType={handleChangeLinkType}
            clearState={clearSelectState}
            toggleQuestionnaireModal={toggleSelectQuestionnaireDrawer}
            questionnaire={questionnaire}
            playlist={playlist}
            togglePlaylistModal={toggleSelectPlaylistDrawer}
          />
        </DrawerInnerWrapper>

        <ConfirmModal
          isModal={isCancelModalOpen}
          toggleModal={toggleCancelModal}
          handleConfirmModal={cancelCreateLink}
        />
      </Drawer>
      <SelectHearingSetDrawer
        open={isQuestionnaireDrawerOpen}
        toggleOpen={toggleSelectQuestionnaireDrawer}
        questionnaireList={questionnaireList}
        handleSearchQuestionnaire={handleSearchHearing}
        loading={loadingQuestionnaireList}
        isParentComponentOpen={isAddLinkModalOpen}
        currentQuestionnaireId={hearingLink.hearing_set_id || 0}
        selectedQuestionnaireId={selectedQuestionnaireId}
        handleSelectQuestionnaire={handleSelectQuestionnaire}
        handleSaveQuestionnareId={(id: number) => handleChangeHearingLink({ hearing_set_id: id })}
        getHearingSetList={getHearingSetList}
      />
      <SelectFileDrawer
        productContentList={fileList}
        open={isFileDrawerOpen}
        toggleOpen={toggleSelectFileDrawer}
        getProductContentList={getFileList}
        tags={currentOrganization?.tags || []}
        isParentComponentOpen={isAddLinkModalOpen}
        currentFileId={hearingLink.product_content_id || 0}
        selectedFileId={selectedFileId}
        handleSaveFileId={(id: number) => handleChangeHearingLink({ product_content_id: id })}
        handleSelectFile={handleSelectFile}
        loading={loadingFileList}
        defaultLoadingContentNumber={defaultLoadingContentNumber}
      />
      <SelectPlaylistDrawer
        open={isPlaylistDrawerOpen}
        toggleOpen={toggleSelectPlaylistDrawer}
        playlistList={playlistList}
        isParentComponentOpen={isAddLinkModalOpen}
        currentPlaylistId={hearingLink.content_playlist_id || 0}
        selectedPlaylistId={selectedPlaylistId}
        handleSelectPlaylist={handleSelectPlaylist}
        handleSavePlaylistId={(id: number) => handleChangeHearingLink({ content_playlist_id: id })}
        getPlaylistList={getPlaylistList}
        loading={loadingPlaylistList}
      />
    </>
  );
};

export default observer(DrawerWithContentSelector);

const DrawerInnerWrapper = styled('div')({
  position: 'relative',
});

const Title = styled('h2')({
  marginBottom: 24,
});
