import { EmbeddingLinkDetail, EmbeddingVideoMeta } from 'types/EmbeddingLink';
import { ListMeta } from 'types/common';

export const defaultEmbeddingLink: EmbeddingLinkDetail = {
  id: 1,
  user_id: 1,
  organization_id: 1,
  created_at: '',
  updated_at: '',
  title: '',
  content_type: 'embedding_video',
  thumbnail_url: '',
  has_contents: true,
  description: '',
  feedjs_url: '',
  creative_search_id: undefined,
  embedding_video: {
    id: 1,
    show_playback_control: false,
    show_volume_control: false,
    show_center_playback_button: false,
    fullscreen: false,
    end_screen_type: 'loop',
    content: null,
    muted: true,
    autoplay: true,
    service_resource_id_for_logger: '',
  },
  embedding_interactive_hearing_set: null,
  service_resource_id_for_logger: '',
};

export const defaultListMeta: ListMeta = {
  total: undefined,
  page: 1,
  sort: 'desc',
  order: 'updated_at',
};

export const defaultEmbeddingVideoMeta: EmbeddingVideoMeta = {
  creativeId: undefined,
};
