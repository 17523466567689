import { TabsProps } from '@material-ui/core/Tabs';
import React from 'react';
import { StyledTabs } from '../TabsWrapperSimple';

type Props = {
  handleChange: (index: number) => void;
} & TabsProps;

//Seperate Tabs and Tab, tabIndex and onChange function are passed from parent component, used when you want to control tab state with external states like pagination, query string, sorting, etc. If there's no such need, use TabsWrapperSimple instead.
const Tabs: React.FC<Props> = ({ handleChange, ...rest }) => {
  const onChange = (_: unknown, nextIndex: number) => {
    handleChange(nextIndex);
  };

  return <StyledTabs {...rest} onChange={onChange} />;
};

export default Tabs;
