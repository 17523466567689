import i18n from 'i18n';

export const getChangeStatusAction = (handleChangeStatus: () => void, status?: string) => {
  const changeStatusLabel =
    status === 'published' ? 'admin.common.changeToDraft' : 'admin.common.changeToPublic';

  const changeStatusAction = {
    label: i18n.t(changeStatusLabel),
    handleClick: () => handleChangeStatus(),
  };

  return changeStatusAction;
};
