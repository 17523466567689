import React, { FC, useState, MouseEvent } from 'react';
import styled from '@emotion/styled';
import _MoreVertIcon from '@material-ui/icons/MoreHoriz';
import {
  DropdownMenu as _DropdownMenu,
  DropdownItem as _DropdownItem,
  DropdownWrapper as _DropdownWrapper,
} from 'views/components/primitive';
import { palette } from 'entity/createTheme';

export type MenuItem = {
  label: string;
  handleClick: () => void;
};

type Props = {
  menuItems: MenuItem[];
};

const MoreActionsMenu: FC<Props> = ({ menuItems }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleOpen = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpen(!isOpen);
  };

  if (menuItems.length === 0) return null;

  return (
    <DropdownWrapper toggleOpenDropdown={toggleOpen} isOpen={isOpen} size="sm">
      <MoreVertIcon />
      <DropdownMenu isOpen={isOpen}>
        {menuItems.map((item, index) => (
          <DropdownItem key={index} handleClickItem={item.handleClick}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default MoreActionsMenu;

const DropdownWrapper = styled(_DropdownWrapper)({
  padding: 0,
  marginLeft: 6,
  background: 'transparent',
  border: 'none',
  '&:hover': {
    color: palette.deepBlueLight,
    svg: {
      fill: palette.deepBlueLight,
    },
  },
});

const DropdownMenu = styled(_DropdownMenu)({
  width: 'unset',
  borderTopRightRadius: '6px',
  borderTopLeftRadius: '6px',
  borderTop: `1px solid ${palette.borderInput}`,
  top: 'calc(100% + 5px)',
  boxShadow: '0 1px 10px rgb(0 0 0 / 10%)',
  right: '0',
  '& div': {
    width: 'unset',
  },
  '& div:first-of-type': {
    display: 'none',
  },
});

const DropdownItem = styled(_DropdownItem)({
  fontWeight: 'bold',
});

const MoreVertIcon = styled(_MoreVertIcon)({
  fill: palette.darkMedium2,
  '&:hover': {
    fill: palette.deepBlueLight,
  },
});
