import React, { FC, memo } from 'react';
import { useStore } from 'hooks';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
interface HeaderItem {
  id: string;
  title?:
    | 'sharingLink.titleAndAccountLabel'
    | 'productContent.createDate'
    | 'common.link'
    | 'sharingLink.createdByLabel'
    | 'sharingLink.switchLink'
    | 'admin.common.archive';
}

const BaseHeader: HeaderItem[] = [
  {
    id: 'thumbnail',
  },
  {
    id: 'title_and_account',
    title: 'sharingLink.titleAndAccountLabel',
  },
  {
    id: 'links',
    title: 'common.link',
  },
];

const getHeader = (isAdmin: boolean): HeaderItem[] => {
  const linkListHeader = [...BaseHeader];
  const creator: HeaderItem = { id: 'created_by', title: 'sharingLink.createdByLabel' };

  if (isAdmin) {
    linkListHeader.push(creator);
  }
  return linkListHeader;
};

const HeaderLinkList: FC = () => {
  const { t } = useTranslation();
  const {
    appStore: { isAdmin },
  } = useStore();

  const headerItems = getHeader(isAdmin);

  return (
    <LinkWrapper isAdmin={isAdmin}>
      {headerItems.map(item => {
        const itemTitle = item.title;
        return (
          <ColumnTitle isAdmin={isAdmin} key={item.id}>
            {itemTitle ? t(itemTitle).toUpperCase() : ''}
          </ColumnTitle>
        );
      })}
    </LinkWrapper>
  );
};

export default memo(HeaderLinkList);

const LinkWrapper = styled('div')<{ isAdmin: boolean }>(props => ({
  display: 'grid',
  gridGap: 32,
  gridTemplateColumns: props.isAdmin ? '142px 3fr 3fr 1fr' : '142px 3fr 3fr 1fr',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  paddingRight: 16,
  marginBottom: 10,
  [`@media ${bp.md}`]: {
    gridGap: 8,
    gridTemplateColumns: props.isAdmin ? '142px 2fr 3fr 1fr' : '142px 3fr 2fr',
  },
}));

const ColumnTitle = styled('p')<{ isAdmin: boolean }>(props => ({
  color: palette.deepBlueLight,
  '&:nth-of-type(2)': {
    minWidth: 200,
    [`@media ${bp.md}`]: {
      margin: '0px 16px',
      minWidth: 132,
    },
  },
  '&:nth-of-type(3)': {
    textAlign: props.isAdmin ? 'center' : 'left',
    maxWidth: 320,
  },
  '&:last-of-type': {
    textAlign: 'center',
  },
}));
