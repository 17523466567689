import React, { MouseEvent } from 'react';
import SVG_ from 'react-inlinesvg';
import styled from '@emotion/styled';

type PropTypes = {
  src: string;
  id?: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  isActive?: boolean; // Added for preventing passing `isAcive` to img and svg
  asImg?: boolean; // For old browsers that don't work well with react-inlinesvg
  autifySelectorName?: string; //TODO: jsxの既存のdata-XXで渡せるようにする
  style?: { [key: string]: string | { [key: string]: string } };
  uniquifyIDs?: boolean; //create unique IDs for each icon, for displaying multiple parts svgs
};

const SVGIcon: React.FC<PropTypes> = ({
  src,
  id,
  className,
  isActive,
  asImg = false,
  autifySelectorName,
  uniquifyIDs,
  ...rest
}) => {
  if (asImg)
    return (
      <img
        src={src}
        {...rest}
        className={className}
        alt="icon"
        id={id}
        data-autify_selector={autifySelectorName}
      />
    );

  return (
    <SVG
      src={src}
      {...rest}
      className={className}
      id={id}
      data-autify_selector={autifySelectorName}
      uniquifyIDs={uniquifyIDs}
    />
  );
};

export default SVGIcon;

const SVG = styled(SVG_)<{
  style_?: { [key: string]: string | { [key: string]: string } } | undefined;
}>(props => ({
  ...props.style_,
}));
