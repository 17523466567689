import styled from '@emotion/styled';
import CheckIcon from 'assets/images/check_icon.svg';
import { bp, palette } from 'entity/createTheme';
import { useStore } from 'hooks';
import { observer } from 'mobx-react';
import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { trimSpace } from 'utility/helpers';
import { ContentCard, CTAButtonList } from 'views/components/compound';
import { SVGIcon as _SVGIcon } from 'views/components/primitive';
import { customFormFields } from './constants';
import ViewSettingDrawer from './ViewSettingDrawer';

const ViewSetting: FC = () => {
  const {
    hearingLinkStore: { hearingLinkDetail, saveViewSetting },
  } = useStore();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const { cta, custom_fields, needs_feedback } = useMemo(() => hearingLinkDetail, [
    hearingLinkDetail,
  ]);

  const { company_name, company_pic_name, email, phone_number } = custom_fields;
  const showCustomForm = company_name || company_pic_name || email || phone_number;

  const closeDrawer = () => {
    setOpen(false);
  };

  const displayedCtaList = cta?.filter(cta => !!trimSpace(cta.link_to)) || [];
  //Auto redirect is enabled if ctaList contains only one item AND the only item has auto_redirect = true;
  const autoRedirect = displayedCtaList.length === 1 && !!displayedCtaList[0].auto_redirect;

  return (
    <>
      <ContentCard
        title={t('sharingLink.viewSetting.title')}
        buttonLabel={t('common.edit')}
        onClickButton={openDrawer}
      >
        <SettingSection>
          <LeftColumn>
            <SubSection>
              <SectionTitle>{t('sharingLink.viewSetting.beforeViewing')}</SectionTitle>
              <ul>
                <SettingOption>
                  <SVGIcon disabled={!showCustomForm} src={CheckIcon} />
                  {t('sharingLink.viewSetting.showCustomForm')}
                </SettingOption>
                {showCustomForm &&
                  customFormFields.map(customField => (
                    <SettingOption indent key={customField.fieldName}>
                      <SVGIcon disabled={!custom_fields[customField.fieldName]} src={CheckIcon} />
                      {t(customField.label)}
                    </SettingOption>
                  ))}
              </ul>
            </SubSection>

            {hearingLinkDetail.content_type !== 'playlist' && (
              <SubSection marginTop={24}>
                <SectionTitle>{t('sharingLink.viewSetting.afterViewing')}</SectionTitle>
                <SettingOption>
                  <SVGIcon src={CheckIcon} disabled={!needs_feedback} />
                  {t('sharingLink.viewSetting.showResult')}
                </SettingOption>
                <SettingOption isDisabledOption={displayedCtaList.length !== 1}>
                  <SVGIcon src={CheckIcon} disabled={!autoRedirect} />
                  {t('sharingLink.viewSetting.ctaSetting.autoRedirect')}
                </SettingOption>
              </SubSection>
            )}
          </LeftColumn>

          <RightColumn>
            <SubSection>
              <SectionTitle>{t('sharingLink.viewSetting.ctaSetting.subSectionTitle')}</SectionTitle>
              {displayedCtaList.length > 0 ? (
                <CTAButtonList ctaList={displayedCtaList} />
              ) : (
                <NoCTAButton>{t('sharingLink.viewSetting.ctaSetting.noCTAButton')}</NoCTAButton>
              )}
            </SubSection>
          </RightColumn>
        </SettingSection>
      </ContentCard>
      <ViewSettingDrawer
        open={open}
        closeDrawer={closeDrawer}
        showCustomForm={showCustomForm}
        linkDetail={hearingLinkDetail}
        saveViewSetting={saveViewSetting}
      />
    </>
  );
};

export default observer(ViewSetting);

const SectionTitle = styled('p')({
  fontSize: '0.75rem',
  marginBottom: 16,
  fontWeight: 'bold',
  lineHeight: '160%',
});

const SettingSection = styled('div')({
  display: 'flex',
  width: '100%',
  paddingRight: 0,
  [`@media ${bp.sm}`]: {
    flexDirection: 'column',
  },
});

const LeftColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  [`@media ${bp.sm}`]: {
    width: '100%',
  },
});

const RightColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  [`@media ${bp.sm}`]: {
    width: '100%',
  },
});

const SettingOption = styled('li')<{ indent?: boolean; isDisabledOption?: boolean }>(props => ({
  listStyleType: 'none',
  fontSize: 14,
  svg: {
    marginRight: 16,
  },
  ':not(:last-of-type)': {
    marginBottom: 14,
  },
  marginLeft: props.indent ? 32 : 0,
  color: props.isDisabledOption ? palette.borderInput : palette.darkMedium2,
}));

const SubSection = styled('div')<{ marginTop?: number }>(props => ({
  width: '100%',
  marginTop: props.marginTop,
}));

const SVGIcon = styled(_SVGIcon)<{ disabled?: boolean }>(props => ({
  '& > path': {
    fill: props.disabled ? palette.borderInput : palette.darkMedium2,
  },
}));

const NoCTAButton = styled('div')({
  width: '100%',
  fontSize: '0.875rem',
});
