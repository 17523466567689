declare global {
  interface Window {
    kzs?: any;
  }
}

const DURATION_THRESHOLD = 1.0; // [sec]
const CURRENT_TIME_THRESHOLD = 2.0; // [sec]
const LOG_QUEUE_MAX_LENGTH = 10;

// src/views/components/compound/VideoPlayer/index.tsx の class 名
const VIDEO_PLAYER_CLASS_NAME = 'video-player';
const VIDEO_PLAYER_EVENT_NAME = 'video-event';

type VideoType = 'vimeo' | 'shaka';
type EventType = 'init' | 'bufferstart' | 'bufferend' | 'ready' | 'begin' | 'end' | 'progress';

interface CustomLog {
  eventType: EventType;
  videoType: VideoType;
  videoId: string;
  vimeoId?: string;
  videoTime: number;
  videoDuration: number;
  videoSession: string;
  device?: string;
  browser?: string;
  [extras: string]: any;
}

export class ProgressTracker {
  readonly videoType: VideoType;
  readonly videoId: string;
  readonly vimeoId?: string;
  enabled: boolean;
  device: string;
  browser: string;
  videoTag?: HTMLVideoElement;
  videoPlayerTag?: HTMLElement;
  playing: boolean;
  sessionId: string;
  lastProgress: { duration: number; currentTime: number };
  readonly queue: CustomLog[];

  constructor(videoType: VideoType, videoId: string, vimeoId: string | undefined = undefined) {
    this.videoType = videoType;
    this.videoId = videoId;
    this.vimeoId = vimeoId;
    this.enabled = false;
    this.device = '';
    this.browser = '';
    this.checkEnabled();
    this.videoTag = undefined;
    this.videoPlayerTag = undefined;
    this.playing = false;
    this.sessionId = '';
    this.lastProgress = { duration: 0, currentTime: 0 };
    this.queue = [];
  }

  checkEnabled() {
    if (!this.enabled) {
      if (ProgressTracker.isSitejsAvailable()) {
        this.enabled = true;
        const browser = window.kzs.navigator.getBrowser();
        this.device = window.kzs.navigator.getDevice();
        this.browser = `${browser.name} ${browser.majorVersion}`;
      }
    }
    return this.enabled;
  }

  track(eventName: string, data: { duration: number; currentTime: number }, extra: any = {}) {
    const { duration, currentTime } = data;

    if (
      eventName === 'init' ||
      eventName === 'ready' ||
      eventName === 'bufferstart' ||
      eventName === 'bufferend'
    ) {
      this.sendLog(eventName, data, extra);
    } else if (eventName === 'ended') {
      if (this.playing) {
        this.playing = false;
        this.sendLog('end', data, extra);
      }
    } else if (!this.playing) {
      if (eventName !== 'play') return;
      this.playing = true;
      this.updateSessionId();
      this.lastProgress = { duration, currentTime };
      this.sendLog('begin', data, extra);
    } else {
      if (!(data.duration >= 0 && data.currentTime >= 0)) return;
      if (
        Math.abs(this.lastProgress.duration - duration) >= DURATION_THRESHOLD ||
        Math.abs(this.lastProgress.currentTime - currentTime) >= CURRENT_TIME_THRESHOLD
      ) {
        this.lastProgress = { duration, currentTime };
        this.sendLog('progress', data, extra);
      }
    }
  }

  sendLog(eventType: EventType, data: { duration: number; currentTime: number }, extra: any = {}) {
    const log: CustomLog = {
      eventType: eventType,
      videoType: this.videoType,
      videoId: this.videoId,
      videoTime: Math.round(data.currentTime * 1000.0) / 1000.0,
      videoDuration: Math.round(data.duration * 1000.0) / 1000.0,
      videoSession: this.sessionId,
      extra: extra,
      // possibly sitejs is not yet available at this point in time, so set following attributes later
      //device: this.device,
      //browser: this.browser,
    };
    if (this.vimeoId) log.vimeoId = this.vimeoId;

    while (this.queue.length >= LOG_QUEUE_MAX_LENGTH) {
      this.queue.shift();
    }
    this.queue.push(log);
    if (this.checkEnabled()) {
      while (this.queue.length > 0) {
        const logToSend = this.queue.shift();
        if (logToSend) {
          logToSend.device = this.device;
          logToSend.browser = this.browser;
          ProgressTracker.sendLogBySitejs(logToSend);
        }
      }
    }

    if (this.videoPlayerTag) {
      const event = new CustomEvent(VIDEO_PLAYER_EVENT_NAME, { detail: log });
      this.videoPlayerTag.dispatchEvent(event);
    }
  }

  updateSessionId() {
    this.sessionId = 'xxxxxxxxxxxx'.replace(/x/g, () =>
      Math.floor(Math.random() * 16).toString(16)
    );
  }

  start(videoTag: HTMLVideoElement) {
    this.videoTag = videoTag;
    if (this.videoTag) {
      const videoTag: HTMLVideoElement = this.videoTag;
      [
        'play',
        'ended',
        'timeupdate',
        'seeking',
        'seeked',
        'durationchange',
        'loadeddata',
        'loadedmetadata',
      ].forEach(type => {
        videoTag.addEventListener(type, ev => {
          const duration = videoTag.duration || 0;
          const currentTime = videoTag.currentTime || 0;
          this.track(ev.type, { duration, currentTime });
        });
      });
      this.videoPlayerTag = ProgressTracker.findVideoPlayerTag(this.videoTag);
    }
  }

  static isSitejsAvailable(): boolean {
    try {
      if (!window.kzs) return false;
      const version = window.kzs.current.sitejsVersion;
      return version === 2 || version === 3;
    } catch (err) {
      console.info(err);
      return false;
    }
  }

  static sendLogBySitejs(log: CustomLog) {
    try {
      window.kzs.current.context.tracker.insightTracker()._track({ type: 'custom', custom: log });
      const debug = [];
      for (const key of [
        'videoType',
        'videoId',
        'videoSession',
        'eventType',
        'videoTime',
        'videoDuration',
      ]) {
        debug.push(log[key]);
      }
      window.kzs.console.warn(`send video log: ${debug.join(', ')}`);
    } catch (err) {
      console.error(err);
    }
  }

  static findVideoPlayerTag(element: HTMLElement): HTMLElement | undefined {
    if (element) {
      if (element.classList?.contains(VIDEO_PLAYER_CLASS_NAME)) {
        return element;
      } else if (element.parentElement && element.parentElement !== element) {
        return this.findVideoPlayerTag(element.parentElement);
      }
    }
    return undefined;
  }
}
