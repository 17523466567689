import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaylistDetail } from 'types/Playlist';
import ContentContainer from './ContentContainer';
import ContentItem from './ContentItem';

type Props = {
  playlist?: PlaylistDetail;
  hasNoPublicPlaylist: boolean;
};

const PlaylistItem: FC<Props> = ({ playlist, hasNoPublicPlaylist }) => {
  const { t } = useTranslation();

  return playlist ? (
    <ContentContainer
      title={t('admin.content.tabName.playlists')}
      hasDraftOrArchived={hasNoPublicPlaylist}
    >
      <ContentWrapper>
        <ContentItem
          key={playlist.id}
          isUnpublic={hasNoPublicPlaylist}
          src={playlist.thumbnail_url}
          title={playlist.title}
          metaTitle={t('admin.playlist.contentCount', {
            count: playlist.product_contents_count,
          })}
        />
      </ContentWrapper>
    </ContentContainer>
  ) : null;
};

export default PlaylistItem;

const ContentWrapper = styled('div')({ marginTop: 15 });
