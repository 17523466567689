import * as React from 'react';
import { palette } from 'entity/createTheme';
import HorizontalChart from 'views/components/compound/HorizontalChart';
import { AggregatedAnswer } from 'types/App';
import { ChartData } from 'chart.js';
import NoImage from 'assets/images/no-thumbnail.jpg';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

type Props = {
  aggregatedAnswers: AggregatedAnswer[];
  totalAnswerCounts: number;
};

const CampaignLinkChartSection: React.VFC<Props> = ({ aggregatedAnswers, totalAnswerCounts }) => {
  return (
    <Root>
      {aggregatedAnswers.map((aggregatedAnswer, index) => (
        <HorizontalChartItem
          aggregatedAnswer={aggregatedAnswer}
          totalAnswerCounts={totalAnswerCounts}
          index={index}
          key={index}
        />
      ))}
    </Root>
  );
};

const HorizontalChartItem: React.VFC<{
  aggregatedAnswer: AggregatedAnswer;
  totalAnswerCounts: number;
  index: number;
}> = ({ aggregatedAnswer, index, totalAnswerCounts }) => {
  const { t } = useTranslation();

  const data: ChartData<'bar', number[], string> = {
    datasets: [
      {
        data: aggregatedAnswer.answered_choices_group_by_content.map(
          content => content.chosen_count
        ),
        label: `${t('embeddingLink.reportTab.answerCount')}`,
        barThickness: 15,
        backgroundColor: palette.chartPrimary,
      },
    ],
    labels: aggregatedAnswer.answered_choices_group_by_content.map(choices => {
      if (choices.other_choice) {
        return t('embeddingLink.reportTab.other');
      }
      return choices.content ?? '';
    }),
  };

  return (
    <ChartLayout>
      <TwoColumnsGridLayout>
        <Thumbnail
          src={aggregatedAnswer.thumbnail_url ?? NoImage}
          alt="thumbnail"
          width="100%"
          height="100%"
        />
        <TitleWithIndex index={index + 1}>{aggregatedAnswer.question_content}</TitleWithIndex>
      </TwoColumnsGridLayout>
      <HorizontalChartLayout>
        <HorizontalChart data={data} key={index} xScaleMax={totalAnswerCounts ?? undefined} />
      </HorizontalChartLayout>
    </ChartLayout>
  );
};

export default CampaignLinkChartSection;

const Root = styled.div`
  & > *:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

const TwoColumnsGridLayout = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: 'center';
  gap: 20px;
`;

const TitleWithIndex = styled('p')<{ index: number }>`
  margin-left: 1rem;
  &:before {
    content: '${props => props.index}. ';
    margin-left: -1rem;
  }
`;

const ChartLayout = styled.div`
  background: ${palette.whitePrimary};
  border-radius: 6px;
  padding: 20px;
  & > *:nth-of-type(1) {
    margin-bottom: 8px;
  }
`;

const Thumbnail = styled.img`
  height: 100px;
  object-fit: cover;
`;

const HorizontalChartLayout = styled.div`
  overflow-x: scroll;
  & > *:nth-of-type(1) {
    min-width: 500px;
  }
`;
