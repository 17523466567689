import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import { Button } from 'views/components/primitive';
import SVGIcon from 'views/components/primitive/SVGIcon';
import LogoutIcon from 'assets/images/ic_logout.svg';

type Props = {
  toggleDrawer: () => void;
};

const AdminActionArea: FC<Props> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        type="default"
        onClick={() => window.open(t('admin.productContent.orderVideoHref'))}
        size="sm"
      >
        <SVGIcon src={LogoutIcon} />
        <Hidden smDown>{t('admin.productContent.orderVideo')}</Hidden>
      </Button>
      <Button
        type="primary"
        label={t('admin.productContent.upload')}
        size="sm"
        onClick={toggleDrawer}
      />
    </>
  );
};

export default AdminActionArea;
