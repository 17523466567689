import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaylistItem } from 'types/Playlist';
import PlaylistPlayRoundedIcon from '@material-ui/icons/PlaylistPlayRounded';
import ThumbnailIcon from 'views/components/compound/ThumbnailIcon';
import { formatTime } from 'utility/helpers';
import { palette } from 'entity/createTheme';

type Props = {
  playlistItem: PlaylistItem;
  className?: string;
  width?: number;
  aspectRatio?: number;
  showContentCount?: boolean;
};

const PlaylistItemForDisplay: FC<Props> = ({
  playlistItem,
  className,
  width = 48,
  aspectRatio = 1,
  showContentCount,
}) => {
  const { t } = useTranslation();
  const { thumbnail_url: thumbnail, title, updated_at, product_contents_count } = playlistItem;
  const [thumbnailWidth, thumbnailHeight] = [width, width / aspectRatio];

  return (
    <Wrapper className={className}>
      <ThumbnailWrapper width={thumbnailWidth} height={thumbnailHeight}>
        <ThumbnailIcon src={thumbnail} size={width} aspectRatio={aspectRatio} />
        {showContentCount && (
          <ContentCount>
            <ContentNumber>{product_contents_count}</ContentNumber>
            <PlaylistPlayRoundedIcon />
          </ContentCount>
        )}
      </ThumbnailWrapper>
      <PlaylistInfo>
        <Title>{title}</Title>
        <UpdatedDate>
          {t('common.lastUpdated')}: {formatTime(updated_at || '', 'yyyy/MM/dd')}
        </UpdatedDate>
      </PlaylistInfo>
    </Wrapper>
  );
};

export default PlaylistItemForDisplay;

const Wrapper = styled('div')({
  display: 'flex',
  borderRadius: 6,
  cursor: 'pointer',
});

const ThumbnailWrapper = styled('div')<{ width?: number; height?: number }>(props => ({
  position: 'relative',
  borderRadius: 6,
  overflow: 'hidden',
  ...(props.width && {
    minWidth: props.width,
    width: props.width,
  }),
  ...(props.height && {
    height: props.height,
  }),
}));

const ContentCount = styled('div')({
  position: 'absolute',
  right: 0,
  top: 0,
  height: '100%',
  width: '40%',
  background: palette.blackPrimary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.6,
  '& > svg > path': {
    fill: palette.whitePrimary,
  },
});

const ContentNumber = styled('p')({ color: palette.whitePrimary });

const PlaylistInfo = styled('div')({
  marginLeft: 12,
});

const Title = styled('p')({
  fontWeight: 'bold',
  fontSize: '0.875rem',
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
});

const UpdatedDate = styled('p')({
  color: palette.deepBlueLight,
  fontSize: '0.875rem',
  marginTop: 8,
});
