import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';
import { HearingItem } from 'types/HearingSet';

class HearingSetApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getHearingSets = async (
    organization_id: string,
    params?: Partial<ListRequestParamsMeta>
  ) => {
    const res = await this.httpClient.request<{
      hearing_sets: HearingItem[];
      total: number;
      organization_id: string;
    }>({
      method: 'GET',
      url: '/hearing_sets',
      params: {
        ...params,
        organization_id,
      },
    });

    return res;
  };

  public getHearingSetById = async (
    hearingId: number,
    organization_id: string
  ): Promise<{ data: HearingItem }> => {
    const res = await this.httpClient.request<HearingItem>({
      method: 'GET',
      url: `/hearing_sets/${hearingId}`,
      params: {
        organization_id,
      },
    });

    return res;
  };
}

export default HearingSetApi;
