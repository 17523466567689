import React, { FC } from 'react';
import styled from '@emotion/styled';
import { customFormFields } from '../SharableLinkViewSetting/constants';
import { SharingLinkForm } from 'types/HearingLink';
import { useTranslation } from 'react-i18next';

type Props = {
  sharingLinkForm: SharingLinkForm;
};

const ClientInfo: FC<Props> = ({ sharingLinkForm }) => {
  const { t } = useTranslation();

  return (
    <ClientInfoWrapper>
      {customFormFields.map(field =>
        sharingLinkForm[field.fieldName] ? (
          <Info key={field.fieldName}>
            <Label>{`${t(field.label)}: `}</Label>
            <Details>{sharingLinkForm[field.fieldName]}</Details>
          </Info>
        ) : undefined
      )}
    </ClientInfoWrapper>
  );
};

export default ClientInfo;

const ClientInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.875rem',
  marginTop: 12,
});

const Info = styled('div')({
  display: 'grid',
  gridTemplateColumns: '124px 1fr',
  marginBottom: 8,
  '&:last-of-type': {
    marginBottom: 24,
  },
});

const Label = styled('p')({});

const Details = styled('p')({
  wordBreak: 'break-word',
});
