import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Chapter as ChapterType } from 'types/App';
import { formatDuration } from 'utility/helpers';

type Props = {
  chapters?: ChapterType[] | null;
  handleSeekVideo: (position: number) => void;
  className?: string;
};

const ChapterListForVideo: FC<Props> = ({ chapters, handleSeekVideo, className }) => {
  const { t } = useTranslation();
  //display duration in format hh:mm:ss only when at least one chapter position >= 1 hour = 60*60*1000 = 3600000 miliseconds
  //assumed that chapters are sorted in ascending order, position of the last chapter in list is the biggest one
  const displayHour =
    (chapters && chapters.length > 0 && chapters.some(chapter => chapter.position >= 3600000)) ||
    false;

  return (
    <ChapterListWrapper className={className}>
      <Title>{t('admin.productContent.chapterSection.title')}</Title>
      {chapters && chapters.length > 0 ? (
        <ChapterList>
          {chapters.map((chapter, index) => (
            <Chapter key={index}>
              <ChapterTime onClick={() => handleSeekVideo(chapter.position)}>
                {formatDuration(chapter.position, displayHour)}
              </ChapterTime>
              <p>{chapter.name}</p>
            </Chapter>
          ))}
        </ChapterList>
      ) : (
        <NoChapter>{t('admin.productContent.chapterSection.noChapter')}</NoChapter>
      )}
    </ChapterListWrapper>
  );
};

export default ChapterListForVideo;

const ChapterListWrapper = styled('div')({
  width: '100%',
});

const Title = styled('h4')({
  margin: '40px 0px 24px',
});

const ChapterList = styled('ul')({});

const Chapter = styled('li')({
  display: 'flex',
  fontSize: '0.875rem',
  lineHeight: 1.6,
});

const NoChapter = styled('p')({
  fontSize: '0.875rem',
});

const ChapterTime = styled('p')({
  marginRight: 20,
  color: palette.linkBlue,
  '&:hover': {
    color: palette.linkBlueLight,
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: palette.linkBlueLight,
  },
});
