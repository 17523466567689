import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'hooks';
import LinkInfo from './LinkInfo';
import { ConfirmModal, Drawer } from 'views/components/compound';
import styled from '@emotion/styled';
import {
  LinkContentData,
  useCreateHearingLink,
} from 'organisms/ShareableLink/useCreateHearingLink';
import { observer } from 'mobx-react';
import { palette } from 'entity/createTheme';

type Props = {
  isAddLinkModalOpen: boolean;
  toggleAddLinkDrawer: () => void;
  linkContentData: LinkContentData;
};

const CONTACT_POINT = 'https://kaizenplatform.com/sales';

const DrawerWithoutContentSelector: FC<Props> = ({
  isAddLinkModalOpen,
  toggleAddLinkDrawer,
  linkContentData,
}) => {
  const { t } = useTranslation();

  const {
    hearingLinkStore: { clientList, submitting },
    appStore: { sfaIntegrated },
  } = useStore();

  const {
    currentOrganization,
    toggleCancelModal,
    isCancelModalOpen,
    handleCancelCreateLink,
    openAccountForm,
    onChangeAccount,
    toggleAccountForm,
    hearingLink,
    handleChangeHearingLink,
    handleCreateHearingLink,
    disableLinkFunctions,
  } = useCreateHearingLink(linkContentData);

  const cancelCreateLink = () => {
    handleCancelCreateLink();
    toggleAddLinkDrawer();
  };

  if (!currentOrganization) return null;

  return (
    <Drawer
      disabledAction={disableLinkFunctions || submitting}
      isOpen={isAddLinkModalOpen}
      onClose={disableLinkFunctions ? toggleAddLinkDrawer : toggleCancelModal}
      onSave={handleCreateHearingLink}
      saveButtonLabel={t('common.createNew')}
    >
      <DrawerInnerWrapper>
        <Title>{t('sharingLink.addLinkDrawerTitle')}</Title>
        {disableLinkFunctions && (
          <DisableCreateGuide>
            {t('sharingLink.disableCreateLink')}
            <span>
              <a href={CONTACT_POINT} target="_blank" rel="noopener noreferrer">
                {t('common.here')}
              </a>
            </span>
          </DisableCreateGuide>
        )}
        <LinkInfo
          hearingLinkDetail={hearingLink}
          clientList={clientList}
          sfaIntegrated={sfaIntegrated}
          onChangeAccount={onChangeAccount}
          handleChangeDetail={handleChangeHearingLink}
          openAccountForm={openAccountForm}
          toggleAccountForm={toggleAccountForm}
          disableEdit={disableLinkFunctions}
        />
      </DrawerInnerWrapper>

      <ConfirmModal
        isModal={isCancelModalOpen}
        toggleModal={toggleCancelModal}
        handleConfirmModal={cancelCreateLink}
      />
    </Drawer>
  );
};

export default observer(DrawerWithoutContentSelector);

const DrawerInnerWrapper = styled('div')({
  position: 'relative',
});

const Title = styled('h2')({
  marginBottom: 24,
});

const DisableCreateGuide = styled('p')({
  background: palette.greenPrimary,
  color: palette.whitePrimary,
  fontSize: '0.875rem',
  padding: 8,
  width: '100%',
  textAlign: 'center',
  marginBottom: 24,
  opacity: 0.65,
  a: {
    color: palette.whitePrimary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
