import React, { FC } from 'react';
import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import { TFunctionResult } from 'i18next';

type PropsType = {
  children: TFunctionResult;
  className?: string;
};

const ScreenTitle: FC<PropsType> = ({ children, className }) => (
  <TruncatedTitle className={className}>{children}</TruncatedTitle>
);

export default ScreenTitle;

const TruncatedTitle = styled('h2')({
  fontSize: '1.5rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [`@media ${bp.md}`]: {
    fontSize: '1.375rem',
  },
  height: 40,
  lineHeight: '40px',
});
