import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { bp, layer, palette } from 'entity/createTheme';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import { useKey, useLockBodyScroll } from 'react-use';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface ModalProps {
  isModal: boolean;
  title: string;
  children: React.ReactNode;
  handleToggle: () => void;
  className?: string;
  onAction: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cancelLabel?: string;
  actionLabel: string;
  disableAction?: boolean;
}

const Modal: FC<ModalProps> = ({
  title,
  children,
  isModal,
  handleToggle,
  className,
  cancelLabel,
  onAction,
  actionLabel,
  disableAction = false,
}) => {
  const { t } = useTranslation();
  useLockBodyScroll(isModal);
  const callback = () => isModal && handleToggle();
  useKey('Escape', callback, undefined, [isModal]);

  return isModal ? (
    <Root className={className}>
      <ContentWrap>
        <Content>
          <Title>{title}</Title>

          {children}
        </Content>

        <ActionBar>
          <ButtonWrapper>
            <Button
              label={cancelLabel || t('common.close')}
              type="default"
              size="md"
              onClick={handleToggle}
              style={{ width: '90px' }}
            />
            <Button
              label={actionLabel}
              type="primary"
              size="md"
              onClick={onAction}
              disabled={disableAction}
            />
          </ButtonWrapper>
        </ActionBar>
      </ContentWrap>
    </Root>
  ) : null;
};

const Root = styled('div')({
  width: '100%',
  height: '100vh',
  position: 'fixed',
  zIndex: layer.modal2,
  top: 0,
  left: 0,
  background: 'rgb(0, 0, 0, 0.6)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${fadeIn} 0.3s linear`,
});

const ContentWrap = styled('div')({
  maxHeight: '90vh',
  width: 640,
  background: palette.whitePrimary,
  borderRadius: 8,
  overflow: 'hidden',
});

const Content = styled('div')({
  padding: 24,
  [`@media ${bp.xs}`]: {
    padding: 16,
  },
});

const Title = styled('h4')({
  marginBottom: 24,
  fontWeight: 800,
});

const ActionBar = styled('div')({
  height: 72,
  width: '100%',
  background: palette.base,
  borderTop: `1px solid ${palette.borderInput}`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 24px',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  '> button': {
    ':last-of-type': {
      marginLeft: 16,
      width: 230,
    },
  },
});

export default function ModalPortal(props: ModalProps) {
  return ReactDOM.createPortal(<Modal {...props} />, document.body);
}
