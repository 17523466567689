import { useEffect, RefObject } from 'react';
import { isTouchDevice } from 'utility/helpers';

const useScrollToTop = <T extends HTMLElement>(ref: RefObject<T>, deps: unknown[] = []) => {
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' }); //scroll to top on PC
    isTouchDevice() && window.scrollTo(0, 0); //scroll to top on iPad (scrollIntoView does not work properly in iPad due to Safari-specific window height)
  }, deps);
};

export default useScrollToTop;
