import React, { FC } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { bp, palette, sizes, layer } from 'entity/createTheme';

type Props = {
  isOpen: boolean;
  className?: string;
  modalRef: React.RefObject<HTMLDivElement>;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const QuestionSetContentModal: FC<Props> = ({ className, children, isOpen, modalRef }) => {
  return (
    <>
      {isOpen && <ModalBackground />}
      <Root className={className} open={isOpen}>
        <ContentWrapper>
          <Content ref={modalRef}>{children}</Content>
        </ContentWrapper>
      </Root>
    </>
  );
};

const ModalBackground = styled('div')({
  position: 'fixed',
  height: '100vh',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: layer.modal2,
  background: 'rgba(0, 0, 0, 0.6)',
  animation: `${fadeIn} 0.35s linear`,
});

const Root = styled('div')<{ open: boolean }>(props => ({
  position: 'fixed',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: layer.modal2,
  visibility: props.open ? 'visible' : 'hidden',
  opacity: props.open ? 1 : 0,
  display: 'flex',
  justifyContent: 'flex-end',
  transform: props.open ? 'translateX(0)' : 'translateX(25%)',
  transition: '0.5s ease',
}));

const ContentWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  maxWidth: 1440,
  paddingLeft: sizes.sizebarWidth,
  height: '100%',
  [`@media ${bp.sm}`]: {
    paddingLeft: 0,
  },
});

const Content = styled('div')({
  width: '100%',
  height: `calc(100vh - ${sizes.headerHeightLg})`,
  padding: 30,
  background: palette.whitePrimary,
  overflowY: 'auto',
});

export default QuestionSetContentModal;
