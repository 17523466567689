import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Appointment } from 'types/Appointment';
import { Textarea } from 'views/components/primitive';
import { WrapperMemo, Title } from './styles';

interface PropTypes {
  memo: string;
  onChange: (data: Partial<Appointment>) => void;
  isEdit?: boolean;
}

const AddMemo: FC<PropTypes> = ({ memo, onChange, isEdit = true }) => {
  const { t } = useTranslation();

  function handleChange(value: string) {
    onChange({ memo: value });
  }

  return (
    <WrapperMemo>
      <Title>{t('common.memoTitle')}</Title>
      <Textarea
        value={memo}
        onChange={handleChange}
        placeholder={t('hearingPlay.result.memoPlaceholder')}
        maxLength={200}
        disabled={!isEdit}
      />
    </WrapperMemo>
  );
};

export default AddMemo;
