import styled from '@emotion/styled';
import { layer, palette } from 'entity/createTheme';
import { useIsAdmin } from 'hooks';
import React, { FC, useMemo, useState } from 'react';
import { QuestionSet } from 'types/HearingSet';
import { getImgFromContent } from 'utility/helpers';
import { ConfirmModal } from 'views/components/compound';
import _QuestionSetInfo from './QuestionSetInfo';
import ActionButtons from './ActionButtons';

type Props = {
  questionSet: QuestionSet;
  index: number;
  hasOneOrLessQuestionSet: boolean;
  deleteQuestionSet(id: number): void;
  toggleEditQuestionSetModal?(): void;
  injectQuestionSetToModal?(questionSet: QuestionSet): void;
  isSortMode: boolean;
  handleSortWithArrow: (currentIndex: number, value: number) => void;
  dragging: boolean;
  allQuestionIds: number[];
  setIsSelectedCreativeLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuestionSetItem: FC<Props> = ({
  questionSet,
  deleteQuestionSet,
  index,
  hasOneOrLessQuestionSet,
  toggleEditQuestionSetModal,
  injectQuestionSetToModal,
  isSortMode,
  handleSortWithArrow,
  dragging,
  allQuestionIds,
  setIsSelectedCreativeLoading,
}) => {
  const isAdmin = useIsAdmin();
  const { questions, id, content, can_delete } = useMemo(() => questionSet, [questionSet]);
  const [modalOpen, setModalOpen] = useState(false);

  const shouldShowDeleteIcon = !!can_delete && !hasOneOrLessQuestionSet;

  function toggleModal() {
    setModalOpen(isDeleteQuestionModal => !isDeleteQuestionModal);
  }

  function onDeleteQuestionSet() {
    toggleModal();
  }

  function handleDeleteQuestionSet() {
    deleteQuestionSet(id);
  }

  function handleOpenModal() {
    setIsSelectedCreativeLoading && setIsSelectedCreativeLoading(true);
    toggleEditQuestionSetModal && toggleEditQuestionSetModal();
    injectQuestionSetToModal && injectQuestionSetToModal(questionSet);
  }

  const thumbnail = getImgFromContent(content);

  return (
    <QuestionDetailItemWrapper isSortMode={isSortMode} dragging={dragging}>
      <QuestionSetInfo
        thumbnail={thumbnail}
        questionList={questions}
        questionSetId={id}
        allQuestionIds={allQuestionIds}
      />
      {isAdmin && (
        <ActionButtons
          index={index}
          isSortMode={isSortMode}
          handleSortWithArrow={handleSortWithArrow}
          shouldShowDeleteIcon={shouldShowDeleteIcon}
          onDeleteQuestion={onDeleteQuestionSet}
          openEditQuestionModal={handleOpenModal}
        />
      )}
      <ConfirmModal
        isModal={modalOpen}
        isDelete
        className="alert_modal"
        toggleModal={toggleModal}
        handleConfirmModal={handleDeleteQuestionSet}
      />
    </QuestionDetailItemWrapper>
  );
};

const ArrowButtons = styled('div')({
  display: 'flex',
  padding: '10px 5px',
  svg: {
    margin: '0px 5px',
    zIndex: layer.bottomAbove2,
    '&:hover': { cursor: 'pointer' },
  },
});

const QuestionDetailItemWrapper = styled('div')(
  ({ isSortMode, dragging }: { isSortMode: boolean; dragging: boolean }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    cursor: 'inherit',
    padding: '10px 0',
    width: '100%',
    minWidth: 0,
    [`${ArrowButtons}`]: {
      svg: {
        display: 'none',
      },
    },

    ...(isSortMode && {
      cursor: 'grab',
      border: `1px solid ${palette.grayPrimary}`,
      borderRadius: 6,
      padding: 10,
      '&:hover': {
        boxShadow: dragging ? 'none' : `1px 2px 5px 0 ${palette.grayBlur2}`,
        [`${ArrowButtons}`]: {
          svg: {
            display: dragging ? 'none' : 'block',
          },
        },
      },
    }),
  })
);

const QuestionSetInfo = styled(_QuestionSetInfo)({
  minWidth: 0,
});

export default QuestionSetItem;
