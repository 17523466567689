import useStore from './useStore';

// Do not export any other none-role-related actions/observables/computed values from this hooks
const useIsAdmin = () => {
  const {
    appStore: { isAdmin },
  } = useStore();

  return isAdmin;
};

export default useIsAdmin;
