import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import CloseIcon from 'assets/images/ic_close.svg';
import BackIcon from 'assets/images/ic_left_arrow_24x24.svg';
import NextIcon from 'assets/images/ic_right_arrow_24x24.svg';
import { bp, layer, palette } from 'entity/createTheme';
import React, { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useKey, useLockBodyScroll } from 'react-use';
import pdfCMapOptions from 'utility/pdfCMapOptions';
import { Button } from 'views/components/primitive';
import SVGIcon from 'views/components/primitive/SVGIcon';
import LoadingIndicator from '../LoadingIndicator';

type Props = {
  url: string;
  isOpen: boolean;
  toggleOpen(): void;
  pageNumber: number;
  syncPageNumber: (currentModalPage: number) => void;
  fixedRatio: number;
  total: number;
  className?: string;
};

const EXTRA_WIDE_RATIO = 1.5; //if width:height ratio of PDF file is over this number, width, height of PDF display area should be decrease so that it can fit screen

const PDFModal: FC<Props> = ({
  url,
  pageNumber,
  syncPageNumber,
  isOpen,
  toggleOpen,
  fixedRatio,
  total,
  className,
}) => {
  useLockBodyScroll(isOpen);
  const callback = () => isOpen && toggleOpen();
  useKey('Escape', callback, undefined, [isOpen]);

  const [modalPageNumber, setModalPageNumber] = useState(pageNumber);
  useEffect(() => setModalPageNumber(pageNumber), [pageNumber]);

  const goBackModalPage = () => {
    setModalPageNumber(prev => prev - 1);
  };
  const goNextModalPage = () => {
    setModalPageNumber(prev => prev + 1);
  };

  const closeModal = () => {
    toggleOpen();
    syncPageNumber(modalPageNumber);
  };

  const isFirstPage = modalPageNumber <= 1;
  const isLastPage = modalPageNumber >= total;

  if (!isOpen) return null;

  return (
    <Root className={className} onClick={closeModal} id="pdf-modal">
      <ModalContent>
        <CloseModal className="close_icon">
          <SVGIcon src={CloseIcon} />
        </CloseModal>
        <ModalMain onClick={e => e.stopPropagation()}>
          <ArrowButton
            id="pdf-modal__back-button"
            fixedRatio={fixedRatio}
            label={<SVGIcon src={BackIcon} id="pdf-modal__back-button__icon" />}
            onClick={goBackModalPage}
            disabled={isFirstPage}
          />
          <DocumentWrapper fixedRatio={fixedRatio}>
            <Document file={url} options={pdfCMapOptions} loading={<LoadIcon />}>
              <Page
                renderAnnotationLayer={false}
                pageNumber={modalPageNumber}
                loading={<LoadIcon />}
                renderTextLayer={false}
              />
            </Document>
          </DocumentWrapper>

          <ArrowButton
            id="pdf-modal__next-button"
            fixedRatio={fixedRatio}
            label={<SVGIcon src={NextIcon} id="pdf-modal__next-button__icon" />}
            onClick={goNextModalPage}
            disabled={isLastPage}
          />
        </ModalMain>
        <Index id="pdf-modal__index">
          {modalPageNumber} / {total}
        </Index>
      </ModalContent>
    </Root>
  );
};

export default PDFModal;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Root = styled('div')({
  position: 'fixed',
  zIndex: layer.modal2,
  top: 0,
  left: 0,
  height: '100vh',
  width: '100%',
  background: 'rgb(0, 0, 0, 0.6)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${fadeIn} 0.3s linear`,
});

const ModalContent = styled('div')({
  textAlign: 'right',
  position: 'relative',
});

const ModalMain = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ArrowButton = styled(Button)<{ fixedRatio: number }>(props => ({
  zIndex: layer.bottomAbove1,
  width: 80,
  height: '60vh',
  background: 'transparent',
  '&:disabled': {
    opacity: 0,
    cursor: 'not-allowed',
  },
  '&:last-of-type': {
    right: 0,
  },
  [`@media ${bp.lg}`]: {
    position: props.fixedRatio > 1 ? 'absolute' : undefined,
    height: props.fixedRatio > 1 ? '80%' : undefined,
    '&:last-of-type': {
      right: 0,
    },
  },
}));

const DocumentWrapper = styled('div')<{ fixedRatio: number }>(props => ({
  height: props.fixedRatio > EXTRA_WIDE_RATIO ? '75vh' : '85vh',
  width:
    props.fixedRatio > EXTRA_WIDE_RATIO
      ? `calc(75vh * ${props.fixedRatio})`
      : `calc(85vh * ${props.fixedRatio})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '.react-pdf__Document': {
    height: '100%',
  },

  '.react-pdf__Page': {
    height: '100% !important',
  },

  '.react-pdf__Page__canvas': {
    width: 'auto !important',
    height: '100% !important',
    objectFit: 'contain',
  },

  [`@media ${bp.md}`]: {
    width: props.fixedRatio > 1 ? '100vw' : '100%',
    height: props.fixedRatio > 1 ? `calc(100vw / ${props.fixedRatio})` : undefined,
    maxHeight: '80vh',
    '.react-pdf__Page__canvas': {
      width: props.fixedRatio > 0.9 ? '100% !important' : undefined,
    },
  },
}));

const CloseModal = styled('span')({
  cursor: 'pointer',
  marginRight: 30,
  svg: {
    width: 16,
  },
});

const Index = styled('div')({
  color: palette.whitePrimary,
  marginTop: 20,
  fontSize: 14,
  textAlign: 'center',
  width: '100%',
});

const LoadIcon = styled(LoadingIndicator)({
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
