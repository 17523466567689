import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import { Question } from 'views/components/compound/QuestionForm';
import { Button as _Button, SVGIcon } from 'views/components/primitive';
import IconAdd from 'assets/images/ic_add.svg';
import { QuestionViewType } from 'entity/Question';
interface PropTypes {
  questions: QuestionViewType[];
  updateQuestion: (index: number, data: Partial<QuestionViewType>) => void;
  addQuestion: () => void;
  removeQuestion: (index: number) => void;
  sortQuestions: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  errorFormsConst: number[];
  errorForms: number[];
  clearError: () => void;
  isQuestionDrawerOpen: boolean;
}

const QuestionList = forwardRef<HTMLDivElement, PropTypes>(
  (
    {
      questions,
      updateQuestion,
      addQuestion,
      removeQuestion,
      sortQuestions,
      errorForms,
      errorFormsConst,
      clearError,
      isQuestionDrawerOpen,
    }: PropTypes,
    ref
  ) => {
    const disableAddQuestion = questions.length >= 10;
    const buttonRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const [scrollToBottom, setScrollToBottom] = useState(false);

    const sortByArrow = (currentIndex: number, value: number) => {
      const newIndex = currentIndex + value;
      if (newIndex < 0 || questions.length <= newIndex) return;
      sortQuestions({ oldIndex: currentIndex, newIndex });
      clearError();
    };

    useEffect(() => {
      if (scrollToBottom) {
        buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
        setScrollToBottom(false);
      }
    }, [scrollToBottom]);

    const handleAddQuestion = () => {
      addQuestion();
      setScrollToBottom(true);
    };

    const onRemoveQuestion = (index: number) => {
      removeQuestion(index);
      clearError();
    };

    return (
      <>
        <Headline>{t('admin.questions.question')}</Headline>
        {questions?.map((question, index) => {
          return (
            <QuestionContentWrapper key={index} ref={errorFormsConst[0] === index ? ref : null}>
              <Question
                hasOneQuestion={questions.length === 1}
                sortByArrow={sortByArrow}
                questionIndex={index}
                handleChangeQuestion={updateQuestion}
                question={question}
                hasError={errorForms.includes(index)}
                removeQuestion={onRemoveQuestion}
                isQuestionDrawerOpen={isQuestionDrawerOpen}
              />
            </QuestionContentWrapper>
          );
        })}
        <ButtonWrapper ref={buttonRef} disableAddQuestion={disableAddQuestion}>
          {disableAddQuestion && (
            <LimitNote>{t('admin.questions.maximumNumberOfQuestion')}</LimitNote>
          )}
          <Button
            type="primary"
            label={
              <>
                <SVGIcon src={IconAdd} />
                {t('common.add')}
              </>
            }
            size="md"
            disabled={disableAddQuestion}
            onClick={handleAddQuestion}
          />
        </ButtonWrapper>
      </>
    );
  }
);

const Headline = styled('h4')({
  marginBottom: 15,
  fontSize: 16,
  color: palette.darkMedium2,
});

const QuestionContentWrapper = styled('div')({
  padding: 20,
  background: palette.whitePrimary,
});

const ButtonWrapper = styled('div')<{ disableAddQuestion: boolean }>(props => ({
  width: '100%',
  display: 'flex',
  justifyContent: props.disableAddQuestion ? 'space-between' : 'flex-end',
  alignItems: 'center',
}));

const Button = styled(_Button)({
  svg: {
    width: 16,
    height: 16,
    marginRight: 6,
    '& > g > g': {
      fill: palette.whitePrimary,
    },
  },
});

const LimitNote = styled('span')({
  fontSize: 14,
  color: palette.grayMedium2,
});

export default QuestionList;
