import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '../stores/index';
import { reporttError } from 'utility/reportError';

class HttpClient {
  private axios: AxiosInstance;

  constructor({ baseURL }: { baseURL: string | undefined }) {
    this.axios = axios.create({
      baseURL,
      withCredentials: true,
    });

    this.axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        const errorStatus = error?.response?.status ?? null;
        if (errorStatus === 401) {
          store.appStore.clearSession();
        }
        if (!errorStatus || errorStatus >= 500) {
          reporttError(error);
        }
        return Promise.reject(error.response);
      }
    );
  }

  public request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.request<T>(config);
  }
}

export default HttpClient;
