import * as shaka from 'shaka-player';
import { restoreSettings, storeSettings } from 'utility/playerSettings';
import { ProgressTracker } from './ProgressTracker';

const STORAGE_VERSION = '0.0';

let _isOfflineSupported: boolean | undefined = undefined;
function isOfflineSupported() {
  if (typeof _isOfflineSupported === 'undefined') {
    _isOfflineSupported = shaka.Player.isBrowserSupported() && shaka.offline.Storage.support();
  }
  return !!_isOfflineSupported;
}
/*
function isOfflineSupported() {
  return true;
}
*/
interface ShakaPlayerInnerOptions {
  videoTag: HTMLVideoElement;
  onLoaded?: () => void;
  onDownloadProgress?: (downloading: boolean, progress: number) => void;
  onError?: (error: any) => void;
  onEnd?: () => void;
  onPlay?: () => void;
}

export class ShakaPlayerInner {
  readonly options: ShakaPlayerInnerOptions;
  readonly videoTag: HTMLVideoElement;
  readonly shakaPlayer: shaka.Player;
  readonly shakaStorage: shaka.offline.Storage;
  progressTracker: ProgressTracker | null;
  downloading: boolean;
  loaded: boolean;

  constructor(options: ShakaPlayerInnerOptions) {
    this.options = options;
    this.videoTag = options.videoTag;
    this.shakaPlayer = new shaka.Player(this.videoTag);
    this.shakaPlayer.addEventListener('error', (ev: shaka.PlayerEvents.ErrorEvent) => {
      console.error('error', ev);
      if (this.options.onError) this.options.onError(ev);
    });
    this.videoTag.addEventListener('ended', e => {
      if (options.onEnd) options.onEnd();
    });
    this.videoTag.addEventListener('play', e => {
      if (options.onPlay) options.onPlay();
    });
    this.shakaStorage = new shaka.offline.Storage(this.shakaPlayer);
    this.shakaStorage.configure({
      offline: {
        progressCallback: this.offlineProgress.bind(this),
        trackSelectionCallback: this.offlineTrackSelection.bind(this),
      },
    });
    this.progressTracker = null;
    this.downloading = false;
    this.loaded = false;
  }

  start() {
    this.videoTag.currentTime = 0;
    this.videoTag.play();
  }

  pause() {
    this.videoTag.pause();
  }

  async loadCachedVideo(manifestId: string): Promise<boolean> {
    if (!ShakaPlayerInner.isSupported()) {
      return false;
    }
    return this.shakaStorage.list().then((contents: Array<any>) => {
      let content: any = null;
      for (const item of contents) {
        if (
          item.appMetadata &&
          item.appMetadata.storageVersion === STORAGE_VERSION &&
          item.appMetadata.manifestId === manifestId
        ) {
          content = item;
        }
      }
      if (content) this.startOfflineVideo(manifestId, content.offlineUri);
      return !!content;
    });
  }

  async downloadVideo(
    manifestId: string,
    manifestUrl: string,
    autoLoad = true,
    autoPlay = true
  ): Promise<boolean> {
    if (!ShakaPlayerInner.isSupported()) {
      return false;
    }
    if (this.downloading) return Promise.resolve(false);

    this.downloading = true;
    if (this.options.onDownloadProgress) this.options.onDownloadProgress(true, 0);

    const metadata = {
      storageVersion: STORAGE_VERSION,
      manifestId: manifestId,
    };
    return this.shakaStorage.store(manifestUrl, metadata).then((result: any) => {
      this.downloading = false;
      if (this.options.onDownloadProgress) this.options.onDownloadProgress(false, 1);

      if (autoLoad) this.startOfflineVideo(manifestId, result.offlineUri, autoPlay);
      return true;
    });
  }

  async eachCachedVideo(manifestIds: string[], callback: (manifestId: string) => void) {
    return this.shakaStorage.list().then((contents: Array<any>) => {
      for (const item of contents) {
        if (item.appMetadata && item.appMetadata.storageVersion === STORAGE_VERSION) {
          const manifestId = manifestIds.find(manifestId => {
            return manifestId === item.appMetadata.manifestId;
          });
          if (manifestId) callback(manifestId);
        }
      }
    });
  }

  startOfflineVideo(manifestId: string, offlineUri: string, autoPlay = true) {
    const onVideoLoaded = () => {
      this.videoTag.removeEventListener('loadeddata', onVideoLoaded);
      if (autoPlay) this.videoTag.play();
      if (this.options.onLoaded) this.options.onLoaded();
      if (!this.loaded) {
        this.loaded = true;
        this.videoTag.addEventListener('volumechange', ev => {
          storeSettings({ muted: this.videoTag.muted, volume: this.videoTag.volume });
        });
        this.progressTracker = new ProgressTracker('shaka', manifestId);
        this.progressTracker.start(this.videoTag);
      }
      this.loaded = true;
    };
    const playerSettings = restoreSettings();
    this.videoTag.muted = playerSettings.muted;
    this.videoTag.volume = playerSettings.volume;
    this.videoTag.autoplay = autoPlay;
    this.shakaPlayer.load(offlineUri, 0);
    this.videoTag.addEventListener('loadeddata', onVideoLoaded);
  }

  offlineProgress(content: any, progress: any) {
    if (this.options.onDownloadProgress) this.options.onDownloadProgress(true, progress);
    // this.emitEvent('downloadprogress', { size: content.size, progress: progress });
    // if (this.downloadButton) { this.downloadButton.updateProgress(progress * 100); }
  }

  offlineTrackSelection(tracks: any) {
    const maxHeightTrack = tracks.reduce((a: any, b: any) => (a.height > b.height ? a : b));
    return [maxHeightTrack];
  }

  public static isSupported() {
    return isOfflineSupported();
  }
}
