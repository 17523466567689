import React, { FC } from 'react';
import { Button } from 'views/components/primitive';
import { useTranslation } from 'react-i18next';
import { ProductItem } from 'types/Product';
import ProductInfo_ from '../ProductInfo';
import styled from '@emotion/styled';

type Props = {
  productContent?: ProductItem;
  toggleProductContentModal: () => void;
};

const ContentSelector: FC<Props> = ({ productContent, toggleProductContentModal }) => {
  const { t } = useTranslation();

  return (
    <>
      {!!productContent && <ProductInfo productContent={productContent} />}
      <Button
        size="md"
        type="default"
        label={t('sharingLink.selectFile')}
        onClick={toggleProductContentModal}
      />
    </>
  );
};

export default ContentSelector;

const ProductInfo = styled(ProductInfo_)({
  padding: '20px 0px',
});
