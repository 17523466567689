import { observable, action } from 'mobx';
import { RootStore } from '../index';
import { Client } from 'types/Client';
import { MessageProps } from 'types/App';
import ClientApi from 'services/ClientApi';

class ClientListStore {
  private readonly clientApi: ClientApi;
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public clientList: Client[] = [];
  @observable public clientListTotal = 0;
  @observable public currentPage = 1;

  constructor({ rootStore, clientApi }: { rootStore: RootStore; clientApi: ClientApi }) {
    this.rootStore = rootStore;
    this.clientApi = clientApi;
  }

  @action.bound
  public putFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getClients({ page = 1, key = '' }: { page?: number; key?: string }) {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.clientApi.getClients({
        page,
        key,
        organization_id,
      });
      this.clientList = data.clients;
      this.clientListTotal = data.total;
      this.currentPage = 1;
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public async loadMore({ key = '' }: { key?: string }) {
    if (this.clientList.length >= this.clientListTotal) {
      return;
    }
    this.isLoading = true;
    const page = this.currentPage + 1;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const { data } = await this.clientApi.getClients({
        page,
        key,
        organization_id,
      });
      this.clientList = this.clientList.concat(data.clients);
      this.clientListTotal = data.total;
      this.currentPage = page;
    } catch (error: any) {
      const content = error?.message ?? 'error!';
      this.putFlashMessages({ content, status: 'error' });
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetClientList() {
    this.isLoading = false;
    this.clientList = [];
    this.clientListTotal = 0;
    this.currentPage = 1;
  }
}

export default ClientListStore;
