import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptThumbnail, acceptVideoImageOrPDF } from 'utility/constants';

type Props = {
  acceptType: 'content' | 'thumbnail';
  className?: string;
  handleChange: (file: any) => void;
  disabled?: boolean;
};

const ReplaceFileButton: FC<Props> = ({ className, handleChange, acceptType, disabled }) => {
  const { t } = useTranslation();

  function handleChangeFile(e: ChangeEvent<HTMLInputElement>) {
    handleChange(!!e.target.files && e.target.files[0]);
  }

  //Make sure onChange funtion is fired event when selecting the same file
  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const accept =
    acceptType === 'content' ? acceptVideoImageOrPDF.join(', ') : acceptThumbnail.join(', ');

  return (
    <Root className={`${className} ${disabled ? 'disabled' : ''}`}>
      {acceptType === 'content'
        ? t('admin.common.uploadFileButtonLabel')
        : t('admin.common.uploadThumbnailButtonLabel')}
      <input accept={accept} onChange={handleChangeFile} type="file" onClick={onClick} />
    </Root>
  );
};

export default ReplaceFileButton;

const Root = styled('label')({
  width: '100%',
  fontSize: 12,
  fontWeight: 800,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  input: {
    display: 'none',
  },
  '&.disabled': {
    display: 'none',
  },
  '&:hover': {
    color: palette.deepBlueLight,
  },
});
