import { Tag } from 'types/App';
import { QuestionPlayData } from 'types/HearingSet';

const getUserAnswerRelatedTags = (question: QuestionPlayData): number[] => {
  const answerIds = question.userAnswers;
  const tags = question.choices
    .filter(choice => answerIds.includes(choice.id))
    .map(choice => choice.tags)
    .flat(2)
    .filter((tag): tag is Tag => !!tag);

  const tagIds = new Set<number>();
  for (const tag of tags) {
    tagIds.add(tag.id);
  }

  const relatedTagIds = Array.from(tagIds);
  relatedTagIds.sort();
  return relatedTagIds;
};

export { getUserAnswerRelatedTags };
