export interface SortOrder {
  id: number;
  label: SortOrderLabel;
  value: 'latest' | 'oldest' | 'desc' | 'asc';
  sort: string;
  order: string;
}

type SortOrderLabel =
  | 'common.sortLatest'
  | 'common.sortOldest'
  | 'common.sortDESC'
  | 'common.sortASC';

export const sortOrders: SortOrder[] = [
  {
    id: 0,
    label: 'common.sortLatest',
    value: 'latest',
    sort: 'desc',
    order: 'updated_at',
  },
  {
    id: 1,
    label: 'common.sortOldest',
    value: 'oldest',
    sort: 'asc',
    order: 'updated_at',
  },
  {
    id: 3,
    label: 'common.sortDESC',
    value: 'desc',
    sort: 'desc',
    order: 'title',
  },
  {
    id: 4,
    label: 'common.sortASC',
    value: 'asc',
    sort: 'asc',
    order: 'title',
  },
];
