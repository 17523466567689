import React, { FC } from 'react';
import styled from '@emotion/styled-base';
import { palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';

const LoadingThumbnail: FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <p>{t('admin.common.loadingThumbnail')}</p>
    </Root>
  );
};

const Root = styled('div')({
  background: palette.grayMedium2,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: {
    fontSize: 12,
    color: palette.whitePrimary,
  },
});

export default LoadingThumbnail;
