import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOrders } from './constants';
import { useLocationSearch, usePushLocationSearch } from 'hooks';
import { Dropdown } from 'views/components/compound';

type Props = {
  notSortTitle?: boolean;
  className?: string;
};

const SortButton: FC<Props> = ({ notSortTitle, className }) => {
  const { sort, order } = useLocationSearch();
  const push = usePushLocationSearch();
  const { t } = useTranslation();

  const sortList = (() => {
    if (notSortTitle) {
      return sortOrders.filter(it => it.id < 3);
    }
    return sortOrders;
  })();

  const currentOption = sortList.find(option => option.sort === sort && option.order === order);

  const handleClickSortItem = (value: string) => {
    const currentSortOption = sortOrders.find(option => option.value === value);
    currentSortOption &&
      push({ page: 1, sort: currentSortOption.sort, order: currentSortOption.order });
  };

  return (
    <Dropdown
      size="sm"
      className={className}
      value={currentOption?.value || ''}
      options={sortList}
      onChange={handleClickSortItem}
      width={100}
      dropdownLabel={t('common.sort')}
    />
  );
};

export default SortButton;
