import React, { FC } from 'react';
import styled from '@emotion/styled';
import { stylesCommon } from './styles';
import { bp } from 'entity/createTheme';

const AppointmentDetailWrapper: FC = ({ children }) => {
  return <Root>{children}</Root>;
};

export default AppointmentDetailWrapper;

const Root = styled('div')`
  display: flex;
  .appointment {
    &-top {
      width: 58%;
      margin-right: 2%;
      & > div:nth-of-type(2) {
        display: none;
      }
    }
    &-bottom {
      width: 40%;

      .by_user {
        ${stylesCommon()};
      }
    }

    @media ${bp.md} {
      &-top,
      &-bottom {
        width: 100%;
      }

      &-bottom {
        & > div:first-of-type {
          display: none;
        }
      }
      &-top {
        & > div:nth-of-type(2) {
          display: block;
        }
      }
    }
  }

  @media ${bp.md} {
    flex-direction: column;
  }
`;
