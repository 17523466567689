import { TFuncKey } from 'react-i18next';
import { ContentType } from 'types/App';

export type QuestionContentTypeOption = {
  value: ContentType;
  label: TFuncKey;
};

export const questionContentTypeOptions: QuestionContentTypeOption[] = [
  {
    value: 'existing_contents',
    label: 'admin.questions.contentFormLabel.existing_contents',
  },
  {
    value: 'videos_images_pdfs',
    label: 'admin.questions.contentFormLabel.videos_images_pdfs',
  },
  {
    value: 'links',
    label: 'admin.questions.contentFormLabel.links',
  },
  {
    value: 'none_data',
    label: 'admin.questions.contentFormLabel.none_data',
  },
];
