import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utility/helpers';
import { Button, ContentTypeLabel as _ContentTypeLabel } from 'views/components/primitive';
import { ProductItem as ProductItemTypes } from 'types/Product';
import { getImgFromContent } from 'utility/helpers';
import BulkDownloader from '../CacheablePlayer/BulkDownloader';
import { palette, bp } from 'entity/createTheme';
import useStore from 'hooks/useStore';

type Props = {
  data: ProductItemTypes;
  handleStartHearing: (id: number, isRecording: boolean) => void;
};

const ProductItem: FC<Props> = ({ data, handleStartHearing }) => {
  const {
    hearingPlayStore: { isRecording },
  } = useStore();
  const { t } = useTranslation();

  const videos: { manifestId: string; manifestUrl: string }[] = [];
  if (data.content_type === 'videos' && data.kaizen_files_id && data.kaizen_files_url) {
    videos.push({ manifestId: data.kaizen_files_id, manifestUrl: data.kaizen_files_url });
  }

  function startHearing() {
    handleStartHearing(data.id, isRecording);
  }

  return (
    <WrapperProductItem data-autify_selector="sales_content_list_item">
      <ContentTypeLabel>
        {t(`common.contentLabelText.${data.content_type}` as const)}
      </ContentTypeLabel>
      {videos.length > 0 && <BulkDownloader videos={videos} />}
      <ProductItemThumbnail>
        <img src={getImgFromContent(data)} alt="prod-img" />
      </ProductItemThumbnail>
      <ProductItemContent>
        <ProductTitle data-autify_selector="product_content_title">{data.title}</ProductTitle>
        <ProductTime>
          {t('common.lastUpdated')}: {formatTime(data.updated_at, 'yyyy/MM/dd')}
        </ProductTime>
        <ActionButtons>
          <Button
            type="primary"
            size="sm"
            label={t('productContent.startAppointment')}
            onClick={startHearing}
          />
        </ActionButtons>
      </ProductItemContent>
    </WrapperProductItem>
  );
};

export default ProductItem;

const WrapperProductItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc((100% - 40px) / 3)',
  margin: '0 20px 20px 0',
  borderRadius: 6,
  background: palette.whitePrimary,
  cursor: 'inherit',
  position: 'relative',
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
  '&:nth-of-type(3n)': {
    marginRight: 0,
  },
  [`@media ${bp.md}`]: {
    width: 'calc(50% - 8px)',
    margin: '0 16px 16px 0',
    '&:nth-of-type(3n)': {
      marginRight: 16,
    },
    '&:nth-of-type(2n)': {
      marginRight: 0,
    },
  },
});

const ContentTypeLabel = styled(_ContentTypeLabel)({
  position: 'absolute',
  top: 10,
  left: 10,
});

const ProductItemThumbnail = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 203,
  width: '100%',
  borderRadius: '6px 6px 0 0',
  overflow: 'hidden',
  background: palette.blackPrimary,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const ProductItemContent = styled('div')({
  padding: '10px 20px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const ProductTitle = styled('div')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  marginBottom: 10,
  '@supports not (overflow-wrap: anywhere)': {
    wordBreak: 'break-word',
  },
  '@supports (overflow-wrap: anywhere)': {
    overflowWrap: 'anywhere',
  },
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
});

const ProductTime = styled('div')({
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: palette.deepBlueLight,
  marginBottom: 20,
});

const ActionButtons = styled('div')({
  flex: 1,
  marginBottom: 13,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  [`@media ${bp.md}`]: {
    marginBottom: 10,
  },
});
