import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import { getImgFromContent, getTotalQuestions } from 'utility/helpers';
import ContentContainer from './ContentContainer';
import ContentItem from './ContentItem';

type Props = {
  hearingItem: HearingItem;
  hasNoPublicHearing: boolean;
};

const HearingSet: FC<Props> = ({ hearingItem, hasNoPublicHearing }) => {
  const { t } = useTranslation();
  const totalQuestions = getTotalQuestions(hearingItem);

  return hearingItem && hearingItem.question_sets.length >= 1 ? (
    <ContentContainer
      title={t('hearingSet.questionnaireTitle')}
      hasDraftOrArchived={hasNoPublicHearing}
    >
      <ContentWrapper>
        <ContentItem
          isUnpublic={hasNoPublicHearing}
          src={getImgFromContent(hearingItem.question_sets[0].content)}
          title={hearingItem.title}
          metaTitle={t('hearingSet.hearingItem.numOfVideo', {
            count: totalQuestions,
          })}
        />
      </ContentWrapper>
    </ContentContainer>
  ) : null;
};

export default HearingSet;

const ContentWrapper = styled('div')({ marginTop: 15 });
