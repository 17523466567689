import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { SVGIcon } from 'views/components/primitive';
import IconEmpty from 'assets/images/ic_empty.svg';

type Props = {
  className?: string;
  isSearchQuery?: boolean;
};

const WrapperEmpty = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 1.125rem;
  }
`;

const EmptyList: FC<Props> = ({ className, isSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <WrapperEmpty className={className}>
      <SVGIcon src={IconEmpty} />
      <h1>{isSearchQuery ? t('common.emptySearchResult') : t('common.emptyMessages')}</h1>
    </WrapperEmpty>
  );
};

export default EmptyList;
