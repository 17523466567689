import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const list = [
  { label: 'En', value: 'en-US', title: '言語' },
  { label: 'Ja', value: 'ja', title: 'Language' },
];

function LanguageToggle() {
  const { i18n } = useTranslation();

  return (
    <>
      {list.map(({ value, title }, index) => (
        <span
          style={{ width: 'auto' }}
          key={index}
          className={`item${i18n.language === value ? ' invisible' : ''}`}
        >
          {title}
        </span>
      ))}
      <LanguageSwitch>
        {list.map(({ value, label }, index) => (
          <span key={index}>
            <button
              onClick={() => {
                i18n.changeLanguage(value);
                localStorage.setItem('locale', value);
              }}
              key={value.toString()}
              className={`item${i18n.language === value ? '' : ' active'}`}
              disabled={`item${i18n.language}` === value ? true : false}
            >
              {label}
            </button>
            {index === 0 ? (
              <span key={index} className={'active'}>
                &nbsp;&nbsp;/&nbsp;&nbsp;
              </span>
            ) : (
              ''
            )}
          </span>
        ))}
      </LanguageSwitch>
    </>
  );
}

export default LanguageToggle;

const LanguageSwitch = styled('div')({
  position: 'absolute',
  right: '20px',
});
