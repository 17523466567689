import React, { FC, useState, useRef, useEffect } from 'react';
import { Player, PlayerOptions } from '@kaizenplatform/kaizen-video-player';
import styled from '@emotion/styled';

type Props = PlayerOptions &
  Required<Pick<PlayerOptions, 'videoId'>> & {
    onReady: () => void;
  };

const KaizenVideoPlayer: FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [player, setPlayer] = useState<Player | null>(null);

  useEffect(() => {
    if (!player && ref.current) {
      const container = ref.current;
      const p = new Player(
        container,
        Object.assign(
          {
            videoType: 'shaka',
            plyrCss: `.plyr{height:100%;width:100%;}`,
          },
          props
        )
      );
      setPlayer(p);
      p.videoTag.onloadeddata = () => {
        if (p && p.videoTag) {
          setWidth(p?.videoTag.videoWidth);
          setHeight(p?.videoTag.videoHeight);
          if (props.onReady) {
            props.onReady();
          }
        }
      };
      p.start();
    }
  }, []);

  return <Root ref={ref} className="kaizen-video-player" width={width} height={height} />;
};

export default KaizenVideoPlayer;

const Root = styled.div<{ width?: number; height?: number }>`
  margin: 0 auto;
  aspect-ratio: auto ${props => `${props.width} / ${props.height}`};
  height: 100%;
  max-width: 100%;
`;
