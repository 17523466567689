import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadImageMeta } from 'types/App';
import { ReplaceFileButton } from 'views/components/compound';

type Props = {
  editable: boolean;
  meta: UploadImageMeta;
  handleChangeUploadFile: (file: any) => void;
  notReady?: boolean;
};

const UploadImage: FC<Props> = ({ editable, meta, handleChangeUploadFile, notReady }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Root>
        <PreviewSection>
          <MainFrameWrap>
            <MainFrame>
              <ImageWrapper>
                <img src={meta.src} alt="thumbnail_image" />
              </ImageWrapper>
            </MainFrame>
          </MainFrameWrap>
          {editable && (
            <ReplaceFileButton acceptType="content" handleChange={handleChangeUploadFile} />
          )}
        </PreviewSection>
        {meta.filename && (
          <FileNameSection>
            <ContentHeading>{t('admin.common.filename')}</ContentHeading>
            <FileName>{meta.filename}</FileName>
          </FileNameSection>
        )}
      </Root>
      <CautionWrapper>
        <p>{t('admin.questions.messages.updateContentCaution1')}</p>
        <p>{t('admin.questions.messages.updateContentCaution2')}</p>
      </CautionWrapper>
      {meta.error !== '' && <Error className="error">{meta.error}</Error>}
    </Fragment>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  [`@media ${bp.md}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const PreviewSection = styled('div')({});

const FileNameSection = styled('div')({
  paddingBottom: 28,
  [`@media ${bp.md}`]: {
    marginTop: 20,
    paddingBottom: 16,
  },
});

const Error = styled('p')({
  color: palette.redPrimary,
  marginTop: 20,
  fontSize: 12,
});

const MainFrameWrap = styled('div')({
  border: `1px solid ${palette.borderInput}`,
  borderRadius: 4,
  width: 582,
  height: 349,
  padding: 24,
  marginBottom: 8,
  marginRight: 24,
  [`@media ${bp.xmd}`]: {
    width: 450,
    height: 270,
  },
  [`@media ${bp.md}`]: {
    padding: 16,
  },
});

const MainFrame = styled('div')({
  border: `1px solid ${palette.borderInput}`,
  width: '100%',
  height: '100%',
  borderRadius: 4,
});

const ImageWrapper = styled('div')({
  background: palette.blackPrimary,
  width: '100%',
  height: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

const ContentHeading = styled('p')({
  fontSize: 12,
  marginBottom: 8,
});

const FileName = styled('div')({
  marginTop: 10,
  color: palette.darkMedium2,
  fontSize: 14,
  letterSpacing: 0,
  width: '100%',
  lineHeight: 1.5,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 8,
  wordBreak: 'break-all',
});

const CautionWrapper = styled('div')({
  marginTop: 10,
  p: {
    fontSize: '0.75rem',
    color: palette.grayMedium4,
    lineHeight: 1.5,
  },
});

export default UploadImage;
