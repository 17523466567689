import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  InputText as _InputText,
  Textarea,
  Checkbox as _Checkbox,
} from 'views/components/primitive';
import _CreatableAccount from 'views/components/compound/HearingLink/CreatableAccount';
import { CreateHearingLinkData } from 'types/HearingLink';
import { Client } from 'types/Client';
import { palette } from 'entity/createTheme';
import { ClientSelect } from 'views/components/compound';

type Props = {
  onChangeAccount(value: any, isNew?: boolean): void;
  hearingLinkDetail: CreateHearingLinkData;
  handleChangeDetail: (updatedLink: Partial<CreateHearingLinkData>) => void;
  clientList: Client[];
  sfaIntegrated: boolean;
  clientName?: string | null;
  openAccountForm: boolean;
  toggleAccountForm: () => void;
  disableEdit: boolean;
};

const LinkInfo: FC<Props> = ({
  hearingLinkDetail,
  handleChangeDetail,
  clientList,
  clientName = null,
  sfaIntegrated,
  onChangeAccount,
  openAccountForm,
  toggleAccountForm,
  disableEdit,
}) => {
  const { t } = useTranslation();
  const { administrative_title, administrative_memo } = useMemo(() => hearingLinkDetail, [
    hearingLinkDetail,
  ]);

  const onChangeAdministrativeTitle = (administrativeTitle: string) => {
    handleChangeDetail({ administrative_title: administrativeTitle });
  };

  const onChangeAdministrativeMemo = (administrativeMemo: string) => {
    handleChangeDetail({ administrative_memo: administrativeMemo });
  };

  const onChangeAccountRequire = () => {
    if (openAccountForm) {
      onChangeAccount({ client_name: '' });
    }
    toggleAccountForm();
  };

  function handleChangeSelectedClient(client?: Client) {
    if (client) {
      onChangeAccount(client.id, false);
    }
  }

  return (
    <Root>
      <Label>
        <MainLabel>{t('sharingLink.administrationSetting.titleLabel')}</MainLabel>
        <SubLabel>{t('sharingLink.administrationSetting.subTitleLabel')}</SubLabel>
      </Label>
      <InputText
        value={administrative_title || ''}
        onChange={onChangeAdministrativeTitle}
        maxLength={40}
        disabled={disableEdit}
      />

      <Label>
        <MainLabel>{t('sharingLink.administrationSetting.memoLabel')}</MainLabel>
        <SubLabel>{t('sharingLink.administrationSetting.subMemoLabel')}</SubLabel>
      </Label>
      <Textarea
        value={administrative_memo || ''}
        height={142}
        onChange={onChangeAdministrativeMemo}
        maxLength={200}
        disabled={disableEdit}
      />

      <CreatableAccountWrapper>
        <SettingOption>
          <Checkbox
            disabled={disableEdit}
            onChange={onChangeAccountRequire}
            checked={openAccountForm}
            customDisableBackground={palette.base}
          />
          <Label>
            <MainLabel>{t('sharingLink.administrationSetting.clientAccountLabel')}</MainLabel>
            <SubLabel>{t('sharingLink.administrationSetting.subClientAccountLabel')}</SubLabel>
          </Label>
        </SettingOption>

        {openAccountForm &&
          (sfaIntegrated ? (
            <ClientSelect onChange={handleChangeSelectedClient} />
          ) : (
            <CreatableAccount
              options={clientList}
              value={clientName || ''}
              onChange={onChangeAccount}
            />
          ))}
      </CreatableAccountWrapper>
    </Root>
  );
};

export default LinkInfo;

const Root = styled('div')({
  marginBottom: 24,
});

const Checkbox = styled(_Checkbox)({
  marginRight: 8,
  padding: 0,
});

const Label = styled('div')({
  marginBottom: 8,
});

const CreatableAccountWrapper = styled('div')`
  .react-select {
    &-container {
      height: 42px;
    }
    &__control {
      height: 100%;
      border: 1.5px solid ${palette.borderInput}!important;
      border-radius: 6px;
      box-shadow: none;
      &:focus {
        outline: none;
      }

      &--is-focused {
        border-color: ${palette.deepBlueLight}!important;
      }
    }
    &__option,
    &__single-value {
      font-size: 0.875rem;
    }
    &__option {
      &--is-selected {
        background: ${palette.deepBlueLight};
      }
    }
    &__menu {
      border-radius: 6px;
      box-shadow: none;
      border: 1.5px solid ${palette.deepBlueLight}!important;
      overflow: hidden;
      &-list {
        padding: 0;
        &::-webkit-scrollbar-track {
          background-color: ${palette.whitePrimary};
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: ${palette.grayMedium1};
        }
        &::-webkit-scrollbar-thumb {
          background-color: ${palette.grayMedium1};
          border: none;
        }
      }
    }
  }
`;

const CreatableAccount = styled(_CreatableAccount)({
  height: 42,
});

const SettingOption = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
});

const MainLabel = styled('p')({
  fontSize: '0.75rem',
  fontWeight: 700,
});

const SubLabel = styled('p')({
  fontSize: '0.75rem',
  color: palette.deepBlueLight,
  lineHeight: 1.5,
});

const InputText = styled(_InputText)({
  '& :disabled': {
    background: palette.base,
    borderColor: palette.base,
  },
});
