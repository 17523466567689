import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import { bp, palette } from 'entity/createTheme';
import React, { MouseEventHandler, MouseEvent, FC } from 'react';
// import Button from '@material-ui/core/Button';

interface PropTypes {
  onClick?: (e?: any) => void;
  label?: React.ReactNode;
  disabled?: boolean;
  size?: 'md' | 'sm';
  type?: 'default' | 'primary' | 'secondary' | 'outline';
  id?: string;
  className?: string;
  ripple?: boolean;
  autifySelectorName?: string;
  children?: any;
  style?: { [key: string]: string };
}

const ButtonComponent: FC<PropTypes> = ({
  onClick,
  label,
  disabled,
  size,
  type,
  id,
  className,
  ripple,
  autifySelectorName,
  children,
  style,
}) => {
  const handleClick: MouseEventHandler = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick(e);
  };

  return (
    <Div
      className={className}
      disabled={disabled}
      onClick={handleClick}
      id={id}
      variant={type}
      size={size}
      ripple
      data-autify_selector={autifySelectorName}
      style_={style}
    >
      <Button component="div" disabled={disabled}>
        {label}
        {children}
      </Button>
    </Div>
  );
};

export default ButtonComponent;

const Div = styled('button')<{
  variant?: 'default' | 'primary' | 'secondary' | 'outline';
  size?: 'md' | 'sm';
  ripple?: boolean;
  style_?: { [key: string]: string } | undefined;
}>(props => ({
  border: 'none',
  borderRadius: 4,
  fontWeight: 'bold',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...props.style_,
  wordBreak: 'keep-all',

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    border: 'none',
    color: `${palette.deepBlueDisable} !important`,
    background: `${palette.borderInput} !important`,
    cursor: 'not-allowed',
    '&:hover': {
      border: `none`,
    },
    svg: {
      '& > g > g': {
        fill: `${palette.deepBlueDisable} !important`,
      },
    },
  },

  ...(props.variant === 'default' && {
    background: palette.whitePrimary,
    border: `1.5px solid ${palette.borderInput}`,
    color: palette.darkMedium2,
    svg: {
      width: 16,
      height: 16,
      marginRight: 6,
      '& > g > g': {
        fill: palette.darkMedium2,
      },
    },
    '&:hover': {
      color: palette.deepBlueLight,
      border: `1.5px solid ${palette.borderInput}`,
      svg: {
        '& > g > g': {
          fill: palette.deepBlueLight,
        },
      },
    },
  }),

  '.MuiButton-root': {
    padding: 'unset',
    rontSize: 'unset',
    fontFamily: 'unset',
    fontWeight: 'unset',
    lineHeight: 'unset',
    borderRadius: 'inherit',
    letterSpacing: 'unset',
    textTransform: 'unset',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    color: 'inherit',
    fontSize: 'inherit',
    '> .MuiButton-label': {
      margin: '0 16px',
      width: 'unset',
      // height: '100%',
      justifyContent: 'unset',
      borderRadius: 'inherit',
      color: 'inherit',
    },
  },

  ...(props.variant === 'primary' && {
    background: palette.deepBluePrimary,
    color: palette.whitePrimary,
    svg: {
      marginRight: '10px',
      '& > g > g': {
        fill: palette.whitePrimary,
      },
    },
    '&:hover': {
      background: palette.deepBlueSecondary,
      color: palette.whitePrimary,
    },
  }),

  ...(props.variant === 'secondary' && {
    background: palette.bluePrimary,
    color: palette.whitePrimary,
    '&:hover': {
      background: palette.blueSecondary,
    },
  }),

  ...(props.variant === 'outline' && {
    background: palette.whitePrimary,
    color: palette.darkMedium2,
    border: `1px solid ${palette.darkMedium2}`,
    '&:hover': {
      background: palette.grayMedium9,
    },
  }),

  ...(props.size === 'md' && {
    height: 40,
    fontSize: '0.875rem',
    // padding: '0 24px',
    '.MuiButton-label': {
      margin: '0 24px !important',
    },
    [`@media ${bp.md}`]: {
      // padding: '0 16px',
    },
  }),

  ...(props.size === 'sm' && {
    height: 32,
    fontSize: '0.75rem',
    // padding: '0 16px',
  }),

  ...(props.ripple && {
    position: 'relative',
  }),
}));
