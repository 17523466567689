import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { WrapperModal } from './styles';
import SVGIcon from 'views/components/primitive/SVGIcon';
import CloseIcon from 'assets/images/ic_close.svg';
import { useLockBodyScroll, useKey } from 'react-use';

interface ModalProps {
  isModal: boolean;
  title: string;
  children: React.ReactNode;
  handleToggle: () => void;
  className?: string;
}

const Modal: FC<ModalProps> = ({ title, children, isModal, handleToggle, className }) => {
  useLockBodyScroll(isModal);
  const callback = () => isModal && handleToggle();
  useKey('Escape', callback, undefined, [isModal]);

  return isModal ? (
    <WrapperModal className={`modal-wrapper ${className}`}>
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
          <span className="close_icon" onClick={handleToggle}>
            <SVGIcon src={CloseIcon} />
          </span>
        </div>
        <div className="modal-content scroll-bar-thumbnail">{children}</div>
      </div>
    </WrapperModal>
  ) : null;
};

export default function ModalPortal(props: ModalProps) {
  return ReactDOM.createPortal(<Modal {...props} />, document.body);
}
