import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import React, { FC, useState } from 'react';
import {
  SVGIcon,
  DropdownMenu,
  DropdownItem,
  DropdownWrapper as _DropdownWrapper,
  CSSIcon,
} from 'views/components/primitive';
import DownloadSVGIcon from 'assets/images/ic_download.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  downloadUTF8(): void;
  downloadShiftJIS(): void;
  className?: string;
}

const CSVDownloadButton: FC<Props> = ({ downloadUTF8, downloadShiftJIS, className }) => {
  const [showOptions, setShowOptions] = useState(false);

  const { t } = useTranslation();

  const showDownloadOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <DropdownWrapper
      size="sm"
      toggleOpenDropdown={showDownloadOptions}
      isOpen={showOptions}
      className={className}
    >
      <DownloadIcon src={DownloadSVGIcon} />
      <Text>{t('common.csvDownload')}</Text>
      <CSSIcon type={showOptions ? 'arrow_up' : 'arrow_down'} ripple={true} />
      <DropdownMenu isOpen={showOptions}>
        <DropdownItem handleClickItem={downloadUTF8}>
          {t('common.csvDownloadOption.utf8')}
        </DropdownItem>
        <DropdownItem handleClickItem={downloadShiftJIS}>
          {t('common.csvDownloadOption.shiftjis')}
        </DropdownItem>
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default CSVDownloadButton;

const DownloadIcon = styled(SVGIcon)({
  marginRight: 10,
  '& > g > g': {
    fill: palette.darkMedium2,
  },
});

const DropdownWrapper = styled(_DropdownWrapper)({
  width: 'auto',
  padding: '0 24px',
  [`@media ${bp.md}`]: {
    padding: '0 16px',
  },
  '&:hover': {
    color: palette.deepBlueLight,
    svg: {
      '& > g > g': {
        fill: palette.deepBlueLight,
      },
    },
  },
});

const Text = styled('span')(() => ({
  marginRight: '10px',
}));
