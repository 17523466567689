import * as React from 'react';
import {
  Chart,
  BarElement,
  BarController,
  ChartData,
  LinearScale,
  CategoryScale,
  Tooltip,
} from 'chart.js';
import { formatLabels, dynamicHeight } from 'utility/chartPlugins';

type Props = {
  data?: ChartData<'bar', number[], string>;
  xScaleMax?: number;
} & JSX.IntrinsicElements['canvas'];

const plugins = [
  {
    id: 'formatLabels',
    beforeInit: formatLabels,
  },
  {
    id: 'dynamicHeight',
    beforeRender: dynamicHeight,
  },
];

Chart.register(BarElement, BarController, LinearScale, CategoryScale, Tooltip, plugins);

const HorizontalChart: React.VFC<Props> = ({ data, xScaleMax, ...rest }) => {
  const defaultXScaleMax = 5;
  const suggestedMax = xScaleMax && xScaleMax >= defaultXScaleMax ? xScaleMax : defaultXScaleMax;
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    if (chartRef.current && data) {
      const horizontalChart = new Chart(chartRef.current, {
        type: 'bar',
        data,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          indexAxis: 'y',

          plugins: {
            tooltip: {
              callbacks: {
                title(tooltipItem) {
                  const newLabel =
                    tooltipItem[0].label.slice(0, 20) + tooltipItem[0].label.slice(21);
                  //hack: if you use formatLabels plugin, "," is inserted at 20st label string so remove it
                  return newLabel;
                },
              },
            },
            legend: {
              display: false,
            },
            formatLabels: {
              maxLength: 40,
              maxLineLength: 20,
            },
            dynamicHeight: {
              space: 55,
            },
          },
          scales: {
            x: {
              suggestedMax,
              beginAtZero: true,
              grid: {
                display: true,
              },
              ticks: {
                precision: 0,
              },
            },

            y: {
              afterFit(scaleInstance) {
                scaleInstance.width = 250;
              },
              ticks: {
                crossAlign: 'near',
              },
              grid: {
                display: false,
              },
            },
          },
        },
      });
      return () => horizontalChart.destroy();
    }
  }, [chartRef.current, data, suggestedMax]);

  return (
    <div>
      <canvas ref={el => (chartRef.current = el)} {...rest} />
    </div>
  );
};

export default HorizontalChart;
