import { PlayerEventListener } from '@kaizenplatform/kaizen-video-player';
import React, { useState, useCallback, FC } from 'react';
import { LoggerOption } from 'types/common';
import { ProductItem } from 'types/Product';
import { ImageViewer, PDFViewer, VideoPlayer } from 'views/components/compound';

type Props = {
  productDetail: ProductItem;
  loggerOption?: LoggerOption;
  muted?: boolean;
  markers?: number[];
  invertTime?: boolean;
  listeners?: PlayerEventListener[];
};

const ProductContentPlayer: FC<Props> = ({
  productDetail,
  loggerOption,
  muted,
  markers,
  invertTime,
  listeners,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const goBackPage = useCallback(() => {
    setPageNumber(prev => prev - 1);
  }, [setPageNumber]);
  const goNextPage = useCallback(() => setPageNumber(prev => prev + 1), [setPageNumber]);
  const updatePageNumber = useCallback(
    (page: number) => {
      setPageNumber(page);
    },
    [setPageNumber]
  );
  const setTotalPages = useCallback(
    (totalPages: number) => {
      setNumPages(totalPages);
    },
    [setNumPages]
  );

  switch (productDetail.content_type) {
    case 'videos': {
      return (
        <VideoPlayer
          width="100%"
          height="100%"
          status={productDetail.upload_status || ''}
          manifestId={productDetail.kaizen_files_id}
          manifestUrl={productDetail.kaizen_files_url}
          loggerOption={loggerOption}
          muted={muted}
          markers={markers}
          invertTime={invertTime}
          listeners={listeners}
        />
      );
    }
    case 'images': {
      return <ImageViewer url={productDetail.kaizen_files_url} />;
    }
    case 'pdfs': {
      return (
        <PDFViewer
          url={productDetail.kaizen_files_url}
          pageNumber={pageNumber}
          updatePageNumber={updatePageNumber}
          goBackPage={goBackPage}
          goNextPage={goNextPage}
          totalPages={numPages}
          setTotalPages={setTotalPages}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ProductContentPlayer;
