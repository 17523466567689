import { Choice as IChoice } from 'types/HearingSet';

export type ChoiceViewType = {
  id?: number;
  question_id?: number;
  content: string;
  feedback?: string;
  can_delete?: boolean;
};

export class Choice {
  private id?: number;
  private content: string;
  private feedback?: string;
  private can_delete?: boolean;
  private question_id: number;

  constructor(data: IChoice) {
    this.id = data.id;
    this.content = data.content;
    this.feedback = data.feedback;
    this.can_delete = data.can_delete;
    this.question_id = data.question_id;
  }

  static isValid(data: any): data is IChoice {
    return true;
  }

  static fromJSON(data: any): Choice {
    if (!this.isValid(data)) throw Error('Invalid type of Choice');
    return new Choice(data);
  }

  toJSON(): ChoiceViewType {
    return {
      id: this.id,
      content: this.content,
      feedback: this.feedback,
      can_delete: this.can_delete,
      question_id: this.question_id,
    };
  }
}
