import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ThumbnailIcon from 'views/components/compound/ThumbnailIcon';
import { palette, bp } from 'entity/createTheme';
import { Question } from 'types/HearingSet';

type Props = {
  thumbnail?: string;
  questionList: Question[];
  className?: string;
  questionSetId: number;
  allQuestionIds: number[];
};

const QuestionSetInfo: FC<Props> = ({
  thumbnail,
  questionList,
  className,
  questionSetId,
  allQuestionIds,
}) => {
  const { t } = useTranslation();

  return (
    <Root className={className} id={questionSetId.toString()}>
      <ThumbnailIcon src={thumbnail} className="item-with-thumbnail__thumbnail" />
      <QuestionList>
        {questionList.map(question => {
          const questionIndex = allQuestionIds.findIndex(questionId => questionId === question.id);
          return (
            <QuestionInfo key={question.id}>
              <Title className="item-with-thumbnail__title">{`${t(
                'admin.hearingSet.questionIndex',
                {
                  index: questionIndex + 1,
                }
              )} ${question.body}`}</Title>
              <MetaTitle className="item-with-thumbnail__meta-title">
                {t('admin.questions.countAnswers', { count: question.choices.length })}
              </MetaTitle>
            </QuestionInfo>
          );
        })}
      </QuestionList>
    </Root>
  );
};

export default QuestionSetInfo;

const Root = styled('div')({
  display: 'flex',
  minWidth: 0,
  width: '100%',
  fontSize: 14,
});

const QuestionInfo = styled('div')({
  minWidth: 0,
  flex: 1,
  marginBottom: 10,
  marginLeft: 5,
});

const MetaTitle = styled('span')({
  color: palette.darkMedium3,
});

const Title = styled('p')({
  fontWeight: 'bold',
  marginBottom: 10,

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});

const QuestionList = styled('div')({
  width: '100%',
  overflow: 'auto',
});
