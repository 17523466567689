import React, { FC, lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { FlashMessages } from 'views/components/compound';
const AuthenticatedApp = lazy(() => import('./views/Root'));

const App: FC = () => {
  const urlHistory = useHistory();
  useEffect(() => {
    urlHistory.listen((location, action) => {
      window.parent.postMessage(
        'sales' + location.pathname.replace(/^\/sales/g, '') + location.search + location.hash,
        '*'
      );
    });
    window.parent.postMessage(
      'sales' +
        window.location.pathname.replace(/^\/sales/g, '') +
        window.location.search +
        window.location.hash,
      '*'
    );
  }, []);

  return (
    <div className="container">
      <Suspense fallback={null}>
        <Switch>
          <Route path="/" component={AuthenticatedApp} />
        </Switch>
      </Suspense>
      <FlashMessages />
    </div>
  );
};

export default App;
