import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { WrapperHearingSet, Title } from './styles';
import { Button } from 'views/components/primitive';
import { HearingItem } from 'types/HearingSet';
import HearingItemForDisplay from '../HearingItemForDisplay';

interface PropTypes {
  hearingList: HearingItem[];
  toggleModal: () => void;
}

const RecordedHearingList: FC<PropTypes> = ({ hearingList, toggleModal }) => {
  const { t } = useTranslation();

  function renderHearing() {
    return hearingList.map((hearingSet, index) => (
      <StyledHearingItem key={index} hearing={hearingSet} />
    ));
  }

  return (
    <WrapperHearingSet>
      <Title>{t('hearingSet.hearingSetTitle')}</Title>
      {<div className="list_hearing scroll-bar-thumbnail">{renderHearing()}</div>}
      <div className="btn-view">
        <Button
          type="default"
          size="sm"
          onClick={toggleModal}
          label={t('quickHearing.viewResult')}
        />
      </div>
    </WrapperHearingSet>
  );
};

export default RecordedHearingList;

const StyledHearingItem = styled(HearingItemForDisplay)({
  marginBottom: 20,
});
