import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  children: React.ReactNode[];
  labels: string[];
  onChangeAction?: (tabId: number) => void;
  defaultTabId?: number;
};

const TabsWrapperSimple: React.FC<Props> = ({
  children,
  labels,
  onChangeAction,
  defaultTabId = 0,
}) => {
  const [tabId, setTabId] = React.useState(defaultTabId);
  const onChange = (e: React.ChangeEvent<any>, newValue: number) => {
    if (!!onChangeAction) onChangeAction(newValue);
    setTabId(newValue);
  };
  const childrenCount = React.Children.count(children);
  const tabMenuItems = [...Array(childrenCount)].map((_, index) => {
    return (
      <Tab
        value={index}
        id={`wrapped-tab-${index}`}
        aria-controls={`wrapped-tabpanel-${index}`}
        key={index}
        label={labels[index]}
        disableRipple
      />
    );
  });
  const customedChildren = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child,
        {
          role: 'tabpanel',
          hidden: tabId !== index,
          id: `tabpanel-${index}`,
          'aria-labelledby': `tab-${index}`,
          index,
          value: tabId,
        },
        { ...child }
      );
    }
    return false;
  });

  return (
    <Root>
      <StyledTabs value={tabId} onChange={onChange} aria-label="tabs">
        {tabMenuItems}
      </StyledTabs>
      {customedChildren}
    </Root>
  );
};

const Root = styled.div`
  & .MuiTabs-root {
    margin-bottom: 24px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${palette.darkMedium2};
    height: 3px;
    border-radius: 6px;
  }
  & .Mui-selected > .MuiTab-wrapper {
    color: ${palette.darkMedium2};
  }
  & .MuiTab-root {
    padding: 0;
    min-width: fit-content;
    margin-right: 24px;
  }
  .MuiTab-wrapper {
    font-family: Hiragino Sans;
    font-size: 16px;
    font-weight: 700;
    color: ${palette.deepBlueLight};
  }
`;

export default TabsWrapperSimple;
