import React, { FC } from 'react';
import { css } from '@emotion/core';
import Spinner from 'assets/images/spinner.gif';

type Props = {
  className?: string;
};

const style = css`
  width: 100%;
  height: 100%;
  overflow: hidden;

  .loading-indicator {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    background: transparent;
    .icon_spinner {
      width: 65px;
    }
  }
`;

const LoadingIndicator: FC<Props> = ({ className }) => {
  return (
    <div css={style} className={`loading-wrapper ${className}`}>
      <div className="loading-indicator">
        <img className="icon_spinner" src={Spinner} alt="icon_spinner" />
      </div>
    </div>
  );
};

export default LoadingIndicator;
