import React, { FC } from 'react';
import styled from '@emotion/styled';
import ThumbnailIcon from 'views/components/compound/ThumbnailIcon';
// import LaunchIcon from '@material-ui/icons/Launch';
// import { routes } from 'utility/constants';
import { bp } from 'entity/createTheme';
//import { bp, palette } from 'entity/createTheme';
import { useTranslation } from 'react-i18next';
import _ViewingLogChart from 'views/components/compound/ViewingLogChart';
import { VideoLog, DocumentLog } from 'types/HearingLink';
import { ContentType } from 'types/App';

type Props = {
  thumbnail?: string;
  title?: string;
  isLinkTypeHearing?: boolean;
  className?: string;
  contentId?: number;
  hearingSetId?: number;
  contentViewLog?: VideoLog[] | DocumentLog[];
  contentType?: ContentType;
};

const ContentItem: FC<Props> = ({
  contentId,
  hearingSetId,
  thumbnail,
  title,
  isLinkTypeHearing,
  className,
  contentViewLog,
  contentType,
}) => {
  const { t } = useTranslation();
  // const handleClickManagementLink = () => {
  //   const managementPageLink = isLinkTypeHearing
  //     ? `${routes.management.questionnaireDetail}/${hearingSetId}`
  //     : `${routes.management.productDetail}/${contentId}`;
  //   window?.open(managementPageLink);
  // };

  return (
    <ContentWrapper className={className}>
      {title && <Title>{title}</Title>}
      <InnerWrapper hasViewLog={!!contentViewLog}>
        <ThumbnailWrapper>
          <ThumbnailIcon src={thumbnail} size={240} aspectRatio={4 / 3} />
          {/* <LinkTo onClick={handleClickManagementLink}>
            {isLinkTypeHearing
              ? t('sharingLink.resultModal.openHearingDetail')
              : t('sharingLink.resultModal.openContentDetail')}
            <LaunchIcon />
          </LinkTo> */}
        </ThumbnailWrapper>
        {contentType === 'pdfs' || contentType === 'videos' ? (
          contentViewLog ? (
            <ViewingLogChart contentType={contentType} contentViewLog={contentViewLog} />
          ) : (
            <NoViewingLog>{t('sharingLink.noViewingLogs')}</NoViewingLog>
          )
        ) : undefined}
      </InnerWrapper>
    </ContentWrapper>
  );
};

export default ContentItem;

const ContentWrapper = styled('div')({});

const ThumbnailWrapper = styled('div')({
  width: 240,
});

const InnerWrapper = styled('div')<{ hasViewLog: boolean }>(props => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  ...(props.hasViewLog && {
    [`@media ${bp.md}`]: {
      flexDirection: 'column',
    },
  }),
}));

const Title = styled('h4')({
  margin: '8px 0px',
});
// const LinkTo = styled('div')({
//   display: 'flex',
//   justifyContent: 'flex-end',
//   fontSize: '0.875rem',
//   color: palette.blueSecondary,
//   '.MuiSvgIcon-root': {
//     fontSize: '1.25rem',
//     fill: palette.blueSecondary,
//     marginLeft: 4,
//   },
//   '&:hover': {
//     cursor: 'pointer',
//     color: palette.bluePrimary,
//     '.MuiSvgIcon-root': {
//       fill: palette.bluePrimary,
//     },
//   },
//   marginTop: 8,
// });

const ViewingLogChart = styled(_ViewingLogChart)({
  marginLeft: 24,
  [`@media ${bp.md}`]: {
    marginLeft: 0,
    marginTop: 16,
  },
});

const NoViewingLog = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
});
