import styled from '@emotion/styled';
import UploadIcon from 'assets/images/ic_upload.svg';
import { bp, palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGIcon } from 'views/components/primitive';

const NoContent: FC = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <SVGIcon src={UploadIcon} />
      <DropzoneGuide>
        <MainText>
          {t('admin.common.dropzoneMainGuide', {
            type: t(`admin.common.dropzoneTarget.none`),
          })}
        </MainText>
        <SubText>{t('admin.common.dropzoneGuide')}</SubText>
      </DropzoneGuide>
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  width: '100%',
  height: 349,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 12,
  border: `solid 1px ${palette.base}`,
  backgroundColor: palette.base,
  borderRadius: 6,
  userSelect: 'none',
  cursor: 'not-allowed',
  svg: {
    width: 48,
    height: 32,
    margin: '0 auto',
    '& > path': {
      fill: palette.deepBlueDisable,
    },
  },
  [`@media ${bp.md}`]: {
    height: 200,
  },
});

const DropzoneGuide = styled('div')({
  marginTop: 24,
  textAlign: 'center',
  [`@media ${bp.md}`]: {
    marginTop: 16,
  },
});

const MainText = styled('p')({
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '160%',
  color: palette.deepBlueDisable,
  [`@media ${bp.md}`]: {
    fontSize: 12,
  },
});

const SubText = styled('p')({
  fontSize: 12,
  marginTop: 4,
  lineHeight: '160%',
  color: palette.deepBlueDisable,
  [`@media ${bp.md}`]: {
    fontSize: 10,
    marginTop: 0,
  },
});

export default NoContent;
