import React, { FunctionComponent } from 'react';

import { CTAItem } from 'types/HearingLink';
import { SortEnd } from 'react-sortable-hoc';
import useDragCursor from 'hooks/useDragCursor';
import SortableCTAList from './SortableCTAList';

type Props = {
  ctaList: CTAItem[];
  isEditMode?: boolean;
  handleSortCTA?: (oldIndex: number, newIndex: number) => void;
  handleRemoveItem?: (index: number) => void;
  handleEditItem?: (index: number) => void;
  className?: string;
};

const CTAButtonList: FunctionComponent<Props> = ({
  ctaList,
  isEditMode,
  handleSortCTA,
  handleRemoveItem,
  handleEditItem,
  className,
}) => {
  const { dragging, handleDragStart, handleDragEnd } = useDragCursor();

  const handleStartSortCTA = () => {
    handleDragStart();
  };

  const handleEndSortCTA = (sort: SortEnd) => {
    const { oldIndex, newIndex } = sort;
    handleDragEnd();
    handleSortCTA && handleSortCTA(oldIndex, newIndex);
  };

  if (ctaList.length <= 0) return null;

  return (
    <SortableCTAList
      lockAxis="y"
      distance={30}
      onSortStart={handleStartSortCTA}
      onSortEnd={handleEndSortCTA}
      helperClass="being-dragged"
      isEditMode={isEditMode}
      ctaList={ctaList}
      handleClickArrow={(index: number) =>
        index > 0 && handleSortCTA && handleSortCTA(index, index - 1)
      }
      handleRemoveItem={(index: number) => handleRemoveItem && handleRemoveItem(index)}
      handleEditItem={(index: number) => handleEditItem && handleEditItem(index)}
      dragging={dragging}
      className={className}
    />
  );
};

export default CTAButtonList;
