import React, { FC, useState, useMemo } from 'react';
import styled from '@emotion/styled';

import {
  SVGIcon,
  DropdownMenuGlobal,
  DropdownItemGlobal,
  DropdownWrapper as _DropdownWrapper,
} from 'views/components/primitive';
import { palette } from 'entity/createTheme';
import DropdownUrl from 'assets/images/ic_dropdown.svg';
import { Organization } from 'types/App';
import { ModalSimple } from '..';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  organizations: Organization[];
  handleChangeOrganization: (id: string) => void;
};

const Organizations: FC<Props> = ({ value, organizations, handleChangeOrganization }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isSelect = useMemo(() => organizations.length >= 2, [organizations.length]);

  function toggleOptions() {
    if (isSelect) {
      setIsOpen(!isOpen);
    }
  }

  function getLabelActive() {
    const item = organizations.find(it => it.id === value);
    return item ? item.name : organizations[0].name;
  }

  function onChangeOrganization(id: string) {
    if (id !== value) {
      handleChangeOrganization(id);
      toggleOptions();
    }
  }

  if (!organizations.length) return null;

  return (
    <>
      {<ModalSimple isModal={isOpen ? true : false} className={'modalsimple'} />}
      <DropdownWrapper isOpen={isOpen} toggleOpenDropdown={toggleOptions} size="md">
        <OrganizationName>{getLabelActive()}</OrganizationName>
        {isSelect && <SVGIcon src={DropdownUrl} />}
        <DropdownMenuGlobal
          isOpen={isOpen}
          title={t('common.organization')}
          toggleOpenDropdown={toggleOptions}
          className="scroll-bar-thumbnail"
        >
          {organizations.map(org => (
            <DropdownItemGlobal
              key={org.id}
              handleClickItem={() => onChangeOrganization(org.id)}
              isSelected={org.id === value ? true : false}
            >
              {org.name}
            </DropdownItemGlobal>
          ))}
        </DropdownMenuGlobal>
      </DropdownWrapper>
    </>
  );
};

export default Organizations;

const OrganizationName = styled('div')({
  fontWeight: 'bold',
  marginRight: 5,
  fontSize: '14px',
});

const DropdownWrapper = styled(_DropdownWrapper)<{ isOpen: boolean }>(props => ({
  padding: 10,
  fontSize: '1rem',
  border: 'none',
  background: `${palette.whitePrimary} !important`,
  svg: {
    transform: props.isOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: 'transform 0.3s ease',
  },
}));
