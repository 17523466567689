import { observable, action, computed } from 'mobx';
import i18n from 'i18n';
import history from 'utility/history';
import HearingAdminApi from 'services/Admin/HearingAdminApi';
import ProductAdminApi from 'services/Admin/ProductAdminApi';
import { UploadProductContentMeta, ProductInfo, ProductItem } from 'types/Product';
import { UploadCompletedProps } from 'types/common';
import { HearingItem } from 'types/HearingSet';
import { RootStore } from '../index';
import {
  Chapter,
  ContentType,
  ContentCreateUpdateAPIBody,
  MessageProps,
  UploadImageMeta,
  UploadPDFMeta,
  UploadVideoMeta,
} from 'types/App';
import { dataURLtoFile, trimSpace, extractFileNameFromPath } from 'utility/helpers';
import { ListMeta } from 'types/common';
import { routes } from 'utility/constants';

import {
  defaultListMeta,
  defaultProductDetail,
  defaultImageMeta,
  defaultVideoMeta,
  defaultPDFMeta,
  defaultProductContentType,
  defaultChapter,
} from './constants';

class ProductAdminStore {
  // Product List
  private readonly productAdminApi: ProductAdminApi;
  private readonly hearingAdminApi: HearingAdminApi;
  @observable public targetVideo: { id: number; status: string } = { id: 0, status: '' };
  @observable public rootStore: RootStore;
  @observable public isLoading = false;
  @observable public errors = {};
  @observable public isSelectProduct = false;
  @observable public productList: ProductItem[] = [];
  @observable public productSelected: number[] = [];
  @observable public listMeta: ListMeta = { ...defaultListMeta };
  @observable public productInfo: ProductItem = { ...defaultProductDetail };
  @observable public newAddedProduct: ProductItem = { ...defaultProductDetail };
  @observable public productHearingSets: HearingItem[] = [];
  @observable public percentUploaded: UploadCompletedProps = { filename: '', completed: 0 };
  @observable public originalProductMeta: UploadProductContentMeta = { ...defaultVideoMeta };
  @observable public contentType: ContentType = defaultProductContentType;
  @observable public videoMeta: UploadVideoMeta = { ...defaultVideoMeta };
  @observable public imageMeta: UploadImageMeta = { ...defaultImageMeta };
  @observable public pdfMeta: UploadPDFMeta = { ...defaultPDFMeta };
  @observable public productMetaTouched = false;
  @observable public productInfoTouched = false;
  @observable public submitting = false;
  @observable public isAddContentDrawerOpen = false;
  @observable public chapterFormError = false;
  @observable public isEditContentDrawerOpen = false;

  constructor({
    rootStore,
    productAdminApi,
    hearingAdminApi,
  }: {
    rootStore: RootStore;
    productAdminApi: ProductAdminApi;
    hearingAdminApi: HearingAdminApi;
  }) {
    this.rootStore = rootStore;
    this.productAdminApi = productAdminApi;
    this.hearingAdminApi = hearingAdminApi;
  }

  @computed get emptyMetaFile() {
    return !this.fileMeta.file;
  }

  @computed get errorMetaFile() {
    switch (this.contentType) {
      case 'videos':
        return !!this.videoMeta.error;
      case 'pdfs':
        return !!this.pdfMeta.error;
      case 'images':
        return !!this.imageMeta.error;
      default:
        return false;
    }
  }

  @computed get disableUploadProduct() {
    return this.errorMetaFile || this.emptyMetaFile || this.percentUploaded.completed !== 0;
  }

  @computed get disableUpdateProduct() {
    if (this.originalProductMeta.service === 'ad' && this.productInfoTouched) return false;

    if (this.originalProductMeta.service === 'ad') return true;

    const touched = this.productMetaTouched || this.productInfoTouched;
    return !touched || this.errorMetaFile || this.percentUploaded.completed !== 0;
  }

  @computed get fileMeta() {
    switch (this.contentType) {
      case 'videos': {
        const {
          file,
          fileThumbnail: thumbnailFile,
          filename,
          firstFrameVideo,
          chapters,
        } = this.videoMeta;
        return { file, thumbnailFile, filename, firstFrameVideo, chapters };
      }
      case 'images': {
        const { file, filename } = this.imageMeta;
        return { file, filename };
      }
      case 'pdfs': {
        const { file, thumbnailFile, filename } = this.pdfMeta;
        return { file, thumbnailFile, filename };
      }
      default:
        return {};
    }
  }

  @computed get chapterTimes() {
    return this.videoMeta.chapters?.map(chapter => chapter.position / 1000);
  }

  @action.bound
  public changeTargetVideo = (id: number, status: string) => {
    this.targetVideo = { ...this.targetVideo, id, status };
  };

  // PUSH FLASH MESSAGES
  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectProduct() {
    if (this.isSelectProduct) {
      this.productSelected = [];
    }
    this.isSelectProduct = !this.isSelectProduct;
  }

  @action.bound
  public selectProductItem(id: number) {
    const productSelected = this.productSelected;
    if (productSelected.includes(id)) {
      this.productSelected = productSelected.filter(it => it !== id);
    } else {
      this.productSelected = [...productSelected, id];
    }
  }

  // GET LIST PRODUCT ADMIN
  @action.bound
  public async getAdminProductList(page = 1, sort = 'desc', order = 'updated_at') {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.productAdminApi.getAdminProductList({
        page,
        sort,
        order,
        organization_id,
      });
      this.productList = data.product_contents;
      this.listMeta = {
        ...this.listMeta,
        sort,
        order,
        page,
        total: data.total,
      };
    } catch (error: any) {
      this.errors = error;
      this.resetAdminProductList();
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetAdminProductList() {
    this.productList = [];
    this.listMeta = { ...defaultListMeta };
  }

  @action.bound
  public changeProductInfo(data: Partial<ProductInfo>) {
    this.productInfo = { ...this.productInfo, ...data };
    this.productInfoTouched = true;
  }

  @action.bound
  public resetProductContent() {
    this.originalProductMeta = { ...defaultVideoMeta };
    this.productInfo = { ...defaultProductDetail };
    this.productHearingSets = [];
    this.contentType = defaultProductContentType;
    this.productMetaTouched = false;
    this.productInfoTouched = false;
    this.errors = {};
    this.resetProductMeta();
  }

  @action.bound
  public injectProductFileMeta = async (productItem: ProductItem) => {
    switch (productItem.content_type) {
      case 'videos': {
        const meta = {
          ...this.videoMeta,
          firstFrameVideo: productItem.kaizen_files_thumbnail_url ?? '',
          service: productItem.service,
          chapters: productItem.chapters,
        };
        this.contentType = 'videos';
        this.handleChangeVideoMeta(meta);
        this.originalProductMeta = meta;
        break;
      }
      case 'pdfs': {
        const meta = {
          ...this.pdfMeta,
          thumbnailSrc: productItem.kaizen_files_thumbnail_url ?? '',
          filename: extractFileNameFromPath(productItem.kaizen_files_url) ?? '',
          service: productItem.service,
        };
        this.contentType = 'pdfs';
        this.handleChangePDFMeta(meta);
        this.originalProductMeta = meta;
        break;
      }
      case 'images': {
        const meta = {
          ...this.imageMeta,
          src: productItem.kaizen_files_url,
          filename: extractFileNameFromPath(productItem.kaizen_files_url) ?? '',
          service: productItem.service,
        };
        this.contentType = 'images';
        this.handleChangeImageMeta(meta);
        this.originalProductMeta = meta;
        break;
      }
      default:
    }
  };

  // GET PRODUCT DETAIL
  @action.bound
  public async getProductDetail(id: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.isLoading = true;
      const { data: product } = await this.productAdminApi.getProductById({ id, organization_id });

      this.productInfo = {
        ...this.productInfo,
        ...product,
        upload_status: product.upload_status ?? '',
        filename: extractFileNameFromPath(product.kaizen_files_url ?? ''),
      };

      this.getProductHearingSets(product.content_id, organization_id);

      this.injectProductFileMeta(product);
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.productContent.messages.notFoundProduct'),
        status: 'error',
      });
      history.push(routes.management.content);
    } finally {
      this.isLoading = false;
    }
  }

  // GET PRODUCT RELATED HEARING SETS
  @action.bound
  public async getProductHearingSets(content_id: number, organization_id: string) {
    try {
      const { data } = await this.hearingAdminApi.getAdminHearingList({
        content_id,
        organization_id,
      });

      this.productHearingSets = data.hearing_sets;
    } catch (error: any) {
      this.errors = error;
      this.pushFlashMessages({
        content: i18n.t('admin.productContent.messages.getHearingSetsError'),
        status: 'error',
      });
    }
  }

  // ARCHIVE PRODUCT
  @action.bound
  public async archiveProductContent(id?: number, isDetail?: boolean) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      // Call Api archive product
      const listId: number[] = id ? [id] : this.productSelected.slice();
      this.isLoading = true;
      await this.productAdminApi.archiveProduct({ listId, organization_id });
      this.productList = this.productList.filter(it => !listId.includes(it.id));
      // Clear data
      this.isSelectProduct = false;
      this.productSelected = [];
      if (!isDetail) {
        const { page, sort, order } = this.listMeta;
        await this.getAdminProductList(page, sort, order);
      } else {
        history.push({ pathname: routes.management.archive, search: '?panel=products' });
      }
      // ** //
      this.pushFlashMessages({
        content: i18n.t('admin.common.messages.archiveSuccess', {
          item: i18n.t('common.actionTarget.products'),
        }),
        status: 'success',
      });
    } catch (error: any) {
      this.errors = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetProductMeta() {
    this.videoMeta = { ...defaultVideoMeta };
    this.imageMeta = { ...defaultImageMeta };
    this.pdfMeta = { ...defaultPDFMeta };
    this.handleChangeContentMeta(this.originalProductMeta, false);
    this.chapterFormError = false;
  }

  @action.bound
  public handleChangeContentType(type: ContentType) {
    this.contentType = type;
    this.resetProductMeta();
  }

  @action.bound
  public handleChangeVideoMeta(meta: UploadVideoMeta) {
    this.videoMeta = { ...this.videoMeta, ...meta };
  }

  @action.bound
  public handleChangeImageMeta(meta: UploadImageMeta) {
    this.imageMeta = { ...this.imageMeta, ...meta };
  }

  @action.bound
  public handleChangePDFMeta(meta: UploadPDFMeta) {
    this.pdfMeta = { ...this.pdfMeta, ...meta };
  }

  @action.bound
  public handleChangeContentMeta(meta: UploadProductContentMeta, metaFileTouched = true) {
    switch (meta.type) {
      case 'videos':
        this.handleChangeVideoMeta(meta);
        break;
      case 'images':
        this.handleChangeImageMeta(meta);
        break;
      case 'pdfs':
        this.handleChangePDFMeta(meta);
        break;
      default:
        throw new Error('wrong content type');
    }
    this.productMetaTouched = metaFileTouched;
  }

  @action.bound
  public prepareProductContentMeta() {
    const data: ContentCreateUpdateAPIBody = {
      type: this.contentType,
      file: undefined,
      thumbnail_file: undefined,
      title: '',
      description: '',
      status: '',
      chapters: [],
    };
    switch (this.contentType) {
      case 'videos': {
        const { file, fileThumbnail } = this.videoMeta;
        const thumbnail =
          typeof fileThumbnail === 'string' ? dataURLtoFile(fileThumbnail) : fileThumbnail;
        if (file) data.file = file;
        if (fileThumbnail) data.thumbnail_file = thumbnail;
        break;
      }
      case 'pdfs': {
        const { file, thumbnailSrc } = this.pdfMeta;
        if (file) {
          const thumbnail = dataURLtoFile(thumbnailSrc);
          data.thumbnail_file = thumbnail;
          data.file = file;
        }
        break;
      }
      case 'images': {
        const { file } = this.imageMeta;
        if (file) data.file = file;
        break;
      }
      default:
    }
    return data;
  }

  @action.bound
  public toggleAddContentDrawer() {
    this.isAddContentDrawerOpen = !this.isAddContentDrawerOpen;
  }

  @action.bound
  public async addProductContent() {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const { title, description, status } = this.productInfo;
      const { filename } = this.fileMeta;

      const data = this.prepareProductContentMeta();
      data.title = trimSpace(title);
      data.description = trimSpace(description);
      data.status = status;

      const onUploadProgress = (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        this.percentUploaded = {
          filename: filename || '',
          completed: percent,
        };
      };

      const { data: newContent } = await this.productAdminApi.addProduct({
        data,
        onUploadProgress,
        organization_id,
      });

      this.newAddedProduct = { ...newContent };

      this.pushFlashMessages({
        content: i18n.t('admin.productContent.messages.createSuccess'),
        status: 'success',
      });
      this.percentUploaded = { filename: '', completed: 0 };
      this.isAddContentDrawerOpen = false;
      history.push(`${routes.management.productDetail}/${newContent.id}`);
    } catch (error: any) {
      this.errors = error.data;
      const messErrors = error.data?.errors.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.common.uploadFailed')}${messErrors}`,
        status: 'error',
      });
      this.percentUploaded = { filename: '', completed: 0 };
      this.isAddContentDrawerOpen = false;
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public async updateProductInfo(id: string, option: Partial<ProductInfo>) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const { title, description, status } = option;

      const payload = {
        ...(title && { title: trimSpace(title) }),
        description: trimSpace(description || ''),
        ...(status && { status }),
      };

      const { data } = await this.productAdminApi.updateProductInfo({
        id,
        data: payload,
        organization_id,
      });

      this.productInfo = {
        ...this.productInfo,
        title: data.title,
        description: data.description,
        status: data.status,
      };

      this.pushFlashMessages({
        content: i18n.t('admin.productContent.messages.updateProductSuccess'),
        status: 'success',
      });
    } catch (error: any) {
      if (!!option.status && error?.message.includes('422')) {
        this.pushFlashMessages({
          content: i18n.t('admin.common.messages.updateStatusFailed'),
          status: 'error',
        });
      } else {
        this.pushFlashMessages({
          content: i18n.t('admin.productContent.messages.updateProductFail'),
          status: 'error',
        });
      }
    } finally {
      this.submitting = false;
    }
  }

  @action.bound
  public toggleEditContentDrawer() {
    this.isEditContentDrawerOpen = !this.isEditContentDrawerOpen;
  }

  @action.bound
  public async updateProductContent(id: any) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      this.submitting = true;
      const data = this.prepareProductContentMeta();

      const { file, filename, thumbnailFile, chapters: chapterList } = this.fileMeta;

      if (this.contentType === 'videos') {
        const sortedChapters =
          chapterList
            ?.slice()
            .sort((a, b) => {
              return a.position - b.position;
            })
            .map(({ name, position }) => ({ name, position })) || [];
        if (sortedChapters.length === 0) {
          data.chapters = [];
        } else {
          for (let i = 0; i < sortedChapters.length; i++) {
            data.chapters.push({
              name: sortedChapters[i].name,
              position: sortedChapters[i].position,
            });
          }
        }
      }

      const onUploadProgress = (progressEvent: any) => {
        if (file) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.percentUploaded = {
            filename: filename ?? '',
            completed: percent,
          };
        }
      };

      const { data: updatedContent } = await this.productAdminApi.updateProduct({
        id,
        data,
        onUploadProgress,
        organization_id,
      });
      if (this.productInfo.id === updatedContent.id) {
        if (thumbnailFile || file) {
          this.productInfo = {
            ...this.productInfo,
            ...updatedContent,
          };
        } else {
          this.getProductDetail(id);
        }
        this.pushFlashMessages({
          content: i18n.t('admin.productContent.messages.updateProductSuccess'),
          status: 'success',
        });
      }
      if (file) {
        this.percentUploaded = { filename: '', completed: 0 };
      }
      this.isEditContentDrawerOpen = false;
    } catch (error: any) {
      this.errors = error;
      const INVALID_CHAPTERS_ERROR = 'Validation failed: Chapters is invalid';
      if (error.data?.errors.findIndex((error: string) => error === INVALID_CHAPTERS_ERROR) > -1) {
        this.pushFlashMessages({
          content: `${i18n.t('admin.productContent.messages.chapterTimeExceedMaxSize')}`,
          status: 'error',
        });
      } else {
        const messErrors = error.data?.errors.join('; ') ?? '';
        this.pushFlashMessages({
          content: `${i18n.t('admin.common.uploadFailed')}${messErrors}`,
          status: 'error',
        });
      }
      this.percentUploaded = { filename: '', completed: 0 };
    } finally {
      this.isLoading = false;
      this.submitting = false;
      this.productInfoTouched = false;
      this.productMetaTouched = false;
    }
  }

  // CHAPTER-RELATED FUNCTION
  @action.bound
  public addChapter = () => {
    this.videoMeta = {
      ...this.videoMeta,
      chapters: this.videoMeta.chapters
        ? [...this.videoMeta.chapters, defaultChapter]
        : [defaultChapter],
    };
    this.productMetaTouched = true;
    this.chapterFormError = false;
  };

  @action.bound
  public handleChangeChapterInfo = (chapterIndex: number, updateValue: Partial<Chapter>) => {
    if (!this.videoMeta.chapters || !this.videoMeta.chapters[chapterIndex]) return;
    this.videoMeta = {
      ...this.videoMeta,
      chapters: this.videoMeta.chapters.map((chapter, index) =>
        index === chapterIndex
          ? {
              ...chapter,
              ...updateValue,
            }
          : chapter
      ),
    };
    this.productMetaTouched = true;
  };

  @action.bound
  public deleteChapter = (chapterIndex: number) => {
    if (!this.videoMeta.chapters || !this.videoMeta.chapters[chapterIndex]) return;
    this.videoMeta = {
      ...this.videoMeta,
      chapters: [...this.videoMeta.chapters.filter((_, index) => index !== chapterIndex)],
    };
    this.productMetaTouched = true;
    this.chapterFormError = false;
  };

  @action.bound
  public handleChangeChapterFormError = (hasFormError: boolean) => {
    this.chapterFormError = hasFormError;
  };

  @action.bound
  public handleErrorProduct(error: Record<string, string>) {
    this.errors = { ...error };
  }

  @action.bound
  public resetNewAddedProduct() {
    this.newAddedProduct = { ...defaultProductDetail };
  }
}

export default ProductAdminStore;
