import styled from '@emotion/styled';
import { bp, palette } from 'entity/createTheme';
import React, { MouseEventHandler, MouseEvent, FC } from 'react';

interface PropTypes {
  type?: 'arrow_right' | 'arrow_up' | 'arrow_down';
  size?: 'md' | 'sm';
  className?: string;
  id?: string;
  ripple?: boolean;
  autifySelectorName?: string;
  onClick?: (MouseEventHandler<HTMLSpanElement> & ((arg0: MouseEvent) => void)) | undefined;
}

const CSSIcon: FC<PropTypes> = ({
  onClick,
  size,
  type,
  id,
  className,
  ripple,
  autifySelectorName,
}) => {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick(e);
  };

  return (
    <Span
      className={className}
      onClick={handleClick}
      id={id}
      variant={type}
      size={size}
      ripple={ripple}
      data-autify_selector={autifySelectorName}
    />
  );
};

export default CSSIcon;

const Span = styled('span')<PropTypes & { variant: PropTypes['type'] }>(props => ({
  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    border: 'none',
    color: `${palette.whitePrimary} !important`,
    background: `${palette.grayMedium1} !important`,
    cursor: 'not-allowed',
  },

  ...(props.variant?.indexOf('arrow_') === 0 && {
    borderTop: `2px solid ${palette.darkMedium2}`,
    borderRight: `2px solid ${palette.darkMedium2}`,
    height: '7px',
    width: '7px',
    position: 'absolute',
    transition: 'transform 0.3s ease',
  }),

  ...(props.variant === 'arrow_right' && {
    transform: `rotate(45deg)`,
  }),

  ...(props.variant === 'arrow_up' && {
    transform: `rotate(315deg)`,
    top: '1px',
  }),

  ...(props.variant === 'arrow_down' && {
    transform: `rotate(135deg)`,
    top: '-2px',
  }),

  ...(props.size === 'md' && {
    height: 40,
    fontSize: '0.875rem',
    padding: '0 24px',
    [`@media ${bp.md}`]: {
      padding: '0 16px',
    },
  }),

  ...(props.size === 'sm' && {
    height: 32,
    fontSize: '0.75rem',
    padding: '0 16px',
  }),

  ...(props.ripple && {
    position: 'relative',
  }),
}));
