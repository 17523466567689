import React, { Component, createRef } from 'react';
import { Player, PlayerOptions } from '@kaizenplatform/kaizen-video-player';
import styled from '@emotion/styled';

type State = Record<string, unknown>;

type Props = PlayerOptions &
  Required<Pick<PlayerOptions, 'videoId'>> & {
    onReady: () => void;
  };
class KaizenVideoPlayer extends Component<Props, State> {
  player: Player | null = null;
  readonly ref = createRef<HTMLDivElement>();

  state = {
    width: undefined,
    height: undefined,
  };

  componentDidMount(): void {
    if (!this.player && this.ref.current) {
      const container = this.ref.current;
      this.player = new Player(
        container,
        Object.assign(
          {
            videoType: 'shaka',
            plyrCss: `.plyr{height:100%;width:100%; --plyr-video-background:transparent} `,
          },
          this.props
        )
      );

      this.player.videoTag.onloadeddata = () => {
        if (this.player && this.player.videoTag) {
          this.setState({
            width: this.player?.videoTag.videoWidth,
            height: this.player?.videoTag.videoHeight,
          });
          if (this.props.onReady) {
            this.props.onReady();
          }
        }
      };
      this.player.start();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
  }

  render() {
    return (
      <Root
        ref={this.ref}
        className="kaizen-video-player"
        width={this.state.width}
        height={this.state.height}
      />
    );
  }
}
const Root = styled.div<{ width?: string; height?: string }>`
  margin: 0 auto;
  aspect-ratio: auto ${props => `${props.width} / ${props.height}`};
  height: 100%;
  max-width: 100%;
`;

export default KaizenVideoPlayer;
