import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import { getImgFromContent, getTotalQuestions } from 'utility/helpers';
import ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';

type Props = {
  interactiveHearing: HearingItem;
  className?: string;
};

const InteractiveHearingItem: FC<Props> = ({ interactiveHearing, className }) => {
  const { t } = useTranslation();

  const totalQuestions = useMemo(() => getTotalQuestions(interactiveHearing), [interactiveHearing]);
  if (!interactiveHearing.question_sets || !interactiveHearing.question_sets.length) return null;

  const thumbnail = getImgFromContent(interactiveHearing.question_sets[0].content);

  return (
    <ItemWithThumbnail
      className={className}
      src={thumbnail}
      title={interactiveHearing.title}
      metaTitle={t('hearingSet.hearingItem.numOfVideo', { count: totalQuestions })}
      size={96}
      aspectRatio={4 / 3}
    />
  );
};

export default InteractiveHearingItem;
