const bp = {
  xs: 'screen and (max-width: 540px)',
  sm: 'screen and (max-width: 768px)',
  md: 'screen and (max-width: 1024px)',
  xmd: 'screen and (max-width: 1366px)',
  lg: 'screen and (max-width: 1440px)',
  xsh: 'screen and (max-height: 650px)',
  smh: 'screen and (max-height: 700px)',
  mdh: 'screen and (max-height: 768px)',
  lgh: 'screen and (max-height: 800px)',
};

const palette = {
  whitePrimary: '#FFFFFF',
  blackPrimary: '#000000',
  greenPrimary: '#1DA772',
  greenSecondary: '#2DCC70',
  greenMedium1: '#2DD28D',
  greenMedium2: '#2ED28D',
  bluePrimary: '#5FA9FF',
  blueSecondary: '#3B8FF1',
  orangePrimary: '#FD925E',
  orangeSecondary: '#EE7D46',
  redPrimary: '#FE7877',
  redSecondary: '#FE6665',
  redMedium1: '#FCCDCD',
  deepBluePrimary: '#394151',
  deepBlueSecondary: '#687082',
  deepBlueLight: '#707889',
  deepBlueDisable: 'rgba(75, 85, 106, 0.24)',
  darkPrimary: '#383A42',
  darkSecondary: '#5C6986',
  darkMedium1: '#40414B',
  darkMedium2: '#4C566C',
  darkMedium3: '#262626',
  darkMedium6: '#656565',
  grayPrimary: '#D8D8D8',
  grayMedium1: '#EAEAEA',
  grayMedium2: '#CACACA',
  grayMedium4: '#989898',
  grayMedium5: '#F9F9F9',
  grayMedium6: '#F1F1F1',
  grayMedium7: '#ABABAB',
  grayMedium9: '#F5F5F5',
  grayMedium10: '#9B9B9B',
  grayMedium11: '#f2f4f5',
  grayMedium12: '#F5F6F7',
  redBlur: 'rgba(254,120,119,20%)',
  grayBlur: 'rgba(0, 0, 0, 0.06)',
  grayBlur2: 'rgba(0,0,0,0.1)',
  grayBlur3: 'rgba(0,0,0,0.2)',
  grayBlur4: 'rgba(0, 0, 0, 0.03)',
  boxShadow: 'rgba(0, 0, 0, 0.04)',
  headerMenu: '#F0F1F2',
  whiteBlur: '#F0F0F0',
  borderInput: '#E6E7E8',
  base: '#f5f6f7',
  placeholder: '#C9CACD',
  linkBlue: '#007AFF',
  linkBlueLight: '#0A95FF',
  chartPrimary: '#4C566C',
};

const sizes = {
  maxSize: '1440px',
  contentWidth: '1180px',
  headerHeightLg: '64px',
  headerHeightSm: '58px',
  headerHeightMd: '72px',
  sizebarWidth: '260px',
};

const layer = {
  progressBar: 160,
  modalContent: 150,
  modal2: 140,
  modal1: 130,
  header: 120,
  sidebar: 110,
  top: 100, // For modal, confirm modal
  topUnder1: 90, // For modal that has action involving confirm modal
  topUnder2: 80, // For sidebar, header
  // Add middle layers when neccessary
  bottomAbove2: 20, // For loading frame
  bottomAbove1: 10, // Equitvalent to z-index: 1. For button, dropdown button
  bottom: 0,
};

export { bp, palette, sizes, layer };
