import { HearingItem } from 'types/HearingSet';
import { trimSpace } from 'utility/helpers';
import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';

class HearingAdminApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getHearingById = async ({
    id,
    organization_id,
  }: {
    id: string;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<HearingItem>({
      method: 'GET',
      url: `admin/hearing_sets/${id}`,
      params: {
        organization_id,
      },
    });
    return res;
  };

  public addHearingSet = async ({
    title,
    status,
    organization_id,
  }: {
    title: string;
    status: string | undefined;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<HearingItem>({
      method: 'POST',
      url: 'admin/hearing_sets',
      data: {
        title: trimSpace(title),
        status,
        organization_id,
      },
    });
    return res;
  };

  public updateHearingSet = async ({
    id,
    title,
    status,
    question_set_ids,
    organization_id,
  }: {
    id: string;
    title?: string;
    status?: string;
    question_set_ids?: number[];
    organization_id: string;
  }) => {
    const res = await this.httpClient.request({
      method: 'PUT',
      url: `admin/hearing_sets/${id}`,
      data: {
        status,
        ...(!!title && { title: trimSpace(title) }),
        ...(question_set_ids && { question_set_ids }),
        organization_id,
      },
    });

    return res;
  };

  public getAdminHearingList = async ({
    page,
    sort,
    order,
    content_id,
    organization_id,
  }: Partial<ListRequestParamsMeta>) => {
    const res = await this.httpClient.request<{ hearing_sets: HearingItem[]; total?: number }>({
      method: 'GET',
      url: 'admin/hearing_sets',
      params: {
        page,
        sort,
        order,
        per_page: 8,
        content_id: content_id,
        organization_id,
      },
    });
    return res;
  };

  public changeHearingStatus = async ({
    status,
    confirm,
    idList,
    organization_id,
  }: {
    status?: string;
    confirm?: boolean;
    idList: number[];
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'POST',
      url: 'admin/hearing_sets/update_status',
      data: {
        ids: idList,
        status,
        confirm,
        organization_id,
      },
    });
  };
}

export default HearingAdminApi;
