import { PlaylistItem } from 'types/Playlist';
import PlaylistApi from 'services/Admin/PlaylistApi';
import { observable, action } from 'mobx';
import { RootStore } from '../index';
import ArchiveAdminApi from 'services/Admin/ArchiveAdminApi';
import HearingLinkApi from 'services/HearingLinkApi';
import { ProductItem } from 'types/Product';
import { HearingItem } from 'types/HearingSet';
import { ArchiveMeta, ArchivePanel } from 'types/Archive';
import { MessageProps } from 'types/App';
import { optionsArchive, TabOption } from 'utility/constants';
import { HearingLinkItem } from 'types/HearingLink';
import i18n from 'i18n';
class ArchiveStore {
  private readonly archiveAdminApi: ArchiveAdminApi;
  private readonly hearingLinkApi: HearingLinkApi;
  private readonly playlistApi: PlaylistApi;
  @observable public loading = false;
  @observable public errors = {};
  @observable public rootStore: RootStore;
  @observable public isSelectRestore = false;
  @observable public listHearingArchive: HearingItem[] = [];
  @observable public archivedProductList: ProductItem[] = [];
  @observable public listSelected: number[] = [];
  @observable public archiveMeta: ArchiveMeta = {
    panel: 'products',
    order: 'updated_at',
    sort: 'latest',
    pageHearing: 1,
    pageProduct: 1,
    pageLink: 1,
    pagePlaylist: 1,
  };
  @observable public optionsArchive: TabOption[] = [...optionsArchive];
  @observable public listLinkArchive: HearingLinkItem[] = [];
  @observable public archivedPlaylistList: PlaylistItem[] = [];

  constructor({
    rootStore,
    archiveAdminApi,
    hearingLinkApi,
    playlistApi,
  }: {
    rootStore: RootStore;
    archiveAdminApi: ArchiveAdminApi;
    hearingLinkApi: HearingLinkApi;
    playlistApi: PlaylistApi;
  }) {
    this.rootStore = rootStore;
    this.archiveAdminApi = archiveAdminApi;
    this.hearingLinkApi = hearingLinkApi;
    this.playlistApi = playlistApi;
  }

  @action.bound
  public pushFlashMessage(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public toggleSelectRestore() {
    if (this.isSelectRestore) {
      this.listSelected = [];
    }
    this.isSelectRestore = !this.isSelectRestore;
  }

  @action.bound
  public async getListArchive(
    panel: ArchivePanel = 'products',
    sort = 'desc',
    order = 'updated_at',
    page = 1
  ) {
    this.loading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    try {
      const [data1, data2, data3] = await Promise.all([
        this.archiveAdminApi.getArchiveList({ panel, sort, order, page, organization_id }),
        this.hearingLinkApi.getArchivedHearingLinks({
          page,
          sort,
          order: 'updated_at',
          per_page: 20,
          organization_id,
        }),
        this.playlistApi.getPlaylists({
          page,
          sort,
          order,
          per_page: 8,
          organization_id,
          status: 'archived',
        }),
      ]);
      const {
        product_contents,
        hearing_sets,
        total_product_contents,
        total_hearing_sets,
        hearing_sets_page,
        product_contents_page,
      } = data1.data;
      const { hearing_links, page: pageLink, total: totalLink } = data2.data;
      const { content_playlists, page: playlistPage, total: playlistTotal } = data3.data;
      this.archivedProductList = product_contents;
      this.listHearingArchive = hearing_sets;
      this.listLinkArchive = hearing_links;
      this.archivedPlaylistList = content_playlists;
      this.archiveMeta = {
        ...this.archiveMeta,
        panel,
        sort,
        order,
        pageHearing: hearing_sets_page,
        pageProduct: product_contents_page,
        pageLink: pageLink,
        pagePlaylist: playlistPage,
      };
      this.optionsArchive = [
        {
          ...this.optionsArchive[0],
          count: total_product_contents,
        },
        {
          ...this.optionsArchive[1],
          count: total_hearing_sets,
        },
        {
          ...this.optionsArchive[2],
          count: playlistTotal,
        },
        {
          ...this.optionsArchive[3],
          count: totalLink,
        },
      ];
    } catch (error: any) {
      this.errors = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public handleChangeSelected(listId: number[]) {
    this.listSelected = listId;
  }

  @action.bound
  public async handleRestoreData(id?: number) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const {
        panel,
        sort,
        order,
        pageHearing,
        pageProduct,
        pageLink,
        pagePlaylist,
      } = this.archiveMeta;
      const listId = id ? [id] : [...this.listSelected];
      this.loading = true;
      switch (panel) {
        case 'links':
          await this.hearingLinkApi.updateHearingLinkStatus({
            ids: listId,
            status: 'inactive',
            organization_id,
          });
          this.getListArchive(panel, sort, order, pageLink);
          break;
        case 'playlists':
          await this.playlistApi.updateMultiplePlaylistsStatus({
            ids: listId,
            status: 'draft',
            organization_id,
          });
          this.getListArchive(panel, sort, order, pagePlaylist);
          break;
        case 'products':
          await this.archiveAdminApi.restoreData({
            field: 'product_content_ids',
            listId,
            organization_id,
          });
          this.getListArchive(panel, sort, order, pageProduct);
          break;
        case 'hearings':
          await this.archiveAdminApi.restoreData({
            field: 'hearing_set_ids',
            listId,
            organization_id,
          });
          this.getListArchive(panel, sort, order, pageHearing);
          break;
        default:
          return;
      }

      this.pushFlashMessage({
        content: i18n.t('admin.common.messages.restoreSuccess', {
          item: i18n.t(`common.actionTarget.${panel}`),
        }),
        status: 'success',
      });
      this.isSelectRestore = false;
      this.listSelected = [];
    } catch (error: any) {
      this.errors = error;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public resetSelectMeta() {
    this.isSelectRestore = false;
    this.listSelected = [];
  }
}

export default ArchiveStore;
