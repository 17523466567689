import React, { FC, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import useStore from 'hooks/useStore';
import { palette } from 'entity/createTheme';

const useStyles = makeStyles({
  root: {
    maxWidth: '400px',
  },
  message: {
    color: palette.whitePrimary,
  },
  icon: {
    '& > svg': {
      fill: palette.whitePrimary,
    },
  },
});

const FlashMessages: FC = observer(() => {
  const classes = useStyles();
  const { appStore } = useStore();
  const [open, setOpen] = useState(true);
  const { content, status } = useMemo(() => appStore.flashMessages, [
    appStore.flashMessages.content,
  ]);

  React.useEffect(() => {
    if (content) setOpen(true);
  });

  function handleClose() {
    appStore.handleFlashMessage({ content: '', status: 'success' });
    setOpen(false);
  }

  if (!content) return null;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert classes={classes} variant="filled" severity={status}>
        {content}
      </Alert>
    </Snackbar>
  );
});

export default FlashMessages;
