import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AnswerItemWrapper } from './styles';
import { InputText, SVGIcon } from 'views/components/primitive';
import IconDelete from 'assets/images/ic_close.svg';

interface PropTypes {
  index: number;
  answer: string;
  feedback?: string;
  status: string;

  hasError: boolean;
  onChange: (name: string, index: number, val: string) => void;
  handleDeleteAnswerItem: (index: number) => void;
  canDeleteAnswer: boolean;
}

const AnswerItem: FC<PropTypes> = ({
  answer,
  feedback,
  index,
  onChange,
  status,

  hasError,
  handleDeleteAnswerItem,
  canDeleteAnswer,
}) => {
  const { t } = useTranslation();

  function handleChangeAnswer(val: string) {
    onChange('content', index, val);
  }

  function handleChangeFeedback(val: string) {
    onChange('feedback', index, val);
  }

  function deleteAnswerItem() {
    handleDeleteAnswerItem(index);
  }

  return (
    <AnswerItemWrapper>
      <div className="input-group">
        <span className={`status ${status}`} />
        <InputText
          className="input_answer"
          placeholder={t('admin.questions.placeholderAnswer', { index: index + 1 })}
          value={answer}
          onChange={handleChangeAnswer}
          error={hasError && !answer.trim() ? t('admin.questions.messages.answerBlank') : ''}
          isCustom
          maxLength={40}
        />
        {canDeleteAnswer && <SVGIcon onClick={deleteAnswerItem} src={IconDelete} />}
      </div>
      {typeof feedback !== 'undefined' && (
        <InputText
          className="input_feedback"
          placeholder={t('admin.questions.placeholderFeedback')}
          value={feedback || ''}
          onChange={handleChangeFeedback}
          isCustom
          maxLength={120}
        />
      )}
    </AnswerItemWrapper>
  );
};

export default AnswerItem;
