import styled from '@emotion/styled';
import React, { FC, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { palette } from 'entity/createTheme';
import useStore from 'hooks/useStore';
import { UploadVideoMeta } from 'types/App';
import UploadMeta from './UploadMeta';
import { ContentViewType } from 'entity/Content';

interface PropTypes {
  originalContent: ContentViewType;
  meta: UploadVideoMeta;
  handleChangeMeta: (data: UploadVideoMeta) => void;
  status: string;
  handleChangeUploadFile: (file: any) => void;
  editMode: boolean;
}

const UploadVideo: FC<PropTypes> = ({
  meta,
  handleChangeMeta,
  status,
  handleChangeUploadFile,
  originalContent,
  editMode,
}) => {
  const { videoSrcConvert, fileThumbnail, firstFrameVideo, file, error } = useMemo(() => meta, [
    meta,
  ]);
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeSrc, setActiveSrc] = useState('');

  const {
    appStore: { handleFlashMessage },
  } = useStore();

  function setActiveThumbnail(data: string) {
    setActiveSrc(data);
  }

  function handlePrintImage() {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (ctx && video.videoHeight && video.videoWidth) {
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const firstFrameVideo = canvas.toDataURL();
        const fileThumbnail = canvas.toDataURL('image/png');
        handleChangeMeta({
          ...meta,
          firstFrameVideo,
          fileThumbnail,
          error: '',
        });
        setActiveSrc(firstFrameVideo);
        video.pause();
      }
    }
  }

  function handlePlayVideo() {
    const video = videoRef.current;
    if (video && video.src) {
      video.play();
    }
  }

  function handleError() {
    if (file) {
      handleChangeMeta({
        ...meta,
        file: '',
        fileThumbnail: '',
        videoSrcConvert: '',
        filename: '',
        firstFrameVideo: '',
        error: t('admin.common.notFileVideo', { filename: file.name }),
      });
    }
  }

  return (
    <UploadVideoWrapper>
      <canvas ref={canvasRef} id="img-canvas" />
      <video
        onLoadedMetadata={handlePlayVideo}
        onTimeUpdate={handlePrintImage}
        ref={videoRef}
        controls
        src={videoSrcConvert || undefined}
        muted
        onError={handleError}
      />
      <UploadMeta
        meta={meta}
        handleChangeMeta={handleChangeMeta}
        firstFrameVideo={firstFrameVideo}
        setActiveThumbnail={setActiveThumbnail}
        activeSrc={activeSrc}
        status={status}
        handleFlashMessage={handleFlashMessage}
        handleChangeUploadFile={handleChangeUploadFile}
        originalContent={originalContent}
        editMode={editMode}
      />
      <CautionWrapper>
        <p>{t('admin.questions.messages.updateContentCaution1')}</p>
        <p>{t('admin.questions.messages.updateContentCaution2')}</p>
      </CautionWrapper>
      {!fileThumbnail && error && <Error className="error">{!fileThumbnail && error}</Error>}
    </UploadVideoWrapper>
  );
};

const UploadVideoWrapper = styled('div')({
  'video,canvas': {
    display: 'none',
  },
});

const Error = styled('p')({
  color: palette.redPrimary,
  marginTop: 20,
  fontSize: 12,
});

const CautionWrapper = styled('div')({
  marginTop: 20,
  p: {
    fontSize: '0.75rem',
    color: palette.grayMedium4,
    lineHeight: 1.5,
  },
});

export default observer(UploadVideo);
