import { EmbedInfo, EmbeddingLink, InteractiveHearingSetResult } from 'types/EmbeddingLink';
import { ListRequestParamsMeta } from 'types/common';
import HttpClient from 'adapter/HTTPClient';
import { EmbeddingLinkDetail, UpdateEmbeddingLinkData } from 'types/EmbeddingLink';

type EmbeddingLinkListResponse = {
  page: number;
  total: number;
  embedding_links: EmbeddingLink[];
};

class EmbeddingLinkApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getEmbeddingLinkById = async (id: number, organization_id: string) => {
    const res = await this.httpClient.request<EmbeddingLinkDetail>({
      method: 'GET',
      url: `sharing_links/embeddings/${id}`,
      params: {
        organization_id,
      },
    });
    return res;
  };

  public getEmbeddingLinks = async ({
    page,
    order,
    sort,
    per_page,
    organization_id,
  }: ListRequestParamsMeta) => {
    const res = this.httpClient.request<EmbeddingLinkListResponse>({
      method: 'GET',
      url: 'sharing_links/embeddings',
      params: {
        page,
        per_page,
        order,
        sort,
        organization_id,
      },
    });

    return res;
  };

  public createEmbeddingLink = async (data: EmbedInfo, organization_id: string) => {
    const res = await this.httpClient.request<EmbeddingLink>({
      method: 'POST',
      url: 'sharing_links/embeddings',
      data: {
        ...data,
        organization_id,
      },
    });

    return res;
  };

  public updateEmbeddingLink = async ({
    id,
    data,
    organization_id,
  }: {
    id: number;
    data: UpdateEmbeddingLinkData;
    organization_id: string;
  }) => {
    const res = this.httpClient.request<EmbeddingLinkDetail>({
      method: 'PUT',
      url: `sharing_links/embeddings/${id}`,
      data: {
        ...data,
        organization_id,
      },
    });
    return res;
  };

  public getInteractiveHearingSetResult = async (id: number, organization_id: string) => {
    const res = await this.httpClient.request<InteractiveHearingSetResult>({
      method: 'GET',
      url: `sharing_links/embeddings/${id}/results`,
      params: {
        organization_id,
      },
    });

    return res;
  };
}

export default EmbeddingLinkApi;
