import HttpClient from 'adapter/HTTPClient';
class AuthenticationApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public logout = async () => {
    await this.httpClient.request({
      method: 'DELETE',
      url: '/authentication_token',
    });
  };
}

export default AuthenticationApi;
