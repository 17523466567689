import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useStore from 'hooks/useStore';
import { Modal } from 'views/components/compound';

import { WrapperMemoModal } from './styles';
import { InputText, Button, Textarea } from 'views/components/primitive';

type Props = {
  handleUnload?: any;
};

const CreateMemoModal: FC<Props> = ({ handleUnload }) => {
  const {
    hearingPlayStore: {
      isMemoModal,
      toggleMemoModal,
      hearingMemo,
      onChangeHearingMemo,
      handleEndHearing,
    },
    appStore: { sfaIntegrated },
  } = useStore();
  const { t } = useTranslation();
  const { company_name, company_pic_name, memo } = hearingMemo;

  function handleChange(value: string) {
    onChangeHearingMemo('memo', value);
  }

  function handleSaveHearing(): void {
    window.removeEventListener('beforeunload', handleUnload);
    handleEndHearing();
  }

  return (
    <Modal
      className="create_memo-modal"
      title={t('hearingPlay.result.createMemo')}
      handleToggle={toggleMemoModal}
      isModal={isMemoModal}
    >
      <WrapperMemoModal>
        <div className="frm-item">
          {sfaIntegrated ? (
            <div className="disabled-input">
              <InputText
                value={t('hearingPlay.result.registerAfterMeeting')}
                onChange={_ => {}}
                label={t('hearingPlay.result.companyLabel')}
                maxLength={255}
                isShowDetail
              />
              <p className="notice-text">{t('hearingPlay.result.registerAfterMeetingNotice')}</p>
            </div>
          ) : (
            <InputText
              value={company_name || ''}
              name="companyName"
              onChange={value => onChangeHearingMemo('company_name', value)}
              label={t('hearingPlay.result.companyLabel')}
              placeholder={t('hearingPlay.result.namePlaceholder')}
              maxLength={255}
            />
          )}
        </div>
        <div className="frm-item">
          <InputText
            name="company_pic_name"
            value={company_pic_name}
            onChange={value => onChangeHearingMemo('company_pic_name', value)}
            label={t('hearingPlay.result.customerNameLabel')}
            placeholder={t('hearingPlay.result.namePlaceholder')}
            maxLength={40}
          />
        </div>
        <div className="frm-item">
          <Textarea
            label={t('hearingPlay.result.memoLabel')}
            value={memo}
            onChange={handleChange}
            placeholder={t('hearingPlay.result.memoPlaceholder')}
            maxLength={2000}
          />
        </div>
        <div className="frm-item">
          <Button
            className="btn_end-hearings"
            size="md"
            type="primary"
            label={t('hearingPlay.result.buttonSave')}
            onClick={handleSaveHearing}
          />
        </div>
      </WrapperMemoModal>
    </Modal>
  );
};

export default observer(CreateMemoModal);
