import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'views/components/primitive';
import { Drawer, InstanceTypeCard, ConfirmModal } from 'views/components/compound';
import { questionnaireModes, inDevelopingEmbedType } from './constants';
import styled from '@emotion/styled';
import { bp } from 'entity/createTheme';
import { trimSpace } from 'utility/helpers';

type Props = {
  open: boolean;
  toggleOpen: () => void;
  originalTitle?: string;
  selectedType?: string;
  handleSaveInfo: (title: string) => void;
  addNew?: boolean;
};

const QuestionnaireInfoDrawer: FC<Props> = ({
  open,
  toggleOpen,
  originalTitle = '',
  selectedType = 'content_questionnaire',
  handleSaveInfo,
  addNew,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(originalTitle);
  const [error, setError] = useState('');
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const toggleCancelModal = () => {
    setCancelModalOpen(!isCancelModalOpen);
  };

  useEffect(() => setTitle(originalTitle), []);

  const changeTitle = (val: string) => {
    setTitle(val);
    !!error && setError('');
    setHasChanges(true);
  };

  const saveInfo = () => {
    if (trimSpace(title) === '') {
      setError(t('common.requiredInputError', { target: t('common.title') }));
      return;
    }
    handleSaveInfo(title);
    setHasChanges(false);
    toggleOpen();
  };

  const handleCancel = () => {
    toggleOpen();
    setTitle(originalTitle);
    setHasChanges(false);
    !!error && setError('');
  };

  return (
    <>
      <Drawer
        disabledAction={!hasChanges}
        isOpen={open}
        onClose={hasChanges ? toggleCancelModal : handleCancel}
        title={t('common.description')}
        onSave={saveInfo}
        saveButtonLabel={addNew ? t('common.createNew') : t('common.save2')}
      >
        <InputText
          onChange={changeTitle}
          value={title}
          isRequired
          maxLength={80}
          error={error}
          label={t('common.title')}
        />
        <SectionTitle>{t('common.format')}</SectionTitle>
        <CardsWrapper>
          {questionnaireModes.map(mode => (
            <InstanceTypeCard
              key={mode.id}
              illustrationSrc={mode.illustrationSrc}
              typeName={t(`${mode.typeName}.label` as const)}
              inDeveloping={inDevelopingEmbedType.includes(mode.id)}
              selected={mode.id === selectedType}
              tooltipText={t(`${mode.typeName}.tooltip` as const)}
            />
          ))}
        </CardsWrapper>
      </Drawer>
      <ConfirmModal
        isModal={isCancelModalOpen}
        toggleModal={toggleCancelModal}
        handleConfirmModal={handleCancel}
      />
    </>
  );
};

export default QuestionnaireInfoDrawer;

const CardsWrapper = styled('div')({
  width: '100%',
  display: 'grid',
  gridGap: 24,
  gridTemplateColumns: '1fr 1fr',
  [`@media ${bp.xs}`]: {
    gridTemplateColumns: '1fr',
  },
  marginBottom: 24,
});

const SectionTitle = styled('p')({
  fontSize: '0.75rem',
  marginBottom: 8,
  lineHeight: '160%',
  margin: '24px 0 12px 0',
});
