import { CTAItem, HearingLink } from 'types/HearingLink';
import { ViewSettingOption, LinkCustomFields } from 'types/HearingLink';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { urlValidator } from 'utility/urlValidator';
import { defaultFormSetting } from 'views/components/compound/SharableLinkViewSetting/constants';
import { defaultCTAItem } from 'stores/HearingLinkStore/constants';
import { trimSpace } from 'utility/helpers';
import arrayMove from 'array-move';

type AfterViewSetting = {
  custom_fields: LinkCustomFields;
  needs_feedback?: boolean;
  autoRedirect?: boolean;
  disableAutoRedirectOption?: boolean;
};

export type CTASetting = {
  ctaList: CTAItem[];
  currentIndex: number;
  isAddMode?: boolean;
};

export const useViewSetting = (
  showCustomForm: boolean,
  linkDetail: HearingLink,
  saveViewSetting: (id: string, viewSetting: ViewSettingOption) => Promise<void>
) => {
  const { id: linkId } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { custom_fields, needs_feedback, cta } = useMemo(() => linkDetail, [linkDetail]);

  const originalAfterViewSetting = {
    custom_fields,
    needs_feedback,
    autoRedirect: cta?.length === 1 && !!cta[0]?.auto_redirect,
    disableAutoRedirectOption: cta?.length !== 1,
  };

  const originalCTASetting = {
    ctaList: cta || [],
    currentIndex: -1,
    isAddMode: false,
  };

  const [showForm, setShowForm] = useState(showCustomForm);
  const [customForms, setCustomForms] = useState<LinkCustomFields>(custom_fields);
  const [afterViewSetting, setAfterViewSetting] = useState<AfterViewSetting>(
    originalAfterViewSetting
  );
  const [hasSettingChanges, setHasSettingChanges] = useState(false);
  const [formSettingError, setFormSettingError] = useState<string>('');
  const [ctaSetting, setCTASetting] = useState<CTASetting>(originalCTASetting);

  useEffect(() => {
    resetViewSetting();
  }, [linkDetail]);

  useEffect(() => {
    const filteredCTAList =
      ctaSetting.ctaList.length > 0
        ? ctaSetting.ctaList.filter(ctaItem => !!trimSpace(ctaItem.link_to))
        : [];
    if (filteredCTAList.length !== 1) {
      changeAfterViewSetting({
        autoRedirect: false,
        disableAutoRedirectOption: true,
      });
    } else {
      changeAfterViewSetting({
        disableAutoRedirectOption: false,
      });
    }
  }, [ctaSetting.ctaList]);

  const resetViewSetting = () => {
    setShowForm(showCustomForm);
    setCustomForms(custom_fields);
    setAfterViewSetting(originalAfterViewSetting);
    resetCTAList();
  };

  const resetCTAList = () => {
    setCTASetting(originalCTASetting);
  };

  const toggleShowCustomForm = () => {
    setFormSettingError('');
    setShowForm(!showForm);
    setHasSettingChanges(true);
  };

  const onChangeForm = (key: keyof LinkCustomFields) => {
    setCustomForms({ ...customForms, [key]: !customForms[key] });
    setHasSettingChanges(true);
    setFormSettingError('');
  };

  const changeAfterViewSetting = (
    setting: Partial<AfterViewSetting>,
    enableSaveChange?: boolean
  ) => {
    setAfterViewSetting({ ...afterViewSetting, ...setting });
    enableSaveChange && setHasSettingChanges(true);
  };

  const toggleAfterViewSetting = (key: keyof AfterViewSetting) => {
    changeAfterViewSetting({ [key]: !afterViewSetting[key] }, true);
  };

  const handleChangeCTASetting = (setting: Partial<CTASetting>, enableSaveChange?: boolean) => {
    setCTASetting({ ...ctaSetting, ...setting });
    enableSaveChange && setHasSettingChanges(true);
  };

  const removeCTAItem = (index: number) => {
    handleChangeCTASetting(
      {
        ctaList: [...ctaSetting.ctaList.slice(0, index), ...ctaSetting.ctaList.slice(index + 1)],
        isAddMode: false,
      },
      true
    );
  };

  const addCTAItem = () => {
    handleChangeCTASetting({
      ctaList: [...ctaSetting.ctaList, { ...defaultCTAItem }],
      currentIndex: ctaSetting.ctaList.length,
      isAddMode: true,
    });
  };

  const cancelAddCTA = () => {
    if (ctaSetting.isAddMode) {
      handleChangeCTASetting({
        ctaList: [...ctaSetting.ctaList.slice(0, ctaSetting.ctaList.length - 1)],
        currentIndex: -1,
        isAddMode: false,
      });
    }
  };

  const handleSortCTA = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const newCTAList = arrayMove([...ctaSetting.ctaList], oldIndex, newIndex);
      handleChangeCTASetting(
        {
          ctaList: newCTAList,
        },
        true
      );
    }
  };

  const validateViewSetting = () => {
    const { company_name, company_pic_name, email, phone_number } = customForms;
    //remove blank cta from cta list
    const ctaList = ctaSetting.ctaList
      .filter(cta => !!cta.link_to)
      .map(cta => ({
        ...cta,
        auto_redirect: ctaSetting.ctaList.length === 1 && !!afterViewSetting.autoRedirect,
      }));
    const viewSetting: ViewSettingOption = {
      custom_fields: showForm ? customForms : defaultFormSetting,
      needs_feedback: afterViewSetting.needs_feedback,
      cta: ctaList,
    };
    const isValidForm = !showForm || company_name || company_pic_name || email || phone_number;
    !isValidForm && setFormSettingError(t('sharingLink.viewSetting.noCustomFieldSelected'));
    return { isValidForm, viewSetting };
  };

  const handleSaveViewSetting = (viewSetting: ViewSettingOption) => {
    saveViewSetting(linkId, viewSetting);
    setHasSettingChanges(false);
  };
  return {
    ctaSetting,
    handleChangeCTASetting,
    hasSettingChanges,
    validateViewSetting,
    resetViewSetting,
    showForm,
    toggleShowCustomForm,
    formSettingError,
    setFormSettingError,
    customForms,
    onChangeForm,
    afterViewSetting,
    toggleAfterViewSetting,
    setHasSettingChanges,
    handleSaveViewSetting,
    removeCTAItem,
    addCTAItem,
    handleSortCTA,
    resetCTAList,
    cancelAddCTA,
  };
};

export const useSettingCTAItem = (
  ctaSetting: CTASetting,
  handleChangeCTASetting: (setting: Partial<CTASetting>, enableSaveChange?: boolean) => void
) => {
  const { t } = useTranslation();
  const originalCTAItem = { ...ctaSetting.ctaList[ctaSetting.currentIndex] } || {
    ...defaultCTAItem,
  };
  const [ctaItem, setCTAItem] = useState<CTAItem>(originalCTAItem);
  const [hasChanges, setHasChanges] = useState(false);
  const [ctaSettingError, setCTASettingError] = useState('');

  useEffect(() => {
    setCTAItem(
      { ...ctaSetting.ctaList[ctaSetting.currentIndex] } || {
        ...defaultCTAItem,
      }
    );
  }, [ctaSetting.ctaList, ctaSetting.currentIndex]);

  const handleChangeCTAItem = (changedInfo: Partial<CTAItem>) => {
    setCTAItem({ ...ctaItem, ...changedInfo });
    setCTASettingError('');
    setHasChanges(true);
  };

  const resetCTAItemSetting = () => {
    setCTAItem(originalCTAItem);
    setCTASettingError('');
    setHasChanges(false);
  };

  const validateCTAItem = () => {
    const { link_to: ctaLink } = ctaItem;
    const hasURL = !!trimSpace(ctaLink || '');
    const isValidURL = hasURL && urlValidator(ctaLink);
    if (!hasURL) {
      setCTASettingError(t('sharingLink.viewSetting.ctaSetting.requireUrl'));
      return false;
    } else if (!isValidURL) {
      setCTASettingError(t('sharingLink.viewSetting.ctaSetting.invalidUrl'));
      return false;
    }
    return true;
  };

  const saveCTAItemChange = () => {
    const newCTAList: CTAItem[] = [
      ...ctaSetting.ctaList.slice(0, ctaSetting.currentIndex),
      ctaItem,
      ...ctaSetting.ctaList.slice(ctaSetting.currentIndex + 1),
    ];
    handleChangeCTASetting({ ctaList: newCTAList }, true);
    return;
  };

  return {
    ctaItem,
    hasChanges,
    ctaSettingError,
    handleChangeCTAItem,
    resetCTAItemSetting,
    saveCTAItemChange,
    validateCTAItem,
  };
};
