import styled from '@emotion/styled';
import { Grid, Paper } from '@material-ui/core';
import { palette } from 'entity/createTheme';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../primitive';

type Props = {
  menuName: string;
  description?: JSX.Element;
  buttonText: string;
  settingPageRoute?: string;
  onClick?: () => void;
  secondaryButton?: {
    buttonText: string;
    onClick: () => void;
  };
};

const SettingMenuItem: FC<Props> = ({
  menuName,
  description,
  buttonText,
  settingPageRoute,
  onClick,
  secondaryButton,
}) => {
  const history = useHistory();

  const goToSettingDetail = () => {
    if (settingPageRoute) history.push(settingPageRoute);
    if (onClick) onClick();
  };

  return (
    <Item>
      <Grid container alignItems="center" spacing={4}>
        <Grid item xs={12} md={7}>
          <Grid container>
            <Grid item xs={4}>
              <MenuName>{menuName}</MenuName>
            </Grid>
            {description ? (
              <Grid item xs={8}>
                {description}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <ActionButtons>
            <Button label={buttonText} onClick={goToSettingDetail} type="default" size="sm" />
            {secondaryButton ? (
              <Button
                label={secondaryButton.buttonText}
                onClick={secondaryButton.onClick}
                type="default"
                size="sm"
              />
            ) : null}
          </ActionButtons>
        </Grid>
      </Grid>
    </Item>
  );
};

export default SettingMenuItem;

const MenuName = styled('p')({
  color: palette.darkMedium2,
  fontWeight: 'bold',
  fontSize: 14,
  paddingLeft: 24,
});

const Item = styled(Paper)({
  boxShadow: `${palette.boxShadow} 0px 1px 9px`,
});

const ActionButtons = styled('div')({
  display: 'flex',
  paddingLeft: 24,
  'button + button': {
    marginLeft: 10,
  },
});
