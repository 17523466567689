import pdfjs from 'pdfjs-dist/build/pdf';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import pdfCMapOptions from './pdfCMapOptions';

interface Option {
  scale?: number;
  width: number;
  height: number;
  image?: 'jpeg' | 'webp' | 'png';
}

class Pdf2Image {
  pdfDoc: PDFDocumentProxy;

  constructor(pdfDoc: PDFDocumentProxy) {
    this.pdfDoc = pdfDoc;
  }

  static async open(pdfUrl: string) {
    const pdfDoc = await pdfjs.getDocument({ url: pdfUrl, ...pdfCMapOptions }).promise;
    return new Pdf2Image(pdfDoc);
  }

  static calcScale(page: PDFPageProxy, option: Option) {
    if (option.scale !== undefined) {
      return option.scale;
    }
    if (option.width === undefined || option.height === undefined) {
      return 1.0;
    }
    const viewport = page.getViewport({ scale: 1.0 });
    return Math.min(option.width / viewport.width, option.height / viewport.height);
  }

  numPages() {
    return this.pdfDoc.numPages;
  }

  // iOS Safari does not GC canvas with height / width, so set them as 0 to allocate memory
  cleanUp(canvas: HTMLCanvasElement) {
    canvas.height = 0;
    canvas.width = 0;
  }

  // return a image data url
  async getImageDataUrl(pageNo: number, option: Option) {
    const page = await this.pdfDoc.getPage(pageNo);
    const scale = Pdf2Image.calcScale(page, option);
    const viewport = page.getViewport({ scale });
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    if (canvasContext) {
      const renderContext = {
        canvasContext,
        viewport,
      };

      await page.render(renderContext).promise;
      switch (option.image) {
        case 'jpeg':
          return canvas.toDataURL('image/jpeg');
        case 'webp':
          return canvas.toDataURL('image/webp');
        default:
          return canvas.toDataURL();
      }
    }

    this.cleanUp(canvas);
  }

  // return array of image data url
  async getAllImageDataUrl(option: Option) {
    const pages = [];
    const numPages = this.numPages();
    for (let i = 1; i <= numPages; i += 1) {
      const img = await this.getImageDataUrl(i, option);
      pages.push(img);
    }
    return pages;
  }
}

export default Pdf2Image;
