import React, { FC, ChangeEvent, useRef, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import { acceptThumbnail, acceptVideoImageOrPDF } from 'utility/constants';

type Props = {
  className?: string;
  handleChange: (file: any) => void;
  acceptType: 'content' | 'thumbnail';
  toggleModal?: () => void;
  isInputOpen?: boolean;
  setIsInputOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmModal?: boolean;
  isHidden?: boolean;
};

const ReplaceFileButtonWithConfirm: FC<Props> = ({
  className,
  handleChange,
  acceptType,
  toggleModal,
  isInputOpen,
  setIsInputOpen,
  showConfirmModal,
  isHidden,
}) => {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const accept =
    acceptType === 'content' ? acceptVideoImageOrPDF.join(', ') : acceptThumbnail.join(', ');

  const buttonLabel =
    acceptType === 'content'
      ? t('admin.common.uploadFileButtonLabel')
      : t('admin.common.uploadThumbnailButtonLabel');

  //Use useLayoutEffect instead of useEffect to enable open input file window in Safari
  useLayoutEffect(() => {
    isInputOpen && inputFile.current && inputFile.current.click();
    setIsInputOpen && setIsInputOpen(false);
  }, [isInputOpen]);

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files && e.target.files[0]) {
      handleChange(e.target.files[0]);
    }
    setIsInputOpen && setIsInputOpen(false);
  };

  const handleOpenInput = () => {
    inputFile.current && inputFile.current.click();
  };

  //Make sure onChange funtion is fired event when selecting the same file
  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <>
      <ReplaceButton
        className={`${className} ${isHidden ? 'hidden' : ''}`}
        onClick={showConfirmModal ? toggleModal : handleOpenInput}
      >
        {buttonLabel}
      </ReplaceButton>
      <Input
        accept={accept}
        onChange={handleChangeFile}
        onClick={onClick}
        type="file"
        ref={inputFile}
      />
    </>
  );
};

export default ReplaceFileButtonWithConfirm;

const Input = styled('input')({
  display: 'none',
});

const ReplaceButton = styled('div')({
  width: '100%',
  fontSize: 12,
  fontWeight: 800,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  '&.hidden': {
    display: 'none',
  },
  '&:hover': {
    color: palette.deepBlueLight,
  },
});
