import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HearingItem } from 'types/HearingSet';
import { getImgFromContent, getTotalQuestions } from 'utility/helpers';
import ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';

type Props = {
  hearing: HearingItem;
  className?: string;
  width?: number;
  aspectRatio?: number;
};

const HearingItemForDisplay: FC<Props> = ({ hearing, className, width, aspectRatio }) => {
  const { t } = useTranslation();

  const totalQuestions = useMemo(() => getTotalQuestions(hearing), [hearing]);
  if (!hearing.question_sets || !hearing.question_sets.length) return null;

  const thumbnail = getImgFromContent(hearing.question_sets[0].content);

  return (
    <ItemWithThumbnail
      className={className}
      src={thumbnail}
      title={hearing.title}
      metaTitle={t('hearingSet.hearingItem.numOfVideo', { count: totalQuestions })}
      size={width}
      aspectRatio={aspectRatio}
    />
  );
};

export default HearingItemForDisplay;
