import React, { FC, useEffect } from 'react';
import { usePushLocationSearch, useLocationSearch } from 'hooks';
import ReactPaginate from 'react-paginate';

import { SVGIcon } from 'views/components/primitive';
import PrevIconURL from 'assets/images/ic_previous_arrow.svg';
import NextIconURL from 'assets/images/ic_next_arrow.svg';

import { BaseListRequestParams } from 'types/common';
import { style } from './styles';

interface PropTypes {
  total?: number;
  currentPage: number;
  perPage: number;
  handlePaginate?: (params: Partial<BaseListRequestParams>) => void;
  modalRef?: React.RefObject<HTMLDivElement>;
}

const isFalsyPage = (page: number) => isNaN(page) || page <= 0;

const Pagination: FC<PropTypes> = ({ total, perPage, currentPage, handlePaginate, modalRef }) => {
  const push = usePushLocationSearch();
  const { page } = useLocationSearch();
  const pageCount = total ? Math.ceil(total / perPage) : undefined;
  const PrevPageButton = <SVGIcon src={PrevIconURL} />;
  const NextPageButton = <SVGIcon src={NextIconURL} />;

  useEffect(() => {
    const parsedPage = Number(page);
    if (page === undefined || pageCount === undefined || isFalsyPage(parsedPage)) return;

    if (parsedPage > pageCount) {
      push({ page: '1' });
      handlePaginate && handlePaginate({ page: 1 });
      return;
    }
  }, [pageCount, page]);

  function handlePageChange(selectedItem: { selected: number }): void {
    const page = selectedItem.selected + 1;
    push({ page });
    handlePaginate && handlePaginate({ page });
    if (modalRef?.current) {
      modalRef?.current.scrollTo(0, 0);
    }
  }

  if (total === undefined || pageCount === undefined || total <= perPage) {
    return null;
  }

  const forcePage = isFalsyPage(currentPage) ? 0 : currentPage - 1;

  return (
    <div css={style} className="pagination">
      <ReactPaginate
        pageCount={pageCount}
        nextLabel={NextPageButton}
        forcePage={forcePage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        previousLabel={PrevPageButton}
        onPageChange={handlePageChange}
        activeClassName="active"
        disabledClassName="inactive"
        pageClassName="page"
      />
    </div>
  );
};

export default Pagination;
