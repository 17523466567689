import React, { FC } from 'react';
import MuiTab, { TabProps } from '@material-ui/core/Tab';

type Props = TabProps;

const Tab: FC<Props> = props => {
  return <MuiTab disableRipple {...props} />;
};

export default Tab;
