import React from 'react';
import { sizes, palette, bp, layer } from './entity/createTheme';
import { Global, css } from '@emotion/core';

const GlobalStyles = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: ${palette.darkMedium2};
      }
      html {
        font-size: 16px;
      }
      html,
      body,
      button,
      textarea,
      input {
        font-family: 'Hiragino Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      }

      .grabbing {
        cursor: grabbing !important;

        * {
          cursor: grabbing !important;
        }
      }

      .being-dragged {
        box-shadow: 1px 2px 5px 0 ${palette.grayBlur2};
        svg {
          display: block !important;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }
      a,
      input,
      textarea {
        &:focus {
          outline: none;
        }
      }
      pre {
        color: ${palette.whiteBlur};
        white-space: pre-wrap;
        word-break: break-all;
      }

      #root {
        .app-loading {
          width: 100%;
          height: 100vh;
        }
      }
      .container {
        position: relative;
        display: block;
        margin: 0 auto;
        height: 100vh;
        overflow: hidden;
        background-color: ${palette.base};

        .sidebar {
          height: 100%;
          width: ${sizes.sizebarWidth};
          position: relative;
          box-shadow: 0px 8px 10px -5px #0003;
          overflow-y: auto;

          @media ${bp.sm} {
            position: fixed;
            height: calc(100% - ${sizes.headerHeightSm});
            width: 100%;
            top: -100vh;
            margin-top: 0;
          }
        }
        .container-inner {
          display: flex;
          flex-direction: row;
          height: calc(100vh - ${sizes.headerHeightLg});
        }
        .main {
          flex: 1;
          height: 100%;
          width: calc(100% - ${sizes.sizebarWidth});

          // Without 'min-width: 0;', the sidebar's width (260px) will be ignored.
          // See bugzilla for more detail https://bugzilla.mozilla.org/show_bug.cgi?id=1242914
          min-width: 0;

          @media ${bp.sm} {
            width: 100%;
          }

          .header {
            width: 100%;
            z-index: ${layer.topUnder2};
          }

          .content {
            height: calc(100%);

            .content-inner {
              padding: 30px;
              max-width: ${sizes.contentWidth};
              margin: 0 auto;
              @media ${bp.md} {
                padding: 16px;
              }
            }

            &.content_hearing-play {
              height: 100vh;
              &.hearing-process {
                overflow-y: auto;
                .hearing-content {
                  overflow: visible;
                }
              }
            }
          }
        }

        @media ${bp.xs} {
          overflow-x: scroll;
        }
      }

      .result-modal {
        .modal {
          width: 1120px;
          height: 90vh;

          @media ${bp.md} {
            width: 750px;
          }
        }
        &.hearing_link {
          .modal-content {
            padding: 0;
          }
        }
      }

      .add_campaigns-modal,
      .add_hearings-modal,
      .add_questions-modal,
      .add_product_video-modal {
        .modal {
          width: 835px;
          height: 643px;

          @media ${bp.md} {
            width: 704px;
          }
        }
      }

      .modal-content {
        .alert_modal {
          p {
            margin-bottom: 30px;
            span {
              font-weight: bold;
            }
          }
          h4 {
            color: ${palette.redPrimary};
          }
        }
      }

      .create_memo-modal {
        .modal {
          width: 600px;
          .modal-header {
            padding: 20px 16px;
          }
        }
      }

      .scroll-bar-thumbnail {
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          background-color: ${palette.whitePrimary};
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: ${palette.grayMedium1};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${palette.grayMedium1};
          border: none;
        }
      }
      .frame_loading {
        position: fixed;
        width: calc(100% - ${sizes.sizebarWidth} + 30px);
        height: calc(100% - ${sizes.headerHeightLg});
        left: calc(${sizes.sizebarWidth} - 30px);
        top: ${sizes.headerHeightLg};
        background: rgba(0, 0, 0, 0.3);
        z-index: ${layer.bottomAbove2};
        @media ${bp.md} {
          width: 100%;
          height: calc(100% - ${sizes.headerHeightMd});
          top: ${sizes.headerHeightMd};
          left: 0;
        }
      }
    `}
  />
);

export default GlobalStyles;
