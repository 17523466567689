import React, { FC } from 'react';
import { useCheckCanPushBack } from 'hooks';
import { css } from '@emotion/core';
import { SVGIcon } from 'views/components/primitive';
import IconLeft from 'assets/images/ic_left.svg';
import { bp, palette } from 'entity/createTheme';

const style = css`
  display: flex;
  align-items: center;
  overflow: hidden;

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    flex-shrink: 0;
    g {
      g {
        fill: ${palette.deepBlueLight} !important;
      }
    }
  }

  h2 {
    font-size: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media ${bp.md} {
    svg {
      width: 22px;
      height: 22px;
    }
    h2 {
      font-size: 22px;
    }
  }
`;

type PropsType = {
  title: string;
  onClick?: () => void;
  autifySelectorName?: string; //TODO: jsxの既存のdata-XXで渡せるようにする
};

const BackButton: FC<PropsType> = ({ title, onClick, autifySelectorName }) => {
  const pushBack = useCheckCanPushBack();
  return (
    <span css={style}>
      <SVGIcon
        src={IconLeft}
        onClick={onClick ?? pushBack}
        autifySelectorName={autifySelectorName}
      />
      <h2>{title}</h2>
    </span>
  );
};

export default BackButton;
