import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { palette } from 'entity/createTheme';
import { ProductItem } from 'types/Product';
import { getUserAnswerRelatedTags } from 'utility/hearingStoreHelpers';
import { getImgWithStatus } from 'utility/helpers';
import history from 'utility/history';
import { QuestionPlayData } from 'types/HearingSet';

interface Props {
  question: QuestionPlayData;
  tagRelatedVideos: Map<string, Extract<ProductItem, { content_type: 'videos' }>[]>;
}

const RelatedVideos: FC<Props> = ({ question, tagRelatedVideos }) => {
  const { t } = useTranslation();
  const tags = getUserAnswerRelatedTags(question);
  const videos = tagRelatedVideos.get(tags.join(','));

  if (!videos || videos.length === 0) return null;

  return (
    <>
      <Title>{t('hearingPlay.result.relatedVideos')}</Title>
      <Videos>
        {videos.map(product => (
          <ProductBox
            key={product.id}
            onClick={() => {
              history.push(`/hearing-set/play/intro?content_id=${product.id}`);
            }}
          >
            <Thumbnail
              src={getImgWithStatus(
                product.upload_status ?? '',
                product.kaizen_files_thumbnail_url
              )}
              alt="prod-img"
            />
            <ProductTitle>{product.title}</ProductTitle>
          </ProductBox>
        ))}
      </Videos>
    </>
  );
};

const hoverStyle = css`
  border-radius: 6px;
  position: absolute;
  content: '';
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Videos = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 20px 10px 0;
  border-radius: 6px;
  background: ${palette.whitePrimary};

  &:nth-of-type(3n) {
    margin-right: 0;
  }
`;

const ProductBox = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc((100% - 48px) / 4);
  overflow: hidden;
  margin: 0 12px 10px 0;
  border-radius: 6px;

  /* hover interaction code */
  cursor: pointer;
  position: relative;

  &::before {
    ${hoverStyle}
  }

  &:hover {
    background: ${palette.grayMedium5};

    &::before {
      background: ${palette.grayBlur};
    }
  }
  /* -------------------------- */
`;

const Title = styled('h4')({
  margin: '14px 0',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const ProductTitle = styled('p')({
  padding: '6px 10px',
  fontSize: '14px',
  fontWeight: 'bold',
});

const Thumbnail = styled('img')({
  width: '100%',
  height: '130px',
  objectFit: 'cover',
});

export default RelatedVideos;
