import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { RootStore } from '../stores';

function useStore(): RootStore {
  const store: RootStore = useContext(MobXProviderContext);
  return store;
}

export default useStore;
