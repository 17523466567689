import React, { FC } from 'react';
import { Modal } from 'views/components/compound';
import { useTranslation } from 'react-i18next';
import { Button as _Button, InputText as _InputText } from 'views/components/primitive';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';

type Props = {
  inputValue: string;
  showModal: boolean;
  handleToggle: () => void;
  handleChange: (val: string) => void;
  handleConfirm: () => void;
};

const ExternalVideoInputModal: FC<Props> = ({
  inputValue,
  showModal,
  handleToggle,
  handleChange,
  handleConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isModal={showModal}
      handleToggle={handleToggle}
      title={t('admin.questions.embedURL')}
      className="video-link-modal"
    >
      <Label>{t('admin.questions.embedModalLabel')}</Label>
      <InputText
        value={inputValue}
        onChange={handleChange}
        placeholder={t('admin.questions.videoURL')}
      />
      <ButtonWrapper>
        <Button onClick={handleToggle} label={t('common.cancel')} size="md" type="default" />
        <Button onClick={handleConfirm} label={t('common.ok')} size="md" type="primary" />
      </ButtonWrapper>
    </Modal>
  );
};

const InputText = styled(_InputText)({
  input: {
    width: 560,
  },
});

const Label = styled('div')({
  color: palette.deepBlueLight,
  fontSize: 14,
  marginBottom: 8,
});

const ButtonWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Button = styled(_Button)({
  width: 122, //modal action buttons must have same width
  '&:first-of-type': {
    marginRight: 10,
  },
});

export default ExternalVideoInputModal;
