import { useStore } from 'hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinkInfoOption } from 'types/HearingLink';
import { trimSpace } from 'utility/helpers';

export type SaveLinkInfo = (id: string, info: LinkInfoOption) => void;

export const useSharableLinkInfoSetting = () => {
  const {
    hearingLinkStore: { hearingLinkDetail: linkDetail, saveLinkInfo },
  } = useStore();
  const { id: linkId } = useParams<{ id: string }>();

  const {
    administrative_title: originalTitle,
    administrative_memo: originalMemo,
    client,
  } = linkDetail;

  const [isInfoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [hasInfoChanges, setHasInfoChanges] = useState(false);
  const handleOpenInfoDrawer = () => setInfoDrawerOpen(true);
  const [title, setTitle] = useState<string | null>(originalTitle || '');
  const [memo, setMemo] = useState(originalMemo);
  const disableInfoDrawerAction = !hasInfoChanges;

  useEffect(() => {
    setTitle(originalTitle || '');
    setMemo(originalMemo);
  }, [originalTitle, originalMemo]);

  const changeTitle = (value: string) => {
    setTitle(value);
    setHasInfoChanges(true);
  };

  const changeMemo = (value: string) => {
    setMemo(value);
    setHasInfoChanges(true);
  };

  const closeInfoDrawer = () => {
    setTitle(originalTitle || '');
    setMemo(originalMemo);
    setInfoDrawerOpen(false);
    setHasInfoChanges(false);
  };

  const handleSaveLinkInfo = () => {
    setInfoDrawerOpen(false);
    saveLinkInfo(linkId, {
      administrative_title: trimSpace(title || ''),
      administrative_memo: trimSpace(memo || ''),
    });
    setHasInfoChanges(false);
  };

  return {
    isInfoDrawerOpen,
    handleOpenInfoDrawer,
    originalTitle,
    originalMemo,
    client,
    disableInfoDrawerAction,
    closeInfoDrawer,
    title,
    changeTitle,
    memo,
    changeMemo,
    handleSaveLinkInfo,
  };
};

export default useSharableLinkInfoSetting;
