import React, { FC, useEffect, useState, useRef } from 'react';
import ShakaPlayer from './ShakaPlayer';
import DownloadButton, { DownloadStatus } from './DownloadButton';
import { restoreSettings, storeSettings } from 'utility/playerSettings';
import KaizenVideoPlayer from 'views/components/compound/KaizenVideoPlayer';
import styled from '@emotion/styled';
import { ProgressTracker } from './ProgressTracker';
import ReactPlayer from 'react-player';
import { useStore } from 'hooks';
import { LoggerOption } from 'types/common';
import { PlayerEventListener } from '@kaizenplatform/kaizen-video-player';

interface PropsTypes {
  width: string;
  height: string;
  url?: string;
  manifestId?: string;
  manifestUrl?: string | null;
  muted: boolean;
  onReady: () => void;
  onError: (error: any) => void;
  isVimeo?: boolean;
  handleMovieEnd?: () => void; // 再生終了時に発火させたい関数をprops経由で渡す口
  handlePlay?: () => void; // 再生時に発火させたい関数をprops経由で渡す口
  shouldRestart?: boolean;
  isPreview?: boolean;
  loggerOption?: LoggerOption;
  markers?: number[];
  invertTime?: boolean;
  listeners?: PlayerEventListener[];
}

enum PlayerType {
  initializing,
  kaizen,
  shaka,
}

const CacheablePlayer: FC<PropsTypes> = ({
  width,
  height,
  url,
  manifestId,
  manifestUrl,
  onReady,
  onError,
  muted,
  isVimeo,
  handleMovieEnd,
  handlePlay,
  shouldRestart,
  loggerOption = {
    logging: false,
    placement: 'none',
    serviceResourceIdForLogger: '',
  },
  markers,
  invertTime = true,
  listeners,
}) => {
  const [mode, setMode] = useState(PlayerType.initializing);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>('hidden');
  const [progress, setProgress] = useState(0.0);
  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const vimeoTracker = useRef<ProgressTracker | null>(null);
  const [playingState, setPlayingState] = useState(true);
  const [shakaPlayingState, setShakaPlayingState] = useState(false);
  const didMountRef = useRef(false);
  const {
    appStore: {
      organizationId,
      userData: { id },
    },
  } = useStore();

  useEffect(() => {
    setPlayingState(true);
  }, [url]);

  useEffect(() => {
    setMode(PlayerType.initializing);
    setDownloadStatus('hidden');
    setProgress(0.0);
    setDownloadTriggered(false);

    const videoId = manifestId;
    const vimeoId = url ? url.replace(/(^.*\/|\D*$)/g, '') : undefined;
    if (videoId) {
      vimeoTracker.current = new ProgressTracker('vimeo', videoId, vimeoId);
      vimeoTracker.current.track('init', { duration: 0, currentTime: 0 });
    }
  }, [url, manifestId, manifestUrl]);

  const playerRef = useRef(null);
  const playerSettings = restoreSettings();
  let vimeoOnly = false;

  function storeVimeoVolume() {
    const p: any = playerRef.current;
    if (p && isVimeo) {
      p.getInternalPlayer()
        .getVolume()
        .then((v: number) => storeSettings({ muted: muted, volume: v }));
    }
  }

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    const p: any = playerRef.current;
    if (shouldRestart === undefined) return;
    if (shouldRestart) {
      if (p) {
        p.seekTo(0);
      }
      setPlayingState(true);
      setShakaPlayingState(true);
    } else {
      setPlayingState(false);
      setShakaPlayingState(false);
    }
    return () => {
      playerRef.current = null;
    };
  }, [shouldRestart]);

  // 動画リンクの再生の時はこの分岐に入る
  if (url !== undefined) {
    // fallback to vimeo
    vimeoOnly = true;
    return (
      <ReactPlayer
        ref={playerRef}
        muted={muted}
        volume={playerSettings.volume}
        controls
        width={width}
        height={height}
        onReady={onVimeoReady}
        onError={onError}
        onPlay={onVimeoPlay}
        onStart={onVimeoStart}
        onEnded={onVimeoEnded}
        onProgress={onVimeoProgress}
        onPause={onVimeoPause}
        url={url}
        playing={playingState}
        playsinline={true}
      />
    );
  }

  function onCacheChecked(cacheAvailable: boolean) {
    if (!cacheAvailable) {
      setMode(PlayerType.kaizen);
      setDownloadStatus('hidden');
    }
  }

  function onShakaReady() {
    setMode(PlayerType.shaka);
    onReady();
  }

  function onDownloadProgress(downloading: boolean, progress: number) {
    setDownloadStatus(downloading ? 'downloading' : 'downloaded');
    setProgress(progress);
  }

  function handleDownloadClick() {
    setDownloadTriggered(true);
  }

  function trackVimeo(
    eventType: 'ready' | 'bufferstart' | 'bufferend' | 'play' | 'ended' | 'progress',
    extra: any = {}
  ) {
    if (vimeoTracker.current && playerRef.current) {
      const progressTracker: ProgressTracker = vimeoTracker.current;
      const player: ReactPlayer = playerRef.current as any;
      if (player) {
        const duration = player.getDuration() || 0;
        const currentTime = player.getCurrentTime() || 0;
        if (!progressTracker.videoPlayerTag) {
          const internalPlayer = player.getInternalPlayer() as any;
          progressTracker.videoPlayerTag = ProgressTracker.findVideoPlayerTag(
            internalPlayer?.element?.parentElement
          );
          if (progressTracker.videoPlayerTag) {
            internalPlayer.on('bufferstart', () => {
              trackVimeo('bufferstart');
            });
            internalPlayer.on('bufferend', () => {
              trackVimeo('bufferend');
            });
          }
        }
        progressTracker.track(eventType, { duration, currentTime }, extra);
      }
    }
  }

  function onVimeoReady() {
    trackVimeo('ready');
    onReady();
  }

  function onVimeoPlay() {
    trackVimeo('play');
    if (!vimeoOnly) setDownloadStatus('hidden');
    storeVimeoVolume();
    if (handlePlay) {
      handlePlay();
    }
  }

  function onVimeoStart() {
    if (!vimeoOnly) setDownloadStatus('ready');
  }

  function onVimeoEnded() {
    setPlayingState(false);
    trackVimeo('ended');
    if (!vimeoOnly) setDownloadStatus('ready');
    if (handleMovieEnd) {
      handleMovieEnd();
    }
  }

  function onVimeoPause() {
    if (!vimeoOnly) setDownloadStatus('ready');
    storeVimeoVolume();
  }

  function onVimeoProgress(state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) {
    trackVimeo('progress', { vimeoState: state });
  }

  return (
    <Root width={width} height={height}>
      {mode === PlayerType.kaizen && (
        <KaizenVideoPlayer
          muted={playerSettings.muted || muted}
          volume={playerSettings.volume}
          videoId={manifestId ?? ''}
          autoplay={true}
          manifestUrl={manifestUrl ?? ''}
          scriptKey={String(organizationId)}
          logging={loggerOption.logging}
          placement={loggerOption.placement}
          service="sales"
          serviceResourceId={loggerOption.serviceResourceIdForLogger}
          onReady={onReady}
          loggerEndpoint={process.env.REACT_APP_VIDEO_LOGGER_ENDPONT}
          userId={id}
          markers={markers}
          plyrOptions={{ invertTime }}
          listeners={listeners}
        />
      )}
      {mode === PlayerType.kaizen && (
        <DownloadButton status={downloadStatus} progress={progress} onClick={handleDownloadClick} />
      )}
      <ShakaPlayer
        visible={mode === PlayerType.shaka}
        manifestId={manifestId ?? ''}
        manifestUrl={manifestUrl ?? ''}
        onCacheChecked={onCacheChecked}
        onReady={onShakaReady}
        onError={onError}
        onDownloadProgress={onDownloadProgress}
        downloadTriggered={downloadTriggered}
        onEnd={() => {
          if (handleMovieEnd) {
            handleMovieEnd();
          }
        }}
        onPlay={() => {
          if (handlePlay) {
            handlePlay();
          }
        }}
        playing={shakaPlayingState}
      />
    </Root>
  );
};

export default CacheablePlayer;

const Root = styled.div<{ width: string; height: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
`;
