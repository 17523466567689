import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { Client } from 'types/Client';

interface PropTypes {
  onChange: (value: string, isNew?: boolean) => void;
  value: string;
  options: Client[];
}

const CreatableAccount: FC<PropTypes> = ({ onChange, value, options }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [newOption, setNewOption] = useState<
    Array<{ label: string; value: any; __isNew__: boolean }>
  >([]);

  const clientOptions = useMemo(
    () => options.map(it => ({ label: it.name, value: it.id })).concat(newOption),
    [options, newOption]
  );

  function handleChange(newValue: any) {
    if (newValue) {
      const isNew = !!newValue.__isNew__;
      const { value } = newValue;
      if (isNew) setNewOption([...newOption, { label: value, value, __isNew__: true }]);
      onChange(value, isNew);
    } else {
      onChange(newValue);
    }
  }

  function getValue() {
    return clientOptions.find(it => it.label === value);
  }

  function handleInputChange(val: string) {
    // max lenth 40 chracter
    setInputValue(val.slice(0, 40));
  }

  return (
    <CreatableSelect
      createOptionPosition="first"
      className="react-select-container"
      classNamePrefix="react-select"
      isClearable
      onChange={handleChange}
      options={clientOptions}
      inputValue={inputValue}
      value={getValue()}
      onInputChange={handleInputChange}
      placeholder=""
      formatCreateLabel={inputValue => `${t('sharingLink.createNewOption')} "${inputValue}"`}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      }}
    />
  );
};

export default CreatableAccount;
