import { routes } from 'utility/constants';
import { DisplayableMenueItems } from 'types/App';

import salesLogIcon from 'assets/images/ic_appointment.svg';
import productContentIcon from 'assets/images/ic_video.svg';
import adminProductContentIcon from 'assets/images/ic_video_admin.svg';
import archiveIcon from 'assets/images/ic_archive_sidebar.svg';
import HearingLinkIcon from 'assets/images/ic_hearing_link.svg';
import CampaignLinkIcon from 'assets/images/ic_campaign_link.svg';
import settingIcon from 'assets/images/ic_setting.svg';

type NavLinksLabel =
  | 'sidebar.salesContent'
  | 'sidebar.salesLog'
  | 'sidebar.hearingLink'
  | 'admin.common.archive'
  | 'sidebar.productContent'
  | 'admin.common.archive'
  | 'sidebar.setting'
  | 'sidebar.embeddingLink';

export interface NavLinkProps {
  id: number;
  activeUrl: string;
  iconUrl: string;
  label: NavLinksLabel;
  requirements: DisplayableMenueItems[];
}

export const navLinksSales: NavLinkProps[] = [
  {
    id: 1,
    activeUrl: routes.salesContent,
    iconUrl: productContentIcon,
    label: 'sidebar.salesContent',
    requirements: ['field_sales'],
  },
  {
    id: 2,
    activeUrl: routes.salesLog,
    iconUrl: salesLogIcon,
    label: 'sidebar.salesLog',
    requirements: ['field_sales'],
  },
  {
    id: 3,
    activeUrl: routes.hearingLink,
    iconUrl: HearingLinkIcon,
    label: 'sidebar.hearingLink',
    requirements: ['field_sales', 'campaign'],
  },
  {
    id: 4,
    activeUrl: routes.embeddingLink,
    iconUrl: CampaignLinkIcon,
    label: 'sidebar.embeddingLink',
    requirements: ['campaign'],
  },
];

export const hearingLinkArchiveForNonAdmin: NavLinkProps[] = [
  {
    id: 5,
    activeUrl: routes.hearingLinkArchive,
    iconUrl: archiveIcon,
    label: 'admin.common.archive',
    requirements: ['field_sales', 'campaign'],
  },
];

export const navLinksAdmin: NavLinkProps[] = [
  {
    id: 1,
    activeUrl: routes.management.content,
    iconUrl: adminProductContentIcon,
    label: 'sidebar.productContent',
    requirements: ['admin', 'not_admin'],
  },
  {
    id: 4,
    activeUrl: routes.management.archive,
    iconUrl: archiveIcon,
    label: 'admin.common.archive',
    requirements: ['admin'],
  },
  {
    id: 5,
    activeUrl: routes.management.setting,
    iconUrl: settingIcon,
    label: 'sidebar.setting',
    requirements: ['admin', 'not_admin'],
  },
];
