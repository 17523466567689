import store from '../stores/index';
import { init, configureScope, captureException } from '@sentry/browser';

init({
  dsn: process.env.REACT_APP_SENTRY_API_DSN,
  environment: process.env.REACT_APP_STAGE,
});

const reporttError = (error: Error) => {
  const { rootStore, userData } = store.appStore;
  configureScope(scope => {
    if (userData.id) {
      scope.setUser({
        id: userData.id,
        username: userData.username,
        email: userData.email,
      });
    }
    scope.setExtras({ userData, rootStore });
  });
  captureException(error);
};

export { reporttError };
