import { HearingItem } from 'types/HearingSet';
import { ProductItem } from 'types/Product';
import HttpClient from 'adapter/HTTPClient';

type ArchiveListResponse = {
  hearing_sets: HearingItem[];
  hearing_sets_page: number;
  total_hearing_sets: number;
  product_contents: ProductItem[];
  product_contents_page: number;
  total_product_contents: number;
};

class ArchiveAdminApi {
  private readonly httpClient: HttpClient;

  constructor({ httpClient }: { httpClient: HttpClient }) {
    this.httpClient = httpClient;
  }

  public getArchiveList = async ({
    panel,
    sort,
    order,
    page,
    organization_id,
  }: {
    panel: string;
    sort: string;
    order: string;
    page: number;
    organization_id: string;
  }) => {
    const res = await this.httpClient.request<ArchiveListResponse>({
      method: 'GET',
      url: `admin/archives`,
      params: {
        sort,
        order,
        ...(panel === 'products' ? { product_contents_page: page } : { hearing_sets_page: page }),
        hearing_sets_per_page: 8,
        product_contents_per_page: 12,
        organization_id,
      },
    });
    return res;
  };

  public restoreData = async ({
    field,
    listId,
    organization_id,
  }: {
    field: 'product_content_ids' | 'hearing_set_ids';
    listId: number[];
    organization_id: string;
  }) => {
    await this.httpClient.request({
      method: 'PUT',
      url: 'admin/archives/restore',
      data: {
        [field]: listId,
        organization_id,
      },
    });
  };
}

export default ArchiveAdminApi;
