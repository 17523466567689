import ProductContent from 'assets/images/embed_type_video.svg';
import Questionnaire from 'assets/images/embed_type_questionnaire.svg';
import Playlist from 'assets/images/playlist.svg';
import { LinkContentType } from 'types/HearingLink';

type EmbedTypeName =
  | 'sharingLink.selectType.productContents'
  | 'sharingLink.selectType.questionnaire'
  | 'sharingLink.selectType.playlist';

type LinkType = {
  id: LinkContentType;
  typeName: EmbedTypeName;
  illustrationSrc: string;
};

export const linkContentTypes: LinkType[] = [
  {
    id: 'product_contents',
    typeName: 'sharingLink.selectType.productContents',
    illustrationSrc: ProductContent,
  },
  {
    id: 'playlist',
    typeName: 'sharingLink.selectType.playlist',
    illustrationSrc: Playlist,
  },
  {
    id: 'hearing_set',
    typeName: 'sharingLink.selectType.questionnaire',
    illustrationSrc: Questionnaire,
  },
];
