import React from 'react';
import styled from '@emotion/styled';
import CopyIcon from 'assets/images/copy_button.svg';
import { bp, palette } from 'entity/createTheme';
import SVG from 'react-inlinesvg';

type PropTypes = {
  isSuccess: 'success' | 'fail' | undefined;
} & React.ComponentPropsWithoutRef<'span'>;

const CopyButton = React.forwardRef<HTMLElement, PropTypes>(
  ({ onClick, isSuccess, ...props }, ref) => {
    return (
      <Wrapper onClick={onClick} {...props} isSuccess={isSuccess}>
        <SVG src={CopyIcon} innerRef={ref} />
      </Wrapper>
    );
  }
);

const Wrapper = styled('span')<{ isSuccess: 'success' | 'fail' | undefined }>`
  svg {
    margin-top: 5px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    flex-shrink: 0;
    fill: ${({ isSuccess }) =>
      isSuccess === 'success'
        ? palette.greenPrimary
        : isSuccess === 'fail'
        ? palette.redPrimary
        : palette.grayPrimary};
    &:hover {
      fill: ${({ isSuccess }) =>
        isSuccess === 'success'
          ? palette.greenPrimary
          : isSuccess === 'fail'
          ? palette.redPrimary
          : palette.darkMedium2};
    }
  }

  @media ${bp.md} {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export default CopyButton;
