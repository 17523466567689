import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';
import { SVGIcon } from 'views/components/primitive';

type PropsTypes = {
  text: string;
  iconSrc: string;
  className?: string;
};

const MessageWithIcon: FC<PropsTypes> = ({ text, iconSrc, className }) => (
  <MessageWrapper className={className}>
    <SVGIcon src={iconSrc} />
    <TextWrapper>{text}</TextWrapper>
  </MessageWrapper>
);

export default MessageWithIcon;

const MessageWrapper = styled('div')({
  display: 'flex',
  height: 80,
  width: 520,
  border: `solid 1px ${palette.orangePrimary}`,
  borderRadius: 6,
  overflow: 'hidden',
  svg: {
    height: '100%',
    backgroundColor: palette.orangePrimary,
    minWidth: 80,
    padding: 16,
  },
  [`@media ${bp.lg}`]: {
    height: 64,
    width: 420,
    svg: {
      minWidth: 64,
      padding: 12,
    },
  },
});

const TextWrapper = styled('p')({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  padding: '0 16px',
  backgroundColor: palette.whitePrimary,
  fontSize: '1rem',
  [`@media ${bp.lg}`]: {
    fontSize: '0.875rem',
  },
});
