import React, { FC, useState } from 'react';
import { VideoPlayer, PDFViewer, ImageViewer } from 'views/components/compound';
import { validateVideoURL } from 'utility/videoLinkHelpers';
import { LoggerOption } from 'types/common';
import { ContentViewType } from 'entity/Content';

type Props = {
  content: ContentViewType;
  loggerOption?: LoggerOption;
};

const HearingContentPlayer: FC<Props> = ({ content, loggerOption }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const goBackPage = () => setPageNumber(prev => prev - 1);
  const goNextPage = () => setPageNumber(prev => prev + 1);

  const setTotalPages = (totalPages: number) => {
    setNumPages(totalPages);
  };

  const updatePageNumber = (page: number) => {
    setPageNumber(page);
  };

  switch (content.content_type) {
    case 'images':
      return <ImageViewer url={content.kaizen_files_url || ''} />;
    case 'pdfs':
      return (
        <PDFViewer
          url={content.kaizen_files_url || ''}
          pageNumber={pageNumber}
          updatePageNumber={updatePageNumber}
          goBackPage={goBackPage}
          goNextPage={goNextPage}
          totalPages={numPages}
          setTotalPages={setTotalPages}
        />
      );
    case 'videos': {
      return (
        <VideoPlayer
          width="100%"
          height="100%"
          status={content.upload_status}
          manifestId={content.kaizen_files_id}
          manifestUrl={content.kaizen_files_url}
          loggerOption={loggerOption}
        />
      );
    }

    case 'links': {
      const { isVimeo } = validateVideoURL(content.url || '');
      return (
        <VideoPlayer
          width="100%"
          height="100%"
          url={content.url || ''}
          status={content.upload_status}
          isVimeo={isVimeo}
        />
      );
    }

    default:
      return null;
  }
};

export default HearingContentPlayer;
