import React, { FC, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
// import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { ConfirmModal, Drawer } from 'views/components/compound';
import { useStore } from 'hooks';
import { ChannelType } from 'services/Admin/SettingApi';

type Props = {
  open: boolean;
  currentValue?: string;
  closeDrawer: () => void;
};

type SelectType = {
  label: string;
  value: string;
};

const SlackIntegrationDrawer: FC<Props> = ({ open, currentValue, closeDrawer }) => {
  const { t } = useTranslation();
  const {
    settingSlackStore: { loading, loadSlackChannels, assignSlackChannel },
  } = useStore();
  const [channels, setChannels] = useState<ChannelType[]>([]);
  const [isChanged, setChanged] = useState(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [selected, setSelected] = useState<SelectType | undefined>(undefined);

  const toggleConfirmModal = () => setOpenConfirmModal(!isOpenConfirmModal);

  const handleClickCancelButton = () => {
    closeDrawer();
  };

  const handleConfirmCancel = () => {
    // resetDisplayedTextList();
    setChanged(false);
    // handleCloseEditTextDrawer();
  };

  const handleSaveSlackIntegration = async () => {
    // handleSaveDisplayedText();
    if (selected) await assignSlackChannel(selected.value);
    setChanged(false);
    closeDrawer();
  };

  useEffect(() => {
    if (!open) return;

    const f = async () => {
      try {
        const items = await loadSlackChannels();
        if (items) setChannels(items);
      } catch (err) {
        console.log(err);
      }
    };
    f();
  }, [open]);

  const options = useMemo(() => {
    const opts = channels.map(it => ({ label: it.name, value: it.id }));
    return opts;
  }, [channels]);

  useEffect(() => {
    if (channels && channels.length > 0 && currentValue) {
      const it = channels.find(it => it.name === currentValue);
      if (it) setSelected({ label: it.name, value: it.id });
    }
  }, [currentValue, channels]);

  function handleChange(newValue: any) {
    if (newValue) {
      setSelected(newValue);
    } else {
      setSelected(undefined);
    }
    setChanged(true);
  }

  return (
    <>
      <Drawer
        disabledAction={!isChanged}
        isOpen={open}
        onClose={handleClickCancelButton}
        title={t('admin.setting.slack.drawer.title')}
        onSave={handleSaveSlackIntegration}
      >
        {/* <Instruction>{t('sharingLink.playlistSetting.instruction')}</Instruction> */}
        <Select
          isLoading={loading}
          onChange={handleChange}
          options={options}
          // onInputChange={handleInputChange}
          value={selected}
          // placeholder=""
          isDisabled={loading}
          isClearable
          // onMenuScrollToBottom={_ => loadMore({ key })}
        />
      </Drawer>
      <ConfirmModal
        isModal={false}
        toggleModal={toggleConfirmModal}
        handleConfirmModal={handleConfirmCancel}
      />
    </>
  );
};

export default SlackIntegrationDrawer;

// const Instruction = styled('p')({
//   whiteSpace: 'pre-line',
//   fontSize: '0.75rem',
//   marginBottom: 16,
// });
