import { PlaylistData } from 'types/Playlist';
import { ListMeta } from 'types/common';
import { action, observable } from 'mobx';
import PlaylistApi from 'services/Admin/PlaylistApi';
import { RootStore } from 'stores';
import { PlaylistItem, PlaylistListRequestParams, ProductListParams } from 'types/Playlist';
import { defaultListMeta } from './constants';
import { MessageProps } from 'types/App';
import ProductContentApi from 'services/ProductContentApi';
import { ProductItem } from 'types/Product';
import i18n from 'i18n';
import history from 'utility/history';
import { routes } from 'utility/constants';
import { trimSpace } from 'utility/helpers';

class PlaylistStore {
  private readonly playlistApi: PlaylistApi;
  private readonly productContentApi: ProductContentApi;
  public readonly defaultLoadingContentNumber = 50;

  @observable public rootStore: RootStore;
  @observable public playlistList: PlaylistItem[] = [];
  @observable public isLoading = false;
  @observable public listMeta: ListMeta = { ...defaultListMeta };
  @observable public productContentList: ProductItem[] = [];
  @observable public idProductContent: { [id: string]: ProductItem } = {};
  @observable public loadingProductList = false;

  constructor({
    rootStore,
    playlistApi,
    productContentApi,
  }: {
    rootStore: RootStore;
    playlistApi: PlaylistApi;
    productContentApi: ProductContentApi;
  }) {
    this.rootStore = rootStore;
    this.playlistApi = playlistApi;
    this.productContentApi = productContentApi;
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }

  @action.bound
  public async getAllPlaylist({
    page = 1,
    sort = 'desc',
    order = 'updated_at',
    per_page = 8,
    key,
    status,
  }: Partial<PlaylistListRequestParams>) {
    this.isLoading = true;
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;

    try {
      const { data } = await this.playlistApi.getPlaylists({
        page,
        per_page,
        sort,
        order,
        organization_id,
        ...(key && { key }),
        ...(status && { status }),
      });
      this.playlistList = data.content_playlists;
      this.listMeta = {
        ...this.listMeta,
        page,
        sort,
        order,
        total: data.total,
      };
    } catch (error: any) {
      const content = i18n.t('admin.playlist.messages.getPlaylistListFailed');
      this.pushFlashMessages({ content, status: 'error' });
      this.resetPlaylistList();
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  public resetPlaylistList() {
    this.playlistList = [];
    this.listMeta = { ...defaultListMeta };
  }

  @action.bound
  public resetProductContentList() {
    this.productContentList = [];
    this.idProductContent = {};
  }

  @action.bound
  public handleSearchPlaylist(keyword: string) {
    this.getAllPlaylist({ ...this.listMeta, key: keyword });
  }

  @action.bound
  public async getProductContentList({
    q = '',
    types = [],
    tags = [],
    page = 1,
    concat = false,
  }: ProductListParams) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.loadingProductList = true;
    try {
      const perPage =
        q.length > 0 || tags.length > 0 ? undefined : this.defaultLoadingContentNumber;
      const { data } = await this.productContentApi.getProductContents(organization_id, {
        page,
        per_page: perPage,
        q,
        types,
        tags,
        sort: 'desc',
      });
      this.productContentList = concat
        ? this.productContentList.concat(data.product_contents)
        : data.product_contents;
      this.productContentList.forEach(pc => (this.idProductContent[pc.id] = pc));
      return { ...data, perPage };
    } catch (error: any) {
      const content = error?.message || 'error!';
      this.pushFlashMessages({ content, status: 'error' });
    } finally {
      this.loadingProductList = false;
    }
  }

  @action.bound
  public async createPlaylist(playlist: PlaylistData) {
    const {
      appStore: { organizationId: organization_id },
    } = this.rootStore;
    this.isLoading = true;
    try {
      const { data: createdContent } = await this.playlistApi.createPlaylistContent(
        {
          status: playlist.status,
          title: trimSpace(playlist.title),
          description: trimSpace(playlist.description),
          product_contents: playlist.product_contents.map(content => ({
            id: content.id,
            playlist_title: null,
          })),
        },
        organization_id
      );
      const newLinkId = createdContent.id;
      this.pushFlashMessages({
        content: i18n.t('admin.playlist.messages.createPlaylistSuccess'),
        status: 'success',
      });
      history.push(`${routes.management.playlistDetail}/${newLinkId}`);
    } catch (error: any) {
      this.pushFlashMessages({
        content: i18n.t('admin.playlist.messages.createPlaylistFailed'),
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }
}

export default PlaylistStore;
