import React, { FC } from 'react';
import { HearingLink } from 'types/HearingLink';

import HearingSet from './HearingSet';
import PlaylistItem from './PlaylistItem';
import ProductItem from './ProductItem';

type Props = {
  linkDetail: HearingLink;
};

const ShareableLinkContentList: FC<Props> = ({ linkDetail }) => {
  const { content_type, hearing_set, off_link, content_playlist } = linkDetail;
  return linkDetail.content_type === 'product_contents' ? (
    <ProductItem linkDetail={linkDetail} />
  ) : content_type === 'hearing_set' ? (
    <HearingSet hearingItem={hearing_set} hasNoPublicHearing={!!off_link} />
  ) : (
    <PlaylistItem
      playlist={content_playlist}
      hasNoPublicPlaylist={content_playlist?.status !== 'published'}
    />
  );
};

export default ShareableLinkContentList;
