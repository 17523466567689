import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownWrapper, DropdownItem, DropdownMenu, CSSIcon } from 'views/components/primitive';
import { TFuncKey } from 'react-i18next';

type Option = {
  value: string;
  label: TFuncKey;
};

type Props = {
  size?: 'sm' | 'md';
  value: string;
  options: Option[];
  onChange: (value: any) => void;
  className?: string;
  isParentComponentShown?: boolean;
  width?: string | number;
  isDisabled?: boolean;
  dropdownLabel?: string;
};

const Select: FC<Props> = ({
  size = 'sm',
  onChange,
  options,
  value,
  className,
  isParentComponentShown,
  width,
  isDisabled,
  dropdownLabel,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [isParentComponentShown, onChange]);

  const handleChange = (nextOption: string) => {
    if (nextOption !== value) {
      onChange(nextOption);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const currentOption = options.find(option => option.value === value) || options[0];
  const label = currentOption.label;

  return (
    <DropdownWrapper
      className={className}
      toggleOpenDropdown={toggleOpen}
      isOpen={isOpen}
      size={size}
      isDisabled={isDisabled}
      width={width}
    >
      <span>{dropdownLabel ?? t(label)}</span>
      <CSSIcon type={isOpen ? 'arrow_up' : 'arrow_down'} ripple={true} />
      <DropdownMenu isOpen={isOpen}>
        {options.map(option => {
          const isActive = option.value === value;
          return (
            <DropdownItem
              key={option.value}
              isActive={isActive}
              handleClickItem={() => handleChange(option.value)}
            >
              {t(option.label)}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </DropdownWrapper>
  );
};

export default Select;
