/**
 * UX Renewal 2021
 */
import React, { FC, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { palette, bp, layer } from 'entity/createTheme';
import { Button as IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';

type Props = {
  isOpen: boolean;
  align?: 'right' | 'left';
  className?: string;
  toggleOpenDropdown: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  title?: string;
};
type PropsMenu = {
  isOpen: boolean;
  align?: 'right' | 'left';
  className?: string;
};

type PropsHeader = {
  hundleclick?: (event: MouseEvent<HTMLDivElement>) => void;
};

const hundleclick = (e: any) => {
  e.stopPropagation();
};

const DropdownMenuComponent: FC<Props> = ({
  children,
  isOpen,
  align,
  className,
  toggleOpenDropdown,
  title,
}) => {
  return (
    <DropdownMenu
      onClick={hundleclick}
      className={className}
      isOpen={isOpen}
      align={align}
      css={css`
      @media ${bp.sm} {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit- transform: translateY(-50%) translateX(-50%);
        margin: auto;
        border-radius: 8px;
        width: 80%;
      }
    `}
    >
      <Header onClick={hundleclick}>
        {title}
        <IconButton
          onClick={e => {
            toggleOpenDropdown(e);
          }}
          style={{ width: 28, height: 28, minWidth: 'unset', borderRadius: '50%' }}
        >
          <CloseIcon style={{ color: '#666', width: 24, height: 24 }} />
        </IconButton>
      </Header>
      {children}
    </DropdownMenu>
  );
};

export default DropdownMenuComponent;

const Header = styled('li')<PropsHeader>(() => ({
  backgroundColor: palette.headerMenu,
  height: '48px',
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: 'rgb(0 0 0 / 16%) 0px 2px 4px',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: layer.bottomAbove1,
  boxSizing: 'border-box',
  textAlign: 'left',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
  textDecoration: 'none',
  marginBottom: '16px',
  cursor: 'default',
}));

//Prevent passing 'isOpen' to List component
const DropdownMenu = styled(List, { shouldForwardProp: prop => prop !== 'isOpen' })<PropsMenu>(
  ({ isOpen, align = 'right' }) => ({
    display: isOpen ? 'block' : 'none',
    width: '320px',
    padding: '0px 0px 16px 0px',
    position: 'absolute',
    top: 'calc(100% + 5px)',
    [`${align}`]: 0,
    border: `solid 1px ${palette.grayPrimary}`,
    background: palette.whitePrimary,
    boxShadow: '0px 4px 12px rgb(0 0 0 / 16%)',
    overflowY: 'auto',
    zIndex: layer.modal2,
    maxHeight: '80vh',
  })
);
