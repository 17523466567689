import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import {
  Button as _Button,
  Checkbox as _Checkbox,
  Divider as _Divider,
} from 'views/components/primitive';
import { Drawer } from 'views/components/compound';
import { customFormFields } from './constants';
import { useViewSetting } from 'organisms/ShareableLink/useSharableLinkViewSetting';
import CTAButtonList from '../CTAButtonList';
import CTASettingDrawer from './CTASettingDrawer';
import { HearingLink, ViewSettingOption } from 'types/HearingLink';
import { trimSpace } from 'utility/helpers';

type Props = {
  open: boolean;
  closeDrawer: () => void;
  showCustomForm: boolean;
  linkDetail: HearingLink;
  saveViewSetting: (id: string, viewSetting: ViewSettingOption) => Promise<void>;
};

const ViewSettingDrawer: FC<Props> = ({
  open,
  closeDrawer,
  showCustomForm,
  linkDetail,
  saveViewSetting,
}) => {
  const { t } = useTranslation();
  const {
    ctaSetting,
    handleChangeCTASetting,
    removeCTAItem,
    addCTAItem,
    hasSettingChanges,
    validateViewSetting,
    resetViewSetting,
    showForm,
    toggleShowCustomForm,
    formSettingError,
    customForms,
    onChangeForm,
    afterViewSetting,
    toggleAfterViewSetting,
    setHasSettingChanges,
    setFormSettingError,
    handleSaveViewSetting,
    handleSortCTA,
    cancelAddCTA,
  } = useViewSetting(showCustomForm, linkDetail, saveViewSetting);

  const [isCTASettingDrawerOpen, setCTASettingDrawerOpen] = useState(false);

  const displayedCtaList = ctaSetting.ctaList?.filter(cta => !!trimSpace(cta.link_to || '')) || [];

  const onCloseDrawer = (resetOriginalData?: boolean) => {
    closeDrawer();
    setHasSettingChanges(false);
    setFormSettingError('');
    if (resetOriginalData) resetViewSetting();
  };

  const saveChangedSetting = () => {
    const { isValidForm, viewSetting } = validateViewSetting();
    if (isValidForm) {
      handleSaveViewSetting(viewSetting);
      onCloseDrawer();
    }
  };

  const toggleCTASettingDrawer = () => setCTASettingDrawerOpen(!isCTASettingDrawerOpen);

  const handleClickAddCTAButton = () => {
    addCTAItem();
    toggleCTASettingDrawer();
  };

  const handleClickEditCTAButton = (index: number) => {
    handleChangeCTASetting({ currentIndex: index, isAddMode: false });
    toggleCTASettingDrawer();
  };

  return (
    <>
      <Drawer
        disabledAction={!hasSettingChanges}
        isOpen={open}
        onClose={() => onCloseDrawer(true)}
        title={t('sharingLink.viewSetting.title')}
        onSave={saveChangedSetting}
        isUpperDrawerOpen={isCTASettingDrawerOpen}
      >
        <Section>
          <SectionTitle>{t('sharingLink.viewSetting.beforeViewing')}</SectionTitle>
          <SettingOption mainOption>
            <Checkbox checked={showForm} onChange={toggleShowCustomForm} />
            {t('sharingLink.viewSetting.showCustomForm')}
          </SettingOption>
          <OptionDescription>{t('sharingLink.viewSetting.subShowFormLabel')}</OptionDescription>
          {!!formSettingError && <ErrorMessage>{formSettingError}</ErrorMessage>}
          {showForm && (
            <DetailedSettings>
              {customFormFields.map(customField => {
                return (
                  <SettingOption key={customField.fieldName}>
                    <Checkbox
                      checked={customForms[customField.fieldName]}
                      onChange={() => onChangeForm(customField.fieldName)}
                    />
                    {t(customField.label)}
                  </SettingOption>
                );
              })}
            </DetailedSettings>
          )}
        </Section>

        <Divider />

        {linkDetail.content_type !== 'playlist' && (
          <Section>
            <SectionTitle>{t('sharingLink.viewSetting.afterViewing')}</SectionTitle>
            <SubSection>
              <SettingOption mainOption>
                <Checkbox
                  checked={afterViewSetting.needs_feedback}
                  onChange={() => toggleAfterViewSetting('needs_feedback')}
                />
                {t('sharingLink.viewSetting.showResult')}
              </SettingOption>
              <OptionDescription>
                {t('sharingLink.viewSetting.subShowResultLabel')}
              </OptionDescription>
            </SubSection>
          </Section>
        )}

        <Section>
          <SectionTitle>{t('sharingLink.viewSetting.ctaSetting.subSectionTitle')}</SectionTitle>
          <SubSection>
            <CTAButtonList
              ctaList={displayedCtaList}
              isEditMode
              handleSortCTA={handleSortCTA}
              handleRemoveItem={removeCTAItem}
              handleEditItem={handleClickEditCTAButton}
            />
            <AddCTAButtonWrapper>
              <Button
                type="primary"
                label={t('sharingLink.viewSetting.ctaSetting.addCTAButton')}
                size="md"
                onClick={handleClickAddCTAButton}
              />
            </AddCTAButtonWrapper>
          </SubSection>
          {linkDetail.content_type !== 'playlist' && (
            <SubSection>
              <SettingOption disabledOption={!!afterViewSetting.disableAutoRedirectOption}>
                <Checkbox
                  disabled={!!afterViewSetting.disableAutoRedirectOption}
                  checked={!!afterViewSetting.autoRedirect}
                  onChange={() => toggleAfterViewSetting('autoRedirect')}
                />
                <CheckBoxLabel>
                  {t('sharingLink.viewSetting.ctaSetting.autoRedirect')}
                  <GuideText>{t('sharingLink.viewSetting.ctaSetting.guideText')}</GuideText>
                </CheckBoxLabel>
              </SettingOption>
            </SubSection>
          )}
        </Section>
      </Drawer>
      <CTASettingDrawer
        isOpen={isCTASettingDrawerOpen}
        toggleOpen={toggleCTASettingDrawer}
        ctaSetting={ctaSetting}
        handleChangeCTASetting={handleChangeCTASetting}
        cancelAddCTA={cancelAddCTA}
      />
    </>
  );
};

export default ViewSettingDrawer;

const Section = styled('div')({
  marginBottom: 24,
});

const SectionTitle = styled('p')({
  fontSize: '0.75rem',
  marginBottom: 8,
  fontWeight: 'bold',
  lineHeight: '160%',
  margin: '24px 0',
  ':first-of-type': {
    marginTop: 0,
  },
});

const SubSection = styled('div')({
  paddingBottom: 16,
});

const SettingOption = styled('li')<{ mainOption?: boolean; disabledOption?: boolean }>(props => ({
  listStyleType: 'none',
  fontSize: '0.875rem',
  marginBottom: props.mainOption ? 4 : 12,
  [`${CheckBoxLabel}`]: {
    ...(props.disabledOption && { color: palette.borderInput }),
  },
  [`${GuideText}`]: {
    ...(props.disabledOption && { color: palette.borderInput }),
  },
}));

const Checkbox = styled(_Checkbox)({
  padding: 0,
  marginRight: 12,
});

const OptionDescription = styled('p')({
  fontSize: '0.75rem',
  margin: '0 0 16px 36px',
});

const DetailedSettings = styled('ul')({
  marginLeft: 36,
});

const Divider = styled(_Divider)({
  margin: '40px 0 24px 0',
});

const ErrorMessage = styled('p')({
  fontSize: 13,
  color: palette.redPrimary,
  margin: '0 0 8px 36px',
});

const GuideText = styled('span')({
  fontSize: '0.75rem',
  color: palette.deepBlueLight,
});

const CheckBoxLabel = styled('p')({
  display: 'inline-flex',
  flexDirection: 'column',
});

const AddCTAButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});

const Button = styled(_Button)({
  marginTop: 12,
});
