import React, { FC, useCallback, useEffect } from 'react';
import HearingPlayHeader from './HearingPlayHeader';
import { WrapperHearingPlay } from './styles';
import ConfirmCancelModal from './ConfirmCancelModal';
import CreateMemoModal from './CreateMemoModal';

type Props = {
  playProductAfterHearingSet?: boolean;
  headerInfo?: { title: string; subTitle: string };
  useref?: React.RefObject<HTMLDivElement>;
};

const HearingPlayLayout: FC<Props> = ({
  children,
  playProductAfterHearingSet,
  headerInfo,
  useref,
}) => {
  const handleUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
    return null;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <WrapperHearingPlay ref={useref}>
      <HearingPlayHeader
        playProductAfterHearingSet={playProductAfterHearingSet}
        headerInfo={headerInfo}
      />
      {children}
      <CreateMemoModal handleUnload={handleUnload} />
      <ConfirmCancelModal handleUnload={handleUnload} />
    </WrapperHearingPlay>
  );
};

export default HearingPlayLayout;
