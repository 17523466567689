import styled from '@emotion/styled';
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { CTAItem } from 'types/HearingLink';
import SortableCTAItem from './SortableCTAItem';

type Props = {
  ctaList: CTAItem[];
  isEditMode?: boolean;
  handleClickArrow: (oldIndex: number) => void;
  handleRemoveItem: (index: number) => void;
  handleEditItem: (index: number) => void;
  dragging: boolean;
  className?: string;
};

const SortableCTAList = SortableContainer(
  ({
    ctaList,
    isEditMode,
    handleClickArrow,
    handleRemoveItem,
    handleEditItem,
    dragging,
    className,
  }: Props) => {
    return (
      <CTAListWrapper className={className}>
        {ctaList.length > 0 &&
          ctaList.map((cta, index) => (
            <SortableCTAItem
              key={index}
              ctaItem={cta}
              index={index}
              ctaItemIndex={index}
              handleClickArrow={handleClickArrow}
              handleRemoveItem={handleRemoveItem}
              handleEditItem={handleEditItem}
              isEditMode={isEditMode}
              dragging={dragging}
              disabled={!isEditMode}
            />
          ))}
      </CTAListWrapper>
    );
  }
);

export default SortableCTAList;

const CTAListWrapper = styled('div')({});
