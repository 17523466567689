import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'views/components/primitive';
import { palette } from 'entity/createTheme';

type Props = {
  isPublishedItem: boolean;
  className?: string;
  toggleCreateLinkDrawer: () => void;
  contentType: 'files' | 'playlists' | 'questionnaires';
};

const CreateLinkButton: FC<Props> = ({
  isPublishedItem,
  className,
  toggleCreateLinkDrawer,
  contentType,
}) => {
  const { t } = useTranslation();
  return (
    <CreateLinkButtonWrapper className={className}>
      {isPublishedItem ? (
        <Button
          label={t('sharingLink.addHearingLink')}
          onClick={toggleCreateLinkDrawer}
          type={'default'}
          size={'sm'}
        />
      ) : (
        <Tooltip
          tooltipText={t('sharingLink.disabledCreateLinkTooltip', {
            type: t(`admin.content.tabName.${contentType}`),
          })}
          disabled={isPublishedItem}
        >
          <DisabledButton>{t('sharingLink.addHearingLink')}</DisabledButton>
        </Tooltip>
      )}
    </CreateLinkButtonWrapper>
  );
};

export default CreateLinkButton;

const CreateLinkButtonWrapper = styled('div')({});

const DisabledButton = styled('div')({
  height: 32,
  fontSize: '0.75rem',
  border: 'none',
  borderRadius: 4,
  fontWeight: 'bold',
  cursor: 'not-allowed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.deepBlueDisable,
  background: palette.borderInput,
  whiteSpace: 'nowrap',
  '&:hover': {
    border: 'none',
  },
  padding: '0 16px',
});
