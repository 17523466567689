import styled from '@emotion/styled';
import keyframes from '@emotion/css';
import {
  Creative,
  CreativeList,
  ICreativeListItem,
  ICreativeListProps,
  IKeywordFormProps,
  KeywordForm,
  useCreativeSearch,
  useCreativeSearchContext,
  QueryCacheType,
} from '@kaizenplatform/creative-search-ui';
import React, { FC, useEffect, useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from 'assets/images/spinner.gif';
import ImgWaitToUpload from 'assets/images/upload.gif';
import { palette } from 'entity/createTheme';
import { useStore } from 'hooks';
import { Tag } from 'types/App';
import { EmbeddingVideoMeta } from 'types/EmbeddingLink';
import { ProductItem } from 'types/Product';
import { CreateShareableLinkDrawer, Pagination, TagSelects } from 'views/components/compound';

import { useCreativeListCardFooter } from './useCreativeListCardFooter';
import { SelectExistingContentsSearchAreaSelectedCreativePreview as SelectedCreativePreview } from './SelectedCreativePreview';
import dayjs from 'dayjs';
import NoContentsImg from 'assets/images/emptystatement1.png';
import { LinkContentData } from 'organisms/ShareableLink/useCreateHearingLink';

const LIMIT_PER_PAGE = 12;

const getCreativeContentType = (contentType: string): string | null => {
  switch (contentType) {
    case 'videos':
      return 'video/mp4';
    case 'images':
      return 'image/gif';
    case 'pdfs':
      return 'application/pdf';
    default:
      return null;
  }
};

const createDummyCreativeItem = (product: ProductItem): ICreativeListItem => {
  return {
    id: '',
    fileUrl: product.content_type === 'videos' ? '' : ImgWaitToUpload,
    thumbnailUrl: ImgWaitToUpload,
    contentType: getCreativeContentType(product.content_type) || '',
    fileSize: 1,
    fileUpdatedAt: dayjs(product.updated_at),
    organizationId: product.organization_id,
    scope: 1,
    service: 'sales',
    tag: [],
    organizationTag: [],
    favorite: 0,
    salesProductContentId: product.id,
    createdDate: product.created_at,
    title: product.title,
    placement: null,
    aspectRatio: null,
    duration: null,
    composition: null,
    optimizerProfileImageUrl: null,
    optimizerId: null,
    height: null,
    industry: null,
    width: null,
    salesIsPublished: null,
    salesCreatedAt: null,
    kaizenFilesId: null,
    originalHlsUrl: '',
    logoWatermarkHlsUrl: '',
    previewWartermarkMp4Url: '',
  };
};

type Props = {
  handleChangeMeta?: (file: any) => void;
  isEditing?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
  creativeId?: string | null;
  isContentTypeChanged?: boolean;
  isSelectedCreativeLoading?: boolean;
  setIsSelectedCreativeLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeEmbeddingVideoMeta?: (videoMeta: Partial<EmbeddingVideoMeta>) => Promise<void>;
  isOpen: boolean;
  contentType?: string[];
  needContentTypeLabel?: boolean;
  resetEmbeddingVideoMeta?: () => Promise<void>;
  resetExistingContentMeta?: () => void;
  needContentTypeSelectbox?: boolean;
  guideText?: string;
  viewDetailProduct?: (id: number) => void;
  newAddedProduct?: ProductItem;
  resetNewAddedProduct?: () => void;
  isContentPublished?: number[];
  isCreatedAtSales?: 0 | 1;
  pageProp?: number;
  showCreateLinkButton?: boolean;
};

export const SelectExistingContentsSearchArea: FC<Props> = props => {
  const {
    appStore: { userData, organizationId, getOrganizationTags },
  } = useStore();
  const { i18n, t } = useTranslation();
  const creativeSearchBaseUrl = process.env.REACT_APP_CREATIVE_API_BASE_URL || '';
  const [myQuery, setMyQuery] = useState<Partial<QueryCacheType>>({});
  const [creatives, setCreatives] = useState<ICreativeListItem[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const {
    state: {
      searchFormInputs: { offset, limit },
    },
    methods: {
      searchFormInputs: { changeOffset },
    },
  } = useCreativeSearchContext();

  const {
    data,
    isLoadingMoreVideos,
    apiErrors: { fetchCreativeList },
    setLocale,
    setAdditionalQuery,
    fetchCreativesByIds,
  } = useCreativeSearch({
    baseUrl: new URL(creativeSearchBaseUrl),
    staticQuery: {
      selectedOrganizations: [organizationId],
      services: ['sales', 'ad'],
      limit: LIMIT_PER_PAGE,
      businessRegion: [],
      contentTypes: props.contentType
        ? props.contentType
        : myQuery.contentTypes
        ? myQuery.contentTypes
        : ['*'],
      isPublishedAtSales: props.isContentPublished || [],
      isCreatedAtSales: props.isCreatedAtSales,
    },
    breakpointForMobile: 0, // 全ての幅でPC用デザインを使用する
    targetStreamingUrl: 'originalHlsUrl',
    loadingImageSrc: ImgWaitToUpload,
  });

  const [selectedCreative, setSelectedCreative] = useState<ICreativeListItem>();

  const [, setIsCreativeSearchLoading] = useState<boolean>(true);
  const [isCreateLinkDrawerOpen, setCreatelinkDrawerOpen] = useState<boolean>(false);
  const [linkContentData, setLinkContentData] = useState<LinkContentData>({
    content_type: 'product_contents',
  });

  const toggleCreateLinkDrawer = () => setCreatelinkDrawerOpen(!isCreateLinkDrawerOpen);

  const handleClickCreateLinkButton = (contentId: number) => {
    toggleCreateLinkDrawer();
    setLinkContentData({ content_type: 'product_contents', product_content_id: contentId });
  };

  const { CreativeListCardFooter } = useCreativeListCardFooter({
    setSelectedCreative,
    handleClickCreateLinkButton,
    ...props,
  });

  const [currentTagIds, setCurrentTagIds] = useState<number[]>([]);

  const [tags, setTags] = useState<Tag[]>([]);

  const [currentContentTypeIds, setCurrentContentTypeIds] = useState<number[]>([]);

  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState(props.pageProp || 1);

  const createCompoundQueryValue = (tags: Tag[]): QueryCacheType['organizationTag'] => {
    const values: { type: 'and' | 'or'; values: string[] }[] = [];
    categoryNames.forEach(category => {
      const selectedTags = tags.filter(tag => tag.category_name === category);
      if (selectedTags.length < 1) return;

      const value = selectedTags.reduce<{ type: 'or'; values: string[] }>(
        (acc, cur) => {
          return { ...acc, values: [...acc.values, `${cur.category_name}:${cur.name}`] };
        },
        { type: 'or', values: [] }
      );

      values.push(value);
    });

    return values.length < 1
      ? []
      : {
          type: 'and',
          values: values,
        };
  };

  const onSelectTags = (tags: Tag[]) => {
    setIsCreativeSearchLoading(true);
    if (tags.length > 0 || currentTagIds.length > 0) {
      setCurrentTagIds(tags.map(t => t.id));
    }
    const compoundQuery = createCompoundQueryValue(tags);
    setMyQuery({ organizationTag: compoundQuery });
  };

  const sleep = setTimeout(() => {
    if (data || currentTagIds) setIsCreativeSearchLoading(false);

    if (fetchCreativeList) setIsCreativeSearchLoading(true);
  }, 400);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (data && !isInitialized) {
      setIsInitialized(true);
    }
  }, [data]);

  // アンケート画面でキーワード検索しコンテンツを選択して一覧画面に戻った時に初期条件の一覧を表示するためにsetMyQuery
  // そのため、初期レンダリングで３回りクエストが発行される
  useEffect(() => {
    changeOffset(0);
    if (isInitialized) {
      setMyQuery({ organizationTag: undefined, query: undefined });
    }
    if (props.isOpen) {
      setCurrentTagIds([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.pageProp) {
      onPageChange(props.pageProp);
    }
  }, [props.pageProp]);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [window.innerWidth]);

  useEffect(() => {
    //eslint-disable-next-line
    sleep;
    return () => {
      clearTimeout(sleep);
    };
  }, [data, fetchCreativeList, currentTagIds, currentPage]);

  useEffect(() => {
    getOrganizationTags(organizationId);
  }, [organizationId]);

  useEffect(() => {
    const organization = userData.organizations.find(item => item.id === organizationId);
    if (!organization?.tags) return;

    setTags(organization?.tags);

    const categoryNms: string[] = [];
    organization?.tags.forEach(tag => {
      if (!categoryNms.includes(tag.category_name)) categoryNms.push(tag.category_name);
    });

    setCategoryNames(categoryNms);
  }, [userData, organizationId]);

  useEffect(() => {
    setAdditionalQuery(myQuery);
  }, [myQuery]);

  useEffect(() => {
    setLocale(i18n.language === 'en-US' ? 'en' : 'ja');
  }, [i18n.language]);

  useEffect(() => {
    setSelectedCreative(undefined);
    if (!props.isOpen) return;

    if (!props.creativeId) {
      props.setIsSelectedCreativeLoading && props.setIsSelectedCreativeLoading(false);
      return;
    }

    const f = async () => {
      if (!props.creativeId) return;

      const creatives = await fetchCreativesByIds([props.creativeId]);
      creatives && setSelectedCreative(creatives[0]);
    };
    f();
  }, [props.creativeId, props.isOpen]);

  useEffect(() => {
    props.isContentTypeChanged && setSelectedCreative(undefined);
  }, [props.isContentTypeChanged]);

  useEffect(() => {
    if (typeof offset !== 'number' || typeof limit !== 'number') return;

    setCurrentPage(offset / limit + 1);
  }, [offset, limit]);

  useEffect(() => {
    if (props.newAddedProduct?.id !== -1 && props.newAddedProduct && currentPage === 1) {
      const newAddedCreative = data?.creatives.find(
        c => c.salesProductContentId === props.newAddedProduct?.id
      );
      if (!newAddedCreative) {
        if (!data?.creatives) return;

        const dummyCreative = createDummyCreativeItem(props.newAddedProduct);
        setCreatives([dummyCreative].concat(data!.creatives));
      } else {
        setCreatives(data?.creatives || []);
        props.resetNewAddedProduct && props.resetNewAddedProduct();
      }
    } else {
      setCreatives(data?.creatives || []);
    }
  }, [data?.creatives, props.newAddedProduct]);

  const onPageChange = (page: number | undefined) => {
    if (typeof page !== 'number') return;

    changeOffset(LIMIT_PER_PAGE * (page - 1));
  };

  const search = () => {
    setIsCreativeSearchLoading(true);
  };

  const selectCreative = async (creative: Creative) => {
    props.viewDetailProduct &&
      creative.salesProductContentId &&
      props.viewDetailProduct(creative.salesProductContentId);

    setSelectedCreative(creative);
    props.handleChangeMeta &&
      props.handleChangeMeta({
        type: 'existing_contents',
        creativeId: creative.id,
        creative_type: creative.contentType,
      });
    if (props.modalRef?.current) {
      props.modalRef?.current.scrollTo(0, 0);
    }
    props.handleChangeEmbeddingVideoMeta &&
      props.handleChangeEmbeddingVideoMeta({ creativeId: creative.id });
  };

  const contentTypesForSelect = [
    {
      id: 1,
      name: t('creativeSearch.contentType.video'),
      category_name: t('creativeSearch.fileType'),
    },
    {
      id: 2,
      name: t('creativeSearch.contentType.image'),
      category_name: t('creativeSearch.fileType'),
    },
    {
      id: 3,
      name: t('creativeSearch.contentType.pdf'),
      category_name: t('creativeSearch.fileType'),
    },
  ];

  const onSelectContentTypes = (tags: Tag[]) => {
    setIsCreativeSearchLoading(true);
    if (tags.length > 0 || currentContentTypeIds.length > 0) {
      setCurrentContentTypeIds(tags.map(t => t.id));
    }
    const query: string[] = [];
    tags.forEach(tag => {
      switch (tag.id) {
        case 1:
          query.push('video/*');
          break;
        case 2:
          query.push('image/*');
          break;
        case 3:
          query.push('application/pdf');
          break;
      }
    });

    setMyQuery({ contentTypes: query.length > 0 ? query : ['*'] });
  };

  const kaizenPlayerCss: CSSProperties = {
    width: '100%',
    height: '100%',
    minWidth: 'unset',
    borderRadius: '6px 6px 0 0',
  };

  if (!data)
    return (
      <LoaderWrapper>
        <img className="spinner" src={Spinner} alt="spinner" />
      </LoaderWrapper>
    );

  if (selectedCreative)
    return (
      <SelectedCreativePreview
        selectedCreative={selectedCreative}
        setSelectedCreative={setSelectedCreative}
        isEditing={props.isEditing}
        setIsSelectedCreativeLoading={props.setIsSelectedCreativeLoading}
        resetEmbeddingVideoMeta={props.resetEmbeddingVideoMeta}
        resetExistingContentMeta={props.resetExistingContentMeta}
      />
    );

  if (!props.isSelectedCreativeLoading && props.isOpen)
    return (
      <Wrapper>
        {props.guideText && <Guide>{props.guideText}</Guide>}
        <KeywordForm searchWithCondition={search} overrideCss={overrideKeywordFormCss} />
        <SelectboxWrapper>
          {props.needContentTypeSelectbox && (
            <SelectboxInnerWrapper>
              <TagSelects
                tags={contentTypesForSelect}
                currentTagIds={currentContentTypeIds}
                onSelectTags={onSelectContentTypes}
              />
            </SelectboxInnerWrapper>
          )}
          <TagSelects tags={tags} currentTagIds={currentTagIds} onSelectTags={onSelectTags} />
        </SelectboxWrapper>
        {isLoadingMoreVideos ? (
          <LoaderWrapper>
            <img className="spinner" src={Spinner} alt="spinner" />
          </LoaderWrapper>
        ) : creatives.length > 0 ? (
          <>
            <CreativeList
              cols={3}
              isSelectable={false}
              creatives={creatives}
              displayProperties={['title', 'cardFooter']}
              cardFooterElement={CreativeListCardFooter}
              needOpenPreviewModal={false}
              needOpenTagEditModal={false}
              onClickCard={selectCreative}
              overrideCss={overrideCreativeListCss(windowWidth, 3)}
              needVideoControl={true}
              kaizenPlayerCss={kaizenPlayerCss}
            />
            {!!props.showCreateLinkButton && (
              <CreateShareableLinkDrawer
                isAddLinkModalOpen={isCreateLinkDrawerOpen}
                toggleAddLinkDrawer={toggleCreateLinkDrawer}
                linkContentData={linkContentData}
              />
            )}
          </>
        ) : (
          <NoContentsImgWrapper>
            <img src={NoContentsImg} />
          </NoContentsImgWrapper>
        )}
        {typeof offset === 'number' && typeof limit === 'number' && data?.count > 0 && (
          <Pagination
            perPage={LIMIT_PER_PAGE}
            total={data.count}
            currentPage={currentPage}
            handlePaginate={({ page }) => {
              onPageChange(page);
              setIsCreativeSearchLoading(true);
            }}
            modalRef={props.modalRef}
          />
        )}
      </Wrapper>
    );

  return null;
};

// styled
const Wrapper = styled('div')({
  marginBottom: 72,
});

const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '211px',
  marginTop: '15px',
  '.spinner': {
    width: '40px',
    height: '40px',
  },
});
const SelectboxWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '20px',
});
const SelectboxInnerWrapper = styled('div')({
  marginRight: '10px',
});
const Guide = styled('p')({
  fontSize: 12,
});
const NoContentsImgWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const buttonKeyframes = keyframes`
    0% {
      background: radial-gradient(circle at center, rgba( 255, 125 , 125, 0 ) 0%, #fff 0%, #fff 100%);
    }
    100% {
      color: #fff;
      background: radial-gradient(circle at center, ${palette.greenPrimary} 99%, #fff 100%, #fff 100%);
    }
`;

const overrideKeywordFormCss: IKeywordFormProps['overrideCss'] = () => ({
  '&.csui-kf-wrapper': {
    margin: '15px 0 20px 0',
  },
  '.csui-kf-wrapper-pc': {
    display: 'flex',
  },
  '.csui-kf-input-wrapper-pc': {
    width: '100%',
    border: 'none',
    background: palette.whitePrimary,
  },
  '.csui-kf-input-pc': {
    height: '40px',
    margin: '0',
    color: palette.darkMedium3,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    WebkitTextFillColor: palette.darkMedium3,
    WebkitOpacity: '1',
    padding: '12px 10px',
    border: `1px solid ${palette.grayMedium1}`,
    borderRadius: '6px',
    background: palette.whitePrimary,
    '&::placeholder': {
      color: palette.grayMedium2,
      WebkitTextFillColor: palette.grayMedium2,
    },
  },
  '.csui-kf-search-icon-pc': {
    marginLeft: '10px',
    border: 'none',
    borderRadius: '6px',
    height: '40px',
    backgroundColor: palette.deepBluePrimary,
    color: palette.whitePrimary,
    '&:hover': {
      background: palette.deepBlueSecondary,
      color: palette.grayMedium9,
    },
    svg: {
      stroke: palette.whitePrimary,
    },
    '&:active': {
      animation: `${buttonKeyframes} 1s`,
      background: `${palette.greenPrimary}`,
      color: palette.whitePrimary,
      boxShadow: 'none',
    },
  },
});

const overrideCreativeListCss = (
  windowWidth: number,
  cols: number
): ICreativeListProps['overrideCss'] => {
  return {
    wrapper:
      windowWidth <= 480
        ? () => ({
            '.csui-cl-inner-wrapper': {
              '.csui-cl-card-wrapper': {
                width: 'calc(50% - 8px)',
              },
              '.csui-cl-card-wrapper:nth-of-type(2n)': {
                marginRight: 0,
              },
            },
          })
        : () => ({
            '.csui-cl-inner-wrapper': {
              '.csui-cl-card-wrapper:nth-of-type(3n)': {
                marginRight: 0,
              },
            },
          }),
    card: () => ({
      '&.csui-cl-card-wrapper': {
        cursor: 'pointer',
        margin: '0 2% 20px 0',
        width: '32%',
        maxWidth: 'none',
        border: 'transparent 2px solid',
        '&:active': {
          border: '2px solid ' + palette.greenMedium2,
          borderRadius: 6,
          boxSizing: 'border-box',
        },
      },
      '.csui-cl-card-pc-wrapper': {
        borderRadius: '6px',
        boxShadow: 'none',
        border: '1px solid ' + palette.borderInput,
      },
      '.csui-cl-card-pc-footer-wrapper': {
        paddingTop: 5,
      },
      '.csui-cl-card-pc-footer-content': {
        width: '100%',
      },
      '.csui-cl-card-pc-video-title': {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '10px',
        cursor: 'pointer',
        margin: '0',
        height: '40px',
      },
      '.csui-cl-card-pc-video-time': {
        fontSize: '14px',
        lineHeight: '18px',
        color: palette.grayMedium4,
      },
      '.csui-cl-card-pc-video-screen': {
        borderRadius: '6px 6px 0 0',
        backgroundColor: palette.blackPrimary,
      },
      '.csui-cl-card-pc-video-thumbnail-wrapper': {
        backgroundColor: palette.blackPrimary,
      },
      '.csui-cl-card-pc-video-thumbnail': {
        borderRadius: '6px 6px 0 0',
      },
      '.csui-cl-card-pc-creative-wrapper': {
        height: windowWidth >= 480 ? '' : '80px',
        maxHeight: windowWidth >= 480 ? '' : '80px',
        borderRadius: '6px 6px 0 0',
        backgroundColor: palette.blackPrimary,
      },
      '.csui-cl-card-pc-img-screen-wrapper': {
        borderRadius: '6px 6px 0 0',
      },
      '.csui-cl-card-pc-img-screen-img': {
        objectFit: 'contain',
        backgroundColor: palette.blackPrimary,
      },
      '.csui-cl-card-pc-view': {
        display: 'block',
        borderTop: '1px solid ' + palette.borderInput,
        backgroundColor: palette.grayMedium12,
      },
    }),
  };
};
