import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import _Dropdown from 'views/components/compound/Dropdown';

export const PRODUCT_CONTENT_FILTER_OPTIONS = ['all', 'other', 'videos'];

export const typesFromFilterOptions = (type: string) => {
  if (type === 'other') return ['pdfs', 'images'];
  if (type === 'all') return ['videos', 'pdfs', 'images'];
  return [type];
};

type Props = {
  currentType: string;
  handleFilter: (typeParams: string) => void;
  className?: string;
};

type FilterType = 'all' | 'videos' | 'other';

type FilterLabel =
  | 'productContent.filterType.all'
  | 'productContent.filterType.video'
  | 'productContent.filterType.other';

type FilterOption = {
  label: FilterLabel;
  value: FilterType;
};

const filterOptions: FilterOption[] = [
  {
    label: 'productContent.filterType.all',
    value: 'all',
  },
  {
    label: 'productContent.filterType.video',
    value: 'videos',
  },
  {
    label: 'productContent.filterType.other',
    value: 'other',
  },
];

const FileTypeButton: FC<Props> = ({ currentType, handleFilter, className }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      value={currentType}
      options={filterOptions}
      onChange={handleFilter}
      dropdownLabel={t('common.filter')}
      width={124}
      className={className}
    />
  );
};

export default FileTypeButton;

const Dropdown = styled(_Dropdown)({
  marginRight: 10,
});
