import React, { FC } from 'react';
import styled from '@emotion/styled';
import { palette } from 'entity/createTheme';
import Check from 'assets/images/ic_green_check.svg';
import { SVGIcon as _SVGIcon } from '..';

type Props = {
  handleClickItem: () => void;
  isActive?: boolean;
  className?: string;
};

const DropdownItemComponent: FC<Props> = ({ children, isActive, handleClickItem, className }) => {
  return (
    <DropdownItem className={className} onClick={handleClickItem} isActive={isActive}>
      {children}
      <SVGIcon src={Check} isActive={isActive} asImg={true} />
    </DropdownItem>
  );
};

export default DropdownItemComponent;

const DropdownItem = styled('div')<{ isActive?: boolean }>(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px',
  margin: 0,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  '&:hover': {
    cursor: 'pointer',
    background: palette.grayMedium5,
  },
}));

const SVGIcon = styled(_SVGIcon)<{ isActive: boolean | undefined }>(props => ({
  display: props.isActive ? 'block' : 'none',
  alt: 'isSelected',
  position: 'absolute',
  right: '15px',
  height: '20px',
}));
