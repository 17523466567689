import React, { FC, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { AnsweredQuestion } from 'types/App';
import { Textarea } from 'views/components/primitive';
import { bp, palette } from 'entity/createTheme';

type Props = {
  questionSetAnswers: AnsweredQuestion[];
  handleChangeNote?: (answerId: number, note: string) => void;
  isLinkCreator?: boolean;
};

const QuestionAnswerList: FC<Props> = ({ questionSetAnswers, handleChangeNote, isLinkCreator }) => {
  const { t } = useTranslation();
  const _tbodyRef = useRef<HTMLTableSectionElement>(null);
  const [heightBody, setHeightBody] = useState(0);

  useEffect(() => {
    if (_tbodyRef.current && !heightBody) {
      setHeightBody(_tbodyRef.current.clientHeight || 100);
    }
  }, [heightBody]);

  return (
    <AnswerList>
      {questionSetAnswers.map(answer => {
        const { id, note, body, answered_choices } = answer;
        function onChangeNoteResult(note: string) {
          if (handleChangeNote && answer && id) {
            handleChangeNote(id, note);
          }
        }
        return (
          <Answer key={id}>
            <QuestionContent>{body}</QuestionContent>
            <AnswerTableWrapper>
              <AnswerTable cellPadding="0" cellSpacing="0">
                <thead>
                  <TableRow>
                    <TableHeader>{t('hearingPlay.result.answerLabel')}</TableHeader>
                    <TableHeader>{t('hearingPlay.result.feedbackLabel')}</TableHeader>
                    <TableHeader isNoteColumn>{t('hearingPlay.result.noteLabel')}</TableHeader>
                  </TableRow>
                </thead>
                <tbody ref={_tbodyRef}>
                  {answered_choices.map((choice, index) => (
                    <TableRow key={choice.id}>
                      <TableContent>
                        {!choice.choice_id
                          ? `[${t('hearingPlay.result.otherAnswerLabel')}] ${choice.content}`
                          : choice.content}
                      </TableContent>
                      <TableContent>{choice.feedback}</TableContent>
                      {index === 0 && (
                        <TableContent isNoteColumn rowSpan={answered_choices.length}>
                          <Textarea
                            height={heightBody}
                            maxLength={200}
                            value={note || ''}
                            onChange={onChangeNoteResult}
                            disabled={!isLinkCreator}
                          />
                        </TableContent>
                      )}
                    </TableRow>
                  ))}
                </tbody>
              </AnswerTable>
              <NoteTable cellPadding="0" cellSpacing="0">
                <thead>
                  <TableRow>
                    <TableHeader>{t('hearingPlay.result.noteLabel')}</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  <TableRow>
                    <TableContent>
                      <Textarea
                        maxLength={200}
                        value={note || ''}
                        onChange={onChangeNoteResult}
                        disabled={!isLinkCreator}
                      />
                    </TableContent>
                  </TableRow>
                </tbody>
              </NoteTable>
            </AnswerTableWrapper>
          </Answer>
        );
      })}
    </AnswerList>
  );
};

export default QuestionAnswerList;

const AnswerList = styled('div')({});

const Answer = styled('div')({
  paddingTop: 20,
});

const QuestionContent = styled('p')({
  fontWeight: 'bold',
  marginBottom: 10,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem',
  [`@media ${bp.md}`]: {
    marginBottom: 8,
  },
});
//Style of Answer Table
const AnswerTableWrapper = styled('div')({
  '& .textarea-wrapper': {
    margin: 0,
    '& > .wrapper-area': {
      border: 'none',
      borderRadius: 0,
      textarea: {
        border: `1px solid ${palette.grayPrimary}`,
        borderRadius: 6,
        fontSize: '0.875rem',
        '&:focus': {
          borderColor: palette.grayPrimary,
        },
      },
      [`@media ${bp.md}`]: {
        height: 80,
      },
    },
  },
});

const AnswerTable = styled('table')({
  width: '100%',
});

const TableRow = styled('tr')({});

const TableHeader = styled('th')<{ isNoteColumn?: boolean }>(props => ({
  width: 'calc(100% / 3)',
  wordBreak: 'break-all',
  fontSize: '0.75rem',
  textAlign: 'left',
  backgroundColor: palette.grayMedium6,
  padding: '8px 20px',
  color: palette.darkMedium6,
  fontWeight: 'bold',
  borderLeft: `1px solid ${palette.grayPrimary}`,
  '&:first-of-type': {
    border: 'none',
  },
  [`@media ${bp.md}`]: {
    padding: '8px 16px',
    ...(props.isNoteColumn && {
      display: 'none',
    }),
  },
}));

const TableContent = styled('td')<{ isNoteColumn?: boolean }>(props => ({
  width: 'calc(100% / 3)',
  wordBreak: 'break-all',
  backgroundColor: palette.grayMedium1,
  padding: '10px 20px',
  fontSize: '0.875rem',
  borderLeft: `1px solid ${palette.grayPrimary}`,
  '&:first-of-type': {
    border: 'none',
  },
  [`@media ${bp.md}`]: {
    padding: '10px 16px',
    ...(props.isNoteColumn && {
      display: 'none',
    }),
  },
}));

const NoteTable = styled('table')({
  display: 'none',
  [`@media ${bp.md}`]: {
    display: 'inline-table',
    marginTop: 10,
    width: '100%',
  },
});
