import { LinkCustomFields, CustomFormField } from 'types/HearingLink';

export const customFormFields: CustomFormField[] = [
  {
    fieldName: 'company_name',
    label: 'common.companyName',
  },
  {
    fieldName: 'company_pic_name',
    label: 'common.name',
  },
  {
    fieldName: 'email',
    label: 'common.mailAddress',
  },
  {
    fieldName: 'phone_number',
    label: 'common.phoneNumber',
  },
];

export const defaultFormSetting: LinkCustomFields = {
  company_name: false,
  company_pic_name: false,
  email: false,
  phone_number: false,
};
