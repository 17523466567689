import styled from '@emotion/styled';
import { palette, bp } from 'entity/createTheme';

export function resizePadding(key: string | '', pc: number, sp: number) {
  return `
    padding${key ? `-${key}` : ''}: ${pc}px;
    @media ${bp.md} {
      padding${key ? `-${key}` : ''}: ${sp}px;
    }
  `;
}

function resizeMargin(key: string | '', pc: number, sp: number) {
  return `
  margin${key ? `-${key}` : ''}: ${pc}px;
  @media ${bp.md} {
    margin${key ? `-${key}` : ''}: ${sp}px;
  }
`;
}

function resizeFontsize(pc: number, sp: number) {
  return `
  font-size: ${pc}px;
  @media ${bp.md} {
    font-size: ${sp}px;
  }
`;
}

export function stylesCommon() {
  return `
  background: ${palette.whitePrimary};
  ${resizePadding('', 20, 16)};
  ${resizeMargin('bottom', 20, 16)};
  border-radius: 6px;
  `;
}

export const Title = styled('h3')`
  font-size: 16px;
  ${resizeMargin('bottom', 20, 16)};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 19px;
  color: ${palette.darkMedium2};
`;

export const WrapperInfo = styled('div')`
  ${stylesCommon()};
  box-shadow: ${palette.boxShadow} 0px 1px 9px;
  & > div {
    ${resizeMargin('bottom', 20, 16)};
    &:last-of-type {
      margin-bottom: 0;
    }
    label {
      font-size: 14px;
      color: ${palette.deepBlueLight};
      margin-bottom: 8px;
    }
    input {
      width: 100%;
      ${resizeFontsize(14, 16)};
    }
  }
`;

export const ProductWrapper = styled('div')`
  ${stylesCommon()};
  ${resizeMargin('bottom', 0, 0)};
  .list_prod {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 16px;
    &-item {
      margin-bottom: 20px;
    }
  }
`;

export const WrapperHearingSet = styled('div')`
  ${stylesCommon()};
  box-shadow: ${palette.boxShadow} 0px 1px 9px;
  .list_hearing {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 16px;

    &-item {
      margin-bottom: 20px;
    }
  }

  .btn-view {
    button {
      margin-left: auto;
      @media ${bp.md} {
        margin: 0 auto;
      }
    }
  }
`;

export const WrapperMemo = styled('div')`
  ${stylesCommon()};
  box-shadow: ${palette.boxShadow} 0px 1px 9px;
  .wrapper-area {
    height: 150px;
    @media ${bp.md} {
      height: 88px;
    }
  }
`;
