import { useStore } from 'hooks';
import React, { FC, Fragment, useEffect } from 'react';
import { HearingItem } from 'types/HearingSet';
import { getImgFromContent } from 'utility/helpers';
import ItemWithThumbnail from '../ItemWithThumbnail';
import RelatedVideos from './RelatedVideos';
import ResultQuestion from './ResultQuestion';

interface TabPanelProps {
  hearing: HearingItem;
}

const ResultTabContent: FC<TabPanelProps> = ({ hearing }) => {
  const {
    hearingPlayStore: {
      fetchAnswerRelatedVideos,
      tagRelatedVideos,
      updateUserAnswerNote,
      questionPlayList,
    },
  } = useStore();

  useEffect(() => {
    fetchAnswerRelatedVideos(hearing.id);
  }, [hearing.id]);

  return (
    <Fragment>
      {hearing.question_sets.map(questionSet => {
        const thumbnail = getImgFromContent(questionSet.content);
        return (
          <Fragment key={questionSet.id}>
            <ItemWithThumbnail src={thumbnail} title="" />
            {questionSet.questions.map((question, index) => {
              const questionPlayData = questionPlayList.filter(q => q.id === question.id)[0];
              return (
                <Fragment key={question.id}>
                  <ResultQuestion
                    questionPlayData={questionPlayData}
                    title={`${index + 1}/ ${question.body}`}
                    updateUserAnswerNote={updateUserAnswerNote}
                  />
                  <RelatedVideos question={questionPlayData} tagRelatedVideos={tagRelatedVideos} />
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ResultTabContent;
