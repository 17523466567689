import { Question as IQuestion } from 'types/HearingSet';
import { Choice, ChoiceViewType } from './Choice';

export type QuestionViewType = {
  id?: number;
  question_set_id?: number;
  body: string;
  choices: ChoiceViewType[];
  is_multiple_choice: boolean;
  has_other: boolean;
  can_delete?: boolean;
};

export class Question {
  private id: number;
  private question_set_id: number; //check if needed
  private body: string;
  private choices: Choice[];
  private is_multiple_choice: boolean;
  private has_other: boolean;
  private can_delete?: boolean;

  constructor(data: IQuestion) {
    this.id = data.id;
    this.question_set_id = data.question_set_id;
    this.body = data.body;
    this.choices = data.choices.map(choice => Choice.fromJSON(choice));
    this.is_multiple_choice = data.is_multiple_choice;
    this.has_other = data.has_other;
    this.can_delete = data.can_delete;
  }

  static isValid(data: any): data is IQuestion {
    return true;
  }

  static fromJSON(data: any): Question {
    if (!this.isValid(data)) throw Error('Invalid type of Question');
    return new Question(data);
  }

  toJSON(): QuestionViewType {
    return {
      id: this.id,
      question_set_id: this.question_set_id,
      body: this.body,
      choices: this.choices.map(choice => choice.toJSON()),
      is_multiple_choice: this.is_multiple_choice,
      has_other: this.has_other,
      can_delete: this.can_delete,
    };
  }
}
