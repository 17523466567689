import { action, observable } from 'mobx';
import { RootStore } from 'stores';
import settingApi, { ChannelType, ChannelListResponse } from 'services/Admin/SettingApi';
import { MessageProps } from 'types/App';
import i18n from 'i18n';

class SettingSlackStore {
  private readonly settingApi: settingApi;
  @observable rootStore: RootStore;
  @observable public loading = false;
  @observable public channels: ChannelType[] = [];

  constructor({ rootStore, settingApi }: { rootStore: RootStore; settingApi: settingApi }) {
    this.rootStore = rootStore;
    this.settingApi = settingApi;
  }

  @action.bound
  public async loadSlackIntegration() {
    this.loading = true;
    const {
      appStore: { organizationId },
    } = this.rootStore;
    try {
      const data = await this.settingApi.loadPersonalSlack(organizationId);
      return data.data;
    } catch (error: any) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.slackIntegrationError')} ${errorMessage}`,
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async assignSlackIntegration(code: string, parentOrganizationId: string | null) {
    this.loading = true;
    const {
      appStore: { organizationId },
    } = this.rootStore;
    try {
      await this.settingApi.updatePersonalSlack(code, parentOrganizationId || organizationId);
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.assignSuccess')}`,
        status: 'success',
      });
    } catch (error: any) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.slackIntegrationError')} ${errorMessage}`,
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async deleteSlackIntegration() {
    this.loading = true;
    const {
      appStore: { organizationId },
    } = this.rootStore;
    try {
      await this.settingApi.deletePersonalSlack(organizationId);
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.deleteSuccess')}`,
        status: 'success',
      });
    } catch (error: any) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.slackIntegrationError')} ${errorMessage}`,
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async loadSlackChannels() {
    this.loading = true;
    const {
      appStore: { organizationId },
    } = this.rootStore;
    try {
      let data: {
        data: ChannelListResponse;
      };
      let next = undefined;
      this.channels = [];
      do {
        data = await this.settingApi.listPersonalSlackChannels(organizationId, next);
        const { channels, response_metadata } = data.data;
        next = response_metadata.next_cursor;
        this.channels = [
          ...this.channels,
          ...channels.filter(x => {
            return x.is_archived === false && x.is_member === true;
          }),
        ];
      } while (next);
      return this.channels;
    } catch (error: any) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.slackIntegrationError')} ${errorMessage}`,
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public async assignSlackChannel(channel_id: string) {
    this.loading = true;
    const {
      appStore: { organizationId },
    } = this.rootStore;
    try {
      await this.settingApi.assignPersonalSlackChannel(organizationId, channel_id);
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.success')}`,
        status: 'success',
      });
    } catch (error: any) {
      const errorMessage = error.data?.errors?.join('; ') ?? '';
      this.pushFlashMessages({
        content: `${i18n.t('admin.setting.slack.messages.slackIntegrationError')} ${errorMessage}`,
        status: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  public pushFlashMessages(data: MessageProps) {
    const { appStore } = this.rootStore;
    appStore.handleFlashMessage(data);
  }
}

export default SettingSlackStore;
