import React, { FC, useState } from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { ConfirmModal, Drawer } from 'views/components/compound';
import _ItemWithThumbnail from 'views/components/compound/ItemWithThumbnail';
import { getImgFromContent } from 'utility/helpers';
import { InputText as _InputText } from 'views/components/primitive';
import { DisplayedTextList, ProductItemForPlaylist } from 'types/Playlist';

type Props = {
  isOpen: boolean;
  handleCloseEditTextDrawer: () => void;
  handleSaveDisplayedText: () => void;
  productContents: ProductItemForPlaylist[];
  displayedTextList: DisplayedTextList;
  handleChangeDisplayedText: (displayedText: string, contentId: number) => void;
  resetDisplayedTextList: () => void;
};

const EditPlaylistTextDrawer: FC<Props> = ({
  isOpen,
  handleCloseEditTextDrawer,
  handleSaveDisplayedText,
  productContents,
  displayedTextList,
  handleChangeDisplayedText,
  resetDisplayedTextList,
}) => {
  const { t } = useTranslation();
  const [isPlaylistChanged, setPlaylistChanged] = useState(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);

  const toggleConfirmModal = () => setOpenConfirmModal(!isOpenConfirmModal);

  const handleClickCancelButton = () => {
    if (isPlaylistChanged) {
      setOpenConfirmModal(true);
    } else {
      handleCloseEditTextDrawer();
    }
  };

  const handleConfirmCancel = () => {
    resetDisplayedTextList();
    setPlaylistChanged(false);
    handleCloseEditTextDrawer();
  };

  const handleSavePlaylist = () => {
    handleSaveDisplayedText();
    setPlaylistChanged(false);
  };

  return (
    <>
      <Drawer
        disabledAction={!isPlaylistChanged}
        isOpen={isOpen}
        onClose={handleClickCancelButton}
        title={t('admin.playlist.playlistSetting.drawerTitle')}
        onSave={handleSavePlaylist}
      >
        <Instruction>{t('admin.playlist.playlistSetting.instruction')}</Instruction>
        <Playlist>
          {productContents.map(content => {
            const thumbnail = getImgFromContent(content);

            const handleChangeText = (displayedText: string) => {
              handleChangeDisplayedText(displayedText, content.id);
              setPlaylistChanged(true);
            };

            const displayedText = displayedTextList[content.id];

            return (
              <PlaylistItem key={content.id}>
                <ItemWithThumbnail src={thumbnail} title={content.title} />
                <InputText
                  value={displayedText ?? content.title}
                  onChange={handleChangeText}
                  maxLength={80}
                />
              </PlaylistItem>
            );
          })}
        </Playlist>
      </Drawer>
      <ConfirmModal
        isModal={isOpenConfirmModal}
        toggleModal={toggleConfirmModal}
        handleConfirmModal={handleConfirmCancel}
      />
    </>
  );
};

export default EditPlaylistTextDrawer;

const Instruction = styled('p')({
  whiteSpace: 'pre-line',
  fontSize: '0.75rem',
  marginBottom: 16,
});

const Playlist = styled('ul')({
  listStyle: 'none',
});

const PlaylistItem = styled('li')({
  marginBottom: 8,
  display: 'flex',
});

const ItemWithThumbnail = styled(_ItemWithThumbnail)({
  width: '60%',
  marginRight: 8,
});

const InputText = styled(_InputText)({
  marginBottom: 0,
  width: '100%',
});
