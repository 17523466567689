import { useStore } from 'hooks';

export const useCurrentOrganization = () => {
  const {
    appStore: { userData, organizationId },
  } = useStore();
  const currentOrganization = userData.organizations.find(item => item.id === organizationId);

  return currentOrganization;
};
