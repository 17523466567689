import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WrapperInfo, Title } from './styles';
import { InputText } from 'views/components/primitive';
import { ClientSelect } from 'views/components/compound';
import { ErrorTypes } from 'types/common';
import { Appointment } from 'types/Appointment';
import { Client } from 'types/Client';

interface PropTypes {
  company: string;
  name: string;
  client?: Client;
  onChange: (data: Partial<Appointment>) => void;
  errors?: ErrorTypes;
  isShowDetail?: boolean;
  sfaIntegrated: boolean;
}

const AddInfo: FC<PropTypes> = ({
  name,
  company,
  client,
  onChange,
  errors,
  isShowDetail,
  sfaIntegrated,
}) => {
  const { t } = useTranslation();

  function handleChangeCompany(val: string) {
    onChange({ company_name: val });
  }

  function handleChangeName(val: string) {
    onChange({ company_pic_name: val });
  }

  function handleChangeSelectedClient(val?: Client) {
    onChange({ client: val });
  }

  return (
    <WrapperInfo>
      <Title>{t('common.infomation')}</Title>
      {sfaIntegrated ? (
        <ClientSelect
          label={t('common.companyName')}
          error={errors && errors.clientId}
          defaultClient={client}
          onChange={handleChangeSelectedClient}
          disabled={isShowDetail}
        />
      ) : (
        <InputText
          error={errors && errors.companyName}
          label={t('common.companyName')}
          value={company}
          onChange={handleChangeCompany}
          isShowDetail={isShowDetail}
          maxLength={255}
        />
      )}
      <InputText
        label={t('common.customerName')}
        value={name}
        onChange={handleChangeName}
        isShowDetail={isShowDetail}
        maxLength={40}
      />
    </WrapperInfo>
  );
};

export default AddInfo;
